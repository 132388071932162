import { Navigate, Route, Routes } from "react-router-dom";
import { CONTROLLERS_NO_SHOP_LINK, CONTROLLERS_SHOP_CHANGHE_LINK } from "../../../assets/utils/constants";
import { useContext } from "react";
import { PlatformLinksContext } from "../../../assets/contexts/platformLinksContext";
import ShopChange from "./ShopChange/ShopChange";
import NoShop from "./NoShop/NoShop";


function Controllers() {

    const { default_link } = useContext(PlatformLinksContext)
    console.log(default_link)
    return (
        <Routes>
            <Route index element={<Navigate to={default_link} />}></Route>
            <Route path={`/${CONTROLLERS_SHOP_CHANGHE_LINK}`}
                element={<ShopChange />}
            ></Route>
            <Route path={`/${CONTROLLERS_NO_SHOP_LINK}`}
                element={<NoShop />}
            ></Route>
        </Routes>
    );
}

export default Controllers