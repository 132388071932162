import { TickIcon } from '../../../assets/icons/controls/controls';
import './SelectCheckbox.css';

function SelectCheckbox({
    isSelected,
    toggleSelect,
    isColorInvert,
}) {
    return (
        <button className={`select-checkbox ${isSelected ? 'select-checkbox__selected' : ''} ${isColorInvert ? 'select-checkbox__inverted' : ''}`} type='button' onClick={(e)=>{
            e.preventDefault()
            e.stopPropagation()
            toggleSelect()
        }}>
            <TickIcon
                mainClassName={'select-checkbox__tick'}
                fillClassName={'select-checkbox__tick-fill'}
            />
        </button>
    );
}

export default SelectCheckbox