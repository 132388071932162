import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import PageHeading from "../../PageHeading/PageHeading";
import "./OrderPage.css";
import {
  parseApiError,
  parseRequestNumber,
} from "../../../../assets/utils/utils";
import { useContext, useEffect, useState } from "react";
import useWindowSize from "../../../../assets/hooks/useWindowSize";
import ItemForm from "../../ItemForm/ItemForm";
import {
  ORDERS_ITEM_LINKS,
  ORDERS_ITEM_MAIN_INFO_LINK,
  ORDERS_ITEM_PREVIOUS_LINK,
  ORDERS_ITEM_СOMPOSITION_LINK,
} from "../../../../assets/utils/constants";
import InternalLinks from "../../InternalLinks/InternalLinks";
import MainOrderInfo from "./MainOrderInfo/MainOrderInfo";
import OrderItems from "./OrderItems/OrderItems";
import PrevOrders from "./PrevOrders/PrevOrders";
import { UserContext } from "../../../../assets/contexts/userContext";
import mainApi from "../../../../assets/api/MainApi";
import useAutoDismissError from "../../../../assets/hooks/useAutoDismissError";
import { OrderConfigContext } from "../../../../assets/contexts/orderConfigContext";

function OrderPageMobileRoutePage({ title, goBackActive, children }) {
  const navigate = useNavigate();

  return (
    <div className="order-page__content">
      <PageHeading
        className={"order-page__heading"}
        title={title}
        goBack={
          goBackActive
            ? {
              onClick: () => {
                navigate(-1);
              },
            }
            : undefined
        }
      />
      <ItemForm containerClassName={"order-page__item-from-container"}>
        {children}
      </ItemForm>
    </div>
  );
}

function OrderPage() {
  const { width } = useWindowSize();
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, showError] = useAutoDismissError();
  // const location = useLocation()
  // const [actions, setActions] = useState([
  //     {
  //         name: 'Создать группу',
  //         path: `${location.pathname}/${PROMOCODES_CREATE_GROUP_LINK}`,
  //         isMainAction: true,
  //     },
  //     // {
  //     //     name: 'OnClick',
  //     //     onClick: () => { console.log('fddsfeee') },
  //     //     inactive: true,
  //     // },
  // ])

  const [orderItmes, setOrderItems] = useState([])


  const [clickedSide, setClickedSide] = useState("left");

  const [config, setConfig] = useState(null)
  useEffect(() => {
    if (!user) return;

    setIsPreloaderVisible(true);

    const shop_id = user.default_shop._id;

    // Function to recursively fetch order items
    function fetchOrderItemsRecursively({ last_id }) {
      return mainApi
        .getOrderItems({ shop_id, _id: id })
        .then((res) => {
          const orders = res.data;
          setOrderItems((prevItems) => last_id ? prevItems.concat(orders) : orders);

          if (res.is_more) {
            // Recursively fetch the next batch of order items
            return fetchOrderItemsRecursively({ last_id: orders[orders.length - 1]._id });
          }
        })
        .catch((err) => {
          if (err.statusCode === 403) {
            setOrderItems([]);
          }
          throw err; // Re-throw error to be caught in the final catch block
        });
    }

    mainApi
      .getExactOrder({ shop_id, _id: id })
      .then((res) => {
        setData(res);

        // Fetch order config in parallel
        const configPromise = mainApi.getOrderConfig({ shop_id })
          .then((configRes) => {
            setConfig(configRes);
          });

        // Fetch order items recursively
        const orderItemsPromise = fetchOrderItemsRecursively({ last_id: null });

        // Wait for both to complete
        return Promise.all([configPromise, orderItemsPromise]);
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setData(undefined);
          setConfig(null);
        }

        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);



  return (
    <>

      {width ? (
        width > 880 ? (
          <div className="order-page">
            <PageHeading
              className={"order-page__heading"}
              title={
                data?.request_number
                  ? `Заказ номер ${parseRequestNumber(data.request_number)}`
                  : ""
              }
            />
            <ItemForm containerClassName={"order-page__item-from-container"}>
              <div className="order-page__two-columns">
                <div
                  className="order-page__products-list"
                  onClick={() => {
                    setClickedSide("left");
                  }}
                >
                  <InternalLinks
                    links={ORDERS_ITEM_LINKS}
                    keyValue={"order-page__desctop"}
                  />
                </div>
                <div
                  className="order-page__products-form"
                  onClick={() => {
                    setClickedSide("right");
                  }}
                >
                  <Routes>
                    <Route
                      index
                      element={<Navigate to={ORDERS_ITEM_MAIN_INFO_LINK} />}
                    ></Route>
                    <Route
                      path={`/${ORDERS_ITEM_MAIN_INFO_LINK}`}
                      element={
                        // <TechnicalDomain />
                        // <>xsdsd</>
                        <OrderConfigContext.Provider value={config}>

                          <MainOrderInfo
                            setOrder={setData}
                            orderItmes={orderItmes}
                            {...{ data, isPreloaderVisible, error }}
                          />
                        </OrderConfigContext.Provider>
                      }
                    ></Route>
                    <Route
                      path={`/${ORDERS_ITEM_СOMPOSITION_LINK}`}
                      element={
                        // <CustomDomain />
                        <OrderItems order={data} />
                      }
                    ></Route>
                    <Route
                      path={`/${ORDERS_ITEM_PREVIOUS_LINK}`}
                      element={
                        // <CustomDomain />
                        <PrevOrders order={data} />
                      }
                    ></Route>
                  </Routes>
                </div>
              </div>
            </ItemForm>
          </div>
        ) : (
          <div className="order-page">
            <Routes>
              <Route
                index
                element={
                  <OrderPageMobileRoutePage
                    goBackActive={false}
                    title={
                      data?.request_number
                        ? `Заказ номер ${parseRequestNumber(
                          data.request_number
                        )}`
                        : ""
                    }
                  >
                    <InternalLinks
                      links={ORDERS_ITEM_LINKS}
                      keyValue={"order-page__mobile"}
                    />
                  </OrderPageMobileRoutePage>
                }
              ></Route>
              <Route
                path={`/${ORDERS_ITEM_MAIN_INFO_LINK}`}
                element={
                  <OrderPageMobileRoutePage
                    goBackActive={true}
                    title={"Основная информация"}
                  >
                    <OrderConfigContext.Provider value={config}>

                      <MainOrderInfo
                        setOrder={setData}
                        orderItmes={orderItmes}
                        {...{ data, isPreloaderVisible, error }}
                      />
                    </OrderConfigContext.Provider>
                  </OrderPageMobileRoutePage>
                }
              ></Route>
              <Route
                path={`/${ORDERS_ITEM_СOMPOSITION_LINK}`}
                element={
                  <OrderPageMobileRoutePage
                    goBackActive={true}
                    title={"Состав заказа"}
                  >
                    <OrderItems order={data} />
                  </OrderPageMobileRoutePage>
                }
              ></Route>
              <Route
                path={`/${ORDERS_ITEM_PREVIOUS_LINK}`}
                element={
                  <OrderPageMobileRoutePage
                    goBackActive={true}
                    title={"Предыдущие заказы"}
                  >
                    <PrevOrders order={data} />
                  </OrderPageMobileRoutePage>
                }
              ></Route>
            </Routes>
          </div>
        )
      ) : null}

    </>
  );
}

export default OrderPage;
