import moment from 'moment-timezone';
import GraphContainer from '../GraphContainer/GraphContainer';
import './StatisticsSells.css';
import { PercentageChange, getOrderWordForm, getProductWordForm, parsePrice } from '../../../../../assets/utils/utils';

function StatisticsSells({
    statistics,
    period_type, 
}) {

    const {
        data,
    } = statistics


    return (
        <div className='statistics-sells'>
            <div className='statistics-sells__container'>
                <h4 className='statistics-sells__title'>Заказы и продажи</h4>
                <div className='statistics-sells__graphs'>
                    <div className='statistics-sells__graph statistics-sells__graph_1'>
                        <GraphContainer
                            graphKeyName={'sells_open'}
                            title={'Новых'}
                            statistics_info={[
                                {
                                    title: `${data.new.count.current} ${getOrderWordForm(data.new.count.current)}`,
                                    change: `${PercentageChange({
                                        current: data.new.count.current,
                                        previous: data.new.count.previous,
                                    })}`,
                                },
                                {
                                    title: `${data.new.items.current} ${getProductWordForm(data.new.items.current)}`,
                                    change: `${PercentageChange({
                                        current: data.new.items.current,
                                        previous: data.new.items.previous,
                                    })}`,
                                },
                                {
                                    title: `на сумму ${parsePrice(data.new.sum.current, 'RUB')}`,
                                    change: `${PercentageChange({
                                        current: data.new.sum.current,
                                        previous: data.new.sum.previous,
                                    })}`,
                                },
                            ]}
                            graph_data={statistics.graph.new.map((item)=>{
                                return {
                                    ...item,
                                    name: item.utc_datetime,
                                }
                            })}
                            period_type={period_type}
                        />
                    </div>
                    <div className='statistics-sells__graph statistics-sells__graph_2'>
                        <GraphContainer
                            graphKeyName={'sells_places'}
                            title={'Завершено'}
                            statistics_info={[
                                {
                                    title: `${data.done.count.current} ${getOrderWordForm(data.done.count.current)}`,
                                    change: `${PercentageChange({
                                        current: data.done.count.current,
                                        previous: data.done.count.previous,
                                    })}`,
                                },
                                {
                                    title: `${data.done.items.current} ${getProductWordForm(data.done.items.current)}`,
                                    change: `${PercentageChange({
                                        current: data.done.items.current,
                                        previous: data.done.items.previous,
                                    })}`,
                                },
                                {
                                    title: `на сумму ${parsePrice(data.done.sum.current, 'RUB')}`,
                                    change: `${PercentageChange({
                                        current: data.done.sum.current,
                                        previous: data.done.sum.previous,
                                    })}`,
                                },
                            ]}
                            graph_data={statistics.graph.done.map((item)=>{
                                return {
                                    ...item,
                                    name: item.utc_datetime,
                                }
                            })}
                            period_type={period_type}
                        />
                    </div>
                    <div className='statistics-sells__graph statistics-sells__graph_3'>
                        <GraphContainer
                            graphKeyName={'sells_recived'}
                            title={'Отменено'}
                            statistics_info={[
                                {
                                    title: `${data.cancelled.count.current} ${getOrderWordForm(data.cancelled.count.current)}`,
                                    change: `${PercentageChange({
                                        current: data.cancelled.count.current,
                                        previous: data.cancelled.count.previous,
                                    })}`,
                                },
                                {
                                    title: `${data.cancelled.items.current} ${getProductWordForm(data.cancelled.items.current)}`,
                                    change: `${PercentageChange({
                                        current: data.cancelled.items.current,
                                        previous: data.cancelled.items.previous,
                                    })}`,
                                },
                                {
                                    title: `на сумму ${parsePrice(data.cancelled.sum.current, 'RUB')}`,
                                    change: `${PercentageChange({
                                        current: data.cancelled.sum.current,
                                        previous: data.cancelled.sum.previous,
                                    })}`,
                                },
                            ]}
                            graph_data={statistics.graph.cancelled.map((item)=>{
                                return {
                                    ...item,
                                    name: item.utc_datetime,
                                }
                            })}
                            period_type={period_type}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StatisticsSells