import { Route, Routes } from "react-router-dom";
import "./Drafts.css";
import DraftsMain from "./DraftsMain/DraftsMain";
import { CATALOG_DRAFTS_CREATE_ITEM_LINK } from "../../../../assets/utils/constants";
import DraftsCreate from "./DraftsCreate/DraftsCreate";

function Drafts() {
  return (
    <div className="drafts">
      <Routes>
        <Route index element={<DraftsMain />} />
        <Route
          path={`${CATALOG_DRAFTS_CREATE_ITEM_LINK}`}
          element={<DraftsCreate />}
        />
        <Route
          path={`*`}
          element={<DraftsMain />}
        />
      </Routes>
    </div>
  );
}

export default Drafts;
