const WEBSITE_SOCIAL = {
    type: "website",
    title: "Веб-сайт",
}
const INSTAGRAM_SOCIAL = {
    type: "instagram",
    title: "Instagram",
}

const VKONTAKTE_SOCIAL = {
    type: "vkontakte",
    title: "ВКонтакте",
}

const FACEBOOK_SOCIAL = {
    type: "facebook",
    title: "Facebook",
}

const YOUTUBE_SOCIAL = {
    type: "youtube",
    title: "YouTube",
}

const TIKTOK_SOCIAL = {
    type: "tiktok",
    title: "TikTok",
}

const ODNOKLASSNIKI_SOCIAL = {
    type: "odnoklassniki",
    title: "Одноклассники",
}

const TWITTER_SOCIAL = {
    type: "twitter",
    title: "Twitter (X)",
}

const WHATSAPP_SOCIAL = {
    type: "whatsapp",
    title: "WhatsApp",
}

const TELEGRAM_SOCIAL = {
    type: "telegram",
    title: "Telegram",
}

const VIBER_SOCIAL = {
    type: "viber",
    title: "Viber",
}

const LINKEDIN_SOCIAL = {
    type: "linkedin",
    title: "LinkedIn",
}

const TWITCH_SOCIAL = {
    type: "twitch",
    title: "Twitch",
}

const DZEN_SOCIAL = {
    type: "dzen",
    title: "Дзен",
}

const VCRU_SOCIAL = {
    type: "vcru",
    title: "VC.ru",
}

const DISCORD_SOCIAL = {
    type: "discord",
    title: "Discord",
}

const YANDEX_MARKET_SOCIAL = {
    type: "yandex-market",
    title: "Яндекс.Маркет",
}

const WILDBERRIES_SOCIAL = {
    type: "wildberries",
    title: "Wildberries",
}

const OZON_SOCIAL = {
    type: "ozon",
    title: "Ozon",
}


export const DEFAULT_SOCIAL_VALUES = [
    WEBSITE_SOCIAL,
    INSTAGRAM_SOCIAL,
    VKONTAKTE_SOCIAL,
    FACEBOOK_SOCIAL,
    YOUTUBE_SOCIAL,
    TIKTOK_SOCIAL,
    ODNOKLASSNIKI_SOCIAL,
    TWITTER_SOCIAL,
    WHATSAPP_SOCIAL,
    TELEGRAM_SOCIAL,
    VIBER_SOCIAL,
    LINKEDIN_SOCIAL,
    TWITCH_SOCIAL,
    DZEN_SOCIAL,
    VCRU_SOCIAL,
    DISCORD_SOCIAL,
    YANDEX_MARKET_SOCIAL,
    WILDBERRIES_SOCIAL,
    OZON_SOCIAL,
]


export const DEFAULT_SOCIAL_VALUES_MAP = DEFAULT_SOCIAL_VALUES.reduce((acc, social) => {
    acc[social.type] = social;
    return acc;
}, {})

console.log(DEFAULT_SOCIAL_VALUES_MAP)