import { useContext, useEffect, useState } from "react";
import "./Tokens.css";
import PageHeading from "../../PageHeading/PageHeading";
import ItemForm from "../../ItemForm/ItemForm";
import ConstructorBlock from "../ConstructorBlock/ConstructorBlock";
import PlatformInput from "../../PlatformInput/PlatformInput";
import ColorInput from "../../ColorInput/ColorInput";
import {
  DEFAULT_COLORS_NEUTRAL_SET,
  DEFAULT_COLORS_PRIMARY_SET,
  DEFAULT_COLORS_SECONDARY_SET,
  DEFAULT_COLORS_SEMANTIC_MINUS_SET,
  DEFAULT_COLORS_SEMANTIC_PLUS_SET,
  DEFAULT_COLOR_WEBSITE_BACKGROUND_SET,
  FONT_WEIGHT_TYPES,
  FONT_WEIGHT_TYPE_BOLD,
  FONT_WEIGHT_TYPE_NORMAL,
  FONT_WEIGHT_TYPE_SEMIBOLD,
  ROUNDIND_TYPES,
  ROUNDIND_TYPE_NONE,
  ROUNDIND_TYPE_SMALL,
  ROUNDING_MAP,
} from "../../../../assets/utils/constructorConstants";
import { ERROR_REQUIRED_FIELD } from "../../../../assets/utils/constants";
import mainApi from "../../../../assets/api/MainApi";
import { UserContext } from "../../../../assets/contexts/userContext";
import PreloaderBox from "../../../PreloaderBox/PreloaderBox";
import { deepEqual, parseApiError, transformColorValuesToApi, transformFontStylesToApi } from "../../../../assets/utils/utils";
import useAutoDismissError from "../../../../assets/hooks/useAutoDismissError";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";

const initialValues = {
  font_styles: null,
  font_family: { title: "Inter", value: "inter" },
  colors: null,
  rounding: ROUNDIND_TYPE_SMALL,
  // padding:
};

const initialValuesValidity = {
  h1_size: {
    errorMessage: "",
    validState: true,
  },
  h2_size: {
    errorMessage: "",
    validState: true,
  },
  h3_size: {
    errorMessage: "",
    validState: true,
  },
  h4_size: {
    errorMessage: "",
    validState: true,
  },
  text_size: {
    errorMessage: "",
    validState: true,
  },
  caption_size: {
    errorMessage: "",
    validState: true,
  },
};

function Tokens() {
  const { user } = useContext(UserContext)
  const [fonts, setFonts] = useState(null)
  const [values, setValues] = useState(null);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const [error, showError] = useAutoDismissError()
  const [preloaders, setPreloaders] = useState({
    font: true,
    tokens: true,
    save: false,
  })
  const actions = [
    {
      name: "Отменить все изменения",
      onClick: handleCancel,
      inactive: !isValuesChanged,
      id: "tokens__cancel-btn",
    },
    {
      name: "Сохранить",
      onClick: handleSave,
      isMainAction: true,
      inactive: isSubmitDisabled,
      isPreloaderVisible: preloaders.save,
      id: "tokens__save-btn",
    },
  ];



  useEffect(() => {
    if (!user) return
    setPreloaders(prevValue => ({
      ...prevValue,
      font: true,
      tokens: true,
    }))
    mainApi.getConstructorFonts({
      shop_id: user.default_shop._id,
    })
      .then((res) => {
        console.log(res)
        setFonts(res.data)
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setFonts(null)
        }

        console.log(err)
        showError(parseApiError(err))
      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          font: false,
        }))
      })
    mainApi.getConstructorTokens({
      shop_id: user.default_shop._id,
    })
      .then((res) => {
        console.log(res)
        // setFonts(res.data)
        // setValues(prevValue => ({
        //   ...prevValue,
        //   font_family: res.data[0]
        // }))
        const { font_data, colour_data, shape_type } = res
        const { font, fonts_list } = font_data
        const font_styles = [
          {
            type: "h1",
            title: "H1",
            weight: FONT_WEIGHT_TYPE_BOLD,
            size: "40 px",
          },
          {
            type: "h2",
            title: "H2",
            weight: FONT_WEIGHT_TYPE_SEMIBOLD,
            size: "20 px",
          },
          {
            type: "h3",
            title: "H3",
            weight: FONT_WEIGHT_TYPE_BOLD,
            size: "16 px",
          },
          {
            type: "h4",
            title: "H4",
            weight: FONT_WEIGHT_TYPE_BOLD,
            size: "12 px",
          },
          {
            type: "text",
            title: "Текст",
            weight: FONT_WEIGHT_TYPE_NORMAL,
            size: "16 px",
          },
          {
            type: "caption",
            title: "Кэпшн",
            weight: FONT_WEIGHT_TYPE_NORMAL,
            size: "12 px",
          },
        ].map((item) => {
          return {
            ...item,
            weight: fonts_list[item.type].weight,
            size: fonts_list[item.type].size + ' px',
          }
        })

        const colors = [
          {
            type: "primary",
            title: "Primary",
            values: DEFAULT_COLORS_PRIMARY_SET,
          },
          {
            type: "secondary",
            title: "Secondary",
            values: DEFAULT_COLORS_SECONDARY_SET,
          },
          {
            type: "semantic_plus",
            title: "Semantic +",
            values: DEFAULT_COLORS_SEMANTIC_PLUS_SET,
          },
          {
            type: "semantic_minus",
            title: "Semantic −",
            values: DEFAULT_COLORS_SEMANTIC_MINUS_SET,
          },
          {
            type: "neutral",
            title: "Neutral",
            values: DEFAULT_COLORS_NEUTRAL_SET,
          },
          {
            type: "background",
            title: "Website background",
            values: DEFAULT_COLOR_WEBSITE_BACKGROUND_SET,
          },
        ].map((item) => {
          if (item.type === 'background') {
            return {
              ...item,
              values: item.values.map((item2) => {
                return {
                  ...item2,
                  value: colour_data[item.type],
                }
              })
            }
          }
          else {
            return {
              ...item,
              values: item.values.map((item2) => {
                return {
                  ...item2,
                  value: colour_data[item.type][item2.type],
                }
                // { type: "active", value: "#1488FF" },
                // { type: "selection", value: "#7ABCFF" },
                // { type: "background", value: "#E0F0FF" },
              })
            }
          }
        })


        setValues(prevValue => ({
          ...prevValue,
          font_family: font,
          rounding: shape_type ? ROUNDING_MAP[shape_type] : ROUNDIND_TYPE_NONE,
          font_styles: font_styles,
          colors: colors,
          initial_font_styles: font_styles,
          initial_colors: colors,
          initial_font_family: font,
          initial_rounding: shape_type ? ROUNDING_MAP[shape_type] : ROUNDIND_TYPE_NONE,
        }))
        console.log(values)
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setValues(null)
        }
        console.log(err)
      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          tokens: false,
        }))
      })
  }, [user])
  // In your component...
  // useEffect(() => {
  //   if (!values) return;

  //   const isChanged = !deepEqual(values, {
  //     font_family: values.initial_font_family,
  //     rounding: values.initial_rounding,
  //     font_styles: values.initial_font_styles,
  //     colors: values.initial_colors,
  //   });
  //   console.log(isChanged)

  //   setIsValuesChanged(isChanged);

  //   // Rest of your validation logic...
  // }, [values, valuesValidity]);
  useEffect(() => {
    if (!values) return;

    // const isFontStylesChanged = values.font_styles.some(
    //   (item, i) =>
    //     item.weight.value !== values.initial_font_styles[i].weight.value ||
    //     Number(item.size.replace(/[^.\d]/g, "")) !==
    //     Number(values.initial_font_styles[i].size.replace(/[^.\d]/g, ""))
    // );
    // const isFontFamilyChanged =
    //   values.font_family.value !== values.initial_font_family.value;

    // const isColorsChanged = values.colors.some(
    //   (item, i) => item.values.value !== values.initial_colors[i].values.value
    // );
    // const isRoundingChanged =
    //   values.rounding.value !== values.initial_rounding.value;

    // const isChanged =
    //   isFontStylesChanged ||
    //   isFontFamilyChanged ||
    //   isColorsChanged ||
    //   isRoundingChanged;
    // console.log({
    //   isFontStylesChanged,
    //   isFontFamilyChanged,
    //   isColorsChanged,
    //   isRoundingChanged,
    // })
    // setIsValuesChanged(isChanged);

    const initialData = {
      font_family: values.initial_font_family,
      rounding: values.initial_rounding,
      font_styles: values.initial_font_styles,
      colors: values.initial_colors,
    };
    const onchangeData = {
      font_family: values.font_family,
      rounding: values.rounding,
      font_styles: values.font_styles,
      colors: values.colors,
    };

    console.log("Current Values:", onchangeData);
    console.log("Initial Values:", initialData);

    const isChanged = !deepEqual(onchangeData, initialData);
    console.log("Is Changed:", isChanged);

    setIsValuesChanged(isChanged);
    setIsSubmitDisabled(
      !(
        // valuesValidity.h1_size.validState &&
        // valuesValidity.h2_size.validState &&
        // valuesValidity.h3_size.validState &&
        // valuesValidity.h4_size.validState &&
        // valuesValidity.text_size.validState &&
        // valuesValidity.caption_size.validState &&
        isChanged
      )
    );
  }, [values, valuesValidity]);

  function handleChange(e) {
    const input = e.target;
    const value = input.value;
    const name = input.name;

    switch (true) {
      case name.includes("weight"): {
        const type = input.type;
        const key = input.key;
        setValues((prevValue) => ({
          ...prevValue,
          font_styles: prevValue.font_styles.map((item) => {
            if (item.type !== type) return item;
            return {
              ...item,
              [key]: value,
            };
          }),
        }));
        break;
      }

      case name.includes("size"): {
        const type = input.type;
        const key = input.key;
        const inputValue = value.replace(/\D/g, "");
        setValues((prevValue) => ({
          ...prevValue,
          font_styles: prevValue.font_styles.map((item) => {
            if (item.type !== type) return item;
            return {
              ...item,
              [key]: inputValue,
            };
          }),
        }));

        const errorText = !Boolean(inputValue) ? ERROR_REQUIRED_FIELD : "";
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name.replace(/-/g, "_")]: {
            errorMessage: errorText,
            validState: Boolean(inputValue),
          },
        }));
        break;
      }

      case name.includes("color"): {
        const type = input.type;
        const key = input.key;
        setValues((prevValue) => ({
          ...prevValue,
          colors: prevValue.colors.map((item) => {
            if (item.type !== type) return item;
            return {
              ...item,
              values: item.values.map((color) => {
                if (color.type !== key) return color;
                return {
                  ...color,
                  value: value,
                };
              }),
            };
          }),
        }));
        break;
      }

      default:
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;
    }
  }

  function handleFocus(e) {
    const input = e.target;
    const value = input.value;
    const name = input.name;

    switch (true) {
      case name.includes("size"): {
        const type = input.type;
        const key = input.key;
        const inputValue = value.replace(/[^.\d]/g, "");
        setValues((prevValue) => ({
          ...prevValue,
          font_styles: prevValue.font_styles.map((item) => {
            if (item.type !== type) return item;
            return {
              ...item,
              [key]: inputValue,
            };
          }),
        }));
        break;
      }

      default:
        break;
    }
  }

  function handleBlur(e) {
    const input = e.target;
    const value = input.value;
    const name = input.name;

    switch (true) {
      case name.includes("size"): {
        const type = input.type;
        const key = input.key;
        const inputValue = value.replace(/[^.\d]/g, "");
        setValues((prevValue) => ({
          ...prevValue,
          font_styles: prevValue.font_styles.map((item) => {
            if (item.type !== type) return item;
            return {
              ...item,
              [key]: Boolean(inputValue) ? inputValue + " px" : inputValue,
            };
          }),
        }));

        const errorText = !Boolean(inputValue) ? ERROR_REQUIRED_FIELD : "";
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name.replace(/-/g, "_")]: {
            errorMessage: errorText,
            validState: Boolean(inputValue),
          },
        }));
        break;
      }

      default:
        break;
    }
  }

  function handleSave() {
    console.log("save", values);
    setPreloaders(prevValue => ({
      ...prevValue,
      save: true,
    }))
    mainApi.setConstructorTokens({
      shop_id: user.default_shop._id,
      shape_type: values.rounding.value === 'none' ? null : values.rounding.value,
      font_data: {
        fonts_list: transformFontStylesToApi(values.font_styles),
        font_id: values.font_family._id,
      },
      colour_data: transformColorValuesToApi(values.colors),
    })
      .then((res) => {
        console.log(res)
        setValues(prevValue => ({
          ...prevValue,
          initial_font_family: prevValue.font_family,
          initial_rounding: prevValue.rounding,
          initial_font_styles: prevValue.font_styles,
          initial_colors: prevValue.colors,
        }));
      })
      .catch((err) => {
        console.log(err)
        showError(parseApiError(err))
      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          save: false,
        }))
      })
  }

  function handleCancel() {
    console.log("cancel");
    setValues(prevValue => ({
      ...prevValue,
      font_family: prevValue.initial_font_family,
      rounding: prevValue.initial_rounding,
      font_styles: prevValue.initial_font_styles,
      colors: prevValue.initial_colors,
    }));
    setValuesValidity(initialValuesValidity);
  }

  return (
    <div className="tokens">
      <PageHeading
        className={"tokens__heading"}
        title={"Токены"}
        actions={actions}
      />
      <ErrorMessage error={error} />
      {values && !(preloaders.font && preloaders.tokens) ?
        <ItemForm containerClassName={"tokens__container"}>
          <form className="tokens__form" onSubmit={handleSave}>
            <ConstructorBlock title={"Начертание шрифта"}>
              {values?.font_styles?.length > 0
                ? values.font_styles.map((item) => (
                  <li
                    className="constructor-block__item tokens__fonts-block"
                    key={item.type}
                  >
                    <p className="constructor-block__subtitle">{item.title}</p>
                    <PlatformInput
                      name={`${item.type}-weight`}
                      value={item.weight}
                      placeholder={""}
                      label={""}
                      isSelect={true}
                      selected_text_value={item.weight.name}
                      maxLength={100}
                      handleChange={(e) => {
                        handleChange({
                          target: {
                            value: e.target.value,
                            name: `${item.type}-weight`,
                            type: item.type,
                            key: "weight",
                          },
                        });
                      }}
                      selectList={values.font_family.weights}
                      selectDifferenceKey={"_id"}
                      selectTextValueKey={"name"}
                    />
                    <PlatformInput
                      name={`${item.type}-size`}
                      value={item.size}
                      placeholder={""}
                      label={""}
                      show_eror_color_only={true}
                      error={
                        valuesValidity[`${item.type}_size`]?.errorMessage
                          ? valuesValidity[`${item.type}_size`].errorMessage
                          : ""
                      }
                      handleChange={(e) => {
                        handleChange({
                          target: {
                            value: e.target.value,
                            name: `${item.type}-size`,
                            type: item.type,
                            key: "size",
                          },
                        });
                      }}
                      onFocus={(e) => {
                        handleFocus({
                          target: {
                            value: e.target.value,
                            name: `${item.type}-size`,
                            type: item.type,
                            key: "size",
                          },
                        });
                      }}
                      onBlur={(e) => {
                        handleBlur({
                          target: {
                            value: e.target.value,
                            name: `${item.type}-size`,
                            type: item.type,
                            key: "size",
                          },
                        });
                      }}
                    />
                  </li>
                ))
                : null}
            </ConstructorBlock>

            <div className="constructor-block__separator-line" />

            <ConstructorBlock>
              <li className="constructor-block__item tokens__colors-block">
                <p className="constructor-block__title">Цвета</p>
                <p className="constructor-block__title">Active</p>
                <p className="constructor-block__title">Selection</p>
                <p className="constructor-block__title">Background</p>
              </li>
              {values?.colors?.length > 0
                ? values.colors.map((item, i) => (
                  <li
                    className={`constructor-block__item tokens__colors-block ${item.values?.length === 1
                      ? "tokens__colors-block_type_single"
                      : ""
                      }`}
                    key={item.type + i}
                  >
                    <p className="constructor-block__subtitle">{item.title}</p>
                    {item.values?.length > 0
                      ? item.values.map((color, i2) => (
                        <ColorInput
                          currentColor={color.value}
                          key={`${item.type}-${color.type}-${i2}`}
                          handleChange={(value) => {
                            handleChange({
                              target: {
                                value: value,
                                name: `color-${item.type}-${color.type}`,
                                type: item.type,
                                key: color.type,
                              },
                            });
                          }}
                        />
                      ))
                      : null}
                  </li>
                ))
                : null}
            </ConstructorBlock>

            <div className="constructor-block__separator-line constructor-block__separator-line_type_long" />

            <ConstructorBlock title={"Шрифт"}>
              <PlatformInput
                name={"font_family"}
                value={values.font_family}
                placeholder={""}
                label={""}
                isSelect={true}
                selected_text_value={values.font_family.name}
                maxLength={100}
                handleChange={handleChange}
                selectList={fonts}
                selectDifferenceKey={"_id"}
                selectTextValueKey={"name"}
              />
            </ConstructorBlock>

            <div className="constructor-block__separator-line" />
            {console.log(values.rounding)}
            <ConstructorBlock title={"Скругление элементов"}>
              <PlatformInput
                name={"rounding"}
                value={values.rounding}
                placeholder={""}
                label={""}
                isSelect={true}
                selected_text_value={values.rounding.title}
                maxLength={100}
                handleChange={handleChange}
                selectList={ROUNDIND_TYPES}
                selectDifferenceKey={"value"}
                selectTextValueKey={"title"}
              />
            </ConstructorBlock>

            {/* <div className="constructor-block__separator-line constructor-block__separator-line_type_long" /> */}
            {/* <ConstructorBlock
           title={"Отступы между элементами"}
         >
           <PlatformInput
             name={"padding"}
             value={values.padding}
             placeholder={""}
             label={""}
             isSelect={true}
             selected_text_value={values.padding.title}
             maxLength={100}
             handleChange={handleChange}
             selectList={PADDING_TYPES}
             selectDifferenceKey={"value"}
             selectTextValueKey={"title"}
           />
         </ConstructorBlock> */}
          </form>
        </ItemForm>

        : <PreloaderBox />}

    </div>
  );
}

export default Tokens;
