export function ProductPlaceholderIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={100}
            height={108}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 100 108"
        >
            <path
                d="M50.025 51.121L3.549 24.568c.722-.662 1.642-1.307 2.76-1.936L41.055 2.68C44.11.893 47.1 0 50.025 0c2.891 0 5.865.893 8.92 2.68l34.796 19.952c1.084.629 1.988 1.274 2.71 1.936L50.025 51.121zM46.624 108a1.526 1.526 0 01-.493-.199 6.294 6.294 0 01-.444-.248L7.047 85.417c-2.266-1.323-4.008-2.829-5.223-4.516C.608 79.18 0 76.516 0 72.91V34.246c0-1.522.131-2.78.394-3.772l46.23 26.603V108zm6.752 0V57.077l46.23-26.603c.263.993.394 2.25.394 3.772V72.91c0 3.606-.608 6.27-1.824 7.99-1.182 1.688-2.924 3.194-5.224 4.517l-38.59 22.136c-.165.1-.329.182-.493.248-.164.1-.329.166-.493.199z"
                fill="#505A72"
                className={fillClassName}
            />
        </svg>
    );
}

export function CategoriesInputCrossIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={10}
            height={10}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 10 10"
        >
            <path
                d="M1.475 10L0 8.525 3.525 5 0 1.475 1.475 0 5 3.525 8.525 0 10 1.475 6.475 5 10 8.525 8.525 10 5 6.475 1.475 10z"
                fill="#505A72"
                className={fillClassName}
            />
        </svg>
    );
}