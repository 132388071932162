import { CartIcon } from "../../../../../assets/icons/constructor/constructor";
import {
  PRICE_BUTTON_TEXT_TYPE,
  PRICE_LOCATION_APART_TYPE,
} from "../../../../../assets/utils/constructorConstants";
import "./ExampleCard.css";

function ExampleCard({ data, isBig }) {
  return isBig ? null
  // (
  //   <div
  //     className="example-card example-card_type_wide"
  //     style={{
  //       width: `calc((150% / ${data.find((value) => value.type === "grid_columns").value.value
  //         }) * 2)`,
  //     }}
  //   >
  //     <div className="example-card__container example-card__container_type_wide">
  //       <div
  //         className="example-card__img"
  //         style={{
  //           borderRadius: `${data
  //             .find((value) => value.type === "image")
  //             .data.find((value) => value.type === "radius").value
  //             }px`,
  //         }}
  //       />
  //       <div className="example-card__content">
  //         {data.map((item) =>
  //           item.type !== "image" ? (
  //             <div
  //               className={`example-card__content-box ${!item.is_visible ? "example-card__content-box_hidden" : ""
  //                 }`}
  //               key={item.type}
  //             >
  //               {item.type === "title" ? (
  //                 <p className="example-card__title">{item.value}</p>
  //               ) : item.type === "description" && item.value ? (
  //                 <p className="example-card__text">{item.value}</p>
  //               ) : item.type === "price" ? (
  //                 <div className="example-card__price">
  //                   {item.data.find((value) => value.type === "price_location")
  //                     .value.value === PRICE_LOCATION_APART_TYPE.value ? (
  //                     <div className="example-card__price-block">
  //                       <p className="example-card__price-text">3131 ₽</p>
  //                       {item.data.find((value) => value.type === "button_type")
  //                         .value.value === PRICE_BUTTON_TEXT_TYPE.value ? (
  //                         <button
  //                           className="example-card__price-btn example-card__price-btn_type_apart example-card__price-btn_type_text"
  //                           type="button"
  //                         >
  //                           Купить
  //                         </button>
  //                       ) : (
  //                         <button
  //                           className="example-card__price-btn example-card__price-btn_type_apart example-card__price-btn_type_icon"
  //                           type="button"
  //                           aria-label="Купить"
  //                         >

  //                           <CartIcon
  //                             mainClassName="example-card__price-btn-icon"
  //                             fillClassName="example-card__price-btn-icon-fill"
  //                           />
  //                           Купить
  //                         </button>
  //                       )}
  //                     </div>
  //                   ) : item.data.find((value) => value.type === "button_type")
  //                     .value.value === PRICE_BUTTON_TEXT_TYPE.value ? (
  //                     <button className="example-card__price-btn" type="button">
  //                       <p className="example-card__price-text">3131 ₽</p>
  //                       Купить
  //                     </button>
  //                   ) : (
  //                     <button
  //                       className="example-card__price-btn example-card__price-btn_type_icon"
  //                       type="button"
  //                       aria-label="Купить"
  //                     >
  //                       <p className="example-card__price-text">3131 ₽</p>
  //                       <div className="example-card__price-btn_type_apart">
  //                         <CartIcon
  //                           mainClassName="example-card__price-btn-icon"
  //                           fillClassName="example-card__price-btn-icon-fill"
  //                         />
  //                         Купить
  //                       </div>

  //                     </button>
  //                   )}
  //                 </div>
  //               ) : null}
  //             </div>
  //           ) : null
  //         )}
  //       </div>
  //     </div>
  //   </div>
  // ) 
  : (
    <div
      className="example-card"
      style={{
        width: `calc(150% / ${data.find((value) => value.type === "grid_columns").value.value
          })`,
      }}
    >
      <div className="example-card__container">
        {data.map((item) => (
          <div
            className={`example-card__content-box ${!item.is_visible ? "example-card__content-box_hidden" : ""
              }`}
            key={item.type}
          >
            {item.type === "title" ? (
              <p className="example-card__title">{item.value}</p>
            ) : item.type === "image" ? (
              <div
                className="example-card__img"
                style={{
                  aspectRatio: item.data.find(
                    (value) => value.type === "aspect_ratio"
                  ).value.value,
                  borderRadius: `${item.data.find((value) => value.type === "radius").value
                    }px`,
                }}
              />
            ) : item.type === "description" && item.value ? (
              <p className="example-card__text">{item.value}</p>
            ) : item.type === "price" ? (
              <div className="example-card__price">
                {item.data.find((value) => value.type === "price_location")
                  .value.value === PRICE_LOCATION_APART_TYPE.value ? (
                  <div className="example-card__price-block">
                    <p className="example-card__price-text">3131 ₽</p>
                    {item.data.find((value) => value.type === "button_type")
                      .value.value === PRICE_BUTTON_TEXT_TYPE.value ? (
                      <button
                        className="example-card__price-btn example-card__price-btn_type_apart example-card__price-btn_type_text"
                        type="button"
                      >
                        Купить
                      </button>
                    ) : (
                      <button
                        className="example-card__price-btn example-card__price-btn_type_apart example-card__price-btn_type_icon"
                        type="button"
                        aria-label="Купить"
                      >

                        <CartIcon
                          mainClassName="example-card__price-btn-icon"
                          fillClassName="example-card__price-btn-icon-fill"
                        />
                        Купить
                      </button>
                    )}
                  </div>
                ) : item.data.find((value) => value.type === "button_type")
                  .value.value === PRICE_BUTTON_TEXT_TYPE.value ? (
                  <button className="example-card__price-btn" type="button">
                    <p className="example-card__price-text">3131 ₽</p>
                    Купить
                  </button>
                ) : (
                  <button
                    className="example-card__price-btn example-card__price-btn_type_icon"
                    type="button"
                    aria-label="Купить"
                  >
                    <p className="example-card__price-text">3131 ₽</p>
                    <div className="example-card__price-btn_type_apart">
                      <CartIcon
                        mainClassName="example-card__price-btn-icon"
                        fillClassName="example-card__price-btn-icon-fill"
                      />
                      Купить
                    </div>

                  </button>
                )}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExampleCard;
