import MiniPreloader from '../MiniPreloader/MiniPreloader';
import './PreloaderBox.css';
 
function PreloaderBox({
    isContrast,
}) {
  return (
    <div className='preloader-box'>
       <MiniPreloader isLinkColor={isContrast} />
    </div>
  );
}
 
export default PreloaderBox