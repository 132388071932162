import { useContext, useEffect, useRef, useState } from 'react';
import './SimpleSubsctiptionCard.css';
import { Card1, Card1Mobile, Card2, Card2Mobile, Card3, Card3Mobile } from '../../../../../../assets/icons/subscribe/subscribe';
import { Link, useNavigate } from 'react-router-dom';
import { parseApiError, parsePrice } from '../../../../../../assets/utils/utils';
import mainApi from '../../../../../../assets/api/MainApi';
import { UserContext } from '../../../../../../assets/contexts/userContext';
import { LK_MAIN_URL, PLATFORM_MAIN_LINK, SUBSCRIPTIONS_MAIN_LINK, SUBSCRIPTIONS_PLATFROM_LINK } from '../../../../../../assets/utils/constants';
import moment from 'moment-timezone';
import useAutoDismissError from '../../../../../../assets/hooks/useAutoDismissError';
import SubmitActionPopup from '../../../../SubmitActionPopup/SubmitActionPopup';
import ChangePaymentMethodPopup from '../../../../../PaymentMethods/ChangePaymentMethodPopup/ChangePaymentMethodPopup';

function SimpleSubsctiptionCard({
    subscriptions,
    current_subscription,
}) {

    const { user } = useContext(UserContext)
    const { default_shop } = user
    const [paymentMethods, setPaymentMethods] = useState(null)
    const [currentSubscription, setCurrentSubscription] = useState(null)
    const navigate = useNavigate()

    const [personal, setPersonal] = useState({
        submit_type: '',
    });
    const [selected, setSelected] = useState(null)
    const [preloaders, setPreloaders] = useState({
        get_current_subscription: true,
        submit_delete: false,
    })

    useEffect(() => {
        if (!current_subscription) {
            setPreloaders(prevValue => ({
                ...prevValue,
                get_current_subscription: false,
            }))
            return
        }
        setPreloaders(prevValue => ({
            ...prevValue,
            get_current_subscription: true,
        }))
        mainApi.getShopSubscrtiption({
            shop_id: default_shop._id,
        })
            .then((res) => {
                console.log(res)
                setCurrentSubscription(res.subscriptions.main)
                setSelected(res.subscriptions.main)
                if (!res.subscriptions.main.payment_id) {
                    mainApi.getAllPaymentMethods()
                        .then((res) => {
                            console.log(res.data)
                            setPaymentMethods(res.data)
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    get_current_subscription: false,
                }))
            })
    }, [current_subscription])



    const CARD = [
        {
            title: 'Ежемесячная<br> оплата',
            description: subscriptions.find((item) => item.int_type === 0).initial_price?.only_once && !subscriptions.find((item) => item.int_type === 0).was_used ? 'Первый платеж <br>со скидкой 50%' :  'Доступ ко всем разделам на платформе',
            type: 'monthly',
            data: subscriptions.find((item) => item.int_type === 0),
        },
        {
            title: 'Оплата за год<br>со скидкой до 10%',
            description: 'Получите бесплатный месяц работы интернет-магазина',
            type: 'year',
            data: subscriptions.find((item) => item.int_type === 1),
        },
        {
            title: 'Тариф Polza',
            is_main_polza_tariff: true,
            description: 'За вас все сделает персональный менеджер',
            custom_number: 'VIP',
            type: 'twoyear',
            data: {
                price: {
                    unit_amount: '18000',
                    currency: 'rub',
                },
                title: '',
                "recurring": {
                    "interval": "days",
                    "count": 30
                },
            },
        },
    ]

    function handleSelectType({ item, name }) {
        setPersonal(prevValue => ({
            ...prevValue,
            [name]: item,
        }))
        setSelected(item)
    }

    function getIconByType({ iconType, strokeClassName, mainClassName }) {
        if (!iconType) return
        switch (iconType) {
            case 'monthly':
                return Card1({ strokeClassName, mainClassName })
            case 'year':
                return Card2({ strokeClassName, mainClassName });
            case 'twoyear':
                return Card3({ strokeClassName, mainClassName });
            default:
                break;
        }
    }
    function getIconByTypeMobile({ iconType, strokeClassName, mainClassName }) {
        if (!iconType) return
        switch (iconType) {
            case 'monthly':
                return Card1Mobile({ strokeClassName, mainClassName })
            case 'year':
                return Card2Mobile({ strokeClassName, mainClassName });
            case 'twoyear':
                return Card3Mobile({ strokeClassName, mainClassName });
            default:
                break;
        }
    }
    const [itemToSubscribe, setItemToSubscribe] = useState(null)
    function handleSubscribe(item) {
        console.log(item.data._id)
        const default_shop = user.default_shop

        if (paymentMethods && paymentMethods.length > 0) {
            console.log(item)
            setItemToSubscribe(item)
            handleOpenChangePaymentMethodPopup()
            return
        }
        // if (default_shop.subscriptions && default_shop.subscriptions.main && moment().diff(moment(default_shop.subscriptions.main.valid_until + '+00:00'), 'seconds') <= 0) return
        mainApi.subscribeMain({
            shop_id: default_shop._id,
            _id: item.data._id,
            payment_id: currentSubscription ? currentSubscription.payment_id : null,
            return_url: `${LK_MAIN_URL}${PLATFORM_MAIN_LINK}`
        })
            .then((res) => {
                console.log(res)
                if (res.url) {
                    window.location.href = res.url
                } else {
                    setCurrentSubscription(prevValue => ({
                        ...prevValue,
                        after_end_id: item.data._id,
                    }))
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    console.log(currentSubscription)


    const [popups, setPopups] = useState({
        delete_approve: false,
    })

    const [deleteError, showDeleteError] = useAutoDismissError()
    function handleDeleteApprove() {
        setPopups(prevValue => ({
            ...prevValue,
            delete_approve: true,
        }))
    }



    function handleCloseSubmitCancel() {
        setPopups(prevValue => ({
            ...prevValue,
            delete_approve: false,
        }))
    }

    function handleCancelSubscribe() {
        setPreloaders(prevValue => ({
            ...prevValue,
            submit_delete: true,
        }))
        console.log(currentSubscription)
        const default_shop = user.default_shop
        // if (default_shop.subscriptions && default_shop.subscriptions.main && moment().diff(moment(default_shop.subscriptions.main.valid_until + '+00:00'), 'seconds') <= 0) return
        mainApi.subscribeMain({
            shop_id: default_shop._id,
            // _id: item._id,
            payment_id: null,
            return_url: window.location.href
        })
            .then((res) => {
                console.log(res)
                handleCloseSubmitCancel()
                setCurrentSubscription(prevValue => ({
                    ...prevValue,
                    after_end_id: null,
                    payment_id: null,
                }))
            })
            .catch((err) => {
                showDeleteError(parseApiError(showDeleteError))
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    submit_delete: false,
                }))
            })
    }

    function handleOpenChangePaymentMethodPopup() {
        setPopups(prevValue => ({
            ...prevValue,
            change_payment_method: true,
        }))
    }

    function handlePopupClose({ name }) {
        setPopups(prevValue => ({
            ...prevValue,
            [name]: false,
        }))
    }
    function handleSelectActivePaymentMethod(item) {
        console.log(item)
        mainApi.subscribeMain({
            shop_id: default_shop._id,
            _id: itemToSubscribe.data._id,
            payment_id: item._id,
            return_url: window.location.href
        })
            .then((res) => {
                console.log(res)
                console.log(currentSubscription)
                setCurrentSubscription(prevValue => ({
                    ...prevValue,
                    payment_method: item,
                    payment_id: item._id,
                    after_end_id: itemToSubscribe.data._id
                }))

                handlePopupClose({ name: 'change_payment_method' })
            })
            .catch((err) => {
                // showDeleteError(parseApiError(showDeleteError))
                console.log(err)
            })
    }



    return (

        <>

            {currentSubscription && !(!currentSubscription.after_end_id || !currentSubscription.payment_id) ?
                <button className='subscription__cancel-subscription' type='button' onClick={handleDeleteApprove}>
                    Отменить подписку
                </button>
                : null}
            {paymentMethods && paymentMethods.length > 0 ?

                <ChangePaymentMethodPopup
                    isOpen={popups.change_payment_method}
                    current_payment_method_id={currentSubscription.payment_id}
                    handleSelectActivePaymentMethod={handleSelectActivePaymentMethod}
                    handleClose={handlePopupClose}
                    popupName={'change_payment_method'}

                >

                </ChangePaymentMethodPopup>
                : null}
            <SubmitActionPopup
                isOpen={popups.delete_approve}
                text={`Вы уверены что хотите отменить подписку?`}
                submitError={deleteError}
                cencelText={'Оставить'}
                submitText={'Отменить'}
                hendleClose={handleCloseSubmitCancel}
                hendleSubmit={handleCancelSubscribe}
                isSubmitPreloader={preloaders.submit_delete}
            />
            <div className='simple-subscribe-card-wixs__box'>

                {!preloaders.get_current_subscription ?
                    CARD.map((item, i) => {
                        return (
                            <div className='simple-subscribe-card-wix__box'>
                                <div className={`simple-subscribe-card-wix
                   ${item.data._id && item.data._id === selected?.data?._id ? 'simple-subscribe-card-wix_choose' : ''} ${item.is_main_polza_tariff ? 'simple-subscribe-card-wix_polza' : ''}`}
                                    key={`personal-info__select-input_SubmitType_${i}`}
                                    type='button'
                                    onClick={() => {
                                        if (item.is_main_polza_tariff) {
                                            navigate(`${PLATFORM_MAIN_LINK}/${SUBSCRIPTIONS_MAIN_LINK}/${SUBSCRIPTIONS_PLATFROM_LINK}`)
                                            return
                                        }
                                        handleSelectType({ item, name: 'submit_type' })
                                    }}>
                                    <div className='simple-subscribe-card-wix__icon-box'>

                                        {getIconByType({
                                            iconType: item.type,
                                            mainClassName: 'simple-subscribe-card-wix__size',
                                            strokeClassName: `simple-subscribe-card-wix__icon ${item?.data?._id === selected?.data?._id ? 'simple-subscribe-card-wix__icon_selected' : ''}`
                                        })}

                                        {getIconByTypeMobile({
                                            iconType: item.type,
                                            mainClassName: 'simple-subscribe-card-wix__size_mobile',
                                            strokeClassName: `simple-subscribe-card-wix__icon ${item?.data?._id === selected?.data?._id ? 'simple-subscribe-card-wix__icon_selected' : ''}`
                                        })}

                                        <p className='simple-subscribe-card-wix__text simple-subscribe-card-wix__title simple-subscribe-card-wix__title_mobile' dangerouslySetInnerHTML={{
                                            __html: item.title,
                                        }} />
                                        <p className='simple-subscribe-card-wix__text simple-subscribe-card-wix__number'>
                                            {item.custom_number ? item.custom_number : `0${i + 1}`}
                                        </p>
                                    </div>
                                    <p className='simple-subscribe-card-wix__text simple-subscribe-card-wix__title simple-subscribe-card-wix__title_laptop'
                                        dangerouslySetInnerHTML={{
                                            __html: item.title,
                                        }} />

                                    {item.data?.initial_price?.only_once && !item.data?.was_used ?
                                        <div className='simple-subscribe-card-wix__price-box'>
                                            <p className='simple-subscribe-card-wix__text simple-subscribe-card-wix__discounted-price'>
                                                {parsePrice(item.data.price.unit_amount, item.data.price.currency)}
                                            </p>
                                            <p className='simple-subscribe-card-wix__text simple-subscribe-card-wix__price'>
                                                {parsePrice(item.data.initial_price.unit_amount, item.data.initial_price.currency)}  <span className='simple-subscribe-card-wix__text simple-subscribe-card-wix__price-period'>{item.data.recurring ? `/ ${item.data.recurring.count === 30 ? 'месяц' : item.data.recurring.count === 365 ? 'год' : ''}` : null}</span>
                                            </p>
                                        </div>

                                        :

                                        <p className='simple-subscribe-card-wix__text simple-subscribe-card-wix__price'>
                                            {parsePrice(item.data.price.unit_amount, item.data.price.currency)} <span className='simple-subscribe-card-wix__text simple-subscribe-card-wix__price-period'>{item.data.recurring ? `/ ${item.data.recurring.count === 30 ? 'месяц' : item.data.recurring.count === 365 ? 'год' : ''}` : null}</span>
                                        </p>
                                    }


                                    <p className='simple-subscribe-card-wix__text simple-subscribe-card-wix__desc' dangerouslySetInnerHTML={{
                                        __html: item.description,
                                    }}>
                                    </p>
                                    <button className='simple-subscribe-card-wix__text simple-subscribe-card-wix__button'>
                                        {item.is_main_polza_tariff ? 'Улучшить' : currentSubscription ? item.data._id === currentSubscription.data._id ? item.data._id === currentSubscription.after_end_id ? (!currentSubscription.after_end_id || !currentSubscription.payment_id) ? `Активна до ${moment(currentSubscription.valid_until + '+00:00').format('DD MMM YYYY')}` : 'Текущий тариф' : `До ${moment(currentSubscription.valid_until + '+00:00').format('DD MMM YYYY')}` : item.data._id === currentSubscription.after_end_id ? `После ${moment(currentSubscription.valid_until + '+00:00').format('DD MMM YYYY')}` : 'Сменить' : item?.data?._id === selected?.data?._id ? 'Выбран' : 'Выбрать'}
                                    </button>
                                </div>
                                {
                                    !currentSubscription || item.data._id !== currentSubscription.after_end_id || (item.data._id === currentSubscription.data._id && (!currentSubscription.after_end_id || !currentSubscription.payment_id))
                                        ?
                                        <button
                                            className={`simple-subscribe-card-wix__text simple-subscribe-card-wix__button simple-subscribe-card-wix__button_choose ${item.data._id && item?.data?._id === selected?.data?._id ? 'simple-subscribe-card-wix__button_visible' : 'simple-subscribe-card-wix__button_unvisible'}`}
                                            onClick={() => {
                                                handleSubscribe(selected)
                                            }}
                                        >
                                            {currentSubscription ? (!currentSubscription.after_end_id || !currentSubscription.payment_id) ? item.data._id === currentSubscription.data._id ? 'Перейти к оплате' : 'Перейти на новый тариф' : 'Cменить тариф' : 'Перейти к оплате'}
                                        </button>
                                        :
                                        null
                                }
                            </div>
                        )
                    })
                    :
                    null}
            </div>
        </>

    );
}

export default SimpleSubsctiptionCard;