
import './TransactionsList.css'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../assets/contexts/userContext'
import mainApi from '../../../../../assets/api/MainApi'
import { DOWNLOAD_LINK_TYPE, STATUS_TYPE, TEXT_TYPE, TITLE_TYPE } from '../../../../../assets/utils/constants'
import Table from '../../../Table/Table'
import PageHeading from '../../../PageHeading/PageHeading'
import moment from 'moment-timezone'
import { parsePrice } from '../../../../../assets/utils/utils'
import MiniPreloader from '../../../../MiniPreloader/MiniPreloader'

const TRANSACTIONS_LIMIT = 25

function TransactionsList() {

    const { user } = useContext(UserContext)
    const [list, setList] = useState(null)
    const [preloaders, setPreloaders] = useState({
        transactions_init: true,
        transactions_more: false,
    })

    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            transactions_init: true
        }))
        mainApi.getFinanceTransactionsByShop({
            shop_id: user.default_shop._id,
            limit: TRANSACTIONS_LIMIT,
        })
            .then((res) => {
                console.log(res)
                setList(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    transactions_init: false
                }))
            })

    }, [user])

    let table_heading_list = [
        {
            name: 'Дата',
            key: 'date',
            type: TEXT_TYPE,
        },
        {
            name: 'ID',
            key: '_id',
            type: TEXT_TYPE,
        },
        {
            name: 'Описание',
            key: 'description',
            type: TEXT_TYPE,
        },
        {
            name: 'Сумма',
            key: 'amount_value',
            type: TITLE_TYPE,
        },
        {
            name: 'Способ оплаты',
            key: 'pay_method',
            type: TEXT_TYPE,
        },
        {
            name: 'Чек',
            key: 'recipt',
            type: DOWNLOAD_LINK_TYPE,
        },
    ]

    function handleLoadMore() {
        setPreloaders(prevValue => ({
            ...prevValue,
            transactions_more: true
        }))
        const last_item = list.data[list.data.length - 1]
        const last_id = last_item._id
        const last_utc_datetime = last_id.utc_datetime

        mainApi.getFinanceTransactionsByShop({
            shop_id: user.default_shop._id,
            limit: TRANSACTIONS_LIMIT,
            last_id: last_id,
            last_utc_datetime: last_utc_datetime,
        })
            .then((res) => {
                console.log(res)
                setList(prevValue => ({
                    is_more: res.is_more,
                    data: prevValue.data.concat(res.data)
                }))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    transactions_more: false
                }))
            })
    }

    return (
        <div className='transactions'>
            <PageHeading
                title={'Транзакции'}
            />
            <div className='transactions__table'>
                <Table
                    nothingText={`Вы еще не совершили ни одной транзакции`}
                    headingList={table_heading_list}

                    list={
                        list && list.data && list.data.length > 0 ?
                            list.data.map((item, i) => {
                                return {
                                    date: moment(item.utc_datetime + '+00:00').format('DD.MM.YYYY - HH:mm'),
                                    amount_value: `${item.amount.direction ? '+' : '–'} ${parsePrice(item.amount.value, 'RUB')}`,
                                    pay_method: `${item.payment_method ? item.payment_method.card.last4 : null} • ${item.payment_method.card.card_type}`,
                                    recipt: item.receipt ? item.receipt.url : null,
                                    ...item,
                                }
                            })
                            :
                            null}

                    isInitTablePreloaderVisible={preloaders.transactions_init}
                />
            </div>
            {!preloaders.transactions_init && !preloaders.transactions_more && list?.is_more ?
                <button className={`transactions__more ${preloaders.transactions_more ? 'transactions__more_loading' : ''}`} type='button' onClick={handleLoadMore}>
                    {!preloaders.transactions_more ? 'Загрузить еще' : <MiniPreloader />}
                </button>
                : null}
        </div>
    )
}

export default TransactionsList