export const BLOCK_EXEPTION_TYPES = [
    'make-order-kover',
]

export function parseType(type) {
  const lineMatch = type.match(/line-(\d)/);
  const lineCount = lineMatch ? parseInt(lineMatch[1], 10) : undefined;
  const isReverse = type.includes("reverse");
  return { baseType: type.replace(/-line-\d|-reverse/g, ''), lineCount, isReverse };
}
