import PlatformInput from "../../../PlatformInput/PlatformInput";
import Toggle from "../../../../Toggle/Toggle";
import "./FilterEditForm.css";

function FilterEditForm({
    values,
    setValues,
    handleConvert,
}) {

    function handleChange(e) {
        const input = e.target;
        const value = input.value;
        const name = input.name;

        switch (name) {
            case 'is_active': {
                setValues((prevValue) => ({
                    ...prevValue,
                    [name]: !prevValue[name],
                }))
                break;
            }
            default: {
                setValues((prevValue) => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
            }

        }
    }

    return (
        <div className={`filter-edit-form`}>
            {/* {values.data_type === 'range' || values.data_type === 'integer' ?
                <button className="filter-edit-form__convert" type="button" onClick={handleConvert}>
                    Конвертировать в {values.data_type === 'integer' ? 'Диапазон' : 'Несколько вариантов'}
                </button>
                : null} */} 
                {/* ВЕРНУТЬ ПОСЛЕ ФИКСА БЕКА */}
            <PlatformInput
                value={values.name}
                placeholder={""}
                label={"Название"}
                handleChange={handleChange}
                name={"name"}
            />
            <div className="filter-edit-form__toggle">
                <p className="filter-edit-form__toggle-title">Видимость фильтра на сайте</p>
                <Toggle
                    isActive={values.is_active}
                    handleClick={() => {
                        handleChange({
                            target: {
                                name: 'is_active',
                            }
                        })
                    }}
                />
            </div>

        </div>
    );
}

export default FilterEditForm;
