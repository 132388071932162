import mainApi from "../../../../../../../assets/api/MainApi";

export const fetchItems = (shopId) => {
    return mainApi.getAllHeaderLinks({ shop_id: shopId });
};

export const deleteItem = (shopId, id) => {
    return mainApi.deleteHeaderLink({ shop_id: shopId, _id: id });
};

export const sortItems = (shopId, ids) => {
    return mainApi.sortHeaderLinks({ shop_id: shopId, data: ids });
};