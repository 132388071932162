import "./DraftsProductForm.css";
import { useEffect, useState } from "react";
import ProductForm from "../../../../ProductForm/ProductForm";
import {
  getRandomId,
  parseApiError,
} from "../../../../../../assets/utils/utils";
import {
  ARTUCUL_EN,
  ARTUCUL_RU,
  DEBOUNCE_TIME,
  DEFAULT_MAX_IMG_SIZE,
  ERROR_OPTION_TITLE,
  ERROR_OPTION_VALUE,
  ERROR_PARAMETER_TITLE,
  ERROR_PHOTO_MAX_LENGTH,
  ERROR_PRICE_DATA_MIN_VALUE,
  ERROR_REQUIRED_FIELD,
  ERROR_UNIQUE_ID,
  PRODUCT_FORM_TYPE_BOOLEAN,
  PRODUCT_FORM_TYPE_NUMBER,
} from "../../../../../../assets/utils/constants";
import MiniPreloader from "../../../../../MiniPreloader/MiniPreloader";
import mainApi from "../../../../../../assets/api/MainApi";
import { useContext } from "react";
import { UserContext } from "../../../../../../assets/contexts/userContext";
import { useParams } from "react-router-dom";
import ProductFormPlaceholder from "../../../../ProductForm/ProductFormPlaceholder/ProductFormPlaceholder";

function DraftsProductForm({
  setProduct,
  product,
  isPreloaderVisible,
  isTwoColumnsOnPC = false,
  showError,
  productValidity,
  setProductValidity,
  getDraftById,
  isViewOnly,
  hasAddPermission,
}) {
  const { user } = useContext(UserContext);
  const { item_id } = useParams();
  const [isPhotoLoading, setIsPhotoLoading] = useState(false);
  const [isIdChanged, setIsIdChanged] = useState(false);

  useEffect(() => {
    if (!item_id) return;

    getDraftById({ _id: item_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item_id]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!product?.unique_sku || !user || !isIdChanged) return;

      const shop_id = user.default_shop._id;
      mainApi
        .checkUniqueId({
          _id: product._id ? product._id : "000000000000000000000000",
          shop_id,
          unique_id: product.unique_sku,
        })
        .then(() => {
          setProductValidity((prevValue) => ({
            ...prevValue,
            unique_sku: {
              errorMessage: "",
              validState: true,
            },
          }));
        })
        .catch((err) => {
          switch (err.statusCode) {
            case 400:
              setProductValidity((prevValue) => ({
                ...prevValue,
                unique_sku: {
                  errorMessage: ERROR_UNIQUE_ID,
                  validState: false,
                },
              }));
              break;

            default:
              setProductValidity((prevValue) => ({
                ...prevValue,
                unique_sku: {
                  errorMessage: parseApiError(err),
                  validState: false,
                },
              }));
              break;
          }
        });
    }, DEBOUNCE_TIME);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, user]);

  function handleChange(e) {
    const input = e.target;
    let value = input.value;
    const name = input.name;

    switch (name) {
      case "unique_sku": {
        let inputValue = value.replace(/[ ]+/g, "");
        setProduct((prevValue) => ({
          ...prevValue,
          [name]: inputValue,
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: Boolean(inputValue) ? "" : ERROR_REQUIRED_FIELD,
            validState: Boolean(inputValue),
          },
        }));
        setIsIdChanged(true);
        setTimeout(() => setIsIdChanged(false), 100);
        break;
      }

      case "title": {
        setProduct((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: Boolean(value) ? "" : ERROR_REQUIRED_FIELD,
            validState: Boolean(value),
          },
        }));
        break;
      }

      case "discount": {
        let inputValue = value.replace(/\D/g, "");
        setProduct((prevValue) => ({
          ...prevValue,
          [name]: !inputValue ? "" : Number(inputValue).toString(),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          [name]: inputValue
            ? Number(inputValue) > 0
              ? {
                errorMessage: "",
                validState: true,
              }
              : {
                errorMessage: ERROR_PRICE_DATA_MIN_VALUE,
                validState: false,
              }
            : {
              errorMessage: "",
              validState: true,
            },
        }));
        break;
      }

      case "price": {
        let inputValue = value.replace(/\D/g, "");
        setProduct((prevValue) => ({
          ...prevValue,
          [name]: !inputValue ? "" : Number(inputValue).toString(),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          [name]: inputValue
            ? Number(inputValue) > 0
              ? {
                errorMessage: "",
                validState: true,
              }
              : {
                errorMessage: ERROR_PRICE_DATA_MIN_VALUE,
                validState: false,
              }
            : {
              errorMessage: ERROR_REQUIRED_FIELD,
              validState: false,
            },
        }));
        break;
      }

      case "amount": {
        let inputValue = value.replace(/\D/g, "");
        setProduct((prevValue) => ({
          ...prevValue,
          [name]: !inputValue ? "" : Number(inputValue).toString(),
        }));
        // setProductValidity((prevValue) => ({
        //   ...prevValue,
        //   [name]: inputValue ?
        //     {
        //       errorMessage: "",
        //       validState: true,
        //     }
        //     :
        //     {
        //       errorMessage: ERROR_REQUIRED_FIELD,
        //       validState: false,
        //     },
        // }));
        break;
      }

      case "add-option": {
        const option_id = getRandomId();
        const value_id = getRandomId();
        setProduct((prevValue) => ({
          ...prevValue,
          options: prevValue.options.concat([
            {
              title: "",
              _id: option_id,
              values: [
                {
                  value: "",
                  amount: "",
                  _id: value_id,
                },
              ],
            },
          ]),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          options: prevValue.options.concat([
            {
              title: {
                errorMessage: "",
                validState: false,
              },
              _id: option_id,
              values: [
                {
                  value: {
                    errorMessage: "",
                    validState: false,
                  },
                  amount: {
                    errorMessage: "",
                    validState: false,
                  },
                  price: {
                    errorMessage: "",
                    validState: false,
                  },
                  _id: value_id,
                },
              ],
            },
          ]),
        }));
        break;
      }

      case "add-value": {
        const value_id = getRandomId();
        let option_id = input.option_id;
        setProduct((prevValue) => ({
          ...prevValue,
          options: prevValue.options.map((item, i) => {
            if (item._id !== option_id) return item;
            return {
              ...item,
              values: item.values.concat([
                {
                  value: "",
                  amount: "",
                  _id: value_id,
                },
              ]),
            };
          }),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          options: prevValue.options.map((item, i) => {
            if (item._id !== option_id) return item;
            return {
              ...item,
              values: item.values.concat([
                {
                  value: {
                    errorMessage: "",
                    validState: false,
                  },
                  amount: {
                    errorMessage: "",
                    validState: false,
                  },
                  price: {
                    errorMessage: "",
                    validState: false,
                  },
                  _id: value_id,
                },
              ]),
            };
          }),
        }));
        break;
      }

      case "option-title": {
        let option_id = input.option_id;
        setProduct((prevValue) => ({
          ...prevValue,
          options: prevValue.options.map((item) => {
            if (item._id !== option_id) return item;
            return {
              ...item,
              title: value,
            };
          }),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          options: prevValue.options.map((item) => {
            if (item._id !== option_id) return item;
            return {
              ...item,
              title: Boolean(value)
                ? product.options.some(
                  (item) => item.title.toLowerCase() === value.toLowerCase()
                )
                  ? {
                    errorMessage: ERROR_OPTION_TITLE,
                    validState: false,
                  }
                  : {
                    errorMessage: "",
                    validState: true,
                  }
                : {
                  errorMessage: ERROR_REQUIRED_FIELD,
                  validState: false,
                },
            };
          }),
        }));
        break;
      }

      case "option-value": {
        let option_id = input.option_id;
        let value_id = input.value_id;
        setProduct((prevValue) => ({
          ...prevValue,
          options: prevValue.options.map((item, i) => {
            if (item._id !== option_id) return item;
            return {
              ...item,
              values: item.values.map((item2, i2) => {
                if (item2._id !== value_id) return item2;
                return {
                  ...item2,
                  value: value,
                };
              }),
            };
          }),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          options: prevValue.options.map((item, i) => {
            if (item._id !== option_id) return item;
            return {
              ...item,
              values: item.values.map((item2, i2) => {
                if (item2._id !== value_id) return item2;
                return {
                  ...item2,
                  value: Boolean(value)
                    ? product.options[i].values.some(
                      (item) =>
                        item.value.toLowerCase() === value.toLowerCase()
                    )
                      ? {
                        errorMessage: ERROR_OPTION_VALUE,
                        validState: false,
                      }
                      : {
                        errorMessage: "",
                        validState: true,
                      }
                    : {
                      errorMessage: ERROR_REQUIRED_FIELD,
                      validState: false,
                    },
                };
              }),
            };
          }),
        }));
        break;
      }

      case "option-amount": {
        let option_id = input.option_id;
        let value_id = input.value_id;
        let inputValue = value.replace(/\D/g, "");
        setProduct((prevValue) => ({
          ...prevValue,
          options: prevValue.options.map((item, i) => {
            if (item._id !== option_id) return item;
            return {
              ...item,
              values: item.values.map((item2, i2) => {
                if (item2._id !== value_id) return item2;
                return {
                  ...item2,
                  amount: !inputValue ? "" : Number(inputValue).toString(),
                };
              }),
            };
          }),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          options: prevValue.options.map((item, i) => {
            if (item._id !== option_id) return item;
            return {
              ...item,
              values: item.values.map((item2, i2) => {
                if (item2._id !== value_id) return item2;
                return {
                  ...item2,
                  amount: {
                    errorMessage: Boolean(value) ? "" : ERROR_REQUIRED_FIELD,
                    validState: Boolean(value),
                  },
                };
              }),
            };
          }),
        }));
        break;
      }

      case "option-price": {
        let option_id = input.option_id;
        let value_id = input.value_id;
        let inputValue = value.replace(/\D/g, "");
        setProduct((prevValue) => ({
          ...prevValue,
          options: prevValue.options.map((item, i) => {
            if (item._id !== option_id) return item;
            return {
              ...item,
              values: item.values.map((item2, i2) => {
                if (item2._id !== value_id) return item2;
                return {
                  ...item2,
                  price: !inputValue ? "" : Number(inputValue).toString(),
                };
              }),
            };
          }),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          options: prevValue.options.map((item, i) => {
            if (item._id !== option_id) return item;
            return {
              ...item,
              values: item.values.map((item2, i2) => {
                if (item2._id !== value_id) return item2;
                return {
                  ...item2,
                  price: {
                    errorMessage: Boolean(value) ? "" : ERROR_REQUIRED_FIELD,
                    validState: Boolean(value),
                  },
                };
              }),
            };
          }),
        }));
        break;
      }

      case "option-value-delete": {
        let option_id = input.option_id;
        let value_id = input.value_id;
        setProduct((prevValue) => ({
          ...prevValue,
          options: prevValue.options
            .map((item, i) => {
              if (item._id !== option_id) return item;
              return {
                ...item,
                values: item.values.filter((item2) => item2._id !== value_id),
              };
            })
            .filter((item) => item.values.length >= 1),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          options: prevValue.options
            .map((item, i) => {
              if (item._id !== option_id) return item;
              return {
                ...item,
                values: item.values.filter((item2) => item2._id !== value_id),
              };
            })
            .filter((item) => item.values.length >= 1),
        }));
        break;
      }

      case "option-value-sort-down": {
        let option_id = input.option_id;
        let value_index = input.value_index;

        setProduct((prevValue) => {
          const updatedOptions = prevValue.options.map((option) => {
            if (option._id !== option_id) return option;

            if (value_index === option.values.length - 1) return option;

            // Create a new array for values and swap the elements
            const newValues = [...option.values];
            [newValues[value_index], newValues[value_index + 1]] = [
              newValues[value_index + 1],
              newValues[value_index],
            ];

            return { ...option, values: newValues };
          });

          return { ...prevValue, options: updatedOptions };
        });
        break;
      }

      case "option-value-sort-up": {
        let option_id = input.option_id;
        let value_index = input.value_index;

        setProduct((prevValue) => {
          const updatedOptions = prevValue.options.map((option) => {
            if (option._id !== option_id) return option;
            if (value_index === 0) return option;
            const newValues = [...option.values];
            [newValues[value_index], newValues[value_index - 1]] = [
              newValues[value_index - 1],
              newValues[value_index],
            ];

            return { ...option, values: newValues };
          });

          return { ...prevValue, options: updatedOptions };
        });
        break;
      }

      case "add-parameter": {
        const parameter_id = getRandomId();
        setProduct((prevValue) => ({
          ...prevValue,
          parameters: prevValue.parameters.concat([
            {
              title: "",
              value: "",
              type: PRODUCT_FORM_TYPE_NUMBER,
              _id: parameter_id,
            },
          ]),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          parameters: prevValue.parameters.concat([
            {
              title: {
                errorMessage: "",
                validState: false,
              },
              value: {
                errorMessage: "",
                validState: false,
              },
              _id: parameter_id,
            },
          ]),
        }));
        break;
      }

      case "parameter-title": {
        const parameter_id = input.parameter_id;
        const parameter_key = input.parameter_key;
        setProduct((prevValue) => ({
          ...prevValue,
          parameters: prevValue.parameters.map((item, i) => {
            if (item._id !== parameter_id) return item;
            return {
              ...item,
              [parameter_key]: value,
            };
          }),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          parameters: prevValue.parameters.map((item) => {
            if (item._id !== parameter_id) return item;
            return {
              ...item,
              [parameter_key]: Boolean(value)
                ? product.parameters.some(
                  (item) =>
                    item.title.toLowerCase() === value.toLowerCase() ||
                    value.toLowerCase() === ARTUCUL_EN ||
                    value.toLowerCase() === ARTUCUL_RU
                )
                  ? {
                    errorMessage: ERROR_PARAMETER_TITLE,
                    validState: false,
                  }
                  : {
                    errorMessage: "",
                    validState: true,
                  }
                : {
                  errorMessage: ERROR_REQUIRED_FIELD,
                  validState: false,
                },
            };
          }),
        }));
        break;
      }

      case "parameter-type": {
        const parameter_id = input.parameter_id;
        const parameter_key = input.parameter_key;

        setProduct((prevValue) => ({
          ...prevValue,
          parameters: prevValue.parameters.map((item, i) => {
            if (item._id !== parameter_id) return item;
            return {
              ...item,
              [parameter_key]: value,
              value: value.type === PRODUCT_FORM_TYPE_BOOLEAN.type ? true : "",
            };
          }),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          parameters: prevValue.parameters.map((item) => {
            if (item._id !== parameter_id) return item;
            return {
              ...item,
              value: {
                errorMessage:
                  value.type === PRODUCT_FORM_TYPE_BOOLEAN.type ||
                    Boolean(item.value)
                    ? ""
                    : ERROR_REQUIRED_FIELD,
                validState:
                  value.type === PRODUCT_FORM_TYPE_BOOLEAN.type ||
                  Boolean(item.value),
              },
            };
          }),
        }));
        break;
      }

      case "parameter-value": {
        const parameter_id = input.parameter_id;
        const parameter_key = input.parameter_key;
        const parameter_type = input.parameter_type;
        let inputValue = value;
        if (parameter_type.type === PRODUCT_FORM_TYPE_NUMBER.type)
          inputValue = value.replace(/[^0-9.]/g, "");

        setProduct((prevValue) => ({
          ...prevValue,
          parameters: prevValue.parameters.map((item) => {
            if (item._id !== parameter_id) return item;
            return {
              ...item,
              [parameter_key]: inputValue,
            };
          }),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          parameters: prevValue.parameters.map((item) => {
            if (item._id !== parameter_id) return item;
            return {
              ...item,
              [parameter_key]: {
                errorMessage: inputValue !== "" ? "" : ERROR_REQUIRED_FIELD,
                validState: inputValue !== "",
              },
            };
          }),
        }));
        break;
      }

      case "parameter-delete": {
        const parameter_id = input.parameter_id;
        setProduct((prevValue) => ({
          ...prevValue,
          parameters: prevValue.parameters.filter(
            (item) => item._id !== parameter_id
          ),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          parameters: prevValue.parameters.filter(
            (item) => item._id !== parameter_id
          ),
        }));
        break;
      }

      case "photo": {
        const files = Array.from(e.target.files);
        let photos = [];
        setIsPhotoLoading(true);

        function makeRequest(photo) {
          let data = new FormData();
          data.append("image", photo);

          return new Promise((resolve, reject) => {
            mainApi
              .uploadImage({ data, type: "images" })
              .then((res) => {
                photos.push(res);
                resolve(res);
              })
              .catch((err) => {
                reject(err);
                // showError(parseApiError(err));
                setProductValidity((prevValue) => ({
                  ...prevValue,
                  [name]: {
                    errorMessage: parseApiError(err),
                    validState: false,
                  },
                }));
              });
          });
        }

        async function runAsync(files) {
          for (let file of files) {
            // console.log(file);
            if (file && file.size / 1000000 >= DEFAULT_MAX_IMG_SIZE) return;

            await makeRequest(file).catch((err) => {
              console.log(err);
            });
          }
        }

        runAsync(files).finally(() => {
          // console.log("FINISHED", photos);
          const photosLength = product.photo.length + photos.length;
          setIsPhotoLoading(false);
          setProduct((prevValue) => ({
            ...prevValue,
            [name]: prevValue.photo ? prevValue.photo.concat(photos) : photos,
          }));
          setProductValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: photosLength <= 10 ? "" : ERROR_PHOTO_MAX_LENGTH,
              validState: photosLength <= 10,
            },
          }));
        });
        break;
      }

      case "photo-delete": {
        const name_key = input.name_key;
        const photosLength = product.photo.length - 1;
        setProduct((prevValue) => ({
          ...prevValue,
          [name_key]: prevValue.photo.filter((item, i) => i !== value),
        }));
        setProductValidity((prevValue) => ({
          ...prevValue,
          [name_key]: {
            errorMessage: photosLength <= 10 ? "" : ERROR_PHOTO_MAX_LENGTH,
            validState: photosLength <= 10,
          },
        }));
        break;
      }

      case "categories": {
        setProduct((prevValue) => ({
          ...prevValue,
          [name]: prevValue.categories
            ? prevValue.categories.concat(value)
            : [value],
        }));
        break;
      }

      case "categories-delete": {
        setProduct((prevValue) => ({
          ...prevValue,
          categories: prevValue.categories.filter((item) => item._id !== value),
        }));
        break;
      }

      default:
        setProduct((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;
    }
  }

  return (
    <div className="drafts-product-form">
      {!isPreloaderVisible ? (
        product ? (
          <ProductForm
            isTwoColumnsOnPC={isTwoColumnsOnPC}
            handleChange={handleChange}
            product={product}
            productValidity={productValidity}
            isImgPreloader={isPhotoLoading}
            isViewOnly={isViewOnly}
          />
        ) : (
          <ProductFormPlaceholder withCreateBtn={hasAddPermission} />
        )
      ) : (
        <div className="drafts-main__preloader">
          <MiniPreloader />
        </div>
      )}
    </div>
  );
}

export default DraftsProductForm;
