import { SETTINGS_PAGES_AND_FILES_DOCS, SETTINGS_PAGES_AND_FILES_DOCS_CREATE, SETTINGS_PAGES_AND_FILES_DOCS_ITEM, SETTINGS_PAGES_AND_FILES_MAIN_PAGE_LINK, SETTINGS_PAGES_AND_FILES_SOCIALS, SETTINGS_PAGES_AND_FILES_HEADER_LINKS, SETTINGS_PAGES_AND_FILES_FOOTER_LINKS } from '../../../../../assets/utils/constants';

import EditMainPage from '../components/EditMainPage/EditMainPage';

import RouterComponent from '../components/Documents/RouterComponent/RouterComponent';

import DocsList from '../components/Documents/components/Lists/DocsList';
import DocForm from '../components/Documents/components/Forms/DocForm';

import SocialsList from '../components/Documents/components/Lists/SocialsList';
import SocialForm from '../components/Documents/components/Forms/SocialForm';

import HeaderLinksList from '../components/Documents/components/Lists/HeaderLinksList';
import HeaderLinksForm from '../components/Documents/components/Forms/HeaderLinksForm';
import FooterLinksList from '../components/Documents/components/Lists/FooterLinksList';
import FooterLinksForm from '../components/Documents/components/Forms/FooterLinksForm';

export const documentsRoutesConfig = {
    index: {
        path: `/${SETTINGS_PAGES_AND_FILES_DOCS}*`,
        element: <DocsList />,
        mobileTitle: 'Документы',
    },
    items: [
        {
            path: `/${SETTINGS_PAGES_AND_FILES_DOCS_CREATE}`,
            element: <DocForm mode="create" />,
            mobileTitle: 'Создать документ',
        },
        {
            path: `/${SETTINGS_PAGES_AND_FILES_DOCS_ITEM}/:id`,
            element: <DocForm mode="edit" />,
            mobileTitle: 'Изменить документ',
        },
    ]
}

export const documentsRouter = {
    path: `/${SETTINGS_PAGES_AND_FILES_DOCS}*`,
    element: <RouterComponent routesConfig={{
        index: documentsRoutesConfig.index,
        items: documentsRoutesConfig.items,
    }} />,
    mobileTitle: 'Документы',
}

export const socialsRoutesConfig = {
    index: {
        path: `/${SETTINGS_PAGES_AND_FILES_SOCIALS}*`,
        element: <SocialsList />,
        mobileTitle: 'Социальные ссылки (подвал)',
    },
    items: [
        {
            path: `/${SETTINGS_PAGES_AND_FILES_DOCS_CREATE}`,
            element: <SocialForm mode="create" />,
            mobileTitle: 'Добавить ссылку',
        },
        {
            path: `/${SETTINGS_PAGES_AND_FILES_DOCS_ITEM}/:id`,
            element: <SocialForm mode="edit" />,
            mobileTitle: 'Изменить ссылку',
        },
    ]
}

export const socialsRouter = {
    path: `/${SETTINGS_PAGES_AND_FILES_SOCIALS}*`,
    element: <RouterComponent routesConfig={{
        index: socialsRoutesConfig.index,
        items: socialsRoutesConfig.items,
    }} />,
    mobileTitle: 'Социальные ссылки (подвал)',
}


export const headerLinksRoutesConfig = {
    index: {
        path: `/${SETTINGS_PAGES_AND_FILES_HEADER_LINKS}*`,
        element: <HeaderLinksList />,
        mobileTitle: 'Ссылки (шапка)',
    },
    items: [
        {
            path: `/${SETTINGS_PAGES_AND_FILES_DOCS_CREATE}`,
            element: <HeaderLinksForm mode="create" />,
            mobileTitle: 'Добавить ссылку',
        },
        {
            path: `/${SETTINGS_PAGES_AND_FILES_DOCS_ITEM}/:id`,
            element: <HeaderLinksForm mode="edit" />,
            mobileTitle: 'Изменить ссылку',
        },
    ]
}

export const headerLinksRouter = {
    path: `/${SETTINGS_PAGES_AND_FILES_HEADER_LINKS}*`,
    element: <RouterComponent routesConfig={{
        index: headerLinksRoutesConfig.index,
        items: headerLinksRoutesConfig.items,
    }} />,
    mobileTitle: 'Ссылки (шапка)',
}

export const footerLinksRoutesConfig = {
    index: {
        path: `/${SETTINGS_PAGES_AND_FILES_FOOTER_LINKS}*`,
        element: <FooterLinksList />,
        mobileTitle: 'Ссылки (подвал)',
    },
    items: [
        {
            path: `/${SETTINGS_PAGES_AND_FILES_DOCS_CREATE}`,
            element: <FooterLinksForm mode="create" />,
            mobileTitle: 'Добавить ссылку',
        },
        {
            path: `/${SETTINGS_PAGES_AND_FILES_DOCS_ITEM}/:id`,
            element: <FooterLinksForm mode="edit" />,
            mobileTitle: 'Изменить ссылку',
        },
    ]
}

export const footerLinksRouter = {
    path: `/${SETTINGS_PAGES_AND_FILES_FOOTER_LINKS}*`,
    element: <RouterComponent routesConfig={{
        index: footerLinksRoutesConfig.index,
        items: footerLinksRoutesConfig.items,
    }} />,
    mobileTitle: 'Ссылки (подвал)',
}



export const routesConfig = [
    {
        path: `/${SETTINGS_PAGES_AND_FILES_MAIN_PAGE_LINK}`,
        element: <EditMainPage />,
        withItemForm: true,
        mobileTitle: 'Главная страница',
    },

    // <DOCUMENTS>
    documentsRouter,
    // </DOCUMENTS>

    // <SOCIALS>
    socialsRouter,
    // </SOCIALS>

    // <HEADER LINKS>
    headerLinksRouter,
    // </HEADER LINKS>

    // <FOOTER LINKS>
    footerLinksRouter,
    // </FOOTER LINKS>
];