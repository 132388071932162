// Constants for permission keys
export const PERMISSIONS = {
    ADD: 'add', // Allows adding new items or content.
    VIEW: 'view', // Grants access to view items, content, or information.
    DELETE: 'delete', // Permits deletion of items or content.
    PUBLISH: 'publish', // Enables publishing content or making it live.
    EDIT: 'edit', // Allows editing and modifying existing items or content.
    MOVE_TO_DRAFTS: 'move_to_draft', // Grants the ability to move published items back to drafts.
    UNDELETABLE: 'undeletable', // Marks items as undeletable, preventing their deletion.
    INVISIBLE: 'invisible', // Makes items or content invisible to regular users.
    EDIT_LAYOUT: 'edit_layout', // Permits changes to the layout and structure of pages.
    EDIT_BANNERS: 'edit_banners', // Allows editing and changing banners and promotional materials !!!NOT IN USE!!!.
    TASKS: 'tasks', // Grants access to manage and view tasks on home page.
    VIEW_SHOP_INFO: 'view_shop_info', // Allows viewing shop information like name/legal_name/shop_categories.
    MANAGE_STATIC: 'manage_static', // Permits management of static content such as main page content.
    MANAGE_PAYMENTS: 'manage_payments', // Enables management of payment systems of shop and configurations.
    MANAGE_DOMAIN: 'manage_domain', // Allows managing domain settings and configurations.
    MANAGE_SHOP_INFO: 'manage_shop_info', // Grants capabilities to manage shop information like name/legal_name/shop_categories
    MANAGE_BILLING: 'manage_billing', // Permits management of billing, subscriptions, and financial settings.
    FIRSTC_PRODUCTS: 'firstc_products', // Specific permission related to managing and viewing integration "1С products" !!!ONLY AVAILIBLE FOR SYSTEM MANAGERS!!! !!!NOW NOT BLOCKING VIEW TO MAKE PLACEHOLDER FOR USERS!!!.
    VIEW_WEBEXTRA: 'view_webExtra', // Allows viewing of additional web files like YML/SITEMAP.
    CONNECT_TO_YANDEXMETRICS: 'connect_to_YandexMetrics', // Enables integration with Yandex.Metrics for analytics.
    NOTIFICATIONS: 'notifications', // Grants access to manage and view notifications.

    //STATISTICS PERMISSIONS
    STATISTIC_ONLINE: 'online', // view online on site chart
    STATISTIC_ORDERS: "orders", // view orders chart
    STATISTIC_ADD_TO_CART: "add_to_cart", // view cart's *unfinished cart's* chart
    STATISTIC_ITEM_CREATE: "item_create", // view new item created chart
    STATISTIC_ITEM_SALE: "item_sale", // view item sold number chart
    STATISTIC_COUPON_CODES: "coupon_codes",  // view promocode usage chart
    STATISTIC_ITEM_TOTAL: "item_total", // view total items count in catalog

    // STATIC PAGES 
    EDIT_STATIC_PAGES: 'edit_static_pages',
};


// Function to generate RBAC object with permissions
const generateRBAC = (section, permissions) => {
    const rbacObject = {};
    permissions.forEach(permissionKey => {
        const permission = PERMISSIONS[permissionKey]; // Use the constant
        rbacObject[permission] = `${section}:${permission}`;
    });
    return rbacObject;
};

// Define permissions for each section using the keys from PERMISSIONS
const DRAFTS_PERMISSIONS = ['ADD', 'VIEW', 'DELETE', 'PUBLISH', 'EDIT'];
const PUBLISHED_PERMISSIONS = ['VIEW', 'DELETE', 'EDIT', 'MOVE_TO_DRAFTS'];
const ORDERS_PERMISSIONS = ['VIEW', 'EDIT'];
const COUPONS_PERMISSIONS = ['ADD', 'VIEW', 'EDIT'];
const USER_MANAGEMENT_PERMISSIONS = ['ADD', 'VIEW', 'EDIT', 'DELETE', 'UNDELETABLE', 'INVISIBLE'];
const CONTENT_MANAGEMENT_PERMISSIONS = ['VIEW', 'EDIT_LAYOUT', 'EDIT_BANNERS', 'EDIT_STATIC_PAGES'];
const SHOP_SETTINGS_PERMISSIONS = ['TASKS', 'VIEW_SHOP_INFO', 'MANAGE_STATIC', 'MANAGE_PAYMENTS', 'MANAGE_DOMAIN', 'MANAGE_SHOP_INFO', 'MANAGE_BILLING'];
const INTEGRATIONS_PERMISSIONS = ['VIEW', 'FIRSTC_PRODUCTS', 'VIEW_WEBEXTRA', 'CONNECT_TO_YANDEXMETRICS', 'NOTIFICATIONS'];
const CATEGORY_MANAGEMENT_PERMISSIONS = ['ADD', 'VIEW', 'EDIT', 'DELETE'];
const STATISTIC_PERMISSIONS = ['STATISTIC_ONLINE', 'STATISTIC_ORDERS', 'STATISTIC_ADD_TO_CART', 'STATISTIC_ITEM_CREATE', 'STATISTIC_ITEM_SALE', 'STATISTIC_COUPON_CODES', 'STATISTIC_ITEM_TOTAL'];




// Generate RBAC objects
export const RBAC_DRAFTS = generateRBAC('drafts', DRAFTS_PERMISSIONS);
console.log(RBAC_DRAFTS)
export const RBAC_PUBLISHED = generateRBAC('published', PUBLISHED_PERMISSIONS);
export const RBAC_ORDERS = generateRBAC('orders', ORDERS_PERMISSIONS);
export const RBAC_COUPONS = generateRBAC('coupons', COUPONS_PERMISSIONS);
export const RBAC_USER_MANAGEMENT = generateRBAC('user_management', USER_MANAGEMENT_PERMISSIONS);
export const RBAC_CONTENT_MANAGEMENT = generateRBAC('content_management', CONTENT_MANAGEMENT_PERMISSIONS);
export const RBAC_SHOP_SETTINGS = generateRBAC('shop_settings', SHOP_SETTINGS_PERMISSIONS);
export const RBAC_INTEGRATIONS = generateRBAC('integrations', INTEGRATIONS_PERMISSIONS);
export const RBAC_CATEGORY_MANAGEMENT = generateRBAC('category_management', CATEGORY_MANAGEMENT_PERMISSIONS);
export const RBAC_STATISTIC = generateRBAC('allowed_stats', STATISTIC_PERMISSIONS);

// The permissions defined above are integral to the system's access control framework, designed to fine-tune user capabilities and ensure secure operation. To effectively implement and utilize these permissions, follow the guidelines below:

// 1. Retrieving Permission Strings:
//    Utilize the RBAC_USER_MANAGEMENT object in conjunction with PERMISSIONS constants to construct permission strings.
//    Example: `RBAC_USER_MANAGEMENT[PERMISSIONS.VIEW]` yields "user_management:view".

// 2. Checking User Permissions:
//    Employ the `hasPermission` function to determine if a user possesses a specific permission or set of permissions.
//    Syntax: `hasPermission(permissionObject, permissionStringArray)`.
//    This function evaluates whether the provided permission object (derived from a user's role and permissions) includes the specified permissions, returning a boolean result.

// 3. Parsing User Permissions:
//    Use the `parsePermissions` function to extract and organize permissions from a user's profile, specifically from the `user.default_shop.role.permissions` path.
//    This utility facilitates the dynamic assignment and verification of user permissions based on their roles and activities within the system.

