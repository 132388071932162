import { useContext, useState } from "react";
import { PlatformLinksContext } from "../../assets/contexts/platformLinksContext";
import './NotFoundErrorPage.css';
import error from '../../assets/images/not-found-error-page/error.png'
import error403 from '../../assets/images/not-found-error-page/403.png'
import { keyForErrorOfAccess } from "../../assets/utils/constants";

function NotFoundErrorPage({ errorType }) {

    const { links, default_link } = useContext(PlatformLinksContext)

    return (
        <div className="not-found-error-page">
            <div className="not-found-error-page__img-box">
                {errorType === keyForErrorOfAccess ?
                    <img className="not-found-error-page__img" alt="" src={error403}></img>
                    :
                    <img className="not-found-error-page__img" alt="" src={error}></img>
                }
                <p className="not-found-error-page__error-title">ОШИБКА</p>
                {errorType === keyForErrorOfAccess ?
                    <p className="not-found-error-page__error-subtitle">Доступ к этой странице ограничен</p>
                    :
                    <p className="not-found-error-page__error-subtitle">Такой страницы не существует</p>
                }

            </div>
        </div>
    );
}

export default NotFoundErrorPage