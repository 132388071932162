import { useState } from 'react';
import './TwoFactorBtn.css';
import PreloaderBox from '../../PreloaderBox/PreloaderBox';

function TwoFactorBtn({
    firstStepBtnText,
    secondStepBtnText,
    cancelBtnText,
    confirmText,
    handleAction,
    isLoading,
}) {
    const [isClicked, setIsClicked] = useState(false)
    return (
        <div className='two-factor-btn'>
            {!isClicked ?
                <button className='two-factor-btn__btn' type='button' onClick={() => {
                    setIsClicked(true)
                }}>
                    {firstStepBtnText}
                </button>
                :
                <>
                    <p className='two-factor-btn__confirm' dangerouslySetInnerHTML={{ __html: confirmText }}></p>
                    <div className='two-factor-btn__btns'>
                        <button className='two-factor-btn__btn two-factor-btn__btn_cancel' type='button' onClick={() => {
                            setIsClicked(false)
                        }}>
                            {cancelBtnText}
                        </button>
                        <button className='two-factor-btn__btn' type='button' onClick={handleAction}>
                            {isLoading ? <PreloaderBox /> : secondStepBtnText}
                        </button>
                    </div>
                </>
            }

        </div>
    );
}

export default TwoFactorBtn