import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../../../../../assets/contexts/userContext';
import { ActionsContext } from '../../../../../../../assets/contexts/actionsContext';
import useAutoDismissError from '../../../../../../../assets/hooks/useAutoDismissError';
import { deepEqual } from '../../../../../../../assets/utils/utils';
import { PLATFORM_MAIN_LINK, SETTINGS_MAIN_LINK, SETTINGS_PAGES_AND_FILES_LINK, SETTINGS_PAGES_AND_FILES_DOCS } from '../../../../../../../assets/utils/constants';

export const useForm = ({ mode, initialForm, fetchItem, editItem, createItem, itemResponseFormater, returnToListURL, requestFormFormater, editFormValidity, createFormValidity, }) => {
    const { user } = useContext(UserContext);
    const { setActions } = useContext(ActionsContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const [form, setForm] = useState(initialForm);

    const [initForm, setInitForm] = useState(null);
    const [preloaders, setPreloaders] = useState({ main: false, save: false });
    const [error, setError] = useAutoDismissError();
    const [successMessage, setSuccessMessage] = useAutoDismissError();

    const isEditMode = mode === 'edit';

    useEffect(() => {
        if (isEditMode && id && user) {
            setPreloaders((prev) => ({ ...prev, main: true }));
            fetchItem(user.default_shop._id, id)
                .then((res) => {
                    setForm(itemResponseFormater(res));
                    setInitForm(itemResponseFormater(res));
                })
                .catch((err) => console.error(err))
                .finally(() => setPreloaders((prev) => ({ ...prev, main: false })));
        }
    }, [id, user, isEditMode]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        if (name === 'translit_name') {
            newValue = value.replace(/[а-яё+=,.@#$%^*()?/<>:;!'"±]/gi, "").replace(/\s/g, "-");
        }

        setForm((prev) => ({ ...prev, [name]: newValue }));
    };

    const handleSubmit = () => {
        setPreloaders((prev) => ({ ...prev, save: true }));

        const apiMethod = isEditMode ? editItem : createItem;
        let requestPayload = {
            shop_id: user.default_shop._id,
        }
        if (requestFormFormater) {
            const formatedForm = requestFormFormater(form)
            requestPayload = {
                ...requestPayload,
                ...formatedForm,
            }
        } else {
            requestPayload = {
                ...requestPayload,
                ...form,
            }
        }

        apiMethod(user.default_shop._id, requestPayload)
            .then((res) => {
                if (isEditMode) {
                    setSuccessMessage('Успешно обновлено');
                    setInitForm(form);
                } else {
                    navigate(returnToListURL);
                    resetForm();
                }
            })
            .catch(handleApiError)
            .finally(() => setPreloaders((prev) => ({ ...prev, save: false })));
    };

    const resetForm = () => {
        setForm(initialForm);
    };

    const handleApiError = (err) => {
        if (err?.statusCode === 400) {
            setError('Creation failed. Check translit_name uniqueness');
        } else {
            setError('An error occurred. Please try again later.');
        }
    };

    useEffect(() => {
        setActions([
            {
                name: "Назад",
                onClick: () => navigate(returnToListURL),
                id: `pages-and-files_${isEditMode ? 'edit' : 'create'}-doc-go-back`,
            },
            {
                name: isEditMode ? (successMessage ? "Сохранено" : "Сохранить") : "Создать",
                onClick: handleSubmit,
                isMainAction: true,
                inactive: isEditMode
                    ? editFormValidity(form, initForm)
                    : createFormValidity(form),
                isPreloaderVisible: preloaders.save,
                id: `pages-and-files_${isEditMode ? 'edit' : 'create'}-doc`,
            },
        ]);
    }, [form, preloaders.save, successMessage]);

    return {
        form,
        preloaders,
        error,
        handleChange,
        handleSubmit,
    };
};