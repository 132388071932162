import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import PageHeading from '../../PageHeading/PageHeading';
import './Domain.css';
import { getCurrentPlatfromLocation } from '../../../../assets/utils/utils';
import { useContext, useEffect, useState } from 'react';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import ItemForm from '../../ItemForm/ItemForm';
import { SETTINGS_DOMAIN_CUSTOM_LINK, SETTINGS_DOMAIN_LINKS, SETTINGS_DOMAIN_TECHNICAL_LINK } from '../../../../assets/utils/constants';



import InternalLinks from '../../InternalLinks/InternalLinks';
import TechnicalDomain from './TechnicalDomain/TechnicalDomain';
import CustomDomain from './СustomDomain/СustomDomain'
import mainApi from '../../../../assets/api/MainApi';
import { UserContext } from '../../../../assets/contexts/userContext';
import { PlatformLinksContext } from '../../../../assets/contexts/platformLinksContext';




function DomainMobileRoutePage({
    title,
    goBackActive,
    children,
}) {

    const navigate = useNavigate()

    return (
        <div className='domain__content'>
            <PageHeading
                className={'domain__heading'}
                title={title}
                goBack={goBackActive ? {
                    onClick: () => { navigate(-1) },
                } : undefined}
            />
            <ItemForm
                containerClassName={'domain__item-from-container'}
            >
                {children}
            </ItemForm>
        </div>
    )
}

function Domain() {
    const { width } = useWindowSize()
    // const location = useLocation()
    // const [actions, setActions] = useState([
    //     {
    //         name: 'Создать группу',
    //         path: `${location.pathname}/${PROMOCODES_CREATE_GROUP_LINK}`,
    //         isMainAction: true,
    //     },
    //     // {
    //     //     name: 'OnClick',
    //     //     onClick: () => { console.log('fddsfeee') },
    //     //     inactive: true,
    //     // },
    // ])

    const [clickedSide, setClickedSide] = useState('left')

    const { user } = useContext(UserContext)
    const location = useLocation();
    const { links, default_link } = useContext(PlatformLinksContext)

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })

    const [domainInfo, setDomainInfo] = useState(undefined)
    useEffect(() => {
        if (!user) return
        mainApi.getShopInfo({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                setDomainInfo(res.domain)
                console.log(res)
            })
            .catch((err) => {
                if (err.statusCode === 403) {
                    setDomainInfo(undefined)
                }
                console.log(err)
            })
    }, [user])

    return (
        <>
            {
                width ?
                    width > 880 ?
                        <div className='domain'>
                            <PageHeading
                                className={'domain__heading'}
                                title={current_location?.location?.name}
                            />
                            <ItemForm
                                containerClassName={'domain__item-from-container'}
                            >
                                <div className='domain__two-columns'>
                                    <div className='domain__products-list' onClick={() => {
                                        setClickedSide('left')
                                    }}>
                                        <InternalLinks
                                            links={SETTINGS_DOMAIN_LINKS}
                                            keyValue={'domain__desctop'}
                                        />
                                    </div>
                                    <div className='domain__products-form' onClick={() => {

                                        setClickedSide('right')
                                    }}>
                                        <Routes>
                                            <Route index element={<Navigate to={SETTINGS_DOMAIN_TECHNICAL_LINK} />}>
                                            </Route>
                                            <Route path={`/${SETTINGS_DOMAIN_TECHNICAL_LINK}`} element={
                                                <TechnicalDomain
                                                    domainInfo={domainInfo}
                                                    setDomainInfo={setDomainInfo}
                                                />
                                            }>
                                            </Route>
                                            <Route path={`/${SETTINGS_DOMAIN_CUSTOM_LINK}`} element={
                                                <CustomDomain
                                                    domainInfo={domainInfo}
                                                    setDomainInfo={setDomainInfo}
                                                />
                                            }>
                                            </Route>

                                        </Routes>
                                    </div>

                                </div>
                            </ItemForm>
                        </div>
                        :
                        <div className='domain'>
                            <Routes>
                                <Route index element={
                                    <DomainMobileRoutePage
                                        goBackActive={false}
                                        title={current_location.location.name}
                                    >
                                        <InternalLinks
                                            links={SETTINGS_DOMAIN_LINKS}
                                            keyValue={'domain__mobile'}
                                        />
                                    </DomainMobileRoutePage>
                                }>
                                </Route>
                                <Route path={`/${SETTINGS_DOMAIN_TECHNICAL_LINK}`} element={
                                    <DomainMobileRoutePage
                                        goBackActive={true}
                                        title={'Технический домен'}
                                    >
                                        <TechnicalDomain
                                            domainInfo={domainInfo}
                                            setDomainInfo={setDomainInfo}
                                        />
                                    </DomainMobileRoutePage>
                                }>
                                </Route>
                                <Route path={`/${SETTINGS_DOMAIN_CUSTOM_LINK}`} element={
                                    <DomainMobileRoutePage
                                        goBackActive={true}
                                        title={'Собственный домен'}
                                    >
                                        <CustomDomain
                                            domainInfo={domainInfo}
                                            setDomainInfo={setDomainInfo}
                                        />
                                    </DomainMobileRoutePage>
                                }>
                                </Route>

                            </Routes>

                        </div >
                    :
                    null
            }
        </>
    );
}

export default Domain