import { useContext, useState } from 'react';
import ItemForm from '../../../ItemForm/ItemForm';
import PageHeading from '../../../PageHeading/PageHeading';
import './ImportMain.css';
import { ImportHintIcon, LoaderLogoCircleIcon, LoaderLogoNumberIcon } from '../../../../../assets/icons/import/import';
import { useNavigate } from 'react-router-dom';
import { CATALOG_IMPORT_FILE_LINK, CATALOG_IMPORT_LINK, CATALOG_MAIN_LINK, PLATFORM_MAIN_LINK } from '../../../../../assets/utils/constants';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import useAutoDismissError from '../../../../../assets/hooks/useAutoDismissError';
import mainApi from '../../../../../assets/api/MainApi';
import { parseApiError } from '../../../../../assets/utils/utils';
import { ProductPlaceholderIcon } from '../../../../../assets/icons/product/product';
import { UserContext } from '../../../../../assets/contexts/userContext';

const MAX_IMPORT_SIZE = 45

function ImportMain() {
    const { user } = useContext(UserContext)

    const [error, showError] = useAutoDismissError()
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFileLoading, setFileLoading] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (isFileLoading) return
        processFile(e.dataTransfer.files[0]);
    };

    const handleFileChange = (e) => {
        processFile(e.target.files[0]);
    };


    const navigate = useNavigate()
    const processFile = (file) => {
        if (file && (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'text/csv')) {
            if (file) {
                setSelectedFile(file);
                setFileLoading(true);
                if (file.size / 1000000 > MAX_IMPORT_SIZE) {
                    showError(`Файл слишком большой, максимальный размер файла ${MAX_IMPORT_SIZE}MB`)
                    setFileLoading(false)
                    setSelectedFile(null)
                    return
                }
                console.log(file);
                let data = new FormData();
                data.append("file", file);
                // setTimeout(() => {
                //     showError('Возникла проблема при загрузке файла, пожалуйста обратитесь в поддержку')
                //     setFileLoading(false)
                //     setSelectedFile(null)
                // }, 2500);

                mainApi
                    .shopImportProducts({ data, shop_id: user.default_shop._id })
                    .then((res) => {

                    })
                    .catch((err) => {
                        showError(parseApiError(err));
                    })
                    .finally(() => {
                        setFileLoading(false)
                    });




                // setTimeout(() => {
                //     navigate(`${PLATFORM_MAIN_LINK}/${CATALOG_MAIN_LINK}/${CATALOG_IMPORT_LINK}/${CATALOG_IMPORT_FILE_LINK}/${10}`)
                // }, 500);


                // mainApi
                //     .uploadImage({ data, type: "images" })
                //     .then((res) => {
                //         console.log(res);
                //         // handleChange({ type: 'parent', name: 'image', value: res, key_value })
                //         setForm((prevValue) => ({
                //             ...prevValue,
                //             children: {
                //                 ...prevValue.children,
                //                 [type]: prevValue.children[type].map((map_item) => {
                //                     if (
                //                         item._id
                //                             ? map_item._id === item._id
                //                             : map_item.random_id === item.random_id
                //                     )
                //                         return {
                //                             ...map_item,
                //                             image: res,
                //                         };
                //                     else return map_item;
                //                 }),
                //             },
                //         }));
                //     })
                //     .catch((err) => {
                //         console.log(err);
                //     })
                //     .finally(() => {
                //         setTimeout(() => {
                //             setPhotoLoading(false);
                //         }, 1000);
                //     });
            } else {
                // alert('File is too large. Maximum size is 30 MB.');
            }
        } else {
            return
        }
    };


    const actions = isFileLoading ?
        []
        :
        [
            {
                name: 'Помощь',
                path: `https://info.polza.tech/online-shop/products/import/`, //SUPPORT TABLE FORMATS LINK
                // isMainAction: true,
            },
        ]

    function handleReset() {
        setFileLoading(false)
        setSelectedFile(null)
    }

    return (
        <div className='import-main'>
            <PageHeading
                className={'import-main__heading'}
                title={'Импорт товаров из файла'}
                // goBack={{
                //     onClick: () => { console.log('fdffd') },
                // }}
                actions={actions}
            />
            <ErrorMessage error={error} />
            <ItemForm>
                <div
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    className='import-main__file-drop-area'
                >
                    {/* <input type="file" onChange={handleFileChange} /> */}
                    {!isFileLoading && !selectedFile ?
                        <label
                            className="import-main__file-input-label"
                            htmlFor={`import-file-input`}
                        // onClick={() => console.log(type, index)}
                        >
                            <input
                                className="import-main__file-input"
                                id={`import-file-input`}
                                name={`import-file-input`}
                                type="file"
                                accept=".xlsx,.csv"
                                onChange={(evt) => handleFileChange(evt)}
                            />
                            {/* {item.image ? childCard.edit_photo : childCard.upload_photo} */}
                        </label>
                        : null}
                    {isFileLoading ?
                        <div className='import-main__loader-box'>
                            <div className='import-main__loader'>
                                {/* <LoaderLogoNumberIcon
                                    mainClassName={'import-main__loader-logo-number-icon'}
                                    fillClassName={'import-main__loader-logo-number-icon-fill'}
                                /> */}
                                <LoaderLogoCircleIcon
                                    mainClassName={'import-main__loader-logo-circle-icon'}
                                    fillClassName={'import-main__loader-logo-circle-icon-fill'}
                                />
                            </div>
                            {/* <p className='import-main__hint-text'>Нажмите сюда, чтобы выбрать файл XLS или CSV, или перетащите его в&nbsp;это окно</p> */}
                            <p className='import-main__loader-text'>Идет загрузка файла <br /><span className='import-main__loader-text_file-name'>{selectedFile && selectedFile.name}</span></p>
                        </div>
                        :
                        selectedFile ?
                            < div className='import-main__hint-box'>
                                <ProductPlaceholderIcon
                                    mainClassName={'import-main__hint-icon'}
                                    fillClassName={'import-main__hint-icon-fill'}
                                />
                                <p className='import-main__hint-text'>
                                    {selectedFile && <span className='import-main__loader-text_file-name'>{selectedFile.name}</span>}
                                    <br />
                                    успешно загружен и находится в обработке.
                                    <br /><br />
                                    После завершения обработки, ваши товары будут доступны в разделе «Черновики».
                                    <br /><br />
                                    Ориентировочное время обработки файла — 24ч.
                                </p>
                                <button className={`import-main__control`} type='button' onClick={handleReset}>
                                    Загрузить еще
                                </button>
                            </div>

                            :

                            <div className='import-main__hint-box'>
                                <ImportHintIcon
                                    mainClassName={'import-main__hint-icon'}
                                    fillClassName={'import-main__hint-icon-fill'}
                                />
                                <p className='import-main__hint-text'>Нажмите сюда, чтобы выбрать файл XLSX или CSV, или перетащите его в&nbsp;это окно</p>
                            </div>


                    }

                </div>
            </ItemForm >
        </div >
    );
}

export default ImportMain