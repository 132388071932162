
import { useContext, useEffect } from 'react';
import './List.css';
import { UserContext } from '../../../../../../../../../assets/contexts/userContext';
import PreloaderBox from '../../../../../../../../PreloaderBox/PreloaderBox';
import Button from '../../../../../../../../Button/Button';
import { SETTINGS_PAGES_AND_FILES_DOCS_CREATE, SETTINGS_PAGES_AND_FILES_DOCS_ITEM } from '../../../../../../../../../assets/utils/constants';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteCrossIcon, SortArrowIcon } from '../../../../../../../../../assets/icons/controls/controls';
import SubmitActionPopup from '../../../../../../../SubmitActionPopup/SubmitActionPopup';
import { ActionsContext } from '../../../../../../../../../assets/contexts/actionsContext';
import { useList } from '../../../hooks/useList';
import { updateList } from '../../../utils/listUtils';

function List({
    deletePopupTextFormater,
    itemNameTextFormater,
    fetchItems,
    deleteItem,
    sortItems,
    emptyText = 'Тут пока пусто',
}) {
    const { setActions } = useContext(ActionsContext)
    const { user } = useContext(UserContext)
    const navigate = useNavigate()

    const {
        items,
        initialItems,
        preloaders,
        popups,
        itemToDelete,
        setItmes,
        setPopups,
        setItemToDelete,
        handleDeleteItem,
        handleSortItems,
    } = useList({
        user,
        fetchItems,
        deleteItem,
        sortItems,
    });


    const handleChangeList = (index, doc, change) => {
        setItmes(prevDocs => updateList(prevDocs, index, change === 'sort-up' ? 'up' : 'down'))
    };

    function handleToDelete({ item }) {
        setItemToDelete(item)
        setPopups(prevValue => ({
            ...prevValue,
            delete: true,
        }))
    }

    useEffect(() => {
        setActions(
            initialItems && items && !initialItems.every((item, index) => item === items[index]) ?
                [
                    {
                        name: "Сохранить сортировку",
                        onClick: handleSortItems,
                        // inactive: isSubmitDisabled,
                        isPreloaderVisible: preloaders.sort,
                        id: "pages-and-files_sort",
                    },
                    {
                        name: "Создать",
                        onClick: () => navigate(`${SETTINGS_PAGES_AND_FILES_DOCS_CREATE}`),
                        isMainAction: true,
                        // inactive: isSubmitDisabled,
                        id: "pages-and-files_new-doc",
                    },
                ]
                :
                [
                    {
                        name: "Создать",
                        onClick: () => navigate(`${SETTINGS_PAGES_AND_FILES_DOCS_CREATE}`),
                        isMainAction: true,
                        // inactive: isSubmitDisabled,
                        id: "pages-and-files_new-doc",
                    },
                ]
        )

    }, [initialItems, items, preloaders.sort])
    return (
        <div className='list'>
            <SubmitActionPopup
                isOpen={popups.delete}
                hendleClose={() => {
                    setPopups(prevValue => ({
                        ...prevValue,
                        delete: false,
                    }))
                    setTimeout(() => {
                        setItemToDelete(null)
                    }, 300);
                }}
                hendleSubmit={handleDeleteItem}
                text={deletePopupTextFormater(itemToDelete)}
                submitText={'Удалить'}
                cencelText={'Отменить'}
                isSubmitPreloader={preloaders.delete}
            />
            {preloaders.all ?
                <PreloaderBox />
                :
                <>
                    {items && items.length > 0 ?
                        <div className='list__list'>
                            <LayoutGroup>
                                <AnimatePresence>
                                    {items.map((item, i) => (
                                        <motion.div
                                            className={`list__list-item ${!item.is_visible ? 'list__list-item_invisible' : ''}`}
                                            key={`list__list-item-${item._id}}`}
                                            layout
                                        >
                                            <Link className='list__list-item-btn' to={`${SETTINGS_PAGES_AND_FILES_DOCS_ITEM}/${item._id}`}>
                                                {itemNameTextFormater(item)}
                                            </Link>
                                            <div className={`list__list-item-controls ${i === 0 ? 'list__list-item-controls_first' : ''}`}>
                                                <button className={`list__list-item-control ${i + 1 === items.length ? 'list__list-item-control_inactive' : ''}`} type='button' onClick={() => {
                                                    handleChangeList(i, item, 'sort-down')
                                                }}>
                                                    <SortArrowIcon
                                                        mainClassName={'list__list-item-control-icon'}
                                                        fillClassName={'list__list-item-control-icon-fill'}
                                                    />
                                                </button>
                                                <button className={`list__list-item-control ${i === 0 ? 'list__list-item-control_inactive' : ''}`} type='button' onClick={() => {
                                                    handleChangeList(i, item, 'sort-up')
                                                }}>
                                                    <SortArrowIcon
                                                        mainClassName={'list__list-item-control-icon list__list-item-control-icon_up'}
                                                        fillClassName={'list__list-item-control-icon-fill'}
                                                    />
                                                </button>
                                                <button className='list__list-item-control' type='button' onClick={() => {
                                                    handleToDelete({ item: item, })
                                                }}>
                                                    <DeleteCrossIcon
                                                        mainClassName={'list__list-item-control-icon'}
                                                        fillClassName={'list__list-item-control-icon-fill'}
                                                    />
                                                </button>
                                            </div>
                                        </motion.div>
                                    ))
                                    }
                                </AnimatePresence>
                            </LayoutGroup>
                        </div>
                        :
                        <div className='list__empty'>
                            <p className='list__create-title'>{emptyText}</p>
                            <Button
                                text={'Создать'}
                                path={`${SETTINGS_PAGES_AND_FILES_DOCS_CREATE}`}
                                isContrastColor={true}
                                className={'list__create-btn'}
                            />
                        </div>}
                </>}
        </div>
    );
}

export default List