import { Route, Routes } from 'react-router-dom';
import './Import.css';
import ImportMain from './ImportMain/ImportMain';
import ImportFile from './ImportFile/ImportFile';
import { CATALOG_IMPORT_FILE_LINK } from '../../../../assets/utils/constants';

function Import() {
    return (
        <div className='import'>
            <Routes>
                <Route index element={
                    <ImportMain />
                }>
                </Route>
                <Route path={`/${CATALOG_IMPORT_FILE_LINK}/:id/*`} element={
                    <ImportFile />
                }>
                </Route>
            </Routes>
        </div>
    );
}

export default Import