import "./CategoryBlock.css";
import { AddIcon } from "../../../../../../assets/icons/controls/controls";
import CategoryBtn from "./CategoryBtn/CategoryBtn";
import ProductPreviewCard from "./ProductPreviewCard/ProductPreviewCard";
import { ProductListIcon } from "../../../../../../assets/icons/category/category";
import { Link } from "react-router-dom";
import {
  CATALOG_DRAFTS_CREATE_ITEM_LINK,
  CATALOG_DRAFTS_LINK,
  CATALOG_MAIN_LINK,
  CATALOG_PRODUCTS_LINK,
  PLATFORM_MAIN_LINK,
  SUBCATEGORIES_LEVEL_LIMIT,
} from "../../../../../../assets/utils/constants";
import MiniPreloader from "../../../../../MiniPreloader/MiniPreloader";
import { useEffect, useRef } from "react";

function CategoryBlock({
  block,
  level,
  handleCategorySelect,
  selectedCategories,
  selectedProduct,
  handleProductSelect,
  handleAddInput,
  isInputOpen,
  addCategory,
  addCategoryInputOnKeyDown,
  values,
  handleChange,
  onMoreClick,
  isMoreBtnVisible,
  isPreloaderVisible,
  hasAddPermission,
  hasAddDraftPermission,
  hasEditPermission,
}) {
  const category_input_ref = useRef();

  useEffect(() => {
    if (
      isInputOpen.isOpen &&
      isInputOpen.level === level &&
      (!isInputOpen.parent ||
        isInputOpen.parent.translit_name === block.parent.translit_name) &&
      category_input_ref.current
    ) {
      category_input_ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputOpen, block, category_input_ref]);

  return (
    <div className="category-block">
      <div className="category-block__container">
        {block?.data?.length > 0
          ? block.parent?.is_final
            ? block.data.map((item) => (
              <ProductPreviewCard
                key={item._id}
                {...{
                  item,
                  selectedProduct,
                  handleProductSelect,
                }}
              />
            ))
            : block.data.map((item) => (
              <CategoryBtn
                key={item._id}
                isCategoryPreloader={isPreloaderVisible.category}
                {...{
                  item,
                  selectedCategories,
                  handleCategorySelect,
                  level,
                  hasEditPermission,
                }}
              />
            ))
          : null}

        {isInputOpen.isOpen &&
          isInputOpen.level === level &&
          (!isInputOpen.parent ||
            isInputOpen.parent.translit_name === block.parent.translit_name) && hasAddPermission ? (
          <form
            className="category-block__form"
            onSubmit={(evt) =>
              addCategory({
                evt,
                level,
                parent: block.parent ? block.parent : null,
              })
            }
            onKeyDown={addCategoryInputOnKeyDown}
          >
            <input
              className="category-block__input"
              name={`category-${level}`}
              value={values[`category-${level}`]}
              onChange={handleChange}
              ref={category_input_ref}
            />
            <button
              className={`category-block__submit-btn ${!Boolean(values[`category-${level}`]) || isPreloaderVisible.add
                  ? "category-block__submit-btn_disabled"
                  : ""
                }`}
              type="submit"
            >
              OK
            </button>
          </form>
        ) : null}

        {level === 0 && hasAddPermission ? (
          <button
            className="category-block__add-btn"
            type="button"
            onClick={() => handleAddInput(level)}
          >
            <AddIcon
              mainClassName="category-block__add-icon"
              fillClassName="category-block__icon-fill"
            />
            Добавить категорию
          </button>
        ) : null}

        {level > 0 &&
          level <= SUBCATEGORIES_LEVEL_LIMIT &&
          !block.parent?.is_final && hasAddPermission ? (
          <button
            className="category-block__add-btn"
            type="button"
            onClick={() => handleAddInput(level, block)}
          >
            <AddIcon
              mainClassName="category-block__add-icon"
              fillClassName="category-block__icon-fill"
            />
            Добавить категорию
          </button>
        ) : null}

        {block.parent?.is_final && isMoreBtnVisible ? (
          <button
            className={`category-block__more-btn ${isPreloaderVisible.feed ? "category-block__more-btn_disabled" : ""
              }`}
            type="button"
            onClick={onMoreClick}
          >
            <p
              className={`category-block__more-btn-text ${!isPreloaderVisible.feed
                  ? "category-block__more-btn-text_visible"
                  : ""
                }`}
            >
              Загрузить ещё
            </p>
            <span
              className={`category-block__btn-preloader ${isPreloaderVisible.feed
                  ? "category-block__btn-preloader_visible"
                  : ""
                }`}
            >
              <MiniPreloader />
            </span>
          </button>
        ) : null}

        {block.parent?.is_final && block.data?.length > 0 ? (
          <Link
            className="category-block__add-btn"
            to={`${PLATFORM_MAIN_LINK}/${CATALOG_MAIN_LINK}/${CATALOG_PRODUCTS_LINK}`}
          >
            <ProductListIcon
              mainClassName="category-block__add-icon"
              fillClassName="category-block__icon-fill"
            />
            Открыть список товаров
          </Link>
        ) : null}

        {level > 0 &&
          ((level <= SUBCATEGORIES_LEVEL_LIMIT &&
            (block.parent?.is_final || block.parent?.is_final === null)) ||
            level > SUBCATEGORIES_LEVEL_LIMIT) &&
          block.data?.filter((fliter_item) => fliter_item.translit_name)
            .length === 0 && hasAddDraftPermission ? (
          <Link
            className="category-block__add-btn"
            to={`${PLATFORM_MAIN_LINK}/${CATALOG_MAIN_LINK}/${CATALOG_DRAFTS_LINK}/${CATALOG_DRAFTS_CREATE_ITEM_LINK}`}
          >
            <AddIcon
              mainClassName="category-block__add-icon"
              fillClassName="category-block__icon-fill"
            />
            Добавить товары
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default CategoryBlock;
