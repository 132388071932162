import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import validator from 'validator'

import PageHeading from '../../../PageHeading/PageHeading';
import { DEBOUNCE_TIME, PLATFORM_MAIN_LINK, PROMOCODES_MAIN_LINK, SETTINGS_MAIN_LINK, SETTINGS_USERS_LINK } from '../../../../../assets/utils/constants';
import ItemForm from '../../../ItemForm/ItemForm';
import PlatformInput from '../../../PlatformInput/PlatformInput';
import './UsersCreate.css';
import mainApi from '../../../../../assets/api/MainApi';
import { UserContext } from '../../../../../assets/contexts/userContext';
import PreloaderBox from '../../../../PreloaderBox/PreloaderBox';
import { createAction, hasPermission, parseApiError, parsePermissions } from '../../../../../assets/utils/utils';
import { PERMISSIONS, RBAC_USER_MANAGEMENT } from '../../../../../assets/utils/permissions_rbac';
import useAutoDismissError from '../../../../../assets/hooks/useAutoDismissError';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';




function UsersCreate() {
    const navigate = useNavigate()

    const [values, setVelues] = useState({
        name: '',
        email: '',
        role: null,
        // code: '',
        // discont: '',
        // limit_date: '',
        // limit_count: '',
    })


    const [valuesValidity, setVeluesValidity] = useState({
        email: {},
    })

    const [preloaders, setPreloaders] = useState({
        roles: true,
        save: false,
    })
    const [roles, setRoles] = useState(null)
    useEffect(() => {
        setPreloaders(prevValue => ({
            ...prevValue,
            roles: true,
        }))
        mainApi.getUserRoles()
            .then((res) => {
                console.log(res.data)
                setRoles(res.data)
                setVelues(prevValue => ({
                    ...prevValue,
                    role: res.data.map((item) => {
                        return {
                            ...item,
                            hint: item.description.ru,
                            title: item.title.ru,
                        }
                    })[0],
                }))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    roles: false,
                }))
            })
    }, [])


    const { user } = useContext(UserContext)
    const [error, showError] = useAutoDismissError()

    function handleSave() {
        setPreloaders(prevValue => ({
            ...prevValue,
            save: true,
        }))
        mainApi.createUserManagmentUser({
            shop_id: user.default_shop._id,
            email: values.email,
            comment: values.name,
            role_id: values.role._id,
        })
            .then((res) => {
                console.log(res)
                navigate(`${PLATFORM_MAIN_LINK}/${SETTINGS_MAIN_LINK}/${SETTINGS_USERS_LINK}`)
            })
            .catch((err) => {
                console.log(err)
                showError(parseApiError(err))
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    save: false,
                }))
            })
    }

    const hasAddPermission = hasPermission(parsePermissions(user), [RBAC_USER_MANAGEMENT[PERMISSIONS.ADD]])


    function isSaveInactive() {
        // Consolidate the condition to determine the "Save" button's inactive state
        return !(values.name && values.email && valuesValidity.email.validState && values.role);
    }

    function addActionsBasedOnPermissions() {
        const actions = [];

        if (hasAddPermission) {
            actions.push(createAction('Сохранить', handleSave, {
                isMainAction: true,
                inactive: isSaveInactive(),
                isPreloaderVisible: preloaders.save,
            }));
        }

        return actions;
    }

    const actions = addActionsBasedOnPermissions();

    function handleChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name

        switch (name) {

            case "email": {
                setVeluesValidity((prevValue) => ({
                    ...prevValue,
                    [name]: {
                        errorMessage: "",
                        validState: false,
                    },
                }));
                setVelues((prevValue) => ({
                    ...prevValue,
                    [name]: value,
                }));
                break;
            }
            default:
                setVelues(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
        }
    }


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log(values.email)
            if (!values.email || values.email.length === 0) return
            if (!values.email) {
                setVeluesValidity((prevValue) => ({
                    ...prevValue,
                    email: {
                        errorMessage: "",
                        validState: false,
                    },
                }));
            }
            if (values.email.length >= 5) {
                if (validator.isEmail(values.email)) {
                    setVeluesValidity((prevValue) => ({
                        ...prevValue,
                        email: {
                            errorMessage: "",
                            validState: true,
                        },
                    }));
                    // ADD LOGIC TO CHECK UNIQUNESS OF EMAIL IN CURRENT SHOP/SYSTEM
                } else {
                    setVeluesValidity((prevValue) => ({
                        ...prevValue,
                        email: {
                            errorMessage: "Несуществующая эл. почта",
                            validState: false,
                        },
                    }));
                }
            }

        }, DEBOUNCE_TIME)

        return () => {
            clearTimeout(delayDebounceFn);
        }
    }, [values.email])

    return (

        <div className='users-create'>
            <PageHeading
                className={'users-create__heading'}
                title={'Новый пользователь'}
                goBack={{
                    onClick: () => { navigate(`${PLATFORM_MAIN_LINK}/${SETTINGS_MAIN_LINK}/${SETTINGS_USERS_LINK}`) },
                }}
                actions={actions}
            />
            <ErrorMessage error={error} />
            <ItemForm>
                <>
                    {
                        !preloaders.roles ?
                            <div className='users-create_form'>
                                <div className='users-create_form-column'>
                                    <PlatformInput
                                        value={values.name}
                                        placeholder={''}
                                        label={'Имя'}
                                        // hint={'Видно только вам'}
                                        handleChange={handleChange}
                                        name={'name'}
                                    >
                                    </PlatformInput>
                                    <PlatformInput
                                        value={values.email}
                                        placeholder={''}
                                        label={'Эл. почта'}
                                        // hint={'Видно только вам'}
                                        handleChange={handleChange}
                                        name={'email'}
                                        error={valuesValidity.email.errorMessage ? valuesValidity.email.errorMessage : ''}
                                    >
                                    </PlatformInput>
                                    <PlatformInput
                                        value={values.role}
                                        selected_text_value={values.role ? values.role.title ? values.role.title : '' : ''}
                                        placeholder={''}
                                        label={'Роль'}
                                        hint={values.role ? values.role.hint ? values.role.hint : '' : ''}
                                        handleChange={handleChange}
                                        name={'role'}
                                        isSelect={true}
                                        selectList={roles.map((item) => {
                                            return {
                                                ...item,
                                                hint: item.description.ru,
                                                title: item.title.ru,
                                            }
                                        })}
                                        selectDifferenceKey={'_id'}
                                        selectTextValueKey={'title'}
                                    >
                                    </PlatformInput>
                                </div>
                            </div>
                            :
                            <PreloaderBox />
                    }
                </>
            </ItemForm>
        </div>


    );
}

export default UsersCreate