import { useNavigate, useParams } from "react-router-dom";
import PageHeading from "../../PageHeading/PageHeading";
import "./CreatePromocodeGroup.css";
import {
  PLATFORM_MAIN_LINK,
  PROMOCODES_MAIN_LINK,
} from "../../../../assets/utils/constants";
import { useContext, useEffect, useState } from "react";
import ItemForm from "../../ItemForm/ItemForm";
import PlatformInput from "../../PlatformInput/PlatformInput";
import { UserContext } from "../../../../assets/contexts/userContext";
import mainApi from "../../../../assets/api/MainApi";
import useAutoDismissError from "../../../../assets/hooks/useAutoDismissError";
import { createAction, hasPermission, parseApiError, parsePermissions } from "../../../../assets/utils/utils";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { PERMISSIONS, RBAC_COUPONS } from "../../../../assets/utils/permissions_rbac";

function CreatePromocodeGroup() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const [group, setGroup] = useState(undefined);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(false);
  const [isNamePreloader, setIsNamePreloader] = useState(false);
  const [error, showError] = useAutoDismissError();
  const [values, setValues] = useState({
    name: "",
    // code: '',
    // discont: '',
    // limit_date: '',
    // limit_count: '',
  });

  const hasAddPermission = hasPermission(parsePermissions(user), [RBAC_COUPONS[PERMISSIONS.ADD]])



  function isSaveActionInactive() {
    // Logic to determine if the "Save" action should be inactive
    return !(values.name && user?.email_verified && values.name !== group?.name) ||
      isPreloaderVisible ||
      isNamePreloader;
  }

  function addSaveAction() {
    const actions = [];

    if (hasAddPermission) {
      actions.push(createAction("Сохранить", handleSave, {
        isMainAction: true,
        inactive: isSaveActionInactive(),
        isPreloaderVisible: isPreloaderVisible,
      }));
    }

    return actions;
  }

  const actions = addSaveAction();
  // const [valuesValidity, setValuesValidity] = useState({
  //     code: {},
  // })

  useEffect(() => {
    if (!user || !id) return;

    setIsNamePreloader(true);
    const shop_id = user.default_shop._id;
    mainApi
      .getExactCouponGroup({ shop_id, _id: id })
      .then((res) => {
        setValues((prevVal) => ({ ...prevVal, name: res.name }));
        setGroup(res);
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setValues({
            name: "",
            // code: '',
            // discont: '',
            // limit_date: '',
            // limit_count: '',
          });
          setGroup(undefined)
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsNamePreloader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  function handleChange(e) {
    const input = e.target;
    let value = input.value;
    const name = input.name;

    switch (name) {
      default:
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;
    }
  }

  function handleSave() {
    const shop_id = user.default_shop._id;
    setIsPreloaderVisible(true);
    id ? editGroup({ shop_id }) : createGroup({ shop_id });
  }

  function createGroup({ shop_id }) {
    mainApi
      .createCouponGroup({ shop_id, name: values.name })
      .then(() => {
        navigate(`${PLATFORM_MAIN_LINK}/${PROMOCODES_MAIN_LINK}`);
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
      });
  }

  function editGroup({ shop_id }) {
    mainApi
      .editCouponGroup({ shop_id, name: values.name, _id: group._id })
      .then(() => {
        navigate(`${PLATFORM_MAIN_LINK}/${PROMOCODES_MAIN_LINK}`);
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
      });
  }

  return (
    <div className="create-promocode-group">
      <PageHeading
        className={"create-promocode-group__heading"}
        title={id ? group?.name : "Новая группа"}
        goBack={{
          onClick: () => {
            navigate(`${PLATFORM_MAIN_LINK}/${PROMOCODES_MAIN_LINK}`);
          },
        }}
        actions={actions}
        isTitlePreloader={isNamePreloader}
      />
      <ErrorMessage error={error} />
      <ItemForm>
        <div className="create-promocode-group_form">
          <div className="create-promocode-group_form-column">
            <PlatformInput
              value={values.name}
              placeholder={""}
              label={"Название"}
              hint={"Видно только вам"}
              handleChange={handleChange}
              name={"name"}
            ></PlatformInput>
          </div>
        </div>
      </ItemForm>
    </div>
  );
}

export default CreatePromocodeGroup;
