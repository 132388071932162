import { NavLink } from 'react-router-dom';
import './InternalLinks.css';

function InternalLinks({
    links,
    keyValue,
}) {
    return (
        <div className='internal-links'>
            <div className='internal-links__heading'>
                <p className='internal-links__title'>Название</p>
            </div>
            <div className='internal-links__links'>
                {links.map((item, i) => (
                    <NavLink
                        key={`internal-links__link-${i}${keyValue}`}
                        to={`${item.path}`}
                        className={({ isActive }) =>
                            `internal-links__link ${isActive ? "internal-links__link_active" : ""
                            } ${item.inactive ? 'internal-links__link_inactive' : '' }`
                        }
                    >
                        {item.title}
                    </NavLink>
                ))}
            </div>
        </div>
    );
}

export default InternalLinks