import { Link, useLocation } from "react-router-dom";
import "./CategoryBtn.css";
import {
  EditIcon,
  LabelIcon,
  ProductIcon,
} from "../../../../../../../assets/icons/category/category";
import { CATALOG_CATEGORY_EDIT_LINK } from "../../../../../../../assets/utils/constants";
import MiniPreloader from "../../../../../../MiniPreloader/MiniPreloader";

function CategoryBtn({
  item,
  level,
  selectedCategories,
  handleCategorySelect,
  isCategoryPreloader,
  hasEditPermission,
}) {
  const location = useLocation();
  const isSelected =
    selectedCategories?.length > 0 &&
    selectedCategories[level]?._id === item._id;
  const isLastSelected =
    selectedCategories.length > 0 &&
    selectedCategories.length - 1 === level &&
    selectedCategories[level]?._id === item._id &&
    isCategoryPreloader;

  return (
    <button
      className={`category-btn ${isSelected ? "category-btn_selected" : ""} ${isCategoryPreloader ? "category-btn_disabled" : ""
        }`}
      type="button"
      onClick={() => handleCategorySelect({ data: item, level })}
    >
      <p className="category-btn__title">{item.name}</p>
      <div className="category-btn__info">
        {isLastSelected ? (
          <MiniPreloader />
        ) :
          hasEditPermission ? (
            <Link
              className="category-btn__edit-link"
              to={`${location.pathname}/${CATALOG_CATEGORY_EDIT_LINK}/${item._id}`}
            >
              <EditIcon
                mainClassName="category-btn__edit-icon"
                fillClassName="category-btn__icon-fill"
              />
            </Link>
          )
            :
            null}
        {item.is_final ? (
          <div className="category-btn__label">
            <p className="category-btn__label-text">{item.count}</p>
            <ProductIcon
              mainClassName="category-btn__product-icon"
              fillClassName="category-btn__icon-fill"
            />
          </div>
        ) : item.is_final !== null ? (
          <div className="category-btn__label">
            <p className="category-btn__label-text">{item.count}</p>
            <LabelIcon
              mainClassName="category-btn__label-icon"
              fillClassName="category-btn__icon-fill"
            />
          </div>
        ) : null}
      </div>
    </button>
  );
}

export default CategoryBtn;
