import { useState } from 'react';
import validator from 'validator';
import './Recovery.css';
import Input from '../../../Input/Input';
import Button from '../../../Button/Button';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AUTH_LOGIN_LINK, AUTH_RECOVERY_LINK, AUTH_RECOVERY_STEP_2_LINK } from '../../../../assets/utils/constants';
import mainApi from '../../../../assets/api/MainApi';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import useAutoDismissError from '../../../../assets/hooks/useAutoDismissError';
import { parseApiError } from '../../../../assets/utils/utils';

function Recovery() {
  const [searchParams] = useSearchParams()
  const email_in_processing = searchParams.get('email_in_processing')

  const [values, setVelues] = useState({
    email: '',
  })

  const [valuesValidity, setVeluesValidity] = useState({
    email: {},
  })

  function handleChange(e) {
    const input = e.target;
    let value = input.value;
    const name = input.name

    switch (name) {

      case "email":
        if (!value) {
          setVeluesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: false,
            },
          }));
        }
        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setVeluesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: "",
                validState: true,
              },
            }));
          } else {
            setVeluesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: !e.target.validity.valid
                  ? e.target.validationMessage
                  : "Invalid email",
                validState: false,
              },
            }));
          }
        }
        setVelues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;

      default:
        setVelues(prevValue => ({
          ...prevValue,
          [name]: value,
        }))
        break;
    }
  }


  const navigate = useNavigate()
  const [error, showError] = useAutoDismissError();

  const [isLoading, setLoading] = useState(false)
  function handleSubmit() {
    setLoading(true)
    mainApi.resetPasswordNOAuth({
      email: values.email,
    })
      .then((res) => {
        console.log(res)
        navigate(`${AUTH_RECOVERY_STEP_2_LINK}?${new URLSearchParams({ email_in_processing: values.email })}`)
      })
      .catch((err) => {
        console.log(err)
        showError(parseApiError(err))
      })
      .finally(() => {
        setLoading(false)
      })

  }

  return (
    <div className='recovery'>
      {email_in_processing ?
        <>
          <Button
            className={'recovery__ok-btn'}
            isContrastColor={true}
            text={'ОК'}
            path={AUTH_LOGIN_LINK}
            isInvalid={!(values.email && valuesValidity.email.validState)}
          />
        </>
        :
        <>
          <div className='recovery__form'>
            <Input
              value={values.email}
              placeholder={'Эл. почта'}
              handleChange={handleChange}
              name={'email'}
              inputMode='email'
            />
            <Button
              isLoading={isLoading}
              isContrastColor={true}
              text={'Сбросить'}
              onClick={handleSubmit}
              isInvalid={!(values.email && valuesValidity.email.validState)}
            />
            <ErrorMessage error={error} />
          </div>
          <Link className='recovery__go-back' to={AUTH_LOGIN_LINK}>&#8249; Назад</Link>
        </>}

    </div>
  );
}

export default Recovery