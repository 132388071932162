import { useContext, useEffect, useState } from 'react';
import './Finance.css';
import mainApi from '../../../../assets/api/MainApi';
import ItemForm from '../../ItemForm/ItemForm';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import PageHeading from '../../PageHeading/PageHeading';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../../assets/contexts/userContext';
import { PlatformLinksContext } from '../../../../assets/contexts/platformLinksContext';
import { getCurrentPlatfromLocation, parseApiError } from '../../../../assets/utils/utils';
import useAutoDismissError from '../../../../assets/hooks/useAutoDismissError';
import PreloaderBox from '../../../PreloaderBox/PreloaderBox';
import FinanceInfo from './FinanceInfo/FinanceInfo';
import TransactionsList from './TransactionsList/TransactionsList';



function Finance() {

    const { user, setUser } = useContext(UserContext)
    const location = useLocation();

    const { links, default_link } = useContext(PlatformLinksContext)

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })


    const [preloaders, setPreloaders] = useState({
        subscription: true,
    })


    const [data, setData] = useState([])
    const [error, showError] = useAutoDismissError()
    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            subscription: true,
        }))
        mainApi.getShopSubscrtiption({
            shop_id: user.default_shop._id
        })
            .then((res) => {
                // console.log(res.subscriptions.main)
                setData(res)
            })
            .catch((err) => {
                console.log(err)
                showError(parseApiError(err))
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    subscription: false
                }))
            })

       

    }, [user])


    return (
        <div className='finance'>
            <PageHeading
                className={'finance__heading'}
                title={current_location.location.name}
            // goBack={{
            //     onClick: () => { console.log('fdffd') },
            // }}
            // actions={actions}
            />
            <ErrorMessage error={error} />
            <div
                containerClassName={'finance__item-from-container'}
            >
                {!preloaders.subscription && data ?
                    <FinanceInfo info={data} setInfo={setData} />
                    :
                    <PreloaderBox />
                }
                <TransactionsList />
            </div>

        </div>
    );
}

export default Finance