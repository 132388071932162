import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';

import "./FilterCard.css";
import FilterControls from '../FilterControls/FilterControls';
import FilterEditForm from '../FilterEditForm/FilterEditForm';
import { useContext, useState } from 'react';
import mainApi from '../../../../../assets/api/MainApi';
import { UserContext } from '../../../../../assets/contexts/userContext';
import useAutoDismissError from '../../../../../assets/hooks/useAutoDismissError';
import { parseApiError, parsePrice } from '../../../../../assets/utils/utils';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import PlatformInput from '../../../PlatformInput/PlatformInput';

const FILTER_NAMES_MAP = {
    'integer': {
        ru: 'Несколько вариантов',
        value: (filter) => filter.features.values,
    },
    'string': {
        ru: 'Несколько вариантов',
        value: (filter) => filter.features.values,
    },
    'range': {
        ru: 'Диапазон',
        value: (filter) => `от ${parsePrice(filter.features.gte, 'RUB')} до ${parsePrice(filter.features.lte, 'RUB')}`,
    },
    'bool': {
        ru: 'Да/Нет',
    },
}

function FilterCard({
    filter,
    handleToggleFilter,
    handleToggleEdit,
    handleSaveEditedFilter,
    handleConvertFilterClick,
}) {
    const { user } = useContext(UserContext);
    const [preloaders, setPreloaders] = useState({
        save: false,
    })
    const [values, setValues] = useState(filter)

    function handleToggleClick() {
        handleToggleFilter({ filter })
    }

    function handleEditClick() {
        handleToggleEdit({ _id: filter._id })
    }

    function handleCancelEdit() {
        setValues(filter)
        handleToggleEdit({ _id: filter._id })
    }

    const [error, showError] = useAutoDismissError();
    function handleSaveEdit() {
        setPreloaders(prevValue => ({
            ...prevValue,
            save: true,
        }))
        const shop_id = user.default_shop._id;
        mainApi.editFilter({
            shop_id,
            name: values.name,
            is_active: values.is_active,
            _id: filter._id,
        })
            .then((res) => {
                console.log(res)
                handleSaveEditedFilter({
                    _id: filter._id,
                    name: values.name,
                    is_active: values.is_active,
                })
            })
            .catch((err) => {
                console.log(err)
                showError(parseApiError(err))
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    save: false,
                }))

            })

    }
    // console.table({ filter_type: filter.filter_type, data_type: filter.data_type })
    const MAPED_FILTER = FILTER_NAMES_MAP[filter.data_type]
    const MAPED_VALUE = MAPED_FILTER.value ? MAPED_FILTER.value(filter) : null

    function handleConvert() {
        handleConvertFilterClick({ filter })
    }
    return (
        <motion.div className={`filter-card ${filter._is_edit_active ? 'filter-card_type_edit' : ''}`}>

            <div className="filter-card__heading">
                <div className='filter-card__name-box'>
                    <p className="filter-card__name">{filter.name}</p>
                    <span className='filter-card__name filter-card__name-type'>({MAPED_FILTER ? MAPED_FILTER.ru : ''})</span>
                </div>

                <FilterControls
                    handleEditClick={handleEditClick}
                    editActive={filter._is_edit_active}
                    isToggleActive={filter.is_active}
                    handleToggleClick={handleToggleClick}
                    is_special={filter.is_special}
                    handleCancelEdit={handleCancelEdit}
                    save_inavtive={!(values.name && values.name !== filter.name)}
                    handleSaveEdit={handleSaveEdit}
                    save_preloader_active={preloaders.save}
                />
            </div>
            {MAPED_VALUE && typeof MAPED_VALUE === 'string' ? <p className='filter-card__value'>{MAPED_VALUE}</p> : null}
            {MAPED_VALUE && Array.isArray(MAPED_VALUE) && !filter._is_edit_active ?
                <div className='filter-card__options'>
                    {MAPED_VALUE.map((item, i) => (
                        <p className='filter-card__option' key={`filter-${filter._id}__option-${i}`}>
                            {item}
                        </p>
                    ))}
                </div>
                : null}
            <AnimatePresence>
                <ErrorMessage error={error} />
                {filter._is_edit_active && (
                    <motion.div
                        initial={{ opacity: 0, height: 0, y: -20, }}
                        animate={{ opacity: 1, height: 'auto', y: 0, }}
                        exit={{ opacity: 0, height: 0, y: -20, }}
                        transition={{ duration: 0.3 }}
                        className='filter-card__form'
                    >
                        <FilterEditForm
                            values={values}
                            setValues={setValues}
                            handleConvert={handleConvert}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
}

export default FilterCard;
