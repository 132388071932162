import React, { useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import font_normal from '../../../../../../assets/fonts/Roboto/Roboto-Regular.ttf';
import font_bold from '../../../../../../assets/fonts/Roboto/Roboto-Bold.ttf';


import logo from '../../../../../../assets/images/utils/pdf-polza-logo.png'
import { formatAddress, parsePhone, parsePrice, parseStatus } from '../../../../../../assets/utils/utils';
import moment from 'moment-timezone';
import { PAYMENT_TYPE_VALUES } from '../../../../../../assets/utils/constants';


function getArticleOrSysId(paramsArray, SYS_ID) {
    const articleParam = paramsArray.find(param => param.name === "Артикул");
    if (articleParam) {
        return articleParam.value;
    } else {
        return `Уникальный ID: ${SYS_ID}`;
    }
}


// Register font
Font.register({
    family: 'Roboto',
    fontWeight: 'normal',
    src: font_normal,
});
Font.register({
    family: 'Roboto',
    fontWeight: 'bold',
    src: font_bold,
});

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: "Roboto"
    },
    title: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20,
    },

    section: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        maxWidth: '170px',
    },
    sectionRight: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        maxWidth: '170px',
    },
    tableTitleBox: {
        marginTop: 30,
    },
    tableTitle: {
        fontSize: '12px'
    },
    table: {
        display: 'table',
        width: 'auto',
        marginTop: 10,
    },
    tableRow: {
        flexDirection: 'row',
        marginTop: '-1px',
    },
    tableCellHeader: {
        margin: '4px',
        fontSize: '8px',
        fontWeight: 'normal',
        textAlign: 'left',
    },
    tableColHeaderItemNumber: {
        width: '5%',
        borderStyle: 'solid',
        borderWidth: 1,
        alignItems: 'flex-end',
    },
    tableColHeaderPozition: {
        marginLeft: '-1px',
        width: '40%',
        borderStyle: 'solid',
        borderWidth: 1,
        textAlign: 'left',
    },
    tableColHeaderArticle: {
        marginLeft: '-1px',
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 1,
    },
    tableColHeaderAmount: {
        marginLeft: '-1px',
        width: '10%',
        borderStyle: 'solid',
        borderWidth: 1,
        alignItems: 'flex-end',
    },
    tableColHeaderPrice: {
        marginLeft: '-1px',
        width: '12.5%',
        borderStyle: 'solid',
        borderWidth: 1,
        alignItems: 'flex-end',
    },
    tableColItemNumber: {
        width: '5%',
        fontSize: '8px',
        borderStyle: 'solid',
        alignItems: 'flex-end',
        borderWidth: 1,
    },
    tableColPozition: {
        marginLeft: '-1px',
        fontSize: '8px',
        width: '40%',
        borderStyle: 'solid',
        borderWidth: 1,
    },
    tableColArticle: {
        marginLeft: '-1px',
        fontSize: '8px',
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 1,
        maxWidth: '20%',
    },
    tableCellNumber: {
        margin: '5px',
        fontSize: '8px',
        fontWeight: 'bold',
        textAlign: 'left',
    },
    tableColAmount: {
        marginLeft: '-1px',
        fontSize: '8px',
        width: '10%',
        borderStyle: 'solid',
        alignItems: 'flex-end',
        borderWidth: 1,
    },
    tableColPrice: {
        marginLeft: '-1px',
        fontSize: '8px',
        width: '12.5%',
        borderStyle: 'solid',
        borderWidth: 1,
        alignItems: 'flex-end',
    },
    tableColPriceTotal: {
        marginLeft: '-1px',
        fontSize: '8px',
        width: '12.5%',
        borderStyle: 'solid',
        borderWidth: 1,
        alignItems: 'flex-end',
        fontWeight: 'bold',
    },
    tableCell: {
        margin: '5px',
        fontSize: '8px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },

    tableCellBarcode: {
        margin: '5px',
        fontSize: '8px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        fontFamily: 'LibreBarcodeEAN13Text'
    },
    heading: {
        marginBottom: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headingLogo: {
        width: '83px',
        height: '20px',
    },
    sectionText: {
        fontSize: 10,
        width: '120px',
    },
    info: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    sectionTextBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
    },
    sectionTitle: {
        fontSize: 10,
        fontWeight: 'bold',
        width: '40px',
    },
    sectionTitleRight: {
        fontSize: 10,
        fontWeight: 'bold',
        width: '70px',
    },
    separator: {
        width: '100%',
        height: '1px',
        backgroundColor: '#000000',
        opacity: '0.3',
        marginTop: '20px',
        marginBottom: '10px'
    },
    total: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    totalTitle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    totalPromoTitle: {
        fontSize: 14,
        fontWeight: 'light',
    },
    totalValue: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    totalPromo: {
        fontSize: 16,
        fontWeight: 'light',
    },
    headingPages: {
        fontSize: '8px',
    }
});

function encodeToEAN13(input) {
    // Ensure the input is numeric
    if (!/^\d+$/.test(input)) {
        return false
    }

    // Pad the input to ensure it's 12 digits
    input = input.padStart(12, '0');

    // Calculate the check digit
    let sum = 0;
    for (let i = 0; i < 12; i++) {
        let digit = parseInt(input[i], 10);
        if (i % 2 === 0) {
            sum += digit;
        } else {
            sum += digit * 3;
        }
    }

    let checkDigit = (10 - (sum % 10)) % 10;

    // Return the full EAN-13 code
    return input + checkDigit;
}

// Create PDF document component
function OrderPdfGenerator({ data, orderItmes }) {

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.heading} fixed>
                    <Image style={styles.headingLogo} src={logo} />
                    <Text style={styles.headingPages} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} />
                </View>
                <View style={styles.info}>
                    <View style={styles.section}>
                        <View style={styles.sectionTextBox}>
                            <Text style={styles.sectionTitle}>Заказчик:</Text>
                            <Text style={styles.sectionText}>{data.first_name} {data.last_name}</Text>
                        </View>
                        <View style={styles.sectionTextBox}>
                            <Text style={styles.sectionTitle}>Телефон:</Text>
                            <Text style={styles.sectionText}>{parsePhone(data.phone)}</Text>
                        </View>
                        <View style={styles.sectionTextBox}>
                            <Text style={styles.sectionTitle}>Email:</Text>
                            <Text style={styles.sectionText}>{data.email}</Text>
                        </View>
                        <View style={styles.sectionTextBox}>
                            <Text style={styles.sectionTitle}>Адрес:</Text>
                            <Text style={styles.sectionText}>{formatAddress(data.address)}</Text>
                        </View>
                    </View>
                    <View style={styles.sectionRight}>
                        <View style={styles.sectionTextBox}>
                            <Text style={styles.sectionTitleRight}>Номер заказа:</Text>
                            <Text style={styles.sectionText}>№{data.ai_id}</Text>
                        </View>
                        <View style={styles.sectionTextBox}>
                            <Text style={styles.sectionTitleRight}>Статус:</Text>
                            <Text style={styles.sectionText}>{parseStatus(data.status)}</Text>
                        </View>
                        <View style={styles.sectionTextBox}>
                            <Text style={styles.sectionTitleRight}>Метод оплаты:</Text>
                            <Text style={styles.sectionText}>{PAYMENT_TYPE_VALUES[data.payment_type]}</Text>
                        </View>
                        <View style={styles.sectionTextBox}>
                            <Text style={styles.sectionTitleRight}>Дата заказа:</Text>
                            <Text style={styles.sectionText}>{moment(data.utc_date).format('DD.MM.YYYY HH:mm')}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.tableTitleBox}>
                    <Text style={styles.tableTitle}>Состав заказа:</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderItemNumber}>
                            <Text style={styles.tableCellHeader}>№</Text>
                        </View>
                        <View style={styles.tableColHeaderPozition}>
                            <Text style={styles.tableCellHeader}>Позиция</Text>
                        </View>
                        <View style={styles.tableColHeaderArticle}>
                            <Text style={styles.tableCellHeader}>Артикул</Text>
                        </View>
                        <View style={styles.tableColHeaderAmount}>
                            <Text style={styles.tableCellHeader}>Кол-во</Text>
                        </View>
                        <View style={styles.tableColHeaderPrice}>
                            <Text style={styles.tableCellHeader}>Стоимость</Text>
                        </View>
                        <View style={styles.tableColHeaderPrice}>
                            <Text style={styles.tableCellHeader}>Итого</Text>
                        </View>
                    </View>

                    {orderItmes.map((item, index) => {
                        return (
                            <View style={styles.tableRow} key={item._id} wrap={false}>
                                <View style={styles.tableColItemNumber}>
                                    <Text style={styles.tableCellNumber}>{index + 1}</Text>
                                </View>
                                <View style={styles.tableColPozition}>
                                    <Text style={styles.tableCell}>{item.item.name}{item.options && item.options.length > 0 ? `, ` : ''}{item.options.map(opt => `${opt.name}: ${opt.value}`).join(', ')}</Text>
                                </View>
                                <View style={styles.tableColArticle}>
                                    <Text style={styles.tableCell}>{getArticleOrSysId(item.item.parameters, item.item.unique_id)?.split('').map((seg, i) => { return (seg) })}</Text>
                                </View>

                                <View style={styles.tableColAmount}>
                                    <Text style={styles.tableCell}>{item.amount} шт.</Text>
                                </View>
                                <View style={styles.tableColPrice}>
                                    <Text style={styles.tableCell}>{parsePrice(item.price, 'rub')}</Text>
                                </View>
                                <View style={styles.tableColPriceTotal}>
                                    <Text style={styles.tableCell}>{parsePrice(`${Number(item.price) * Number(item.amount)}`, 'rub')}</Text>
                                </View>
                            </View>
                        )
                    }
                    )}
                    <View style={styles.separator} />
                    {data.discount && data.promo_code ?
                        <View style={styles.total}>
                            <Text style={styles.totalPromoTitle}>Скидка по промокоду {data.promo_code} (-{data.discount}%)</Text>
                            <Text style={styles.totalPromo}>{parsePrice(Number(data.items_price) - Number(data.total_price), 'rub')}</Text>
                        </View>
                        : null}
                    <View style={styles.total}>
                        <Text style={styles.totalTitle}>Итого</Text>
                        <Text style={styles.totalValue}>{parsePrice(data.total_price, 'rub')}</Text>
                    </View>

                </View>
            </Page>
        </Document>
    )
};

export default OrderPdfGenerator;
