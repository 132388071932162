import { useState } from 'react';
import validator from 'validator';
import Input from '../../../Input/Input';
import './Login.css';
import Button from '../../../Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { AUTH_RECOVERY_LINK, AUTH_SIGNUP_LINK, PLATFORM_MAIN_LINK, PLATFORM_MAIN_LINKS, SUPPORT_AUTH_LINK } from '../../../../assets/utils/constants';
import mainApi from '../../../../assets/api/MainApi';
import { parseApiError } from '../../../../assets/utils/utils';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import useAutoDismissError from '../../../../assets/hooks/useAutoDismissError';

function Login({
    setUser,
}) {

    const [values, setVelues] = useState({
        email: '',
        password: '',
    })

    const [valuesValidity, setVeluesValidity] = useState({
        email: {},
        password: {},
    })

    function handleChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name

        switch (name) {

            case "email":
                if (!value) {
                    setVeluesValidity((prevValue) => ({
                        ...prevValue,
                        [name]: {
                            errorMessage: "",
                            validState: false,
                        },
                    }));
                }
                if (value.length >= 2) {
                    if (validator.isEmail(value)) {
                        setVeluesValidity((prevValue) => ({
                            ...prevValue,
                            [name]: {
                                errorMessage: "",
                                validState: true,
                            },
                        }));
                    } else {
                        setVeluesValidity((prevValue) => ({
                            ...prevValue,
                            [name]: {
                                errorMessage: !e.target.validity.valid
                                    ? e.target.validationMessage
                                    : "Invalid email",
                                validState: false,
                            },
                        }));
                    }
                }
                setVelues((prevValue) => ({
                    ...prevValue,
                    [name]: value,
                }));
                break;

            default:
                setVelues(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
        }
    }

    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false)
    const [error, showError] = useAutoDismissError();
    function handleSubmit() {
        setLoading(true)
        mainApi.login({
            email: values.email,
            password: values.password,
        })
            .then((res) => {
                console.log(res)
                setUser(res)
                navigate(PLATFORM_MAIN_LINKS[0].children
                    ? PLATFORM_MAIN_LINK +
                    "/" +
                    PLATFORM_MAIN_LINKS[0].path +
                    "/" +
                    PLATFORM_MAIN_LINKS[0].children[0].path
                    : PLATFORM_MAIN_LINK + "/" + PLATFORM_MAIN_LINKS[0].path)
            })
            .catch((err) => {
                console.log(err)
                switch (err.statusCode) {
                    case 401:
                        showError('Неверая эл. почта или пароль. Проверьте данные или восстановите пароль.')
                        break;

                    default:
                        showError(parseApiError(err))
                        break;
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className='login'>
            <div className='login__form'>
                <Input
                    value={values.email}
                    placeholder={'Эл. почта'}
                    handleChange={handleChange}
                    name={'email'}
                    inputMode='email'
                />
                <Input value={values.password}
                    placeholder={'Пароль'}
                    handleChange={handleChange}
                    name={'password'}
                    isPassword={true}
                ></Input>
                <Button
                    isLoading={isLoading}
                    isContrastColor={true}
                    text={'Войти'}
                    onClick={handleSubmit}
                    isInvalid={!(values.password && values.email && values.password.length >= 8 && valuesValidity.email.validState)}
                />
                <ErrorMessage error={error} />
            </div>
            <Link className='login__recovery' to={AUTH_RECOVERY_LINK}>Восстановить пароль</Link>
            {/* <Link className='login__support' to={SUPPORT_AUTH_LINK}>Не помню почту, на которую создан мой аккаунт</Link> */}
            <p className='login__change-page'>Еще нет аккаунта? <br /><Link className='login__change-page-link' to={AUTH_SIGNUP_LINK}>Зарегистрироваться</Link></p>
        </div>
    );
}

export default Login