
import PlatformInput from '../../../PlatformInput/PlatformInput';
import ActionBtn from '../../../ActionBtn/ActionBtn';
import ServiceContainer from '../../../ServiceContainer/ServiceContainer';
import TwoFactorBtn from '../../../TwoFactorBtn/TwoFactorBtn';
import './OneSProducts.css';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../assets/contexts/userContext';
import mainApi from '../../../../../assets/api/MainApi';
import PreloaderBox from '../../../../PreloaderBox/PreloaderBox';
import { hasPermission } from '../../../../../assets/utils/utils';
import { PERMISSIONS, RBAC_INTEGRATIONS } from '../../../../../assets/utils/permissions_rbac';


function OneSProducts() {

    const is_connected = false


    const { user } = useContext(UserContext)
    const hasAccess = hasPermission(user.default_shop.role.permissions, [RBAC_INTEGRATIONS[PERMISSIONS.FIRSTC_PRODUCTS]])

    const [data, setData] = useState(null)
    const [preloaders, setPreloaders] = useState({
        main: true,
        reveal: false,
    })
    useEffect(() => {
        if (!user) return
        if (!hasAccess) {
            setData(null)
            setPreloaders(prevValue => ({
                ...prevValue,
                main: false,
            }))
        }

        setPreloaders(prevValue => ({
            ...prevValue,
            main: true,
        }))
        mainApi.get1SProductsIntegration({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                setData({
                    ...res,
                    // is_connected: true,
                })
            })
            .catch((err) => {
                if (err.statusCode === 403) {
                    setData(null)
                }
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    main: false,
                }))
            })
    }, [user])

    function handleDisconnect() {

    }

    function handleRevealPassword() {
        setPreloaders(prevValue => ({
            ...prevValue,
            reveal: true,
        }))
        mainApi.revealPasswordFor1SProductsIntegration({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                setData({
                    ...res,
                    // is_connected: true,
                })
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    reveal: false,
                }))
            })
    }

    return (

        <>
            {!preloaders.main ?
                <div className='one-s-products'>
                    <p className='one-s-products__title'>Подключение 1С: Товары</p>
                    {hasAccess ?
                        !is_connected ?
                            <>
                                <PlatformInput
                                    value={data.url}
                                    readOnly={true}
                                    label={'Адрес сайта'}
                                />
                                <ActionBtn
                                    text={'Скопировать адрес'}
                                    isCopy={true}
                                    copyValue={data.url}
                                />

                                <PlatformInput
                                    value={data.login}
                                    readOnly={true}
                                    label={'Имя пользователя'}
                                />
                                <ActionBtn
                                    text={'Скопировать логин'}
                                    isCopy={true}
                                    copyValue={data.login}
                                />

                                <PlatformInput
                                    value={data.password ? data.password : '********'}
                                    readOnly={true}
                                    label={'Пароль'}
                                />
                                {!data.password ?
                                    <p className='one-s-products__hint'>Пароль можно просмотреть только один раз, после будет запрошен новый пароль. Не&nbsp;забудьте передать новый пароль 1С&nbsp;специалистам</p>
                                    : null}
                                {data.password ?
                                    <ActionBtn
                                        text={'Скопировать пароль'}
                                        isCopy={true}
                                        copyValue={data.password}
                                    />
                                    :
                                    <ActionBtn
                                        text={'Получить новый пароль'}
                                        handleAction={handleRevealPassword}
                                        isContrast={true}
                                        isLoading={preloaders.reveal}
                                    />
                                }

                                <p className='one-s-products__hint'>Подключите выгрузку товаров из&nbsp;1С&nbsp;к&nbsp;нашему сервису через стандартную функциональность&nbsp;1С (Выгрузка&nbsp;на&nbsp;сайт) <a className='one-s-products__hint-link' href='https://aspro.ru/docs/course/course40/lesson1690/' target='_blank' rel='noreferrer'>Туториал для клиента</a></p>
                            </>
                            :
                            <>
                                <ServiceContainer
                                    title={'Система подключена'}
                                    subtitle={'https://1c-connect.com/media/uploads/distribs/1c-connectsfx'}
                                >

                                </ServiceContainer>
                                <TwoFactorBtn
                                    firstStepBtnText={'Отключить 1С'}
                                    secondStepBtnText={'Отключить'}
                                    handleAction={handleDisconnect}
                                    cancelBtnText={'Отмена'}
                                    confirmText={'Вы&nbsp;уверены что хотите отключить активную интеграцию с&nbsp;1С?'}
                                />
                            </>
                        :

                        <>
                            <p className='one-s-products__hint one-s-products__hint_contrast'>Для подключения данной интеграции, пожалуйста, свяжитесь с вашим персональным менеджером.</p>
                            <br />
                            <p className='one-s-products__hint'>Подключите выгрузку товаров из&nbsp;1С&nbsp;к&nbsp;нашему сервису через стандартную функциональность&nbsp;1С (Выгрузка&nbsp;на&nbsp;сайт)</p>

                        </>}



                </div>
                :
                <PreloaderBox />
            }
        </>

    );
}

export default OneSProducts