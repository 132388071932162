import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentPlatfromLocation } from '../../../assets/utils/utils';
import MobilePopup from '../../MobilePopup/MobilePopup';
import './MenuPopup.css';
import { useContext, useEffect, useState } from 'react';
import { LogoIcon } from '../../../assets/icons/header/header';
import { MENU_POPUP_NAME, PLATFORM_MAIN_LINK, PLATFORM_MAIN_LINKS } from '../../../assets/utils/constants';
import PopupControlBtn from '../../MobilePopup/components/PopupCloseBtn/PopupCloseBtn';
import { PlatformLinksContext } from '../../../assets/contexts/platformLinksContext';




function MenuPopup({ isOpen, handleClose }) {
    const location = useLocation();
    const { links, default_link } = useContext(PlatformLinksContext)

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })

    const [popupLocation, setPopupLocation] = useState(current_location)

    useEffect(() => {
        if (!isOpen) return
        setPopupLocation(current_location)
    }, [isOpen])



    function handleGoBack() {
        setPopupLocation(prevValue => ({
            parent: null,
            location: prevValue.parent,
        }))
    }

    const navigate = useNavigate()

    function handleLinkSelect({ item }) {
        if (!item.children) {
            navigate(`${PLATFORM_MAIN_LINK}/${popupLocation && popupLocation.parent ? `${popupLocation.parent.path}/${item.path}` : item.path}`)
            setPopupLocation(prevValue => ({
                parent: popupLocation && popupLocation.parent ? popupLocation.parent : null,
                location: item,
            }))
            handleClose({ name: MENU_POPUP_NAME })
        } else {
            setPopupLocation(prevValue => ({
                parent: item,
                location: null,
            }))
        }

    }

    return (
        <MobilePopup
            isOpen={isOpen}
            handleClose={handleClose}
            popupName={MENU_POPUP_NAME}
        >
            <div className='menu-popup'>

                {popupLocation && popupLocation.parent ?
                    <div className='menu-popup__heading menu-popup__heading_type_children'>
                        <PopupControlBtn
                            POPUP_NAME={MENU_POPUP_NAME}
                            onClick={handleGoBack}
                            type={'go-back'}
                        />
                        <p className='menu-popup__heading-name'>{popupLocation.parent.name}</p>
                        <PopupControlBtn
                            POPUP_NAME={MENU_POPUP_NAME}
                            onClick={handleClose}
                            type={'close'}
                        />
                    </div>
                    :
                    <div className='menu-popup__heading'>
                        <LogoIcon
                            mainClassName={'menu-popup__logo'}
                            fillClassName={'menu-popup__logo-fill'}
                        />
                        <PopupControlBtn
                            POPUP_NAME={MENU_POPUP_NAME}
                            onClick={handleClose}
                            type={'close'}
                        />
                    </div>
                }
                <div className='menu-popup__items'>
                    {popupLocation && popupLocation.parent ?
                        popupLocation.parent.children ?
                            popupLocation.parent.children.map((item, i) => (
                                <button className={`menu-popup__item ${popupLocation && popupLocation.location && popupLocation.location.path === item.path ? 'menu-popup__item_selected' : ''} ${item.disabled ? 'menu-popup__item_disabled' : ''}`} type='button' key={`menu-popup__item_children_${i}`} onClick={() => {
                                    handleLinkSelect({ item: item })
                                }}>
                                    {item.name}
                                </button>
                            ))
                            :
                            null
                        :
                        links && links.length > 0 ?
                            links.map((item, i) => (
                                <>
                                    {item.isInvisible ?
                                        null
                                        :
                                        <button className={`menu-popup__item ${popupLocation && popupLocation.location && popupLocation.location.path === item.path ? 'menu-popup__item_selected' : ''} ${item.disabled ? 'menu-popup__item_disabled' : ''}`} type='button' key={`menu-popup__item_main_${i}`} onClick={() => {
                                            handleLinkSelect({ item: item })
                                        }}>
                                            {item.name}
                                        </button>
                                    }
                                </>

                            ))
                            :
                            null
                    }
                </div>

            </div>
        </MobilePopup>
    );
}

export default MenuPopup