import { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Navigation } from "swiper";
import { motion, AnimatePresence } from 'framer-motion';

// import { motion } from 'framer-motion'

import "swiper/css";
import "swiper/css/pagination";


import './WalkThrough.css';
import { Link } from 'react-router-dom';
import { ACCOUNT_MAIN_LINK, CATALOG_CATEGORY_LINK, CATALOG_DRAFTS_CREATE_ITEM_LINK, CATALOG_DRAFTS_LINK, CATALOG_MAIN_LINK, PLATFORM_MAIN_LINK, PROMOCODES_MAIN_LINK, SETTINGS_DOMAIN_LINK, SETTINGS_DOMAIN_TECHNICAL_LINK, SETTINGS_INTEGRATIONS_LINK, SETTINGS_INTEGRATIONS_NOTIFICATIONS_EMAIL_LINK, SETTINGS_INTEGRATIONS_PAYMNET_SYSTEMS_LINK, SETTINGS_MAIN_LINK, SETTINGS_USERS_LINK } from '../../../../../assets/utils/constants';
import { UserContext } from '../../../../../assets/contexts/userContext';
import mainApi from '../../../../../assets/api/MainApi';
import { WalkThroughDoneIcon } from '../../../../../assets/icons/walkThrough/walkThrough';
import { hasPermission } from '../../../../../assets/utils/utils';
import { PERMISSIONS, RBAC_SHOP_SETTINGS } from '../../../../../assets/utils/permissions_rbac';

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.3,
            duration: 0.5
        }
    }
};

function findNearestNotDoneTaskIndex(tasks) {
    // Find the index of the first task with is_done: false
    const index = tasks.findIndex(task => !task.is_done);
    return index >= 0 ? index : 0;
}

function WalkThrough() {

    const [my_swiper, set_my_swiper] = useState({});
    const { user } = useContext(UserContext)
    const [cards, setCards] = useState(null)

    function handleReset() {
        setCards(null)
    }
    useEffect(() => {
        if (!user) return
        // if (!hasPermission(user.default_shop.role.permissions, [RBAC_SHOP_SETTINGS[PERMISSIONS.TASKS]])) {
        //     handleReset()
        //     return
        // }
        mainApi.getShopTasks({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                setCards(res.data.filter((card) => card.task !== "add_shop_payment"))
            })
            .catch((err) => {
                if (err.statusCode === 403) {
                    handleReset()
                }

                console.log(err)
            })
    }, [user])

    const index = cards ? findNearestNotDoneTaskIndex(cards) : 0;
    const is_all_done = cards ? cards.every((item) => item.is_done) : false

    return (
        <AnimatePresence>
            {cards && !is_all_done ?
                <motion.div
                    className='walk-through'
                    initial={{ opacity: 0, height: 0, }}
                    animate={{ opacity: 1, height: 'auto', }}
                    exit={{ opacity: 0, height: 0, }}
                    transition={{ duration: 0.3 }}
                >
                    <div className='walk-through__content'>
                        <div className='walk-through__slider'>
                            {cards && cards.length > 0 ?
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={16}
                                    pagination={{
                                        clickable: true,
                                        dynamicBullets: true,
                                        dynamicMainBullets: 4,
                                    }}
                                    mousewheel={{
                                        forceToAxis: true,
                                    }}
                                    initialSlide={index}
                                    modules={[Mousewheel]}
                                    preventInteractionOnTransition={true}
                                    className="walk-through__slides"
                                    onInit={(ev) => {
                                        set_my_swiper(ev)
                                    }}

                                >
                                    {
                                        cards.map((card, i) => (

                                            (index === i) ?
                                                <SwiperSlide className={`walk-through__slide walk-through__slide_${card.card_type} ${card.is_done ? 'walk-through__slide_done' : ''} walk-through__slide_to-do`} >
                                                    <div className='walk-through__slide-content'>
                                                        <p className='walk-through__slide-title'>{card.title.ru}</p>
                                                        {card.is_done ?
                                                            <div className='walk-through__slide-btn'>
                                                                <WalkThroughDoneIcon
                                                                    mainClassName={'walk-through__slide-btn-done-icon'}
                                                                    fillClassName={'walk-through__slide-btn-done-icon-fill'}
                                                                />
                                                                Готово
                                                            </div>
                                                            :
                                                            <Link className='walk-through__slide-btn' to={`${card.path}?${new URLSearchParams({ 'walk-through': true })}`}>
                                                                {card.btn_text.ru}
                                                            </Link>
                                                        }
                                                    </div>
                                                    <p className='walk-through__slide-description'>{card.description.ru}</p>

                                                </SwiperSlide>
                                                :
                                                <SwiperSlide className={`walk-through__slide walk-through__slide_${card.card_type} ${card.is_done ? 'walk-through__slide_done' : ''}`} >
                                                    <p className='walk-through__slide-title'>{card.title.ru}</p>
                                                    {card.is_done ?
                                                        <div className='walk-through__slide-btn'>
                                                            <WalkThroughDoneIcon
                                                                mainClassName={'walk-through__slide-btn-done-icon'}
                                                                fillClassName={'walk-through__slide-btn-done-icon-fill'}
                                                            />
                                                            Готово
                                                        </div>
                                                        :
                                                        <Link className='walk-through__slide-btn' to={`${card.path}?${new URLSearchParams({ 'walk-through': true })}`}>
                                                            {card.btn_text.ru}
                                                        </Link>
                                                    }

                                                </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                                : null}
                        </div>
                    </div>
                </motion.div >
                : null}
        </AnimatePresence>

    );
}

export default WalkThrough