import Toggle from "../../../../Toggle/Toggle";
import PlatformInput from "../../../PlatformInput/PlatformInput";
import "./ConstantInput.css";

function ConstantInput({
    item,
    value,
    setValues,
}) {

    function handleChange(e) {
        const input = e.target;
        const input_value = input.value;
        const name = input.name;

        switch (name) {
            case 'bool': {
                setValues((prevValue) => ({
                    ...prevValue,
                    [value.key]: {
                        ...prevValue[value.key],
                        value: !prevValue[value.key].value,
                    },
                }))
                break;
            }

            case 'integer': {
                let inputValue = input_value.replace(/\D/g, "");
                if(Number(inputValue) > item.validation.lte) {
                    inputValue = '1000000'
                }
                if(Number(inputValue) < item.validation.gte) {
                    inputValue = '0'
                }
                setValues((prevValue) => ({
                    ...prevValue,
                    [value.key]: {
                        ...prevValue[value.key],
                        value: Number(inputValue),
                    },
                }))
                break;
            }

            default: {
                let inputValue = input_value.substring(0, item.validation.lte);
                setValues((prevValue) => ({
                    ...prevValue,
                    [value.key]: {
                        ...prevValue[value.key],
                        value: inputValue,
                    },
                }))
                break;
            }

        }
    }


    const type = item.validation.type
    return (
        <div className={`constant-input`}>
            <div className="constant-input__heading">
                <p className="constant-input__title">{item.title}</p>

            </div>
            {type === 'bool' ?
                <Toggle
                    isActive={value.value}
                    className={'constant-input__toggle'}
                    handleClick={() => {
                        handleChange({
                            target: {
                                name: 'bool',
                            }
                        })
                    }}
                />
                : null}
            {type === 'string' ?
                <PlatformInput
                    value={value.value}
                    placeholder={""}
                    label={""}
                    handleChange={handleChange}
                    name={"string"}
                />
                : null}
            {type === 'integer' ?
                <PlatformInput
                    value={`${value.value}`}
                    placeholder={""}
                    label={""}
                    handleChange={handleChange}
                    name={"integer"}
                />
                : null}
        </div>
    );
}

export default ConstantInput;
