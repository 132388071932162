import { CARD_ICON_TYPE, TEXT_TYPE } from '../../../../../../assets/utils/constants';
import './FinanceInfoBox.css';


function FinanceInfoBox({
    title,
    change_btn,
    main_value,
    sub_value,
}) {



    return (
        <div className='finance-info-box'>
            <div className='finance-info-box__heading'>
                <p className='finance-info-box__heading-title'>{title}</p>
                {change_btn ?
                    <button className='finance-info-box__heading-btn' type='button' onClick={() => {
                        if (change_btn.onClick) {
                            change_btn.onClick()
                        }
                    }}>
                        {change_btn.text ? 'Изменить' : ''}
                    </button>
                    : null}
            </div>
            <div className='finance-info-box__values'>
                <p className='finance-info-box__main-value'>{main_value}</p>
                {sub_value ?
                    <>
                        {sub_value.type === TEXT_TYPE ?
                            <p className='finance-info-box__sub-value-text'>{sub_value.value}</p>
                            : null}

                        {sub_value.type === CARD_ICON_TYPE ?
                            <sub_value.value mainClassName={'finance-info-box__sub-value-card-icon'} />
                            : null}
                    </>
                    : null}

            </div>
        </div>
    );
}

export default FinanceInfoBox