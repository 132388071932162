import { useContext, useEffect, useState } from "react";
import "./KitCard.css";
import PageHeading from "../../PageHeading/PageHeading";
import ItemForm from "../../ItemForm/ItemForm";
import ConstructorBlock from "../ConstructorBlock/ConstructorBlock";
import PlatformInput from "../../PlatformInput/PlatformInput";
import ExampleCard from "./ExampleCard/ExampleCard";
import FormControls from "../../FormControls/FormControls";
import {
  GRID_TEMPLATE_COLUMN_FOUR,
  GRID_TEMPLATE_COLUMN_TYPES,
  IMAGE_FORMAT_HORIZONTAL_TYPE,
  IMAGE_FORMAT_TYPES,
  PRICE_BUTTON_TEXT_TYPE,
  PRICE_BUTTON_TYPES,
  PRICE_LOCATION_APART_TYPE,
  PRICE_LOCATION_TYPES,
} from "../../../../assets/utils/constructorConstants";
import { ERROR_REQUIRED_FIELD } from "../../../../assets/utils/constants";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import mainApi from "../../../../assets/api/MainApi";
import { UserContext } from "../../../../assets/contexts/userContext";
import { transformConstructorCardApiResponse, transformConstructorCardToApiResponse } from "../../../../assets/utils/utils";
import PreloaderBox from "../../../PreloaderBox/PreloaderBox";

const initialValuesList = [
  {
    type: "title",
    title: "Название товара",
    value: "Название",
    is_visible: true,
  },
  {
    type: "image",
    title: "Изображение",
    is_visible: true,
    data: [
      {
        type: "aspect_ratio",
        is_type_select: true,
        title: "Формат",
        value: IMAGE_FORMAT_HORIZONTAL_TYPE,
        values_list: IMAGE_FORMAT_TYPES,
      },
      {
        type: "radius",
        is_type_select: false,
        title: "Скругление",
        value: "12",
      },
    ],
  },
  {
    type: "description",
    title: "Описание товара",
    value: "Описание",
    is_visible: true,
  },
  {
    type: "price",
    title: "Блок с ценой",
    is_visible: true,
    data: [
      {
        type: "price_location",
        is_type_select: true,
        title: "Цена",
        value: PRICE_LOCATION_APART_TYPE,
        values_list: PRICE_LOCATION_TYPES,
      },
      {
        type: "button_type",
        is_type_select: true,
        title: "Кнопка Купить",
        value: PRICE_BUTTON_TEXT_TYPE,
        values_list: PRICE_BUTTON_TYPES,
      },
    ],
  },
  {
    type: "grid_columns",
    is_type_select: true,
    title: "Размер сетки на декстопе",
    value: GRID_TEMPLATE_COLUMN_FOUR,
    values_list: GRID_TEMPLATE_COLUMN_TYPES,
  },
];

const initialValuesValidity = {
  title: {
    errorMessage: "",
    validState: false,
  },
  radius: {
    errorMessage: "",
    validState: true,
  },
};

function KitCard() {
  const { user } = useContext(UserContext)
  const [values, setValues] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const [preloaders, setPreloaders] = useState({
    main: true,
  })
  const actions = [
    {
      name: "Отменить все изменения",
      onClick: handleCancel,
      // inactive: !isValuesChanged,
      id: "kit-card__cancel-btn",
    },
    {
      name: "Сохранить",
      onClick: handleSave,
      isMainAction: true,
      // inactive: isSubmitDisabled,
      id: "kit-card__save-btn",
    },
  ];

  useEffect(() => {
    if (!user) return
    setPreloaders(prevValue => ({
      ...prevValue,
      main: true,
    }))
    mainApi.getConstructorCard({
      shop_id: user.default_shop._id,
    })
      .then((res) => {
        console.log(values, initialValues)
        console.log(res, transformConstructorCardApiResponse(res))
        const response = transformConstructorCardApiResponse(res)
        setValues(response)
        setInitialValues(response)
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setValues(null)
          setInitialValues(null)
        }

        console.log(err)
      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          main: false,
        }))
      })
  }, [user])

  useEffect(() => {
    if (!values || values?.length === 0) return;

    const isChanged = values.some((item, i) =>
      item.data
        ? item.data.some((item2, i2) =>
          item2.is_type_select
            ? item2.value.value !== initialValues[i].data[i2].value.value
            : item2.value !== initialValues[i].data[i2].value
        )
        : item.value !== initialValues[i].value
    );
    setIsValuesChanged(isChanged);

    const isDisabled = Object.values(valuesValidity).some(
      (item) => !item.validState
    );
    setIsSubmitDisabled(isDisabled || !isChanged);
  }, [initialValues, values, valuesValidity]);

  function handleChange(e) {
    const input = e.target;
    const value = input.value;
    const name = input.name;

    switch (name) {
      case "aspect_ratio": {
        const type = input.type;
        handleSelect({ type, name, value });
        break;
      }

      case "price_location": {
        const type = input.type;
        handleSelect({ type, name, value });
        break;
      }

      case "button_type": {
        const type = input.type;
        handleSelect({ type, name, value });
        break;
      }

      case "radius": {
        const type = input.type;
        const inputValue = value.replace(/\D/g, "");
        setValues((prevValue) =>
          prevValue.map((item) => {
            if (item.type !== type) return item;
            return {
              ...item,
              data: item.data.map((item2) => {
                if (item2.type !== name) return item2;
                return {
                  ...item2,
                  value: inputValue,
                };
              }),
            };
          })
        );
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: "",
            validState: inputValue !== "",
          },
        }));
        break;
      }

      case "grid_columns": {
        setValues((prevValue) =>
          prevValue.map((item) => {
            if (item.type !== name) return item;
            return {
              ...item,
              value: value,
            };
          })
        );
        break;
      }

      case "value-sort-down": {
        const index = input.index;
        setValues((prevValue) => {
          const newValues = [...prevValue];
          [newValues[index], newValues[index + 1]] = [
            newValues[index + 1],
            newValues[index],
          ];
          return newValues;
        });
        setInitialValues((prevValue) => {
          const newValues = [...prevValue];
          [newValues[index], newValues[index + 1]] = [
            newValues[index + 1],
            newValues[index],
          ];
          return newValues;
        });
        break;
      }

      case "value-sort-up": {
        const index = input.index;
        setValues((prevValue) => {
          const newValues = [...prevValue];
          [newValues[index], newValues[index - 1]] = [
            newValues[index - 1],
            newValues[index],
          ];
          return newValues;
        });
        setInitialValues((prevValue) => {
          const newValues = [...prevValue];
          [newValues[index], newValues[index - 1]] = [
            newValues[index - 1],
            newValues[index],
          ];
          return newValues;
        });
        break;
      }

      case "value-visibility": {
        const type = input.type;
        setValues((prevValue) =>
          prevValue.map((item) => {
            if (item.type !== type) return item;
            return {
              ...item,
              is_visible: !item.is_visible,
            };
          })
        );
        break;
      }

      default:
        const errorText = !Boolean(value) ? ERROR_REQUIRED_FIELD : "";
        setValues((prevValue) =>
          prevValue.map((item) => {
            if (item.type !== name) return item;
            return {
              ...item,
              value: value,
            };
          })
        );
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: errorText,
            validState: Boolean(value),
          },
        }));
        break;
    }
  }

  function handleSelect({ type, name, value }) {
    setValues((prevValue) =>
      prevValue.map((item) => {
        if (item.type !== type) return item;
        return {
          ...item,
          data: item.data.map((item2) => {
            if (item2.type !== name) return item2;
            return {
              ...item2,
              value: value,
            };
          }),
        };
      })
    );
  }

  function handleBlur(e) {
    const input = e.target;
    const value = input.value;
    const name = input.name;

    switch (name) {
      case "title": {
        const errorText = !Boolean(value) ? ERROR_REQUIRED_FIELD : "";
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: errorText,
            validState: Boolean(value),
          },
        }));
        break;
      }

      case "radius": {
        const type = input.type;
        const inputValue = value === "" ? "0" : value;
        setValues((prevValue) =>
          prevValue.map((item) => {
            if (item.type !== type) return item;
            return {
              ...item,
              data: item.data.map((item2) => {
                if (item2.type !== name) return item2;
                return {
                  ...item2,
                  value: inputValue,
                };
              }),
            };
          })
        );
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: "",
            validState: true,
          },
        }));
        break;
      }

      default:
        break;
    }
  }

  function handleSave() {
    console.log("save", values,);
    const transformed_values = transformConstructorCardToApiResponse(values)
    mainApi.setConstructorCard({
      shop_id: user.default_shop._id,
      image: transformed_values.image,
      price: transformed_values.price,
      card_in_line: transformed_values.card_in_line,
      order: transformed_values.order,
    })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleCancel() {
    console.log("cancel");
    setValues(initialValuesList);
    setInitialValues(initialValuesList);
    setValuesValidity(initialValuesValidity);
  }

  return (
    <div className="kit-card">
      <PageHeading
        className={"kit-card__heading"}
        title={"Внешний вид карточки товара"}
        actions={actions}
      />
      <ItemForm containerClassName={"kit-card__container"}>
        {!preloaders.main ?
          <form className="kit-card__form" onSubmit={handleSave}>
            <ConstructorBlock>
              <LayoutGroup>
                <AnimatePresence>

                  {values?.length > 0
                    ? values.map((block, i) => (
                      <motion.div
                        key={`${block.type}_kit-card_`}
                        layout
                        initial={{ opacity: 0, y: 0 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 0 }}
                        transition={{ duration: 0.2 }}
                      >

                        <ConstructorBlock
                          title={block.data ? block.title : undefined}
                          mainClassName={"kit-card__constructor-block"}
                          key={block.type}
                        >
                          <div
                            className={`kit-card__block-container ${block.type === "price"
                              ? "kit-card__block-container_type_column"
                              : ""
                              } ${block.type === "grid_columns"
                                ? "kit-card__block-container_type_wide"
                                : ""
                              }`}
                            style={{
                              "--template-kit-card-column": `${block.data ? block.data.length : 1
                                }`,
                            }}
                          >
                            {block.data ? (
                              block.data.map((item) =>
                                item.is_type_select ? (
                                  <PlatformInput
                                    name={item.type}
                                    value={item.value}
                                    placeholder={""}
                                    label={item.title}
                                    isSelect={item.is_type_select}
                                    selected_text_value={item.value.title}
                                    handleChange={(e) => {
                                      handleChange({
                                        target: {
                                          value: e.target.value,
                                          name: item.type,
                                          type: block.type,
                                        },
                                      });
                                    }}
                                    selectList={item.values_list}
                                    selectDifferenceKey={"value"}
                                    selectTextValueKey={"title"}
                                    key={item.type}
                                  />
                                ) : (
                                  <PlatformInput
                                    name={item.type}
                                    value={item.value}
                                    placeholder={""}
                                    label={item.title}
                                    handleChange={(e) => {
                                      handleChange({
                                        target: {
                                          value: e.target.value,
                                          name: item.type,
                                          type: block.type,
                                        },
                                      });
                                    }}
                                    onBlur={(e) => {
                                      handleBlur({
                                        target: {
                                          value: e.target.value,
                                          name: item.type,
                                          type: block.type,
                                        },
                                      });
                                    }}
                                    key={item.type}
                                  />
                                )
                              )
                            ) : block.type === "description" ? (
                              <PlatformInput
                                name={block.type}
                                value={block.value}
                                placeholder={""}
                                label={block.title}
                                maxLength={1000}
                                isTextArea={true}
                                maxRows={5}
                                minRows={1}
                                handleChange={handleChange}
                              />
                            ) : block.is_type_select ? (
                              <PlatformInput
                                name={block.type}
                                value={block.value}
                                placeholder={""}
                                label={block.title}
                                isSelect={block.is_type_select}
                                selected_text_value={block.value.title}
                                handleChange={handleChange}
                                selectList={block.values_list}
                                selectDifferenceKey={"value"}
                                selectTextValueKey={"title"}
                              />
                            ) : (
                              <PlatformInput
                                name={block.type}
                                value={block.value}
                                placeholder={""}
                                label={block.title}
                                handleChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  valuesValidity[block.type]?.errorMessage
                                    ? valuesValidity[block.type].errorMessage
                                    : ""
                                }
                              />
                            )}

                            {block.type !== "grid_columns" ? (
                              <div className="kit-card__control-btns">
                                <FormControls
                                  index={i}
                                  lastIndex={values.length - 2}
                                  sortDownTarget={{
                                    name: "value-sort-down",
                                    value: null,
                                    index: i,
                                  }}
                                  sortUpTarget={{
                                    name: "value-sort-up",
                                    value: null,
                                    index: i,
                                  }}
                                  visibilityTarget={{
                                    name: "value-visibility",
                                    value: null,
                                    type: block.type,
                                  }}
                                  isVisible={block.is_visible}
                                  handleChange={handleChange}
                                  isVisibilityType
                                />
                              </div>
                            ) : null}
                          </div>
                        </ConstructorBlock>
                      </motion.div>

                    ))
                    : null}
                </AnimatePresence>
              </LayoutGroup>
            </ConstructorBlock>


            <div className="constructor-block__separator-line" />

            <ConstructorBlock>
              {values?.length > 0 ? (
                <div className="kit-card__example-block">
                  <ExampleCard data={values} />
                  <ExampleCard data={values} isBig />
                </div>
              ) : null}
            </ConstructorBlock>
          </form>
          : <PreloaderBox />}

      </ItemForm>
    </div>
  );
}

export default KitCard;
