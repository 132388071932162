import { Route, Routes, useLocation } from 'react-router-dom';
import PageHeading from '../../PageHeading/PageHeading';
import './Users.css';

import { SETTINGS_USERS_СREATE_LINK } from '../../../../assets/utils/constants';
import UsersMain from './UsersMain/UsersMain';
import UsersCreate from './UsersCreate/UsersCreate';

function Users() {

    return (
        <div className='users'>
            <Routes>
                <Route index element={
                    <UsersMain />
                }>
                </Route>
                <Route path={`${SETTINGS_USERS_СREATE_LINK}/`} element={
                    <UsersCreate />
                }>
                </Route>
            </Routes>
        </div>
    );
}

export default Users