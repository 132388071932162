import React, { useEffect } from 'react';
import PlatformInput from '../../../../../../../PlatformInput/PlatformInput';
import PreloaderBox from '../../../../../../../../PreloaderBox/PreloaderBox';
import ErrorMessage from '../../../../../../../../ErrorMessage/ErrorMessage';
import './Form.css'

function Form({ formFields, handleChange, loading, error }) {

    return (
        <div className="form-container">
            {loading ? (
                <PreloaderBox />
            ) : (
                <>
                    <ErrorMessage error={error} />
                    {formFields.map((field, i) => {
                        switch (field.type) {
                            case 'text': {
                                return (
                                    <PlatformInput
                                        key={`pages-and-files__form-container_text_${i}`}
                                        label={field.label}
                                        value={field.value}
                                        handleChange={handleChange}
                                        name={field.name}
                                    />
                                )
                            }
                            case 'textarea': {
                                return (
                                    <PlatformInput
                                        key={`pages-and-files__form-container_textarea_${i}`}
                                        label={field.label}
                                        value={field.value}
                                        handleChange={handleChange}
                                        name={field.name}
                                        isTextArea={true}
                                        maxLength={65536}
                                    />
                                )
                            }

                            case 'boolean': {
                                return (
                                    <PlatformInput
                                        label={field.label}
                                        value={field.value}
                                        handleChange={handleChange}
                                        name={field.name}
                                        isBooleanSelector={true}
                                        boolean_values={{
                                            true: { title: field.boolean_values.true.title },
                                            false: { title: field.boolean_values.false.title },
                                        }}
                                    />
                                )
                            }

                            case 'select': {
                                return (
                                    <PlatformInput
                                        label={field.label}
                                        value={field.value}
                                        placeholder={''}
                                        isSelect={true}
                                        selected_text_value={field.value[field.selectTextValueKey]}
                                        maxLength={100}
                                        handleChange={handleChange}
                                        selectList={field.selectList}
                                        selectDifferenceKey={field.selectDifferenceKey}
                                        selectTextValueKey={field.selectTextValueKey}
                                        name={field.name}
                                    />
                                )
                            }

                            default: {
                                return null
                            }
                        }
                    })}
                    <div className='form-container__empty'></div>
                </>
            )}
        </div>
    );
}

export default Form;