import { useState, useEffect, useRef } from 'react';

export default function useAutoDismissError(duration = 3000) {
    const [error, setError] = useState('');
    const timerRef = useRef(null);

    const clearErrorTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    const showError = (errorMessage) => {
        clearErrorTimer();  // Clear any existing timer
        setError(errorMessage);

        // Set a new timer for the error message
        timerRef.current = setTimeout(() => {
            setError('');
        }, duration);
    };

    useEffect(() => {
        // Clean up the timer when the component unmounts
        return () => clearErrorTimer();
    }, []);

    return [error, showError];
};