import './NotificationLine.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AUTH_LOGIN_LINK, NOTIFY_REGISTRATION_TYPE, NOTIFY_REGISTRATION_WALK_THROUGH, NOTIFY_SUBSCRIPTION_TYPE, PLATFORM_MAIN_LINK, SUBSCRIPTIONS_MAIN_LINK, SUBSCRIPTIONS_PLATFROM_LINK } from '../../assets/utils/constants';
import mainApi from '../../assets/api/MainApi';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../assets/contexts/userContext';
import moment from 'moment-timezone';
import { GoBackIcon } from '../../assets/icons/controls/controls';
import { hasPermission, parsePermissions } from '../../assets/utils/utils';
import { PERMISSIONS, RBAC_SHOP_SETTINGS } from '../../assets/utils/permissions_rbac';

const redText = 'Пожалуйста, перейдите по ссылке из нашего письма для подтверждения адреса электронной почты'
const greenText = 'Это демо-версия личного кабинета. Чтобы начать работу с собственным интернет-магазином, выберите и оплатите тариф'
// const greenText = 'Это демо-версия личного кабинета'
const greenTextforNoOwner = 'Это демо-версия личного кабинета. Чтобы начать работу с собственным интернет-магазином, попросите владельца магазина произвести оплату'
const greenTextButton = 'Выбрать тариф'
const redTextButton = 'Отправить письмо еще раз'



function NotificationLine({ isOpen, onClose }) {
    const { user, setUser } = useContext(UserContext)

    const navigate = useNavigate()
    const location = useLocation()

    const [isResendSend, setResendSend] = useState(false)
    function handleEmailResend() {
        mainApi.resendVerificationEmail()
            .then((res) => {
                setResendSend(true)
                console.log(res)
                onClose(NOTIFY_REGISTRATION_TYPE)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const [now, setNow] = useState(moment())
    useEffect(() => {
        setTimeout(() => {
            setNow(moment())
        }, 10000);
    }, [now])


    function handleLogout() {
        mainApi.logout()
            .then((res) => {
                console.log(res)
                setUser(undefined)
                navigate(`${AUTH_LOGIN_LINK}`)
                localStorage.removeItem("categories")
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const hasBillingPermission = user ? hasPermission(parsePermissions(user), [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_BILLING]]) : false


    return (
        <>
            {isOpen[NOTIFY_REGISTRATION_TYPE] && user ?
                <div className={`notification-line notification-line_red`}>
                    <div className='notification-line__text-box'>
                        <p className='notification-line__text'>{redText}</p>
                        <button onClick={() => {
                            if (isResendSend || now.diff(moment(user.email_verification.generated_utc + '+00:00'), 'minutes') < 2) return
                            handleEmailResend()

                        }}
                            className={`notification-line__button notification-line__button_red`}>
                            {isResendSend || now.diff(moment(user.email_verification.generated_utc + '+00:00'), 'minutes') < 2 ? 'Письмо отправленно' : redTextButton}
                        </button>
                        <button onClick={handleLogout}
                            className={`notification-line__button notification-line__button_red`}>
                            Выйти из аккаунта
                        </button>
                    </div>
                </div> : null}



            {isOpen[NOTIFY_SUBSCRIPTION_TYPE] && location.pathname !== `${PLATFORM_MAIN_LINK}/${SUBSCRIPTIONS_MAIN_LINK}/${SUBSCRIPTIONS_PLATFROM_LINK}` ?

                <div className={`notification-line notification-line_green`}>
                    <div className='notification-line__text-box'>
                        <p className='notification-line__text'> {hasBillingPermission ? greenText : greenTextforNoOwner}</p>
                        {hasBillingPermission ?
                            <button onClick={() => {
                                navigate(`${PLATFORM_MAIN_LINK}/${SUBSCRIPTIONS_MAIN_LINK}/${SUBSCRIPTIONS_PLATFROM_LINK}`)
                                onClose(NOTIFY_SUBSCRIPTION_TYPE)
                            }}
                                className={`notification-line__button notification-line__button_green`}>
                                {greenTextButton}
                            </button>
                            : null}
                    </div>
                </div>

                : null
            }

            {isOpen[NOTIFY_REGISTRATION_WALK_THROUGH] ?
                <div className={`notification-line notification-line_walk-through`}>
                    <div className='notification-line__text-box'>
                        <Link to={-1} className='notification-line__go-back'>
                            <div className='notification-line__go-back-icon-box'>
                                <GoBackIcon
                                    mainClassName={'notification-line__go-back-icon'}
                                    strokeClassName={'notification-line__go-back-icon-stroke'}
                                />
                            </div>
                            Вернутся назад
                        </Link>
                    </div>
                </div>
                : null
            }
        </>
    );
}

export default NotificationLine