export const FONT_WEIGHT_TYPE_BOLD = { value: "bold", title: "Bold" };
export const FONT_WEIGHT_TYPE_SEMIBOLD = {
  value: "semibold",
  title: "SemiBold",
};
export const FONT_WEIGHT_TYPE_NORMAL = { value: "normal", title: "Regular" };
export const FONT_WEIGHT_TYPES = [
  FONT_WEIGHT_TYPE_NORMAL,
  FONT_WEIGHT_TYPE_BOLD,
  FONT_WEIGHT_TYPE_SEMIBOLD,
];

export const DEFAULT_COLORS_PRIMARY_SET = [
  { type: "active", value: "#1488FF" },
  { type: "selection", value: "#7ABCFF" },
  { type: "background", value: "#E0F0FF" },
];

export const DEFAULT_COLORS_SECONDARY_SET = [
  { type: "active", value: "#A614FF" },
  { type: "selection", value: "#CC7AFF" },
  { type: "background", value: "#F3E0FF" },
];

export const DEFAULT_COLORS_SEMANTIC_PLUS_SET = [
  { type: "active", value: "#14FF8E" },
  { type: "selection", value: "#7AFFBF" },
  { type: "background", value: "#E0FFF0" },
];

export const DEFAULT_COLORS_SEMANTIC_MINUS_SET = [
  { type: "active", value: "#B80000" },
  { type: "selection", value: "#D25A5A" },
  { type: "background", value: "#FF9B9B" },
];

export const DEFAULT_COLORS_NEUTRAL_SET = [
  { type: "active", value: "#282B3C" },
  { type: "selection", value: "#545666" },
  { type: "background", value: "#DCDCDC" },
];

export const DEFAULT_COLOR_WEBSITE_BACKGROUND_SET = [
  { type: "background", value: "#FFFFFF" },
];

export const ROUNDIND_TYPE_NONE = { value: "none", title: "Без скругления" };
export const ROUNDIND_TYPE_SMALL = { value: "small", title: "Небольшое" };
export const ROUNDIND_TYPE_BIG = { value: "medium", title: "Заметное" };
export const ROUNDIND_TYPE_FULL = { value: "full", title: "Полное" };
export const ROUNDIND_TYPES = [
  ROUNDIND_TYPE_NONE,
  ROUNDIND_TYPE_SMALL,
  ROUNDIND_TYPE_BIG,
  ROUNDIND_TYPE_FULL,
];
export const ROUNDING_MAP = {
  [ROUNDIND_TYPE_NONE.value]: ROUNDIND_TYPE_NONE,
  [ROUNDIND_TYPE_SMALL.value]: ROUNDIND_TYPE_SMALL,
  [ROUNDIND_TYPE_BIG.value]: ROUNDIND_TYPE_BIG,
  [ROUNDIND_TYPE_FULL.value]: ROUNDIND_TYPE_FULL,
} 

export const IMAGE_FORMAT_HORIZONTAL_TYPE = { value: "16/10", title: "16×10" };
export const IMAGE_FORMAT_SQUARE_TYPE = { value: "1/1", title: "1×1" };
export const IMAGE_FORMAT_VERTICAL_TYPE = { value: "10/16", title: "10×16" };
export const IMAGE_FORMAT_TYPES = [
  IMAGE_FORMAT_HORIZONTAL_TYPE,
  IMAGE_FORMAT_SQUARE_TYPE,
  IMAGE_FORMAT_VERTICAL_TYPE,
];

export const PRICE_LOCATION_APART_TYPE = { value: "separately", title: "Отдельно" };
export const PRICE_LOCATION_TOGETHER_TYPE = {
  value: "together",
  title: "На кнопке",
};
export const PRICE_LOCATION_TYPES = [
  PRICE_LOCATION_APART_TYPE,
  PRICE_LOCATION_TOGETHER_TYPE,
];

export const PRICE_BUTTON_BUTTON_TYPE = {
  value: "button",
  title: "В виде кнопки",
};
export const PRICE_BUTTON_TEXT_TYPE = {
  value: "text",
  title: "В виде текста",
};
export const PRICE_BUTTON_TYPES = [
  PRICE_BUTTON_BUTTON_TYPE,
  PRICE_BUTTON_TEXT_TYPE,
];

export const GRID_TEMPLATE_COLUMN_THREE = {
  value: 3,
  title: "3 карточки в ряд",
};
export const GRID_TEMPLATE_COLUMN_FOUR = {
  value: 4,
  title: "4 карточки в ряд",
};
export const GRID_TEMPLATE_COLUMN_FIVE = {
  value: 5,
  title: "5 карточек в ряд",
};
export const GRID_TEMPLATE_COLUMN_SIX = { value: 6, title: "6 карточек в ряд" };
export const GRID_TEMPLATE_COLUMN_TYPES = [
  GRID_TEMPLATE_COLUMN_THREE,
  GRID_TEMPLATE_COLUMN_FOUR,
  GRID_TEMPLATE_COLUMN_FIVE,
  GRID_TEMPLATE_COLUMN_SIX,
];

export const MENU_HORIZONTAL_TYPE = {
  value: "menu-horizontal",
  title: "Горизонтальное",
};
export const MENU_HORIZONTAL_WITH_BTN_TYPE = {
  value: "menu-horizontal-with-button",
  title: "Горизонтальное с кнопкой",
};
export const MENU_VERTICAL_TYPE = {
  value: "menu-vertical",
  title: "Вертикальное",
};
export const MENU_TYPES = [
  MENU_HORIZONTAL_TYPE,
  MENU_HORIZONTAL_WITH_BTN_TYPE,
  MENU_VERTICAL_TYPE,
];

export const BLOCK_PROMO_TYPE = { value: "promo-banner-100%", title: "Промо Баннер 100%" };
export const BLOCK_PROMO_SCROLL_TYPE = {
  value: "promo-banner-scroll",
  title: "Промо Баннер со скроллом",
};
export const BLOCK_TEXT_SHOWCASE_TYPE = {
  value: "promo-text-showcase",
  title: "Промо с текстом и товарами",
};
export const BLOCK_TEXT_PICTURE_TYPE = {
  value: "promo-text-picture",
  title: "Промо с текстом и изображением",
};
export const BLOCK_WITH_PROMO_ONE_TYPE = {
  value: "showcase-with-promo-one-lines",
  title: "Витрина с промо · 1 строка",
};
export const BLOCK_WITH_PROMO_TWO_TYPE = {
  value: "showcase-with-promo-two-lines",
  title: "Витрина с промо · 2 строки",
};
export const BLOCK_SHOWCASE_SCROLL_TYPE = {
  value: "showcase-scroll",
  title: "Витрина со скроллом",
};
export const BLOCK_SHOWCASE_1_LINE_TYPE = { value: "showcase-line-1", title: "Витрина 1 строка" };
export const BLOCK_SHOWCASE_2_LINE_TYPE = { value: "showcase-line-2", title: "Витрина 2 строки" };
export const BLOCK_SHOWCASE_3_LINE_TYPE = { value: "showcase-line-3", title: "Витрина 3 строки" };
export const BLOCK_WITH_HERO_TYPE = {
  value: "showcase-with-hero",
  title: "Витрина с главным товаром",
};
export const BLOCK_TYPES = [
  BLOCK_PROMO_TYPE,
  BLOCK_PROMO_SCROLL_TYPE,
  // BLOCK_TEXT_SHOWCASE_TYPE,
  BLOCK_TEXT_PICTURE_TYPE,
  // BLOCK_WITH_PROMO_ONE_TYPE,
  // BLOCK_WITH_PROMO_TWO_TYPE,
  // BLOCK_SHOWCASE_SCROLL_TYPE,
  BLOCK_SHOWCASE_1_LINE_TYPE,
  BLOCK_SHOWCASE_2_LINE_TYPE,
  BLOCK_SHOWCASE_3_LINE_TYPE,
  // BLOCK_WITH_HERO_TYPE,
];


export const PHOTO_GALLERY_TYPE_GALLERY = {
  value: 'GALLERY',
  title: 'Фотогалерея',
}
export const PHOTO_GALLERY_TYPE_ALL_ON_SCREEN = {
  value: 'ALL_ON_SCREEN',
  title: 'Все на экране'
}
export const PHOTO_GALLERY_TYPES = [
  PHOTO_GALLERY_TYPE_GALLERY,
  PHOTO_GALLERY_TYPE_ALL_ON_SCREEN
]