import { DEFAULT_MAX_IMG_SIZE } from '../../../../../../../assets/utils/constants';
import { uploadMainPageImage } from '../services/editMainPageApi';
import { getRandomId, parseApiError } from '../../../../../../../assets/utils/utils';

export function transformFormatV2(dataArray) {
    return dataArray.map(item => {
        const { title, text, link, btn_text, image, image_tablet, image_mobile } = item;

        return {
            ...(title && { title }),
            ...(text && { text }),
            ...(link && { link }),
            ...(btn_text && { btn_text }),
            ...(image?.[0]?._id && { image: image[0]._id }),
            ...(image_tablet?.[0]?._id && { image_tablet: image_tablet[0]._id }),
            ...(image_mobile?.[0]?._id && { image_mobile: image_mobile[0]._id }),
        };
    });
}

// Helper function for updating layout
const updateLayoutItem = (setLayout, itemId, updateCallback) => {

    setLayout(prevLayout => prevLayout.map(item => {
        if (item._id !== itemId) return item;

        return { ...item, data: updateCallback(item.data) };
    }));
};

const handleImageUpload = async (input, setLayout, setError) => {
    const resolution = input.resolution
    const file = input.files[0];
    if (!file || file.size / 1000000 >= DEFAULT_MAX_IMG_SIZE) return;

    let formData = new FormData();
    formData.append("image", file);

    try {
        const res = await uploadMainPageImage(formData);

        updateLayoutItem(setLayout, input.item._id, data =>
            data.map((dataItem, i) => (i !== input.index ? dataItem : { ...dataItem, [`image${resolution ? `_${resolution}` : ''}`]: [res] }))
        );
    } catch (err) {
        console.log(err)
        setError(parseApiError(err));
    }
};

export function handleChange(e, setLayout, showError) {
    const input = e.target;
    const { value, name, subname, item, index, resolution } = input;

    const updateDataItem = (fieldName) => {
        updateLayoutItem(setLayout, item._id, dataItems =>
            dataItems.map((dataItem, i2) => {
                if (i2 !== index) return dataItem;
                return { ...dataItem, [subname || fieldName]: value };
            })
        );
    };

    switch (subname) {
        case 'title':
        case 'link':
        case 'text':
        case 'btn_text': {
            updateDataItem(subname);

            break
        }

        case 'image': {
            handleImageUpload(input, setLayout, showError);
            break
        }

        case 'image-delete': {
            updateLayoutItem(setLayout, item._id, data =>
                data.map((dataItem, i) => (i === index ? { ...dataItem, [`image${resolution ? `_${resolution}` : ''}`]: [] } : dataItem))
            );
            break
        }

        case 'sort': {
            const direction = input.direction;
            updateLayoutItem(setLayout, item._id, data => {
                if (direction === 'down' && index < data.length - 1) {
                    [data[index], data[index + 1]] = [data[index + 1], data[index]];
                } else if (direction === 'up' && index > 0) {
                    [data[index], data[index - 1]] = [data[index - 1], data[index]];
                }
                return data;
            });
            break
        }


        case 'add':
            updateLayoutItem(setLayout, item._id, data =>
                data.concat([{ _id: getRandomId(), image: [], title: '', link: '' }])
            );
            break;

        case 'delete':
            updateLayoutItem(setLayout, item._id, data => data.filter((_, i) => i !== index));
            break;

        default: {
            break
        }
    }
}