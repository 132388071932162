import useAutoDismissError from "../../../assets/hooks/useAutoDismissError";
import { CrossIcon } from "../../../assets/icons/controls/controls";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import "./SubmitActionPopup.css";
import React, { useEffect } from "react";

function SubmitActionPopup({
  isOpen,
  hendleClose,
  hendleSubmit,
  text,
  submitText,
  cencelText,
  isSubmitPreloader,
  submitError,
}) {
  const [error, showError] = useAutoDismissError();

  useEffect(() => {
    showError(submitError)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitError])

  return (
    <div
      className={`submit-act-popup ${isOpen ? "submit-act-popup_active" : ""}`}
    >
      <div
        className={`submit-act-popup__container ${
          isOpen ? "submit-act-popup__container_active" : ""
        }`}
      >
        <div className="submit-act-popup__close" onClick={hendleClose}>
          {/* <svg className="submit-act-popup__close-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 18L18 2M2 2L18 18" stroke="black" strokeWidth="3.2" strokeLinecap="round" />
          </svg> */}
          <CrossIcon
            mainClassName={"submit-act-popup__close-icon"}
            strokeClassName={"submit-act-popup__close-icon-stroke"}
          />
        </div>
        <p className="submit-act-popup__text" dangerouslySetInnerHTML={{__html: text}}></p>
        <div className="submit-act-popup__btns">
          <button
            className="submit-act-popup__btn submit-act-popup__btn_agree"
            type="button"
            onClick={hendleSubmit}
          >
            {isSubmitPreloader ? <MiniPreloader /> : submitText}
          </button>
          <button
            className="submit-act-popup__btn submit-act-popup__btn_disagree"
            type="button"
            onClick={hendleClose}
          >
            {cencelText}
          </button>
        </div>
        <ErrorMessage error={error} />
      </div>
      <div
        className={`submit-act-popup__background ${
          isOpen ? "submit-act-popup__background_active" : ""
        }`}
        onClick={hendleClose}
      ></div>
    </div>
  );
}

export default SubmitActionPopup;
