import { Link, useNavigate } from 'react-router-dom';
import { ProductPlaceholderIcon } from '../../../../assets/icons/product/product';
import './ProductFormPlaceholder.css';
import { CATALOG_DRAFTS_CREATE_ITEM_LINK, CATALOG_DRAFTS_LINK, CATALOG_MAIN_LINK, PLATFORM_MAIN_LINK } from '../../../../assets/utils/constants';
import ActionBtn from '../../ActionBtn/ActionBtn';

function ProductFormPlaceholder({
    withCreateBtn,
}) {
    const navigate = useNavigate()
    return (
        <div className='product-form-placeholder'>
            <ProductPlaceholderIcon
                mainClassName={'product-form-placeholder__icon'}
                fillClassName={'product-form-placeholder__icon-fill'}
            />
            <span className='product-form-placeholder__text'>Выберите товар, чтобы <br />увидеть его параметры</span>
            {withCreateBtn ?
                <ActionBtn
                    text={'Добавить товары'}
                    className={'product-form-placeholder__create'}
                    isContrast={true}
                    handleAction={() => {
                        navigate(`${PLATFORM_MAIN_LINK}/${CATALOG_MAIN_LINK}/${CATALOG_DRAFTS_LINK}/${CATALOG_DRAFTS_CREATE_ITEM_LINK}`)
                    }}
                /> : null}
        </div>
    );
}

export default ProductFormPlaceholder