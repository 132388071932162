import { Route, Routes } from 'react-router-dom';
import './Statistics.css';
import StatisticsMain from './StatisticsMain/StatisticsMain';

function Statistics() {
    return (
        <div className='statistics'>
            <Routes>
                <Route index element={
                    <StatisticsMain />
                }>
                </Route>
            </Routes>
        </div>
    );
}

export default Statistics