import { useEffect } from 'react';



import './DesktopPopup.css';

function DesktopPopup({ isOpen, children, handleClose, popupName }) {

    return (
        <div className={`desktop-popup__container ${isOpen ? 'desktop-popup__container_active' : ''}`}>
            <div className={`desktop-popup ${isOpen ? 'desktop-popup_active' : ''}`}>
                {children}
            </div>
            <div className='desktop-popup__bg' onClick={() => {
                if (!popupName) {
                    handleClose()
                } else {
                    handleClose({ name: popupName })
                }
            }}></div>
        </div>

    );
}

export default DesktopPopup;