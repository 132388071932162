import { useState } from 'react';
import './ActionBtn.css';
import PreloaderBox from '../../PreloaderBox/PreloaderBox';

function ActionBtn({
    text,
    handleAction,
    isCopy,
    copyValue,
    isInactive,
    isContrast,
    className,
    isLoading,
}) {

    const [isCopied, setCopied] = useState(false)


    function copyToClipboard(text) {

        var textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';

            setCopied(true)
            setTimeout(() => {
                setCopied(false)
            }, 1500);
            console.log('Copying text command was ' + msg);
        } catch (err) {
            console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
    }

    return (
        <button className={`action-btn ${isContrast ? 'action-btn_type_contrast' : ''} ${className ? className : ''} ${isInactive ? 'action-btn__inactive' : ''} ${isLoading ? 'action-btn__loading' : ''}`} type='button' onClick={() => {
            if (isCopy) {
                if (isCopied) return
                copyToClipboard(copyValue)
            } else {
                handleAction()
            }
        }}>
            {isLoading ? <PreloaderBox isContrast={isContrast} />: isCopy ? isCopied ? 'Cкопировано' : text : text}
        </button>
    );
}

export default ActionBtn