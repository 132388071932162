
import './Products.css';
import ProductsMain from './ProductsMain/ProductsMain';


function Products() {
    return (
        <div className='products'>
            <ProductsMain />
        </div>
    );
}

export default Products