import mainApi from "../../../../../../../assets/api/MainApi";

export const fetchItems = (shopId) => {
    return mainApi.getAllSocials({ shop_id: shopId });
};

export const deleteItem = (shopId, id) => {
    return mainApi.deleteSocial({ shop_id: shopId, _id: id });
};

export const sortItems = (shopId, ids) => {
    return mainApi.sortSocials({ shop_id: shopId, data: ids });
};