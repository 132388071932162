import { useContext, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import "./CategorySelectInput.css";
import { CrossIcon, GoBackIcon } from "../../../assets/icons/controls/controls";
import { CategoriesInputCrossIcon } from "../../../assets/icons/product/product";
import {
  LabelIcon,
  ProductIcon,
} from "../../../assets/icons/category/category";
import { UserContext } from "../../../assets/contexts/userContext";
import mainApi from "../../../assets/api/MainApi";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import useAutoDismissError from "../../../assets/hooks/useAutoDismissError";
import { parseApiError } from "../../../assets/utils/utils";

const dropdownVariants = {
  open: {
    clipPath: "inset(0% 0% 0% 0% round 8px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.5,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  closed: {
    clipPath: "inset(0 90% 90% 10% round 8px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3,
    },
  },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

function CategorySelectInput({
  value,
  label,
  hint,
  inputError,
  name,
  handleChange,
  readOnly,
}) {
  const { user } = useContext(UserContext);
  const categoryDropdown = useRef();
  const [isCategoryDropOpen, setCategoryDropOpen] = useState(false);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [error, showError] = useAutoDismissError();
  const isFirstCategory =
    categories?.length !== 0 && !categories[categories.length - 1].parent;

  useEffect(() => {
    function handleOutsideClickClose(evt) {
      if (
        isCategoryDropOpen &&
        categoryDropdown.current &&
        !categoryDropdown.current.contains(evt.target)
      ) {
        setCategoryDropOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOutsideClickClose);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickClose);
    };
  });

  useEffect(() => {
    if (!user) return;

    const shop_id = user.default_shop._id;
    setIsPreloaderVisible(true);
    mainApi
      .getAllCategories({ shop_id })
      .then((res) => {
        setCategories([{ data: res.data, parent: null }]);
      })
      .catch((err) => {
        console.log(err);
        if (err.statusCode === 403) {
          setCategories([])
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function toggleDropdown() {
    setCategoryDropOpen((prevValue) => !prevValue);
  }

  function handleSelect(value) {
    if (value.is_final || value.is_final === null) {
      handleChange({ target: { value, name } });
    } else {
      getSiblings(value);
    }
  }

  function getSiblings(category) {
    if (!user) return;

    const shop_id = user.default_shop._id;
    setIsPreloaderVisible(true);
    mainApi
      .getAllCategories({ shop_id, _id: category._id })
      .then((res) => {
        setCategories((prevVal) => [
          ...prevVal,
          { data: res.data, parent: category },
        ]);
      })
      .catch((err) => {
        console.log(err);
        if (err.statusCode === 403) {
          setCategories([])
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
      });
  }

  function handleGoBack() {
    setCategories((prevVal) => prevVal.slice(0, categories.length - 1));
  }

  function handleDelete(value) {
    handleChange({
      target: {
        value: value,
        name: name + "-delete",
      },
    });
  }

  return (
    <div className="category-input">
      {label || hint ? (
        <div className="category-input__heading">
          {label ? (
            <span className="category-input__label">{label}</span>
          ) : null}
          {error ? (
            <span
              className="category-input__error"
              dangerouslySetInnerHTML={{ __html: error }}
            />
          ) : inputError ? (
            <span
              className="category-input__error"
              dangerouslySetInnerHTML={{ __html: inputError }}
            />
          ) : hint ? (
            <span
              className="category-input__hint"
              dangerouslySetInnerHTML={{ __html: hint }}
            />
          ) : null}
        </div>
      ) : null}

      <div className="category-input__container">
        <div className={`category-input__input-box ${readOnly ? 'category-input__input-box_read-only' : ''}`}>
          {value?.length > 0
            ? value.map((item) => (
              <div className="category-input__category">
                <p className="category-input__category-text">{item.name}</p>
                <button
                  className="category-input__delete-btn"
                  type="button"
                  onClick={() => {
                    if (readOnly) return
                    handleDelete(item._id)
                  }}
                >
                  <CategoriesInputCrossIcon
                    mainClassName={"category-input__delete-icon"}
                    fillClassName={"category-input__delete-icon-fill"}
                  />
                </button>
              </div>
            ))
            : 
            readOnly ?
            <span className="category-input__read-only-placeholder">-</span>
            :
            null
            }

          {!readOnly ?
            <button
              className="category-input__add-btn"
              type="button"
              onClick={() => {
                if (readOnly) return
                toggleDropdown()
              }}
            >
              Добавить...
            </button>
            : null}
            
        </div>

        {categories?.length !== 0 ? (
          <div
            className={`category-input__dropdown ${isCategoryDropOpen ? "category-input__dropdown_opened" : ""
              }`}
            ref={categoryDropdown}
          >
            <motion.div
              className="category-input__box-with-dropdown"
              initial={false}
              animate={isCategoryDropOpen ? "open" : "closed"}
            >
              <motion.div
                variants={dropdownVariants}
                style={{ pointerEvents: isCategoryDropOpen ? "auto" : "none" }}
                className="category-input__dropdown-container"
              >
                <div className="category-input__dropdown-heading">
                  <button
                    className={`category-input__dropdown-control-btn ${isFirstCategory
                      ? "category-input__dropdown-control-btn_hidden"
                      : ""
                      }`}
                    type="button"
                    onClick={() => {
                      if (readOnly) return
                      handleGoBack()
                    }}
                  >
                    <GoBackIcon
                      mainClassName={"category-input__control-btn-icon"}
                      strokeClassName={
                        "category-input__control-btn-icon-stroke"
                      }
                    />
                  </button>
                  {!isPreloaderVisible ? (
                    <p className="category-input__dropdown-title">
                      {isFirstCategory
                        ? "Выберите категорию"
                        : categories[categories.length - 1].parent.name}
                    </p>
                  ) : (
                    <MiniPreloader />
                  )}
                  <button
                    className="category-input__dropdown-control-btn"
                    type="button"
                    onClick={() => {
                      if (readOnly) return
                      toggleDropdown()
                    }}
                  >
                    <CrossIcon
                      mainClassName={"category-input__control-btn-icon"}
                      strokeClassName={
                        "category-input__control-btn-icon-stroke"
                      }
                    />
                  </button>
                </div>
                <ul className="category-input__dropdown-list">
                  {categories?.length > 0 &&
                    categories[categories.length - 1].data?.length > 0
                    ? categories[categories.length - 1].data.map((item, i) => (
                      <motion.li
                        className={`category-input__dropdown-item ${value.length > 0 &&
                          value.some((category) => category._id === item._id)
                          ? "category-input__dropdown-item_selected"
                          : ""
                          }`}
                        variants={itemVariants}
                        key={`dropdown-type-cat-item-${i}`}
                      >
                        <button
                          className={`category-input__cat-btn ${isPreloaderVisible
                            ? "category-input__cat-btn_disabled"
                            : ""
                            }`}
                          type="button"
                          onClick={() => {
                            if (readOnly) return
                            handleSelect(item)
                          }}
                        >
                          <p className="category-input__cat-btn-text">
                            {item.name}
                          </p>
                          <div className="category-input__cat-type">
                            <p className="category-input__cat-type-text">
                              {Number(item.count).toLocaleString("us")}
                            </p>
                            {item.is_final ? (
                              <ProductIcon
                                mainClassName={
                                  "category-input__cat-type-icon"
                                }
                                fillClassName={
                                  "category-input__cat-type-icon-fill"
                                }
                              />
                            ) : item.is_final !== null ? (
                              <LabelIcon
                                mainClassName={
                                  "category-input__cat-type-icon"
                                }
                                fillClassName={
                                  "category-input__cat-type-icon-fill"
                                }
                              />
                            ) : null}
                          </div>
                        </button>
                      </motion.li>
                    ))
                    : null}
                </ul>
              </motion.div>
            </motion.div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CategorySelectInput;
