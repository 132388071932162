import { Route, Routes } from 'react-router-dom';

import './HomePage.css';
import HomePageMain from './HomePageMain/HomePageMain';

function HomePage() {
    return (
        <div className='home-page'>
            <Routes>
                <Route index element={
                    <HomePageMain />
                }>
                </Route>
            </Routes>
        </div>
    );
}

export default HomePage
