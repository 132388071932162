import { HomeIcon } from "../icons/header/header";
import { PERMISSIONS, RBAC_CATEGORY_MANAGEMENT, RBAC_CONTENT_MANAGEMENT, RBAC_COUPONS, RBAC_DRAFTS, RBAC_INTEGRATIONS, RBAC_ORDERS, RBAC_PUBLISHED, RBAC_SHOP_SETTINGS, RBAC_USER_MANAGEMENT } from "./permissions_rbac";

export const MAIN_URL = 'https://app.polza.tech'
// export const MAIN_URL = 'https://app-demo.polza.tech'
// export const MAIN_URL = 'https://sellone-backend.cabatest.ru'
// export const MAIN_URL = 'https://192.168.0.149:3089'
export const LK_MAIN_URL = 'https://lk.polza.tech'

export const AUTH_MAIN_LINK = "auth"
export const AUTH_SIGNUP_TYPE = "signup";
export const AUTH_SIGNUP_WITH_SHOP_TYPE = "signup-with-shop";
export const AUTH_LOGIN_TYPE = "login";
export const AUTH_RECOVERY_TYPE = "recovery";
export const AUTH_RECOVERY_STEP_2_TYPE = "recovery-step-2";
export const AUTH_RESET_PASSWORD_TYPE = "reset-password";

export const MAIN_DOMAIN = 'polza.tech'
export const SHOP_DOMAIN = 'shops.polza.tech'


export const TERMS_OF_USE_LINK = '/docs/terms-of-use'
export const PERSONAL_DATA_USE_LINK = '/docs/personal-data-use'

export const SUPPORT_AUTH_LINK = "/support/auth";

export const AUTH_SIGNUP_LINK = `/${AUTH_MAIN_LINK}/${AUTH_SIGNUP_TYPE}`;
export const AUTH_SIGNUP_WITH_SHOP_LINK = `/${AUTH_MAIN_LINK}/${AUTH_SIGNUP_WITH_SHOP_TYPE}`;
export const AUTH_LOGIN_LINK = `/${AUTH_MAIN_LINK}/${AUTH_LOGIN_TYPE}`;
export const AUTH_RECOVERY_LINK = `/${AUTH_MAIN_LINK}/${AUTH_RECOVERY_TYPE}`;
export const AUTH_RECOVERY_STEP_2_LINK = `/${AUTH_MAIN_LINK}/${AUTH_RECOVERY_STEP_2_TYPE}`;
export const AUTH_RESET_PASSWORD_LINK = `/${AUTH_MAIN_LINK}/${AUTH_RESET_PASSWORD_TYPE}`;


export const PASSWORD_MIN_LENGTH = 8;
export const ERROR_PASSWORD_MIN_LENGTH = `Минимальная длина пароля ${PASSWORD_MIN_LENGTH} символов`;
export const ERROR_PASSWORD_CONFRINM_DIFFERENCE = "Пароли должны совпадать";
export const ERROR_REQUIRED_FIELD = "Поле не может быть пустым";
export const ERROR_UNIQUE_ID = "Данный ID уже используется вами"
export const PRICE_DATA_MIN_VALUE = 1
export const ERROR_PRICE_DATA_MIN_VALUE = `Минимальное значение ${PRICE_DATA_MIN_VALUE}`
export const ERROR_PARAMETER_TITLE = 'Названия параметров не могут совпадать'
export const ERROR_OPTION_TITLE = 'Названия опций не могут совпадать'
export const ERROR_OPTION_VALUE = 'Значения опции не могут совпадать'
export const PHOTO_MAX_LENGTH = 10;
export const ERROR_PHOTO_MAX_LENGTH = `Можно загрузить не более ${PHOTO_MAX_LENGTH} фото`
export const DEFAULT_MAX_IMG_SIZE = 15;

export const PLATFORM_MAIN_LINK = "/platform";

export const HOME_MAIN_LINK = "home";

export const STATISTICS_MAIN_LINK = "statistics";

export const PROMOCODES_MAIN_LINK = "promo-codes";
export const PROMOCODES_GROUP_LINK = "group";
export const PROMOCODES_CREATE_GROUP_LINK = "create-group";
export const PROMOCODES_EDIT_GROUP_LINK = "edit-group";
export const PROMOCODES_CREATE_CODE_LINK = "create-code";

export const ORDERS_MAIN_LINK = "orders";
export const ORDERS_ITEM_LINK = "item";
export const ORDERS_ITEM_MAIN_INFO_LINK = "main";
export const ORDERS_ITEM_СOMPOSITION_LINK = "composition";
export const ORDERS_ITEM_PREVIOUS_LINK = "previous";
export const ORDERS_ITEM_LINKS = [
    {
        title: `Основная информация`,
        path: ORDERS_ITEM_MAIN_INFO_LINK,
    },
    {
        title: 'Состав заказа',
        path: ORDERS_ITEM_СOMPOSITION_LINK,
    },
    {
        title: 'Предыдущие заказы',
        path: ORDERS_ITEM_PREVIOUS_LINK,
    },
]

export const SETTINGS_MAIN_LINK = "settings";
export const SETTINGS_PAYMNET_SYSTEMS_LINK = "payment-systems";
export const SETTINGS_INTEGRATIONS_LINK = "integrations";
export const SETTINGS_INTEGRATIONS_PAYMNET_SYSTEMS_LINK = "payment-systems";
export const SETTINGS_INTEGRATIONS_1S_PRODUCTS_LINK = "1s-products";
export const SETTINGS_INTEGRATIONS_YANDEX_METRICS_LINK = "yandex-metrics";
export const SETTINGS_INTEGRATIONS_WEBSERVICE_LINK = "webservice";
export const SETTINGS_INTEGRATIONS_NOTIFICATIONS_EMAIL_LINK =
    "notifications-email";
export const SETTINGS_INTEGRATIONS_NOTIFICATIONS_TELEGRAM_LINK =
    "notifications-telegram";
export const SETTINGS_INTEGRATIONS_LINKS = [
    {
        title: "Платежная система ЮKassa",
        path: SETTINGS_INTEGRATIONS_PAYMNET_SYSTEMS_LINK,
        // inactive: true,
        requiredPermissions: [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_PAYMENTS]],
    },
    {
        title: "1С: Товары",
        path: SETTINGS_INTEGRATIONS_1S_PRODUCTS_LINK,
        // requiredPermissions: [RBAC_INTEGRATIONS[PERMISSIONS.FIRSTC_PRODUCTS]],
    },
    {
        title: "Яндекс Метрика",
        path: SETTINGS_INTEGRATIONS_YANDEX_METRICS_LINK,
        requiredPermissions: [RBAC_INTEGRATIONS[PERMISSIONS.CONNECT_TO_YANDEXMETRICS]],
    },
    {
        title: "Веб-сервисы",
        path: SETTINGS_INTEGRATIONS_WEBSERVICE_LINK,
        requiredPermissions: [RBAC_INTEGRATIONS[PERMISSIONS.VIEW_WEBEXTRA]],
    },
    {
        title: "Уведомления о заказах на почту",
        path: SETTINGS_INTEGRATIONS_NOTIFICATIONS_EMAIL_LINK,
        requiredPermissions: [RBAC_INTEGRATIONS[PERMISSIONS.NOTIFICATIONS]],
    },
    {
        title: "Уведомления о заказах в Телеграмме",
        path: SETTINGS_INTEGRATIONS_NOTIFICATIONS_TELEGRAM_LINK,
        // inactive: true,
        requiredPermissions: [RBAC_INTEGRATIONS[PERMISSIONS.NOTIFICATIONS]],
    },

];

export const SETTINGS_PAGES_AND_FILES_LINK = 'pages-and-files'
export const SETTINGS_PAGES_AND_FILES_MAIN_PAGE_LINK = 'main-page'
export const SETTINGS_PAGES_AND_FILES_DELIVERY_AND_PAYMENT_LINK = 'delivery-and-payment'
export const SETTINGS_PAGES_AND_FILES_USER_AGREEMENT_LINK = 'user-agreement'
export const SETTINGS_PAGES_AND_FILES_DOCS = 'edit-docs'
export const SETTINGS_PAGES_AND_FILES_SOCIALS = 'edit-socials'
export const SETTINGS_PAGES_AND_FILES_HEADER_LINKS = 'edit-header-links'
export const SETTINGS_PAGES_AND_FILES_FOOTER_LINKS = 'edit-footer-links'
export const SETTINGS_PAGES_AND_FILES_DOCS_CREATE = 'create'
export const SETTINGS_PAGES_AND_FILES_SOCIALS_CREATE = 'create'
export const SETTINGS_PAGES_AND_FILES_DOCS_ITEM = 'item'
export const SETTINGS_PAGES_AND_FILES_SOCIALS_ITEM = 'item'
export const SETTINGS_PAGES_AND_FILES_LINKS = [
    {
        title: `Главная страница`,
        path: SETTINGS_PAGES_AND_FILES_MAIN_PAGE_LINK,
        requiredPermissions: [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_STATIC]],
    },
    {
        title: 'Документы',
        path: SETTINGS_PAGES_AND_FILES_DOCS,
        requiredPermissions: [RBAC_CONTENT_MANAGEMENT[PERMISSIONS.EDIT_STATIC_PAGES]],
    },
    {
        title: 'Социальные ссылки (подвал)',
        path: SETTINGS_PAGES_AND_FILES_SOCIALS,
        requiredPermissions: [RBAC_CONTENT_MANAGEMENT[PERMISSIONS.EDIT_STATIC_PAGES]],
    },
    {
        title: 'Ссылки (шапка)',
        path: SETTINGS_PAGES_AND_FILES_HEADER_LINKS,
        requiredPermissions: [RBAC_CONTENT_MANAGEMENT[PERMISSIONS.EDIT_STATIC_PAGES]],
    },
    {
        title: 'Ссылки (подвал)',
        path: SETTINGS_PAGES_AND_FILES_FOOTER_LINKS,
        requiredPermissions: [RBAC_CONTENT_MANAGEMENT[PERMISSIONS.EDIT_STATIC_PAGES]],
    },
]

export const SETTINGS_FINANCE_LINK = "finance";
export const SETTINGS_INFO_LINK = 'info'
export const SETTINGS_CONSTANTS_LINK = 'constants'
export const SETTINGS_USERS_LINK = 'users'
export const SETTINGS_USERS_СREATE_LINK = 'create'
export const SETTINGS_DOMAIN_LINK = 'domain'
export const SETTINGS_DOMAIN_TECHNICAL_LINK = 'technical'
export const SETTINGS_DOMAIN_CUSTOM_LINK = 'custom'
export const SETTINGS_DOMAIN_LINKS = [
    {
        title: `Адрес на ${MAIN_DOMAIN}`,
        path: SETTINGS_DOMAIN_TECHNICAL_LINK,
    },
    {
        title: 'Собственный домен',
        path: SETTINGS_DOMAIN_CUSTOM_LINK,
    },
]

export const SETTINGS_INDEX_LINK = SETTINGS_PAYMNET_SYSTEMS_LINK

export const ACCOUNT_MAIN_LINK = "account";
export const ACCOUNT_INFO_LINK = "info";

export const CONSTRUCTOR_MAIN_LINK = "constructor";
export const CONSTRUCTOR_TOKENS_LINK = "tokens";
export const CONSTRUCTOR_CARD_LINK = "card";
export const CONSTRUCTOR_LANDING_LINK = "main";
export const CONSTRUCTOR_PRODUCT_PAGE_LINK = "product-page";
export const CONSTRUCTOR_INDEX_LINK = CONSTRUCTOR_TOKENS_LINK;

export const CATALOG_MAIN_LINK = "catalog";
export const CATALOG_CATEGORY_LINK = "categories";
export const CATALOG_CATEGORY_EDIT_LINK = "edit";
export const CATALOG_CATEGORY_FILTERS_LINK = "filters";
export const CATALOG_IMPORT_LINK = "import";
export const CATALOG_IMPORT_FILE_LINK = "file";
export const CATALOG_IMPORT_FILE_ITEM_LINK = "item";
export const CATALOG_DRAFTS_LINK = "drafts";
export const CATALOG_DRAFTS_ITEM_LINK = "item";
export const CATALOG_DRAFTS_CREATE_ITEM_LINK = 'create-item'
export const CATALOG_PRODUCTS_LINK = "products";
export const CATALOG_PRODUCTS_ITEM_LINK = "item";
export const CATALOG_INDEX_LINK = CATALOG_CATEGORY_LINK;

export const CONTROLLERS_MAIN_LINK = "controllers";
export const CONTROLLERS_SHOP_CHANGHE_LINK = "shop-change";
export const CONTROLLERS_NO_SHOP_LINK = "no-shop";

export const SUBSCRIPTIONS_MAIN_LINK = 'subscriptions'
export const SUBSCRIPTIONS_PLATFROM_LINK = 'platfrom'
export const SUBSCRIPTIONS_PROMO_SIMPLE_LINK = 'promo-sellavi'
export const SUBSCRIPTIONS_PROMO_SIMPLE_WIX_LINK = 'promo-wix'

export const PLATFORM_SUBSCRIPTIONS_LINKS = {
    name: "Подписки",
    path: SUBSCRIPTIONS_MAIN_LINK,
    isInvisible: true,
    children: [
        {
            name: "Тариф",
            path: SUBSCRIPTIONS_PLATFROM_LINK,
        },
    ],
}


export const PLATFORM_CONTROLLERS_LINKS = {
    name: "",
    path: CONTROLLERS_MAIN_LINK,
    isInvisible: true,
}

export const PLATFORM_HOME_LINKS = {
    name: "Домашний экран",
    path: HOME_MAIN_LINK,
    icon: HomeIcon,
}

export const PLATFORM_STATISTICS_LINKS = {
    name: "Статистика",
    path: STATISTICS_MAIN_LINK,
    isInvisible: true,
}

export const PLATFORM_ORDERS_LINKS = {
    name: "Заказы",
    path: ORDERS_MAIN_LINK,
    requiredPermissions: [RBAC_ORDERS[PERMISSIONS.VIEW]],
}

export const PLATFORM_CATALOG_LINKS = {
    name: "Каталог товаров",
    path: CATALOG_MAIN_LINK,
    requiredPermissions: [RBAC_CATEGORY_MANAGEMENT[PERMISSIONS.VIEW], RBAC_DRAFTS[PERMISSIONS.VIEW], RBAC_PUBLISHED[PERMISSIONS.VIEW]],
    children: [
        {
            name: "Категории",
            path: CATALOG_CATEGORY_LINK,
            requiredPermissions: [RBAC_CATEGORY_MANAGEMENT[PERMISSIONS.VIEW]],
        },
        {
            name: "Импорт",
            path: CATALOG_IMPORT_LINK,
            requiredPermissions: [RBAC_PUBLISHED[PERMISSIONS.EDIT]],
        },
        {
            name: "Черновики",
            path: CATALOG_DRAFTS_LINK,
            requiredPermissions: [RBAC_DRAFTS[PERMISSIONS.VIEW]],
        },
        {
            name: "Товары",
            path: CATALOG_PRODUCTS_LINK,
            requiredPermissions: [RBAC_PUBLISHED[PERMISSIONS.VIEW]],
        },
    ],
}

export const PLATFORM_PROMOCODES_LINKS = {
    name: "Промо-коды",
    path: PROMOCODES_MAIN_LINK,
    requiredPermissions: [RBAC_COUPONS[PERMISSIONS.VIEW]],
}

export const PLATFORM_SETTINGS_LINKS = {
    name: "Настройки",
    path: SETTINGS_MAIN_LINK,
    requiredPermissions: [RBAC_INTEGRATIONS[PERMISSIONS.VIEW], RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_BILLING], RBAC_SHOP_SETTINGS[PERMISSIONS.VIEW_SHOP_INFO], RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_STATIC], RBAC_USER_MANAGEMENT[PERMISSIONS.VIEW], RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_DOMAIN]],
    children: [
        {
            name: "Настройки магазина",
            path: SETTINGS_INFO_LINK,
            requiredPermissions: [RBAC_SHOP_SETTINGS[PERMISSIONS.VIEW_SHOP_INFO]],
        },
        {
            name: "Интеграции",
            path: SETTINGS_INTEGRATIONS_LINK,
            requiredPermissions: [RBAC_INTEGRATIONS[PERMISSIONS.VIEW]],
        },
        {
            name: "Страницы и файлы",
            path: SETTINGS_PAGES_AND_FILES_LINK,
            requiredPermissions: [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_STATIC], RBAC_CONTENT_MANAGEMENT[PERMISSIONS.EDIT_STATIC_PAGES]],
        },
        {
            name: "Пользователи",
            path: SETTINGS_USERS_LINK,
            requiredPermissions: [RBAC_USER_MANAGEMENT[PERMISSIONS.VIEW]],
        },
        {
            name: "Финансы",
            path: SETTINGS_FINANCE_LINK,
            requiredPermissions: [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_BILLING]],
        },
        {
            name: "Домен",
            path: SETTINGS_DOMAIN_LINK,
            requiredPermissions: [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_DOMAIN]],
        },
    ],
}

export const PLATFORM_ACCOUNT_LINKS = {
    name: "Аккаунт",
    isInvisible: true,
    path: ACCOUNT_MAIN_LINK,
    children: [
        {
            name: "Аккаунт",
            path: ACCOUNT_INFO_LINK,
        },
    ],
}

export const PLATFORM_CONSTRUCTOR_LINKS = {
    name: "Конструктор",
    path: CONSTRUCTOR_MAIN_LINK,
    requiredPermissions: [RBAC_CONTENT_MANAGEMENT[PERMISSIONS.EDIT_LAYOUT]],
    children: [
        {
            name: "Токены",
            path: CONSTRUCTOR_TOKENS_LINK,
        },
        {
            name: "Карточка",
            path: CONSTRUCTOR_CARD_LINK,
        },
        {
            name: "Главная страница",
            path: CONSTRUCTOR_LANDING_LINK,
        },
        {
            name: "Страница товара",
            path: CONSTRUCTOR_PRODUCT_PAGE_LINK,
            disabled: true,
        },
    ],
}
export const PLATFORM_MAIN_LINKS = [
    PLATFORM_HOME_LINKS,
    PLATFORM_STATISTICS_LINKS,
    PLATFORM_ORDERS_LINKS,
    PLATFORM_CATALOG_LINKS,
    PLATFORM_PROMOCODES_LINKS,
    PLATFORM_SETTINGS_LINKS,
    PLATFORM_ACCOUNT_LINKS,
    PLATFORM_CONSTRUCTOR_LINKS,
    PLATFORM_CONTROLLERS_LINKS,
    PLATFORM_SUBSCRIPTIONS_LINKS,
];

export const ACTION_POPUP_NAME = "action";
export const MENU_POPUP_NAME = "menu";

export const DEBOUNCE_TIME = 1500;

export const ADMIN_ROLE = {
    type: "admin",
    hint: "Полный доступ ко&nbsp;всем разделам и&nbsp;функциям платформы",
    title: "Администратор",
    _id: "000000000000000000000001",
};

export const MANAGER_ROLE = {
    type: "manager",
    hint: "Управление заказами, товарами и&nbsp;промо-кодами, без доступа к&nbsp;настройкам системы",
    title: "Менеджер",
    _id: "000000000000000000000002",
};

export const EDITOR_ROLE = {
    type: "editor",
    hint: "Создание и&nbsp;редактирование товаров",
    title: "Редактор",
    _id: "000000000000000000000003",
};

export const PRODUCT_FORM_TYPE_NUMBER = {
    type: "integer",
    // hint: 'олько цифры. Например, 1, 2, 3 и т.д.',
    title: "Число",
};
export const PRODUCT_FORM_TYPE_TEXT = {
    type: "string",
    // hint: 'олько цифры. Например, 1, 2, 3 и т.д.',
    title: "Текст",
};
export const PRODUCT_FORM_TYPE_BOOLEAN = {
    type: "boolean",
    // hint: 'олько цифры. Например, 1, 2, 3 и т.д.',
    title: "Да / Нет",
};
export const PRODUCT_FORM_TYPES = [PRODUCT_FORM_TYPE_NUMBER, PRODUCT_FORM_TYPE_TEXT, PRODUCT_FORM_TYPE_BOOLEAN]

export const NOTIFY_SUBSCRIPTION_TYPE = 'subscriptions'
export const NOTIFY_REGISTRATION_TYPE = 'registered'
export const NOTIFY_REGISTRATION_WALK_THROUGH = 'walk-through'


export const SHOP_CAT_ARRAY = [
    { "title": "Автозапчасти", "translit_value": "автозапчасти" },
    { "title": "Авто и Мото салоны", "translit_value": "авто_и_мото_салоны" },
    { "title": "Аптека", "translit_value": "аптека" },
    { "title": "Ателье по пошиву одежды", "translit_value": "ателе_по_пошиву_одежды" },
    { "title": "Бытовая техника", "translit_value": "бытовая_техника" },
    { "title": "Ветеринарная аптека", "translit_value": "ветеринарная_аптека" },
    { "title": "Ветеринарная клиника", "translit_value": "ветеринарная_клиника" },
    { "title": "Детские товары", "translit_value": "детские_товары" },
    { "title": "Зоомагазин", "translit_value": "зоомагазин" },
    { "title": "Кафе", "translit_value": "кафе" },
    { "title": "Книги и канцелярские товары", "translit_value": "книги_и_канцелярские_товары" },
    { "title": "Косметика порфюмерия", "translit_value": "косметика_порфюмерия" },
    { "title": "Оборудование для бизнеса", "translit_value": "оборудование_для_бизнеса" },
    { "title": "Доставка еды", "translit_value": "доставка_еды" },
    { "title": "Цифровые товары", "translit_value": "цифровые_товары" },
    { "title": "Кофейня", "translit_value": "кофейня" },
    { "title": "Кулинария", "translit_value": "кулинария" },
    { "title": "Лаборатория клинических анализов", "translit_value": "лаборатория_клинических_анализов" },
    { "title": "Матрасы", "translit_value": "матрасы" },
    { "title": "Мебель", "translit_value": "мебель" },
    { "title": "Настольные игры и головоломки", "translit_value": "настольные_игры_и_головоломки" },
    { "title": "Обои", "translit_value": "обои" },
    { "title": "Обувь", "translit_value": "обувь" },
    { "title": "Одежда", "translit_value": "одежда" },
    { "title": "Оптика", "translit_value": "оптика" },
    { "title": "Досуг, развленения, хобби", "translit_value": "досуг_развленения_хобби" },
    { "title": "Подарки, сувениры", "translit_value": "подарки_сувениры" },
    { "title": "Посуда", "translit_value": "посуда" },
    { "title": "Продукты питания", "translit_value": "продукты_питания" },
    { "title": "Строительство", "translit_value": "строительство" },
    { "title": "Сантехника", "translit_value": "сантехника" },
    { "title": "Свет", "translit_value": "свет" },
    { "title": "Спортивные товары", "translit_value": "спортивные_товары" },
    { "title": "Текстиль", "translit_value": "текстиль" },
    { "title": "Фермерске и экопродукты", "translit_value": "фермерске_и_экопродукты" },
    { "title": "Фотоателье", "translit_value": "фотоателье" },
    { "title": "Хозяйственные товары", "translit_value": "хозяйственные_товары" },
    { "title": "Цветов", "translit_value": "цветов" },
    { "title": "Цифровая техника", "translit_value": "цифровая_техника" },
    { "title": "Шторы", "translit_value": "шторы" },
    { "title": "Товары для взрослых 18+", "translit_value": "товары_для_взрослых_18+" },
    { "title": "Дача и сад", "translit_value": "дача_и_сад" },
    { "title": "Ювелирные украшения", "translit_value": "ювелирные_украшения" },
    { "title": "Товары для гейминга", "translit_value": "товары_для_гейминга" },
    { "title": "Кейтеринг", "translit_value": "кейтеринг" }
]



export const DELIVERY_TYPE_VALUES =
{
    "free": 'Бесплатная (15-25 дней)',
    "standard": 'Стандартная (14-20 дней)',
    "fast": 'Экспресс (8-12 дней)',
}


export const SUBMIT_TYPE_VALUES =
{
    'phone': 'Телефон',
    'whatsapp': 'WhatsApp',
    'telegram': 'Telegram',
}

export const PAYMENT_TYPE_VALUES =
{
    "manager": 'Менеджер',
    "yookassa": 'Банковская карта',
}

export const TEXT_TYPE = 'text'
export const TITLE_TYPE = 'title'
export const PRICE_TYPE = 'price'
export const PHONE_TYPE = 'phone'
export const EMAIL_TYPE = 'email'
export const FULL_NAME_TYPE = 'full_name'
export const CONTROLS_TYPE = 'controls'
export const SUBMIT_TYPE_TYPE = 'submit_type'
export const PAYMENT_TYPE_TYPE = 'payment_type'
export const ADDRESS_TYPE = 'address_type'
export const REQUEST_NUMBER_TYPE = 'request_number'
export const DATE_WITH_FORMAT_TYPE = 'date_with_format'
export const NUMBER_STRING_TYPE = 'number_string'
export const STATUS_TYPE = 'status'
export const ICON_TYPE = 'icon'
export const CARD_ICON_TYPE = 'card-icon'
export const DOWNLOAD_LINK_TYPE = 'download-link'


export const CURRENCY_MAP = {
    'USD': '$',  // United States Dollar
    'EUR': '€',  // Euro
    'JPY': '¥',  // Japanese Yen
    'GBP': '£',  // British Pound
    'AUD': 'A$', // Australian Dollar
    'CAD': 'C$', // Canadian Dollar
    'CHF': 'CHF', // Swiss Franc
    'CNY': '¥',  // Chinese Yuan
    'SEK': 'kr',  // Swedish Krona
    'NZD': 'NZ$', // New Zealand Dollar
    'MXN': '$',  // Mexican Peso
    'SGD': 'S$', // Singapore Dollar
    'HKD': 'HK$', // Hong Kong Dollar
    'NOK': 'kr',  // Norwegian Krone
    'KRW': '₩',  // South Korean Won
    'TRY': '₺',  // Turkish Lira
    'INR': '₹',  // Indian Rupee
    'RUB': '₽',  // Russian Ruble
    'BRL': 'R$', // Brazilian Real
    'ZAR': 'R',  // South African Rand
};

export const COUPON_GROUPS_LIMIT = 25
export const CODES_GROUPS_LIMIT = 25
export const ORDERS_LIMIT = 25
export const PREV_ORDERS_LIMIT = 25
export const DRAFTS_LIMIT = 25
export const CATEGORY_ITEMS_LIMIT = 25
export const SUBCATEGORIES_LEVEL_LIMIT = 7;

export const ARTUCUL_EN = 'articul'
export const ARTUCUL_RU = 'артикул'


export const SELECTION_ALL_TYPE = 'all'
export const SELECTION_EXCLUDE_TYPE = 'exclude'
export const SELECTION_INCLUDE_TYPE = 'include'

export const DEFAULT_BANNER_SIZE = '1216x380 px'

export const EXEPRION_SHOP_ICONS_IDS = ['000000000000000000000018', '00000000000000000000001e']

export const PLATFROM_DEFAULT_LINK = PLATFORM_MAIN_LINKS[0].children
    ? PLATFORM_MAIN_LINK +
    "/" +
    PLATFORM_MAIN_LINKS[0].path +
    "/" +
    PLATFORM_MAIN_LINKS[0].children[0].path
    : PLATFORM_MAIN_LINK + "/" + PLATFORM_MAIN_LINKS[0].path

export const ONLINE_PING_TIMEOUT = 60000
export const FULL_STAT_RELOAD_TIMEOUT = 900000

export const keyForErrorOfAccess = 'access'

export const SUBSCRIPTION_SUBTYPE_MAIN = 'main'
export const SUBSCRIPTION_SUBTYPE_SIMPLE = 'simple'


export const DEFAULT_VAT_VALUES = [
    {
        type: 1,
        title: 'Общая система налогообложения'
    },
    {
        type: 2,
        title: 'Упрощенная (УСН, доходы)'
    },
    {
        type: 3,
        title: 'Упрощенная (УСН, доходы минус расходы)'
    },
    {
        type: 4,
        title: 'Единый налог на вмененный доход (ЕНВД)'
    },
    {
        type: 5,
        title: 'Единый сельскохозяйственный налог (ЕСН)'
    },
    {
        type: 6,
        title: 'Патентная система налогообложения'
    },
]


