export const CartIcon = ({ mainClassName, fillClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        className={fillClassName}
        d="M0 0.752239C0 0.547927 0.0708066 0.371476 0.21242 0.222886C0.358601 0.0742952 0.529907 0 0.726338 0H3.03555C3.43754 0 3.72077 0.0812604 3.88523 0.243781C4.05425 0.406302 4.15932 0.650083 4.20043 0.975124L4.30321 1.65075H15.164C15.429 1.65075 15.6345 1.72272 15.7807 1.86667C15.9269 2.00597 16 2.18706 16 2.40995C16 2.45638 15.9954 2.50746 15.9863 2.56318C15.9817 2.61426 15.9749 2.66534 15.9657 2.71642L15.4724 6.0806C15.3947 6.63317 15.2074 7.06501 14.9105 7.37612C14.6181 7.68723 14.2001 7.84279 13.6565 7.84279H5.17345L5.28308 8.55323C5.30136 8.71575 5.35617 8.84577 5.44754 8.94328C5.5389 9.03615 5.65539 9.08259 5.797 9.08259H13.5812C13.7593 9.08259 13.9169 9.14527 14.054 9.27065C14.191 9.39602 14.2595 9.55854 14.2595 9.75821C14.2595 9.96716 14.191 10.1343 14.054 10.2597C13.9169 10.3851 13.7593 10.4478 13.5812 10.4478H5.61884C5.07523 10.4478 4.65725 10.2922 4.36488 9.9811C4.07252 9.66998 3.88523 9.24279 3.803 8.6995L2.76831 1.50448H0.726338C0.529907 1.50448 0.358601 1.43018 0.21242 1.28159C0.0708066 1.133 0 0.956551 0 0.752239ZM4.93362 12.7323C4.93362 12.5002 4.99072 12.2866 5.10493 12.0915C5.21913 11.9012 5.36988 11.7502 5.55717 11.6388C5.74447 11.5274 5.9546 11.4716 6.18758 11.4716C6.53019 11.4716 6.82256 11.5947 7.06467 11.8408C7.31135 12.0869 7.43469 12.3841 7.43469 12.7323C7.43469 13.0899 7.31135 13.3894 7.06467 13.6308C6.82256 13.8769 6.53019 14 6.18758 14C5.9546 14 5.74447 13.9443 5.55717 13.8328C5.36988 13.7214 5.21913 13.5682 5.10493 13.3731C4.99072 13.1828 4.93362 12.9692 4.93362 12.7323ZM11.224 12.7323C11.224 12.5002 11.2788 12.2866 11.3884 12.0915C11.5026 11.9012 11.6534 11.7502 11.8407 11.6388C12.0325 11.5274 12.2427 11.4716 12.4711 11.4716C12.8183 11.4716 13.1129 11.5947 13.355 11.8408C13.5971 12.0869 13.7182 12.3841 13.7182 12.7323C13.7182 13.0899 13.5971 13.3894 13.355 13.6308C13.1129 13.8769 12.8183 14 12.4711 14C12.1285 14 11.8338 13.8769 11.5872 13.6308C11.345 13.3894 11.224 13.0899 11.224 12.7323Z"
        fill="var(--text-on-contrast-color)"
      />
    </svg>
  );
};
