import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./Category.css";
import PageHeading from "../../../PageHeading/PageHeading";
import ItemForm from "../../../ItemForm/ItemForm";
import CategoryBlock from "./CategoryBlock/CategoryBlock";
import {
  CATALOG_CATEGORY_EDIT_LINK,
  CATALOG_DRAFTS_ITEM_LINK,
  CATALOG_DRAFTS_LINK,
  CATALOG_MAIN_LINK,
  CATALOG_PRODUCTS_ITEM_LINK,
  CATALOG_PRODUCTS_LINK,
  CATEGORY_ITEMS_LIMIT,
  PLATFORM_MAIN_LINK,
  SELECTION_INCLUDE_TYPE,
} from "../../../../../assets/utils/constants";
import {
  createAction,
  hasPermission,
  parseApiError,
  parsePermissions,
  useWindowSize,
} from "../../../../../assets/utils/utils";
import mainApi from "../../../../../assets/api/MainApi";
import { UserContext } from "../../../../../assets/contexts/userContext";
import MiniPreloader from "../../../../MiniPreloader/MiniPreloader";
import useAutoDismissError from "../../../../../assets/hooks/useAutoDismissError";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import SubmitActionPopup from "../../../SubmitActionPopup/SubmitActionPopup";
import { PERMISSIONS, RBAC_CATEGORY_MANAGEMENT, RBAC_DRAFTS, RBAC_PUBLISHED } from "../../../../../assets/utils/permissions_rbac";

const Category = () => {
  const location = useLocation();
  const { width } = useWindowSize();
  const { user } = useContext(UserContext);
  const [categories, setCategories] = useState([]);
  const [isInputOpen, setIsInputOpen] = useState({ isOpen: false, level: 0 });
  const [inputValues, setInputValues] = useState({});
  const [selectedCategories, setSelectedCategories] = useState(
    localStorage.getItem("categories") !== null
      ? JSON.parse(localStorage.getItem("categories"))
      : []
  );
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState({
    main: true,
    category: false,
    feed: false,
    add: false,
    delete: false,
    exclude: false,
  });
  const [isMoreBtnVisible, setIsMoreBtnVisible] = useState(false);
  const [isSubmitPopupOpen, setSubmitPopupOpen] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const [error, showError] = useAutoDismissError();

  const hasAddDraftPermission = hasPermission(parsePermissions(user), [RBAC_DRAFTS[PERMISSIONS.ADD]])
  const hasDeleteDraftPermission = hasPermission(parsePermissions(user), [RBAC_DRAFTS[PERMISSIONS.DELETE]])
  const hasEditDraftPermission = hasPermission(parsePermissions(user), [RBAC_DRAFTS[PERMISSIONS.EDIT]])
  const hasDeletePublishedPermission = hasPermission(parsePermissions(user), [RBAC_PUBLISHED[PERMISSIONS.DELETE]])
  const hasEditPublishedPermission = hasPermission(parsePermissions(user), [RBAC_PUBLISHED[PERMISSIONS.EDIT]])
  const hasAddPermission = hasPermission(parsePermissions(user), [RBAC_CATEGORY_MANAGEMENT[PERMISSIONS.ADD]])
  const hasDeletePermission = hasPermission(parsePermissions(user), [RBAC_CATEGORY_MANAGEMENT[PERMISSIONS.DELETE]])
  const hasEditPermission = hasPermission(parsePermissions(user), [RBAC_CATEGORY_MANAGEMENT[PERMISSIONS.EDIT]])

  function addCategoryActions() {
    const actions = [];

    if (selectedCategoryId) {
      if (hasDeletePermission) {
        actions.push(createAction("Удалить категорию", toggleSubmitPopup, {
          inactive: isPreloaderVisible.delete,
          isPreloaderVisible: isPreloaderVisible.delete,
        }));
      }

      if (hasEditPermission) {
        const editPath = `${location.pathname}/${CATALOG_CATEGORY_EDIT_LINK}/${selectedCategoryId}`;
        actions.push(createAction("Редактировать категорию", null, {
          inactive: isPreloaderVisible.delete,
          path: editPath,
        }));
      }
    }

    return actions;
  }

  function addProductActions() {
    const actions = [];

    if (selectedProduct) {
      if (selectedProduct.is_draft ? hasDeleteDraftPermission : hasDeletePublishedPermission) {
        actions.push(createAction("Удалить товар", toggleSubmitPopup, {
          inactive: isPreloaderVisible.delete || isPreloaderVisible.exclude,
          isPreloaderVisible: isPreloaderVisible.delete,
        }));
      }

      if (selectedProduct.is_draft ? hasEditDraftPermission : hasEditPublishedPermission) { // Assuming `edit` permission allows excluding products
        actions.push(createAction("Исключить из категории", handleExcludeProduct, {
          inactive: isPreloaderVisible.delete || isPreloaderVisible.exclude,
          isPreloaderVisible: isPreloaderVisible.exclude,
        }));
      }

      const productPath = `${PLATFORM_MAIN_LINK}/${CATALOG_MAIN_LINK}/${selectedProduct.is_draft ? CATALOG_DRAFTS_LINK : CATALOG_PRODUCTS_LINK}/${selectedProduct.is_draft ? CATALOG_DRAFTS_ITEM_LINK : CATALOG_PRODUCTS_ITEM_LINK}/${selectedProduct._id}`;
      actions.push(createAction("Перейти к товару", null, {
        inactive: isPreloaderVisible.delete || isPreloaderVisible.exclude,
        path: productPath,
      }));
    }

    return actions;
  }

  const actionsCategory = addCategoryActions();
  const actionsProduct = addProductActions();

  // get data on page load
  useEffect(() => {
    if (!user) return;

    const shop_id = user.default_shop._id;
    const savedCategories = localStorage.getItem("categories");
    setIsPreloaderVisible((prevVal) => ({ ...prevVal, main: true }));

    if (savedCategories !== null && JSON.parse(savedCategories).length !== 0) {
      const selectedCatArray = JSON.parse(savedCategories).map((item, i) => ({
        ...item,
        level: i,
      }));
      setSelectedCategoryId(selectedCatArray[selectedCatArray.length - 1]._id);

      function makeRequest(item) {
        return new Promise((resolve, reject) => {
          if (item.level === 0) {
            mainApi
              .getAllCategories({ shop_id })
              .then((res) => {
                setCategories([{ data: res.data, parent: null }]);
                if (item.is_final) {
                  getProducts({ data: item, resolve, reject });
                } else {
                  getSubcategory({ data: item, resolve, reject });
                }
              })
              .catch((err) => {
                if (err.statusCode === 403) {
                  setCategories([])
                  localStorage.removeItem("categories")
                }
                showError(parseApiError(err));
                reject(err);
              });
          } else {
            if (item.is_final) {
              getProducts({ data: item, resolve, reject });
            } else {
              getSubcategory({ data: item, resolve, reject });
            }
          }
        });
      }

      function getSubcategory({ data, resolve, reject }) {
        mainApi
          .getAllCategories({ shop_id, _id: data._id })
          .then((res) => {
            setCategories((prevVal) => [
              ...prevVal,
              { data: res.data, parent: data },
            ]);
            resolve(res);
          })
          .catch((err) => {
            if (err.statusCode === 403) {
              setCategories([])
              localStorage.removeItem("categories")
            }
            showError(parseApiError(err));
            reject(err);
          });
      }

      function getProducts({ data, resolve, reject }) {
        mainApi
          .getItemsByCategory({
            shop_id,
            category: data._id,
            limit: CATEGORY_ITEMS_LIMIT,
          })
          .then((res) => {
            setCategories((prevVal) => [
              ...prevVal,
              { data: res.data, parent: data },
            ]);
            setIsMoreBtnVisible(res.is_more);
            resolve(res);
          })
          .catch((err) => {
            if (err.statusCode === 403) {
              setCategories([])
              localStorage.removeItem("categories")
              setIsMoreBtnVisible(false)
            }
            showError(parseApiError(err));
            reject(err);
          });
      }

      async function runAsync(arr) {
        for (let item of arr) {
          await makeRequest(item).catch((err) => {
            console.log(err);
          });
        }
      }

      runAsync(selectedCatArray).finally(() => {
        setIsPreloaderVisible((prevVal) => ({ ...prevVal, main: false }));
      });
    } else {
      getCategory({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // save selected categories array to local storage on every state update
  useEffect(() => {
    localStorage.setItem("categories", JSON.stringify(selectedCategories));
  }, [selectedCategories]);

  // handle category or product click
  function handleCategorySelect({ data, level }) {
    const nextLevel = level + 1;
    if (categories.length > nextLevel)
      setCategories((prevVal) => prevVal.slice(0, nextLevel));

    setIsInputOpen({ isOpen: false, level: 0 });
    setInputValues({});
    setSelectedCategories((prevVal) => [...prevVal.slice(0, level), data]);
    setSelectedCategoryId(data._id);
    setSelectedProduct(undefined);
    setIsPreloaderVisible((prevVal) => ({ ...prevVal, category: true }));
    data.is_final ? getItems({ data }) : getCategory({ data });
  }

  function handleProductSelect(item) {
    setSelectedProduct(item);
    setSelectedCategoryId("");
  }

  // get categories and items data
  function getCategory({ data }) {
    const shop_id = user.default_shop._id;
    mainApi
      .getAllCategories({ shop_id, _id: data ? data._id : null })
      .then((res) => {
        setCategories(
          data
            ? (prevVal) => [...prevVal, { data: res.data, parent: data }]
            : [{ data: res.data, parent: null }]
        );
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setCategories([])
          localStorage.removeItem("categories")
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({
          ...prevVal,
          main: false,
          category: false,
        }));
      });
  }

  function getItems({ data, last_id }) {
    const shop_id = user.default_shop._id;
    mainApi
      .getItemsByCategory({
        shop_id,
        category: data._id,
        last_id,
        limit: CATEGORY_ITEMS_LIMIT,
      })
      .then((res) => {
        setCategories(
          last_id
            ? (prevVal) =>
              prevVal.map((item) => {
                if (item.parent?._id !== data._id) return item;
                return { ...item, data: item.data.concat(res.data) };
              })
            : (prevVal) => [...prevVal, { data: res.data, parent: data }]
        );
        setIsMoreBtnVisible(res.is_more);
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setCategories([])
          localStorage.removeItem("categories")
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({
          ...prevVal,
          feed: false,
          category: false,
        }));
      });
  }

  // load more products
  function getMore() {
    const catArr = categories[categories.length - 1];
    const data = catArr.data;
    const parent = catArr.parent;
    const last_id = data[data.length - 1]._id;
    setIsPreloaderVisible((prevVal) => ({ ...prevVal, feed: true }));
    getItems({ data: parent, last_id });
  }

  // return to previous page on mobile
  function handleGoBack() {
    setCategories((prevVal) => prevVal.slice(0, categories.length - 1));
  }

  // add category action
  function handleAddInput(level, block) {
    setIsInputOpen((prevVal) => ({
      ...prevVal,
      level: level,
      isOpen: true,
      parent: block && block.parent ? block.parent : null,
    }));
    setInputValues((prevVal) => ({ ...prevVal, [`category-${level}`]: "" }));
  }

  function handleCloseInput() {
    setIsInputOpen((prevVal) => ({ ...prevVal, isOpen: false }));
  }

  function addCategoryInputOnKeyDown(evt) {
    switch (evt.keyCode) {
      case 27:
        setIsInputOpen({ isOpen: false, level: 0 });
        setInputValues({});
        break;

      default:
        break;
    }
  }

  function handleChange(e) {
    const input = e.target;
    const value = input.value;
    const name = input.name;
    setInputValues((prevVal) => ({ ...prevVal, [name]: value }));
  }

  function addCategory({ evt, level, parent }) {
    evt.preventDefault();
    if (!Boolean(inputValues[`category-${level}`])) return;

    const shop_id = user.default_shop._id;
    setIsPreloaderVisible((prevVal) => ({ ...prevVal, add: true }));
    mainApi
      .createCategory({
        shop_id,
        name: inputValues[`category-${level}`],
        parent_id: parent ? parent._id : null,
      })
      .then((res) => {
        setCategories((prevVal) =>
          prevVal.map((item, i) => {
            if (level - 1 === i) {
              return {
                ...item,
                data: item.data.map((maped_item, i2) => {
                  if (maped_item._id !== parent._id) return maped_item;
                  return {
                    ...maped_item,
                    count: maped_item.count + 1,
                    is_final:
                      maped_item.is_final === null
                        ? false
                        : maped_item.is_final,
                  };
                }),
              };
            }
            if (level !== i) return item;
            return {
              ...item,
              parent:
                level !== 0
                  ? {
                    ...item.parent,
                    count: item.parent.count + 1,
                    is_final:
                      item.parent.is_final === null
                        ? false
                        : item.parent.is_final,
                  }
                  : null,
              data: [...item.data, res],
            };
          })
        );
        if (parent)
          setSelectedCategories((prevVal) =>
            prevVal.map((item) => {
              if (item._id === parent._id) {
                return {
                  ...item,
                  count: item.count + 1,
                  is_final: item.is_final === null ? false : item.is_final,
                };
              }
              return item;
            })
          );
        handleCloseInput();
        setInputValues((prevVal) => ({
          ...prevVal,
          [`category-${level}`]: "",
        }));
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({ ...prevVal, add: false }));
      });
  }

  // exclude product action
  function handleExcludeProduct() {
    if (isPreloaderVisible.exclude || !user) return;

    setIsPreloaderVisible((prevVal) => ({ ...prevVal, exclude: true }));
    selectedProduct.is_draft ? excludeDraft() : excludePublishedProduct();
  }

  function excludePublishedProduct() {
    const shop_id = user.default_shop._id;
    const _id = selectedProduct._id;
    mainApi
      .getItemPublishedById({ shop_id, _id })
      .then((res) => {
        if (res.categories.length === 1) {
          mainApi
            .moveToDrafts({
              shop_id,
              data: [_id],
              type: SELECTION_INCLUDE_TYPE,
            })
            .then(() => {
              editDraft({ res });
            })
            .catch((err) => {
              showError(parseApiError(err));
            });
        } else {
          editPublishedProduct({ res });
        }
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({ ...prevVal, exclude: false }));
      });
  }

  function excludeDraft() {
    const shop_id = user.default_shop._id;
    const _id = selectedProduct._id;
    mainApi
      .getDraftById({ shop_id, _id })
      .then((res) => {
        editDraft({ res });
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({ ...prevVal, exclude: false }));
      });
  }

  function editPublishedProduct({ res }) {
    if (!user) return;

    const shop_id = user.default_shop._id;
    const level = categories.length - 1;
    const prevLevel = level - 1;
    const categoryId = selectedCategories[prevLevel]._id;

    mainApi
      .editItemPublished({
        ...res,
        shop_id,
        files: res.files.map((img) => img._id),
        categories: res.categories
          .map((item) => item._id)
          .filter((item) => item !== categoryId),
      })
      .then(() => {
        editCategoriesAfterRemoveProduct();
      })
      .catch((err) => {
        showError(parseApiError(err));
      });
  }

  function editDraft({ res }) {
    if (!user) return;

    const shop_id = user.default_shop._id;
    const level = categories.length - 1;
    const prevLevel = level - 1;
    const categoryId = selectedCategories[prevLevel]._id;

    mainApi
      .editDraft({
        ...res,
        shop_id,
        files: res.files.length > 0 ? res.files.map((img) => img._id) : null,
        categories: res.categories
          .map((item) => item._id)
          .filter((item) => item !== categoryId),
      })
      .then(() => {
        editCategoriesAfterRemoveProduct();
      })
      .catch((err) => {
        showError(parseApiError(err));
      });
  }

  // delete category or product action
  function toggleSubmitPopup() {
    setSubmitPopupOpen((prevVal) => !prevVal);
  }

  function handleDelete() {
    if (isPreloaderVisible.delete || !user) return;

    setDeleteError("");
    setIsPreloaderVisible((prevVal) => ({ ...prevVal, delete: true }));

    if (selectedProduct) {
      selectedProduct.is_draft ? deleteDraft() : deletePublishedItem();
    } else {
      deleteCategory();
    }
  }

  function deletePublishedItem() {
    const shop_id = user.default_shop._id;
    const _id = selectedProduct._id;
    mainApi
      .deletePublishedItem({ shop_id, _id })
      .then(() => {
        editCategoriesAfterRemoveProduct();
        toggleSubmitPopup();
      })
      .catch((err) => {
        setDeleteError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({ ...prevVal, delete: false }));
      });
  }

  function deleteDraft() {
    const shop_id = user.default_shop._id;
    const _id = selectedProduct._id;
    mainApi
      .deleteDraft({ shop_id, _id })
      .then(() => {
        editCategoriesAfterRemoveProduct();
        toggleSubmitPopup();
      })
      .catch((err) => {
        setDeleteError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({ ...prevVal, delete: false }));
      });
  }

  function deleteCategory() {
    const shop_id = user.default_shop._id;
    mainApi
      .deleteCategory({
        shop_id,
        _id: selectedCategoryId,
      })
      .then(() => {
        const level = selectedCategories.findIndex(
          (item) => item._id === selectedCategoryId
        );
        const nextLevel = level + 1;
        const prevLevel = level - 1;
        setCategories((prevVal) =>
          prevVal.slice(0, nextLevel).map((item, i) => {
            if (prevLevel === i) {
              return {
                ...item,
                data: item.data.map((maped_item, i2) => {
                  if (maped_item._id !== selectedCategories[prevLevel]._id)
                    return maped_item;
                  return {
                    ...maped_item,
                    count: maped_item.count - 1,
                    is_final:
                      maped_item.count - 1 === 0 ? null : maped_item.is_final,
                  };
                }),
              };
            }
            if (level !== i) return item;
            return {
              ...item,
              data: item.data.filter(
                (category) => category._id !== selectedCategoryId
              ),
              parent:
                level !== 0
                  ? {
                    ...item.parent,
                    count: item.parent.count - 1,
                    is_final:
                      item.parent.count - 1 === 0
                        ? null
                        : item.parent.is_final,
                  }
                  : null,
            };
          })
        );
        level !== 0
          ? setSelectedCategories((prevVal) =>
            prevVal.slice(0, level).map((item, i) => {
              if (prevLevel === i) {
                return {
                  ...item,
                  count: item.count - 1,
                  is_final: item.count - 1 === 0 ? null : item.is_final,
                };
              }
              return item;
            })
          )
          : setSelectedCategories([]);
        setSelectedCategoryId(
          level === 0 ? "" : selectedCategories[prevLevel]._id
        );
        toggleSubmitPopup();
      })
      .catch((err) => {
        setDeleteError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({ ...prevVal, delete: false }));
      });
  }

  // helper for update saved categories data after delete or exclude product
  function editCategoriesAfterRemoveProduct() {
    const level = categories.length - 1;
    const prevLevel = level - 1;
    const categoryId = selectedCategories[prevLevel]._id;
    const productId = selectedProduct._id;

    setCategories((prevVal) =>
      prevVal.map((item, i) => {
        if (prevLevel === i) {
          return {
            ...item,
            data: item.data.map((maped_item, i2) => {
              if (maped_item._id !== categoryId) return maped_item;
              return {
                ...maped_item,
                count: maped_item.count - 1,
                is_final:
                  maped_item.count - 1 === 0 ? null : maped_item.is_final,
              };
            }),
          };
        }
        if (level !== i) return item;
        return {
          ...item,
          data: item.data.filter((product) => product._id !== productId),
          parent:
            level !== 0
              ? {
                ...item.parent,
                count: item.parent.count - 1,
                is_final:
                  item.parent.count - 1 === 0 ? null : item.parent.is_final,
              }
              : null,
        };
      })
    );
    setSelectedCategories((prevVal) =>
      prevVal.map((item, i) => {
        if (prevLevel === i) {
          return {
            ...item,
            count: item.count - 1,
            is_final: item.count - 1 === 0 ? null : item.is_final,
          };
        }
        return item;
      })
    );
    setSelectedProduct(undefined);
    setSelectedCategoryId(level === 0 ? "" : categoryId);
  }

  return (
    <div className="category">
      <PageHeading
        className={"category__heading"}
        title={
          width <= 750
            ? categories[categories.length - 1]?.parent?.name
              ? categories[categories.length - 1].parent.name
              : "Категории товаров"
            : "Категории товаров"
        }
        goBack={
          width <= 750 && categories.length - 1 > 0
            ? { onClick: () => handleGoBack() }
            : undefined
        }
        actions={selectedProduct ? actionsProduct : actionsCategory}
      />
      <ErrorMessage error={error} />
      {!isPreloaderVisible.main ? (
        <ItemForm containerClassName={"category__container"}>
          {categories?.length > 0 ? (
            width > 750 ? (
              categories.map((item, i) => (
                <CategoryBlock
                  key={`category-column-${i}`}
                  block={item}
                  level={i}
                  values={inputValues}
                  onMoreClick={getMore}
                  {...{
                    handleCategorySelect,
                    handleProductSelect,
                    handleAddInput,
                    selectedCategories,
                    selectedProduct,
                    isInputOpen,
                    addCategory,
                    handleChange,
                    isMoreBtnVisible,
                    addCategoryInputOnKeyDown,
                    isPreloaderVisible,
                    hasAddPermission,
                    hasAddDraftPermission,
                    hasEditPermission,
                  }}
                />
              ))
            ) : (
              <CategoryBlock
                key={`category-column-${categories.length - 1}`}
                block={categories[categories.length - 1]}
                level={categories.length - 1}
                values={inputValues}
                onMoreClick={getMore}
                {...{
                  handleCategorySelect,
                  handleProductSelect,
                  handleAddInput,
                  selectedCategories,
                  selectedProduct,
                  isInputOpen,
                  addCategory,
                  handleChange,
                  isMoreBtnVisible,
                  addCategoryInputOnKeyDown,
                  isPreloaderVisible,
                  hasAddPermission,
                  hasAddDraftPermission,
                  hasEditPermission,
                }}
              />
            )
          ) : null}
        </ItemForm>
      ) : (
        <div className="orders-list__preloader">
          <MiniPreloader />
        </div>
      )}
      <SubmitActionPopup
        isSubmitPreloader={isPreloaderVisible.delete}
        hendleSubmit={handleDelete}
        hendleClose={toggleSubmitPopup}
        isOpen={isSubmitPopupOpen}
        text={`Вы уверены что хотите удалить ${selectedProduct ? "этот товар" : "эту категорию"
          }?`}
        submitText={"Удалить"}
        cencelText={"Отменить"}
        submitError={deleteError}
      />
    </div>
  );
};

export default Category;
