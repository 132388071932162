import { useEffect, useState } from 'react';
import './ResetPassword.css';
import Input from '../../../Input/Input';
import Button from '../../../Button/Button';
import { AUTH_LOGIN_LINK, ERROR_PASSWORD_CONFRINM_DIFFERENCE, ERROR_PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH } from '../../../../assets/utils/constants';
import mainApi from '../../../../assets/api/MainApi';
import { useNavigate, useSearchParams } from 'react-router-dom';

function ResetPassword() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [emailToken, setEmailToken] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        let token = searchParams.get("token")
        if (!token) {
            navigate(AUTH_LOGIN_LINK)
        } else {
            setEmailToken(token)
        }
        console.log(token)
    }, [])


    const [values, setVelues] = useState({
        password: '',
        password_confirm: '',
    })

    const [valuesValidity, setVeluesValidity] = useState({
        password: {},
    })

    function handleChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name

        switch (name) {

            default:
                setVelues(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
        }
    }



    const [isLoading, setLoading] = useState(false)
    function handleSubmit() {
        setLoading(true)
        mainApi.changePassword({
            password: values.password,
            token: emailToken,
        })
            .then((res) => {
                console.log(res)
                navigate(AUTH_LOGIN_LINK)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className='reset-password'>
            <div className='reset-password__form'>

                <Input value={values.password}
                    placeholder={'Новый пароль'}
                    handleChange={handleChange}
                    name={'password'}
                    isPassword={true}
                ></Input>
                <Input value={values.password_confirm}
                    placeholder={'Подтвердите пароль'}
                    handleChange={handleChange}
                    name={'password_confirm'}
                    isPassword={true}
                    error={values.password.length >= PASSWORD_MIN_LENGTH && values.password && values.password_confirm && values.password !== values.password_confirm ? ERROR_PASSWORD_CONFRINM_DIFFERENCE : ''}
                ></Input>
                <Button
                    isLoading={isLoading}
                    isContrastColor={true}
                    text={'Сбросить пароль'}
                    onClick={handleSubmit}
                    isInvalid={!(values.password.length >= PASSWORD_MIN_LENGTH && values.password === values.password_confirm)}
                />
                {!(values.password.length >= PASSWORD_MIN_LENGTH && values.password === values.password_confirm) ?
                    <p className='reset-password__form-error'>{values.password && values.password.length >= 4 && values.password.length < PASSWORD_MIN_LENGTH ? ERROR_PASSWORD_MIN_LENGTH : values.password && values.password_confirm && values.password !== values.password_confirm ? ERROR_PASSWORD_CONFRINM_DIFFERENCE : ''}</p>
                    : null}
            </div>
        </div>
    );
}

export default ResetPassword