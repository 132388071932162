import { AddIcon, DeleteCrossIcon } from '../../../../assets/icons/controls/controls';
import { PRODUCT_FORM_TYPE_BOOLEAN, PRODUCT_FORM_TYPE_NUMBER, PRODUCT_FORM_TYPE_TEXT } from '../../../../assets/utils/constants';
import PlatformInput from '../../PlatformInput/PlatformInput';
import './ProductFormParameters.css';

const DEFAULT_OPTIONS_TYPES = [
    PRODUCT_FORM_TYPE_NUMBER,
    PRODUCT_FORM_TYPE_TEXT,
    PRODUCT_FORM_TYPE_BOOLEAN,
]

function ProductFormParameters({
    handleChange,
    product,
    error,
    readOnly,
}) {
    return (
        <div className='product-form__parameters'>
            <div className='product-form__parameter'>
                <PlatformInput
                    value={'Артикул'}
                    placeholder={'Артикул'}
                    label={'Параметр'}
                    // hint={'Видно только вам'}
                    readOnly={true}
                    isSelect={false}
                    //selected_text_value={'Артикул'}
                    // handleChange={handleChange}
                    // name={'article'}
                    maxLength={100}
                    isNoChange={true}
                >
                </PlatformInput>
                <PlatformInput
                    value={'Текст'}
                    placeholder={'Текст'}
                    label={'Тип'}
                    // hint={'Видно только вам'}
                    readOnly={true}
                    isSelect={false}
                    // selected_text_value={'Текст'}
                    maxLength={100}
                    isNoChange={true}
                >
                </PlatformInput>
                <PlatformInput
                    value={product.article}
                    placeholder={''}
                    label={'Значение'}
                    // hint={'Видно только вам'}
                    handleChange={handleChange}
                    name={'article'}
                    maxLength={100}
                    readOnly={readOnly}
                ></PlatformInput>
            </div>
            {product.parameters.map((parameter, parameter_index) => (
                <div className='product-form__parameter' key={`product-form__parameter-${parameter_index}`}>
                    <PlatformInput
                        value={parameter.title}
                        placeholder={''}
                        label={''}
                        readOnly={readOnly}
                        // hint={'Видно только вам'}
                        handleChange={(e) => {
                            handleChange({
                                target: {
                                    value: e.target.value,
                                    name: 'parameter-title',
                                    parameter_id: parameter._id,
                                    parameter_key: 'title',
                                }
                            })
                        }}
                        // name={'article'}
                        maxLength={100}
                        show_eror_color_only={true}
                        error={
                            error && error[parameter_index].title.errorMessage
                                ? error[parameter_index].title.errorMessage
                                : ""
                        }
                    >
                    </PlatformInput>
                    <PlatformInput
                        value={parameter.type}
                        placeholder={''}
                        label={''}
                        readOnly={readOnly}
                        // hint={'Видно только вам'}
                        isSelect={true}
                        selected_text_value={parameter.type.title}
                        maxLength={100}
                        handleChange={(e) => {
                            handleChange({
                                target: {
                                    value: e.target.value,
                                    name: 'parameter-type',
                                    parameter_id: parameter._id,
                                    parameter_key: 'type',
                                }
                            })
                        }}
                        selectList={DEFAULT_OPTIONS_TYPES}
                        selectDifferenceKey={'type'}
                        selectTextValueKey={'title'}
                        name={'parameter-type'}
                    >
                    </PlatformInput>

                    {parameter.type.type === PRODUCT_FORM_TYPE_BOOLEAN.type ?
                        <PlatformInput
                            readOnly={readOnly}
                            value={parameter.value}
                            placeholder={''}
                            label={''}
                            boolean_values={{
                                true: {
                                    title: 'Да',
                                },
                                false: {
                                    title: 'Нет',
                                },
                            }}
                            isBooleanSelector={true}
                            // hint={'Видно только вам'}
                            handleChange={(e) => {
                                handleChange({
                                    target: {
                                        value: e.target.value,
                                        name: 'parameter-value',
                                        parameter_id: parameter._id,
                                        parameter_key: 'value',
                                        parameter_type: parameter.type,
                                    }
                                })
                            }}
                            name={'parameter-value'}
                            maxLength={100}
                        ></PlatformInput>
                        :
                        <PlatformInput
                            readOnly={readOnly}
                            value={parameter.value}
                            placeholder={''}
                            label={''}
                            // hint={'Видно только вам'}
                            handleChange={(e) => {
                                handleChange({
                                    target: {
                                        value: e.target.value,
                                        name: 'parameter-value',
                                        parameter_id: parameter._id,
                                        parameter_key: 'value',
                                        parameter_type: parameter.type,
                                    }
                                })
                            }}
                            name={'parameter-value'}
                            maxLength={100}
                            show_eror_color_only={true}
                            error={
                                error && error[parameter_index].value.errorMessage
                                    ? error[parameter_index].value.errorMessage
                                    : ""
                            }
                        ></PlatformInput>
                    }

                    <button className='product-form__option-value-control' type='button' onClick={() => {
                        if (readOnly) return
                        handleChange({
                            target: {
                                value: null,
                                name: 'parameter-delete',
                                parameter_id: parameter._id,
                            }
                        })
                    }}>
                        <DeleteCrossIcon
                            mainClassName={'product-form__option-value-control-icon'}
                            fillClassName={'product-form__option-value-control-icon-fill'}
                        />
                    </button>
                </div>
            ))}
            {product.parameters.length < 10 && !readOnly ?
                <button className='product-form__btn' type='button' onClick={() => {
                    handleChange({
                        target: {
                            value: null,
                            name: 'add-parameter',
                        }
                    })
                }}>
                    <AddIcon
                        mainClassName={'product-form__btn-icon'}
                        fillClassName={'product-form__btn-icon-fill'}
                    />
                    Добавить параметр
                </button>
                : null}
        </div>
    );
}

export default ProductFormParameters