import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./App.css";
import Auth from "../Auth/Auth";
import Platform from "../Platform/Platform";
import Subscription from "../Platform/Subscription/Subscription";
import { useEffect, useState } from "react";
import {
  AUTH_LOGIN_LINK,
  AUTH_MAIN_LINK,
  AUTH_RESET_PASSWORD_TYPE,
  AUTH_SIGNUP_WITH_SHOP_LINK,
  CONTROLLERS_MAIN_LINK,
  CONTROLLERS_NO_SHOP_LINK,
  NOTIFY_REGISTRATION_TYPE,
  NOTIFY_REGISTRATION_WALK_THROUGH,
  NOTIFY_SUBSCRIPTION_TYPE,
  PLATFORM_MAIN_LINK,
  PLATFORM_MAIN_LINKS,
} from "../../assets/utils/constants";
import NotificationLine from "../NotificationLine/NotificationLine";
import mainApi from "../../assets/api/MainApi";
import { UserContext } from "../../assets/contexts/userContext";
import moment from "moment-timezone";
import { filterLinksForUserRBAC, getDefaultLinkForUserRBAC, hasPermission, parsePermissions } from "../../assets/utils/utils";
import { PlatformLinksContext } from "../../assets/contexts/platformLinksContext";
import { PERMISSIONS, RBAC_SHOP_SETTINGS } from "../../assets/utils/permissions_rbac";


const Overlay = ({ onClick }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
        zIndex: 1 // Ensure it's above other content
      }}
      onClick={onClick}
    />
  );
};

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation()
  const [user, setUser] = useState(undefined);
  const [preloader, setPreloader] = useState(true);
  const [isNotifyOpen, setIsNotifyOpen] = useState({
    [NOTIFY_SUBSCRIPTION_TYPE]: false,
    [NOTIFY_REGISTRATION_TYPE]: false,
    [NOTIFY_REGISTRATION_WALK_THROUGH]: false,
  });

  const navigate = useNavigate()

  useEffect(() => {
    setPreloader(true)
    mainApi
      .getMe()
      .then((res) => {
        console.log(res);
        if (!res.default_shop) {
          navigate(`${PLATFORM_MAIN_LINK}/${CONTROLLERS_MAIN_LINK}/${CONTROLLERS_NO_SHOP_LINK}`)
        }
        setUser(res);

        if (res.register_stage === 1 && res.email_verified === true) {
          navigate(`${AUTH_SIGNUP_WITH_SHOP_LINK}`)

        }

      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloader(false)
      })
  }, []);

  useEffect(() => {
    if (!user || (user.register_stage === 1 && user.email_verified === true)) {
      setIsNotifyOpen({
        [NOTIFY_SUBSCRIPTION_TYPE]: false,
        [NOTIFY_REGISTRATION_TYPE]: false,
        [NOTIFY_REGISTRATION_WALK_THROUGH]: false,
      })
      return
    };
    checkNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, location]);

  function checkNotification() {
    if (!user) return
    if (user.email_verified === false) {
      openNotification(NOTIFY_REGISTRATION_TYPE);
    } else {
      console.log(hasPermission(parsePermissions(user), [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_BILLING]]))
      if (!hasPermission(parsePermissions(user), [RBAC_SHOP_SETTINGS[PERMISSIONS.MANAGE_BILLING]])) {
        closeNotification(NOTIFY_SUBSCRIPTION_TYPE)
        return
      }
      const subscriptions = user?.default_shop?.subscriptions
      if (!subscriptions || !subscriptions.main || moment().diff(moment(subscriptions.main.valid_until + '+00:00'), 'seconds') >= 0) {
        openNotification(NOTIFY_SUBSCRIPTION_TYPE)
      } else {
        closeNotification(NOTIFY_SUBSCRIPTION_TYPE)
      }
    }

    // else {
    //   if (Object.values(user.subscriptions).length === 0)
    //     openNotification(NOTIFY_SUBSCRIPTION_TYPE);
    // }
  }

  function openNotification(type) {
    setIsNotifyOpen((prevVal) => ({ ...prevVal, [type]: true }));
  }

  function closeNotification(type) {
    setIsNotifyOpen((prevVal) => ({ ...prevVal, [type]: false }));
    if (type === NOTIFY_REGISTRATION_TYPE)
      setUser((prevVal) => ({ ...prevVal, registered: true }));
  }


  useEffect(() => {
    let walk_through = searchParams.get("walk-through")
    if (!walk_through) {
      closeNotification(NOTIFY_REGISTRATION_WALK_THROUGH)
      return
    } else {
      console.log(walk_through)
      if (walk_through) openNotification(NOTIFY_REGISTRATION_WALK_THROUGH)
      else {
        closeNotification(NOTIFY_REGISTRATION_WALK_THROUGH)
      }
    }
  }, [searchParams])

  const PLATFORM_LINKS_BY_USER_RBAC = user && user.default_shop && user.default_shop.role ? filterLinksForUserRBAC(user.default_shop.role.permissions, PLATFORM_MAIN_LINKS) : null
  const DEFAULT_PLATFORM_LINK_BY_USER = PLATFORM_MAIN_LINK + getDefaultLinkForUserRBAC(PLATFORM_LINKS_BY_USER_RBAC)
  console.log({ PLATFORM_LINKS_BY_USER_RBAC, DEFAULT_PLATFORM_LINK_BY_USER })

  return (
    <div className="app">

      {!preloader ?
        <UserContext.Provider value={{ user, setUser }}>
          <PlatformLinksContext.Provider value={{
            links: PLATFORM_LINKS_BY_USER_RBAC,
            default_link: DEFAULT_PLATFORM_LINK_BY_USER,
          }}>

            {isNotifyOpen[NOTIFY_REGISTRATION_TYPE]
              //  || 
              // (isNotifyOpen.subscriptions && location.pathname !== `${PLATFORM_MAIN_LINK}/${SUBSCRIPTIONS_MAIN_LINK}/${SUBSCRIPTIONS_PLATFROM_LINK}`)
              ? <Overlay onClick={() => { }} /> : null}
            <NotificationLine
              isOpen={isNotifyOpen}
              onClose={closeNotification}
            />
            <Routes>
              {console.log(user)}
              <Route index element={user ? <Navigate to={DEFAULT_PLATFORM_LINK_BY_USER} /> : <Navigate to={AUTH_LOGIN_LINK} />}></Route>
              <Route path={`/${AUTH_MAIN_LINK}/*`} element={<Auth />}></Route>
              <Route path="/platform/*" element={
                user ?
                  user.register_stage === 1 && user.email_verified === true ?
                    <Navigate to={AUTH_SIGNUP_WITH_SHOP_LINK} />
                    :
                    <Platform />
                  :
                  <Navigate to={AUTH_LOGIN_LINK} />
              }></Route>
              <Route path="/email-verified" element={<Navigate to={DEFAULT_PLATFORM_LINK_BY_USER} />}></Route>
              <Route path="*" element={user ? <Navigate to={DEFAULT_PLATFORM_LINK_BY_USER} /> : <Navigate to={AUTH_LOGIN_LINK} />}></Route>
            </Routes>
          </PlatformLinksContext.Provider>
        </UserContext.Provider>
        : null}

    </div>
  );
}

export default App;
