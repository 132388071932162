export function WalkThroughDoneIcon({
    mainClassName,
    fillClassName,
}) {
    return (
        <svg
            width={11}
            height={12}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 11 12"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.478.324c.312.164.468.246.51.39.044.144-.042.293-.214.59L4.96 11.362c-.238.411-.356.617-.552.636-.196.02-.357-.157-.68-.513L.34 7.746C.106 7.49-.01 7.36.001 7.211c.01-.149.145-.26.414-.483l.176-.146c.269-.222.403-.334.56-.323.155.01.272.14.505.396l2.136 2.357c.15.164.223.246.314.237.09-.01.145-.104.255-.294L9.249.499C9.421.2 9.507.052 9.657.01c.15-.042.306.04.618.205l.203.108z"
                fill="#00945A"
                className={fillClassName}
            />
        </svg>
    );
}
