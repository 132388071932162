import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import SelectCheckbox from "../SelectCheckbox/SelectCheckbox";
import ProductCard from "./ProductCard/ProductCard";
import "./ProductsList.css";
import { memo } from "react";

const Row = memo(({ index, style, data }) => {
  const item = data.items[index];
  const {
    isAllSelected,
    handleSelectItem,
    isSelectable,
    selectedIncludeProducts,
    selectedExcludeProducts,
    is_order_item,
    isPreloaderVisible,
    onMoreClick,
    isMoreBtnVisible,
  } = data;

  return (
    <ProductCard
      isAllSelected={isAllSelected}
      handleSelect={handleSelectItem}
      item={item}
      isSelectable={isSelectable}
      key={`product-card_${item._id}`}
      keyValue={`product-card-inner_${item._id}`}
      selectedIncludeProducts={selectedIncludeProducts}
      selectedExcludeProducts={selectedExcludeProducts}
      style={style}
      is_order_item={is_order_item}
      withMoreBtn={index === data.items.length - 1 && isMoreBtnVisible}
      {...{ isPreloaderVisible, onMoreClick }}
    />
  );
})

function ProductsList({
  className,
  handleSelect,
  isSelectable,
  isAllSelected,
  products,
  name,
  selectedIncludeProducts,
  selectedExcludeProducts,
  is_order_item,
  isPreloaderVisible,
  onMoreClick,
  isMoreBtnVisible,
}) {
  function handleSelectAllClick() {
    handleSelect({
      type: "all",
    }); //ALL
  }

  function handleSelectItem({ item }) {
    handleSelect({
      type: "item",
      item: item,
    });
  }

  return (
    <div className={`products-list ${className ? className : ""}`}>
      <div
        className={`products-list__heading ${!isSelectable ? "products-list__heading_type_no-selectable" : ""
          }`}
      >
        {isSelectable && handleSelect ? (
          <div
            className={`products-list__heading-checkbox ${!products || products.length === 0
              ? "products-list__heading-checkbox_hidden"
              : ""
              }`}
          >
            <SelectCheckbox
              isSelected={
                isAllSelected
                  ? selectedExcludeProducts.length === 0
                  : isAllSelected
              }
              toggleSelect={handleSelectAllClick}
            />
          </div>
        ) : null}
        <h5 className="products-list__heading-title">Товар</h5>
        <h5 className="products-list__heading-title">Цена</h5>
      </div>

      <div className="products-list__items">
        {products?.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <List
                key={`product-cards_${name}`}
                height={height}
                itemCount={products.length}
                itemSize={is_order_item ? 120 : 86}
                width={width}
                itemData={{
                  items: products,
                  isAllSelected,
                  handleSelectItem,
                  isSelectable,
                  selectedIncludeProducts,
                  selectedExcludeProducts,
                  is_order_item,
                  isPreloaderVisible,
                  onMoreClick,
                  isMoreBtnVisible,
                }}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        ) : null}
      </div>
    </div>
  );
}

export default ProductsList;
