import { MasterCardIcon, MirIcon, UnionPayIcon, UnknownCardIcon, VisaIcon } from "../../icons/paymentMethods/paymentMethods"

export function getCardIcon(card_type) {
    const map = {
        'MasterCard': MasterCardIcon,
        'Visa': VisaIcon,
        'Mir': MirIcon,
        'UnionPay': UnionPayIcon,
    }
    return card_type && map[card_type] ? map[card_type] : UnknownCardIcon
}