import FormControlBtn from "./FormControlBtn/FormControlBtn";
import {
  CrossEyeIcon,
  DeleteCrossIcon,
  EyeIcon,
  SortArrowIcon,
} from "../../../assets/icons/controls/controls";
import "./FormControls.css";

function FormControls({
  index,
  lastIndex,
  handleChange,
  isDeleteType = false,
  isVisibilityType = false,
  sortDownTarget,
  sortUpTarget,
  visibilityTarget,
  isVisible,
  deleteTarget,
  readOnly,
}) {
  return (
    <div className="form-controls">
      <FormControlBtn
        target={sortDownTarget}
        isDisabled={index === lastIndex}
        readOnly={readOnly}
        {...{ handleChange }}
      >
        <SortArrowIcon
          mainClassName={"form-controls__icon"}
          fillClassName={"form-controls__icon-fill"}
        />
      </FormControlBtn>

      <FormControlBtn
        target={sortUpTarget}
        isDisabled={index === 0}
        readOnly={readOnly}
        {...{ handleChange }}
      >
        <SortArrowIcon
          mainClassName={"form-controls__icon form-controls__icon_type_reverse"}
          fillClassName={"form-controls__icon-fill"}
        />
      </FormControlBtn>

      {isVisibilityType ? (
        <FormControlBtn target={visibilityTarget} readOnly={readOnly} {...{ handleChange }}>
          <EyeIcon
            mainClassName={`form-controls__icon form-controls__icon_type_visibility ${!isVisible ? "form-controls__icon_hidden" : ""
              }`}
            fillClassName={"form-controls__icon-fill"}
          />
          <CrossEyeIcon
            mainClassName={`form-controls__icon form-controls__icon_type_visibility ${isVisible ? "form-controls__icon_hidden" : ""
              }`}
            fillClassName={"form-controls__icon-fill"}
          />
        </FormControlBtn>
      ) : null}

      {isDeleteType ? (
        <FormControlBtn target={deleteTarget} readOnly={readOnly} {...{ handleChange }}>
          <DeleteCrossIcon
            mainClassName={"form-controls__icon"}
            fillClassName={"form-controls__icon-fill"}
          />
        </FormControlBtn>
      ) : null}
    </div>
  );
}

export default FormControls;
