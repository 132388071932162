import { useContext, useEffect, useState } from 'react';
import './EditMainPage.css';
import { parseApiError } from '../../../../../../assets/utils/utils';
import { UserContext } from '../../../../../../assets/contexts/userContext';
import useAutoDismissError from '../../../../../../assets/hooks/useAutoDismissError';

import PreloaderBox from '../../../../../PreloaderBox/PreloaderBox';
import ErrorMessage from '../../../../../ErrorMessage/ErrorMessage';
import { fetchStaticPagesData, saveStaticPageData } from './services/editMainPageApi';
import { ActionsContext } from '../../../../../../assets/contexts/actionsContext';
import { handleChange } from './utils/editMainPageHelpers';
import { TYPE_MAP } from './utils/editMainPageConstants';





function EditMainPage() {
    const { setActions } = useContext(ActionsContext)

    const { user } = useContext(UserContext)
    const [layout, setLayout] = useState(null)
    const [isSaved, setIsSaved] = useAutoDismissError()
    const [error, showError] = useAutoDismissError()

    const [preloaders, setPreloaders] = useState({
        initial: true,
        save: false,
    })
    const typeIndexes = {};

    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            initial: true,
        }))
        fetchStaticPagesData(user.default_shop._id)
            .then((data) => {
                setLayout(data);
            })
            .catch((err) => {
                if (err.statusCode === 403) {
                    setLayout(null);
                }
                console.error(err);
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    initial: false,
                }));
            });
    }, [user])


    function handleSave(layout) {
        setPreloaders(prevValue => ({
            ...prevValue,
            save: true
        }))
        saveStaticPageData(user.default_shop._id, layout)
            .then(() => {
                setIsSaved(true);
            })
            .catch((err) => {
                console.error(err);
                showError(parseApiError(err));
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    save: false,
                }));
            });

    }

    useEffect(() => {
        setActions(
            [
                {
                    name: isSaved ? 'Успешно сохранено' : "Сохранить",
                    onClick: () => handleSave(layout),
                    isMainAction: true,
                    // inactive: isSubmitDisabled,
                    id: "pages-and-files_save",
                    isPreloaderVisible: preloaders.save,
                },
            ]
        )

    }, [isSaved, preloaders.save, layout])
    return (
        <div className='edit-main-page'>
            <ErrorMessage error={error} />
            {!preloaders.initial ?
                layout && layout.length > 0 ?
                    layout.map((item, i) => {
                        if (!TYPE_MAP[item.block.type]) return null
                        // Initialize or increment the index for this item's type
                        if (typeIndexes[item.block.type] === undefined) {
                            typeIndexes[item.block.type] = 1;
                        } else {
                            typeIndexes[item.block.type]++;
                        }

                        // Generate the title with the current index for this type
                        const title = `${TYPE_MAP[item.block.type].title} (${typeIndexes[item.block.type]})`;

                        return (
                            <div className='edit-main-page__item' key={`${item._id}edit-main-page__item_${i}`}>
                                <p className='edit-main-page__item-title'>{title}</p>
                                {TYPE_MAP[item.block.type].form({ item, handleChange: (input) => handleChange(input, setLayout, showError) })}
                            </div>
                        );
                    })
                    : null
                :
                <PreloaderBox />
            }

        </div>
    );
}

export default EditMainPage