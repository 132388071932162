
import useWindowSize from '../../assets/hooks/useWindowSize';
import { BgShape } from '../../assets/icons/auth/auth';
import './Auth.css';
import AuthForm from './AuthForm/AuthForm';

// import bg from '../../assets/images/auth/bg.png'

function Auth({
}) {
    const { width, height } = useWindowSize();

    return (
        <div className='auth' style={{ minHeight: width <= 800 && height }}>
            <div className='auth__form' style={{ minHeight: width <= 650 && height }}>
                <AuthForm />
                <BgShape
                    mainClassName={'auth__bg-shape auth__bg-shape_type_1'}
                    fillClassName={'auth__bg-shape-fill'}
                />
                <BgShape
                    mainClassName={'auth__bg-shape auth__bg-shape_type_2'}
                    fillClassName={'auth__bg-shape-fill'}
                />
                 <BgShape
                    mainClassName={'auth__bg-shape auth__bg-shape_type_3'}
                    fillClassName={'auth__bg-shape-fill'}
                />
                 <BgShape
                    mainClassName={'auth__bg-shape auth__bg-shape_type_4'}
                    fillClassName={'auth__bg-shape-fill'}
                />
            </div>


            {/* <img className='auth__bg' src={bg} alt='' /> */}
        </div>
    );
}

export default Auth