
import PlatformInput from '../../../PlatformInput/PlatformInput';
import ActionBtn from '../../../ActionBtn/ActionBtn';
import ServiceContainer from '../../../ServiceContainer/ServiceContainer';
import TwoFactorBtn from '../../../TwoFactorBtn/TwoFactorBtn';
import './NotificationsTelegram.css';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../assets/contexts/userContext';
import mainApi from '../../../../../assets/api/MainApi';
import PreloaderBox from '../../../../PreloaderBox/PreloaderBox';

const BOT_NAME = '@sellone_notifications_bot'


function NotificationsTelegram() {

    const { user } = useContext(UserContext)

    const [data, setData] = useState(null)
    const [preloaders, setPreloaders] = useState({
        main: true,
        disconnect: false,
    })

    function updateData() {
        if (!user) return
        mainApi.getTelegramNotificationsIntegration({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                setData({
                    ...res,
                    // is_connected: true,
                })
            })
            .catch((err) => {
                console.log(err)
                if (err.statusCode === 403) {
                    setData(null)
                }
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    main: false,
                }))
            })
    }

    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            main: true,
        }))
        updateData()
    }, [user])



    function handleDisconnect() {
        // setIsConnected(false)
        setPreloaders(prevValue => ({
            ...prevValue,
            disconnect: true,
        }))
        mainApi.unsetTelegramNotificationsIntegration({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                setData({
                    ...res,
                })
            })
            .catch((err) => {
                console.log(err)
                if (err.statusCode === 403) {
                    setData(null)
                }
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    disconnect: false,
                }))
            })
    }

    //TIMEOUT PULL IS TG CONNECTED

    useEffect(() => {
        // Первый вызов для загрузки данных при монтировании компонента
        updateData();

        // Устанавливаем интервал для обновления данных каждые 10 секунд
        const intervalId = setInterval(() => {
            updateData();
        }, 10000);

        // Очищаем интервал при размонтировании компонента
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            {!preloaders.main ?
                <div className='notifications-telegram'>
                    <p className='notifications-telegram__title'>Получение уведомлений о новых заказах в Телеграмме</p>
                    {!data.is_connected ?
                        <>
                            <PlatformInput
                                value={`${data.to_connect.start_command}`}
                                readOnly={true}
                                label={'Уникальная фраза'}
                            />
                            <ActionBtn
                                text={'Скопировать фразу'}
                                isCopy={true}
                                copyValue={`${data.to_connect.start_command}`}
                            />

                            <PlatformInput
                                value={`@${data.to_connect.username}`}
                                readOnly={true}
                                label={'Имя бота в Телеграм'}
                            />
                            <ActionBtn
                                text={'Скопировать имя'}
                                isCopy={true}
                                copyValue={`@${data.to_connect.username}`}
                            />
                            <p className='notifications-telegram__hint'>
                                Получение Уведомлений о Новых Заказах в Телеграмме:<br />
                                <ol>
                                    <li><span className='notifications-telegram__hint_contrast'>Скопируйте Уникальную Фразу:</span> В начале, вам необходимо скопировать уникальную фразу, предоставленную вам выше. Эта фраза является ключом к настройке уведомлений.<br /><br /></li>
                                    <li><span className='notifications-telegram__hint_contrast'>Добавьте Бота в Рабочую Группу:</span> Продолжите, добавив нашего бота <a className='notifications-telegram__hint_contrast' href={`https://t.me/${data.to_connect.username}`} target='_blank' rel='noreferrer'>{`@${data.to_connect.username}`}</a> в вашу рабочую группу в Телеграмме. Это может быть любой групповой чат, в котором вы хотите получать уведомления.<br /><br /></li>
                                    <li><span className='notifications-telegram__hint_contrast'>Отправьте Скопированную Фразу:</span> После добавления бота в группу, отправьте ранее скопированную уникальную фразу в этот групповой чат.<br /></li>
                                </ol>

                                Таким образом вы создадите подписку на уведомления, которую сможете удалить в любой момент.
                            </p>
                            {/* <p className='notifications-telegram__hint'>Подключите 1С&nbsp;к&nbsp;нашему сервису через стандартную функциональность&nbsp;1С (Выгрузка&nbsp;на&nbsp;сайт) <a className='notifications-telegram__hint-link' href='https://aspro.ru/docs/course/course40/lesson1690/' target='_blank' rel='noreferrer'>Туториал для клиента</a></p> */}
                        </>
                        :
                        <>
                            <ServiceContainer
                                title={'Уведомления подключены'}
                                subtitle={'Вы успешно настроили отправку уведомлений о новых заказах в Телеграмме'}
                            >

                            </ServiceContainer>
                            <TwoFactorBtn
                                firstStepBtnText={'Отключить уведомления'}
                                secondStepBtnText={'Отключить'}
                                handleAction={handleDisconnect}
                                cancelBtnText={'Отмена'}
                                isLoading={preloaders.disconnect}
                                confirmText={'Вы&nbsp;уверены что хотите отключить отправку уведомлений в&nbsp;Телеграм?'}
                            />
                        </>
                    }
                </div>
                :
                <PreloaderBox />
            }
        </>

    );
}

export default NotificationsTelegram