import './ServiceContainer.css';

function ServiceContainer({
  title,
  subtitle,
  children,
  title_error,
}) {
  return (
    <div className='service-container'>
      {title ? <h6 className={`service-container__title ${title_error ? 'service-container__title_error' : ''}`}>{title}</h6> : null}
      {subtitle ? <h6 className='service-container__subtitle'
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></h6> : null}
      {children}
    </div>
  );
}

export default ServiceContainer