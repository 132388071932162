import { Link, useLocation } from 'react-router-dom';
import './StatisticsMain.css';
import { PercentageChange, getCurrentPlatfromLocation, getOrderWordForm, hasPermission, parsePrice } from '../../../../assets/utils/utils';
import moment from 'moment-timezone';
import { useContext, useEffect, useState } from 'react';
import StatisticsSells from './StatisticsSells/StatisticsSells';
import GraphContainer from './GraphContainer/GraphContainer';
import StatisticsInfo from './GraphContainer/StatisticsInfo/StatisticsInfo';
import { FULL_STAT_RELOAD_TIMEOUT, ONLINE_PING_TIMEOUT, PLATFORM_MAIN_LINK, PROMOCODES_MAIN_LINK } from '../../../../assets/utils/constants';
import { PlatformLinksContext } from '../../../../assets/contexts/platformLinksContext';
import { UserContext } from '../../../../assets/contexts/userContext';
import mainApi from '../../../../assets/api/MainApi';
import PreloaderBox from '../../../PreloaderBox/PreloaderBox';
import { PERMISSIONS, RBAC_STATISTIC } from '../../../../assets/utils/permissions_rbac';




const STATISTICS_PERIODS = [
    {
        title: 'Cегодня',
        date_from: moment().startOf('day'),
        date_to: moment(),
        period_type: 'day',
    },
    {
        title: '7 дней',
        date_from: moment().subtract(7, 'days').startOf('day'),
        date_to: moment(),
        period_type: 'week',
    },
    {
        title: '30 дней',
        date_from: moment().subtract(30, 'days').startOf('day'),
        date_to: moment(),
        period_type: 'month',
    }
]

function StatisticsMain() {

    const location = useLocation();
    const { links, default_link } = useContext(PlatformLinksContext)

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })
    const [selectedPeriod, setSelectedPeriod] = useState(STATISTICS_PERIODS[0])

    function handleSelectPeriod(period) {
        setSelectedPeriod(period)
        setPreloaders(prevValue => ({
            ...prevValue,
            statistics: true,
        }))
        mainApi.getStatsPageStatistics({
            shop_id: default_shop._id,
            period: period.period_type,
        })
            .then((res) => {
                setStatistics(res);
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    statistics: false,
                }))
            });
        console.log(`${period.date_from.format('DD.MM.YYYY HH:mm:ss')}-${period.date_to.format('DD.MM.YYYY HH:mm:ss')}`)
    }


    const { user } = useContext(UserContext)
    // const [data, setData] = useState(null)
    // useEffect(() => {
    //     mainApi.getShopInfo({
    //         shop_id: user.default_shop._id,
    //     })
    //         .then((res) => {
    //             console.log(res)
    //             setData(res)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }, [])
    const { default_shop } = user
    const {
        ai_id,
        domain,
        icon,
        info,
        logo,
        role,
        subscriptions,
    } = default_shop

    const [statistics, setStatistics] = useState(null)
    const [online, setOnline] = useState(null)
    const [preloaders, setPreloaders] = useState({
        statistics: true,
    })
    useEffect(() => {
        if (!default_shop._id) return
        setPreloaders(prevValue => ({
            ...prevValue,
            statistics: true,
        }))
        mainApi.getStatsPageStatistics({
            shop_id: default_shop._id,
            period: STATISTICS_PERIODS[0].period_type,
        })
            .then((res) => {
                setStatistics(res)
                console.log(res)
                mainApi.getOnlineStatistics({
                    shop_id: default_shop._id,
                })
                    .then((res) => {
                        console.log(res);
                        setOnline(res)
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        setPreloaders(prevValue => ({
                            ...prevValue,
                            statistics: false,
                        }))
                    })
            })
            .catch((err) => {
                console.log(err)
            })

    }, [default_shop])


    useEffect(() => {
        if (!default_shop) return;
    
        // Function to fetch statistics from the API
        const fetchOnlineStatistics = () => {
            mainApi.getOnlineStatistics({
                shop_id: default_shop._id,
            })
                .then((res) => {
                    console.log(res);
                    setOnline(res);
                })
                .catch((err) => {
                    console.error(err);
                });
        };
    
        // Fetch statistics immediately
        fetchOnlineStatistics();
    
        // Set an interval to fetch statistics every 60 seconds
        const interval = setInterval(fetchOnlineStatistics, ONLINE_PING_TIMEOUT);
    
        // Clear interval on component unmount or when default_shop changes
        return () => clearInterval(interval);
    
    }, [default_shop]); // Depend on default_shop to refetch if it changes

    // useEffect for full stats reload every 15 minutes, resets on selectedPeriod change
    useEffect(() => {
        if (!default_shop._id) return;

        let timer;
        const fetchFullStatistics = () => {
            mainApi.getStatsPageStatistics({
                shop_id: default_shop._id,
                period: selectedPeriod.period_type,
            })
                .then((res) => {
                    setStatistics(res);
                    console.log(res);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                });
        };

        // Function to start/restart the timer
        const startTimer = () => {
            // Clear existing timer
            if (timer) {
                clearTimeout(timer);
            }

            // Set a new timer
            timer = setTimeout(() => {
                fetchFullStatistics();
                // Once the timer runs out, set an interval
                timer = setInterval(fetchFullStatistics, FULL_STAT_RELOAD_TIMEOUT);
            }, FULL_STAT_RELOAD_TIMEOUT); // Start with a delay
        };

        // Initial start
        startTimer();

        // Reset timer if selectedPeriod changes
        return () => {
            clearTimeout(timer);
            clearInterval(timer);
        };
    }, [default_shop, selectedPeriod]); // React to changes in default_shop or selectedPeriod


    const HAS_ONLINE_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ONLINE]]) : false
    const HAS_ORDERS_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ORDERS]]) : false
    const HAS_ADD_TO_CART_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ADD_TO_CART]]) : false
    const HAS_ITEM_CREATE_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ITEM_CREATE]]) : false
    const HAS_ITEM_SALE_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ITEM_SALE]]) : false
    const HAS_COUPON_CODES_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_COUPON_CODES]]) : false
    const HAS_ITEM_TOTAL_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ITEM_TOTAL]]) : false


    return (
        <div className='statistics-main'>
            <div className='statistics-main__heading'>
                <h3 className='statistics-main__title'>{current_location.location.name}</h3>
                <div className='statistics-main__periods'>
                    {STATISTICS_PERIODS.map((item, i) => (
                        <button className={`statistics-main__period ${selectedPeriod === item ? 'statistics-main__period_selected' : ''}`} key={`statistics-main__period_${i}`} onClick={() => {
                            handleSelectPeriod(item)
                        }}>
                            {item.title}
                        </button>
                    ))}

                </div>
            </div>
            {
                !preloaders.statistics && statistics ?
                    <>
                        {HAS_ORDERS_PERMISSION && statistics.orders?
                            <StatisticsSells
                                period_type={selectedPeriod.period_type}
                                statistics={statistics.orders}
                                key={`${selectedPeriod.period_type}-StatisticsSells`}
                            />
                            : null
                        }

                        <div className='statistics-main__users-and-promo' key={`${selectedPeriod.period_type}-statistics-main__users-and-promo`}>
                            {HAS_ONLINE_PERMISSION && online && statistics.online ?
                                <div className='statistics-main__graph'>
                                    <p className='statistics-main__graph-title'>Посетители</p>
                                    <GraphContainer
                                        graphKeyName={'users'}
                                        // title={'Оформлено'}
                                        statistics_info={[
                                            {
                                                title: `${online.current} сейчас онлайн`,
                                                change: `${PercentageChange({
                                                    current: online.current,
                                                    previous: online.previous,
                                                })}`,
                                            },
                                            {
                                                title: `${statistics.online.data.current} всего`,
                                                change: `${PercentageChange({
                                                    current: statistics.online.data.current,
                                                    previous: statistics.online.data.previous,
                                                })}`,
                                            },
                                        ]}
                                        graph_data={statistics.online.graph.map((item) => {
                                            return {
                                                ...item,
                                                name: item.utc_datetime,
                                            }
                                        })}
                                        period_type={selectedPeriod.period_type}
                                    />
                                </div>
                                : null
                            }
                            {HAS_COUPON_CODES_PERMISSION && statistics.coupon_codes ?
                                <div className='statistics-main__graph'>
                                    <p className='statistics-main__graph-title'>Промо-коды</p>
                                    <div className='statistics-main__graph-two-columns'>
                                        <div className='statistics-main__graph-info'>
                                            {[
                                                {
                                                    title: `${statistics.coupon_codes.data.count.current} ${getOrderWordForm(statistics.coupon_codes.data.count.current)}`,
                                                    change: `${PercentageChange({
                                                        current: statistics.coupon_codes.data.count.current,
                                                        previous: statistics.coupon_codes.data.count.previous,
                                                    })}`,
                                                },
                                                {
                                                    title: `скидка ${parsePrice(statistics.coupon_codes.data.sell_amount.current, 'RUB')}`,
                                                    change: `${PercentageChange({
                                                        current: statistics.coupon_codes.data.sell_amount.current,
                                                        previous: statistics.coupon_codes.data.sell_amount.previous,
                                                    })}`,
                                                },
                                                {
                                                    title: `+ ${parsePrice(statistics.coupon_codes.data.sale_amount.current, 'RUB')}`,
                                                    change: `${PercentageChange({
                                                        current: statistics.coupon_codes.data.sale_amount.current,
                                                        previous: statistics.coupon_codes.data.sale_amount.previous,
                                                    })}`,
                                                },

                                            ].map((item, i) => (
                                                <StatisticsInfo
                                                    item={item}
                                                    key={`statistics-main__graph-info-promo-${i}`}
                                                />
                                            ))}
                                        </div>
                                        <div className='statistics-main__graph-promos'>
                                            {statistics.coupon_codes.top && statistics.coupon_codes.top.length > 0 ?
                                                statistics.coupon_codes.top.map((item, i) => (
                                                    <div className='statistics-main__graph-promocode' key={`statistics-main__graph-promos-promocode-${i}`}>
                                                        <p className='statistics-main__graph-promocode-text'>{item.code}</p>
                                                        <p className='statistics-main__graph-promocode-count'>{Number(item.count).toLocaleString('us')}</p>
                                                    </div>
                                                ))
                                                :
                                                <p className='statistics-main__graph-no-promo'>В&nbsp;течение указанного периода не&nbsp;зафиксировано активаций промокодов</p>
                                            }
                                            <Link className='statistics-main__graph-promocode-all-btn' to={`${PLATFORM_MAIN_LINK}/${PROMOCODES_MAIN_LINK}`}>
                                                Показать все
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    </>
                    :

                    <PreloaderBox />
            }

        </div>
    );
}

export default StatisticsMain