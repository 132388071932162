import { useContext, useEffect, useState } from "react";
import PageHeading from "../../PageHeading/PageHeading";
import "./OrdersList.css";
import { useLocation } from "react-router-dom";
import Table from "../../Table/Table";
import {
  getCurrentPlatfromLocation,
  parseApiError,
  parseRequestNumber,
  parseStatus,
} from "../../../../assets/utils/utils";
import moment from "moment-timezone";
import {
  CONTROLS_TYPE,
  EMAIL_TYPE,
  FULL_NAME_TYPE,
  ORDERS_ITEM_LINK,
  ORDERS_LIMIT,
  ORDERS_MAIN_LINK,
  PHONE_TYPE,
  PLATFORM_MAIN_LINK,
  PRICE_TYPE,
  TEXT_TYPE,
  TITLE_TYPE,
} from "../../../../assets/utils/constants";
import { UserContext } from "../../../../assets/contexts/userContext";
import mainApi from "../../../../assets/api/MainApi";
import MiniPreloader from "../../../MiniPreloader/MiniPreloader";
import useAutoDismissError from "../../../../assets/hooks/useAutoDismissError";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { PlatformLinksContext } from "../../../../assets/contexts/platformLinksContext";

function OrdersList() {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { links, default_link } = useContext(PlatformLinksContext)
  const current_location = getCurrentPlatfromLocation({
    location: location,
    links,
  });
  const [orders, setOrders] = useState(undefined);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);
  const [isFeedPreloader, setIsFeedPreloader] = useState(false);
  const [isMoreBtnVisible, setIsMoreBtnVisible] = useState(false);
  const [error, showError] = useAutoDismissError();
  const [actions, setActions] = useState([
    {
      name: "Создать новую группу",
      path: `${location.pathname}/create-group`,
    },
    // {
    //     name: 'OnClick',
    //     onClick: () => { console.log('fddsfeee') },
    //     inactive: true,
    // },
  ]);

  useEffect(() => {
    if (!user) return;

    setIsPreloaderVisible(true);
    getOrders({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function getOrders({ last_id }) {
    const shop_id = user.default_shop._id;
    mainApi
      .getOrders({ shop_id, limit: ORDERS_LIMIT, last_id })
      .then((res) => {
        const data = res.data;
        //   {
        //     _id: "asd121",
        //     request_number: "32332",
        //     status: "done",
        //     first_name: "aboba",
        //     last_name: "kristina",
        //     phone: "79112962424",
        //     email: "kris@cloud.com",
        //     different_items: "100",
        //     total_price: "100000",
        //   },
        setOrders(last_id ? (prevArr) => prevArr.concat(data) : data);
        setIsMoreBtnVisible(res.is_more);
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setOrders([]);
          setIsMoreBtnVisible(false)
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
        setIsFeedPreloader(false);
      });
  }

  function getMore() {
    const last_id = orders[orders.length - 1]._id;
    setIsFeedPreloader(true);
    getOrders({ last_id });
  }

  return (
    <div className="orders-list">
      <PageHeading
        className={"orders-list__heading"}
        title={current_location.location.name}
      // goBack={{
      //     onClick: () => { console.log('fdffd') },
      // }}
      // actions={actions}
      />
      <ErrorMessage error={error} />
      <div className="orders-list__table">
        {!isPreloaderVisible ? (
          <>
            <Table
              headingList={[
                {
                  name: "Номер заказ",
                  key: "request_number",
                  type: TITLE_TYPE,
                },
                {
                  name: "Статус",
                  key: "status",
                  type: TEXT_TYPE,
                },
                {
                  name: "Клиент",
                  key: "full_name",
                  type: FULL_NAME_TYPE,
                },
                {
                  name: "Телефон",
                  key: "phone",
                  type: PHONE_TYPE,
                },
                {
                  name: "Почта",
                  key: "email",
                  type: EMAIL_TYPE,
                },
                {
                  name: "Товаров",
                  key: "different_items",
                  type: TEXT_TYPE,
                },

                {
                  name: "Сумма",
                  key: "total_price",
                  type: PRICE_TYPE,
                },

                {
                  name: "Управление",
                  key: "controls",
                  type: CONTROLS_TYPE,
                },
              ]}
              nothingText={`Вы еще не получали заказов`}
              list={
                orders?.length > 0
                  ? orders.map((item, i) => {
                    return {
                      ...item,
                      request_number: parseRequestNumber(item.ai_id),
                      status: parseStatus(item.status),
                      full_name: {
                        first_name: item.first_name,
                        last_name: item.last_name,
                      },
                      controls: [
                        {
                          name: "Подробнее",
                          path: `${PLATFORM_MAIN_LINK}/${ORDERS_MAIN_LINK}/${ORDERS_ITEM_LINK}/${item._id}`,
                        },
                      ],
                    };
                  })
                  : []
              }
            />

            {isMoreBtnVisible ? (
              <button
                className={`orders-list__more-btn ${isFeedPreloader ? "orders-list__more-btn_disabled" : ""
                  }`}
                onClick={getMore}
                type="button"
              >
                <p
                  className={`orders-list__more-btn-text ${!isFeedPreloader ? "orders-list__more-btn-text_visible" : ""
                    }`}
                >
                  Загрузить ещё
                </p>
                <span
                  className={`orders-list__btn-preloader ${isFeedPreloader ? "orders-list__btn-preloader_visible" : ""
                    }`}
                >
                  <MiniPreloader />
                </span>
              </button>
            ) : null}
          </>
        ) : (
          <div className="orders-list__preloader">
            <MiniPreloader />
          </div>
        )}
      </div>
    </div>
  );
}

export default OrdersList;
