import { useForm } from '../../hooks/useForm';
import { fetchItem, createItem, editItem } from '../../services/socailsFormApi';
import { PLATFORM_MAIN_LINK, SETTINGS_MAIN_LINK, SETTINGS_PAGES_AND_FILES_LINK, SETTINGS_PAGES_AND_FILES_SOCIALS } from '../../../../../../../../assets/utils/constants';
import Form from './Form/Form';
import { DEFAULT_SOCIAL_VALUES, DEFAULT_SOCIAL_VALUES_MAP } from '../../../../../../../../assets/utils/socials_constants';
import { deepEqual, isValidUrl } from '../../../../../../../../assets/utils/utils';

const initialForm = {
    type: DEFAULT_SOCIAL_VALUES[0],
    url: '',
    is_visible: true,
};

function SocialForm({ mode }) {
    const { form, preloaders, error, handleChange, handleSubmit } = useForm({
        mode,
        initialForm,
        fetchItem: fetchItem,
        createItem: createItem,
        editItem: editItem, 
        itemResponseFormater: (res) => ({
            ...res,
            type: DEFAULT_SOCIAL_VALUES_MAP[res.type],
        }),
        returnToListURL: `${PLATFORM_MAIN_LINK}/${SETTINGS_MAIN_LINK}/${SETTINGS_PAGES_AND_FILES_LINK}/${SETTINGS_PAGES_AND_FILES_SOCIALS}`,
        requestFormFormater: (form) =>({
            ...form,
            type: form.type.type,
        }),
        editFormValidity: (form, initForm) => (!(form.type && form.url && isValidUrl(form.url) && !deepEqual(initForm, form))),
        createFormValidity: (form) => (!(form.type && form.url && isValidUrl(form.url))),
    });

    const formFields = [
        {
            name: 'type', label: 'Выберите социальную сеть', value: form.type, type: 'select',
            selectList: DEFAULT_SOCIAL_VALUES,
            selectDifferenceKey: 'type',
            selectTextValueKey: 'title',
        },
        { name: 'url', label: 'Ссылка', value: form.url, type: 'text' },
        {
            name: 'is_visible', label: 'Видимость на сайте', value: form.is_visible, type: 'boolean',
            boolean_values: {
                true: {
                    title: 'Виден',
                },
                false: {
                    title: 'Скрыт',
                }
            }
        },
    ];

    return (
        <Form
            error={error}
            formFields={formFields}
            handleChange={handleChange}
            loading={preloaders.main}
        />
    );
}

export default SocialForm;