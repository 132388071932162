import { Link } from 'react-router-dom';
import './Button.css';
import MiniPreloader from '../MiniPreloader/MiniPreloader';

function Button({
    isContrastColor,
    text,
    onClick,
    path,
    isInvalid,
    className,
    isLoading,
}) {
    const buttonClassName = `button ${isContrastColor ? 'button_type_contrast-color' : ''} ${className ? className : ''} ${isInvalid ? 'button_type_invalid' : ''} ${isLoading ? 'button_type_loading' : ''}`

    return (
        <>
            {path ?
                <Link className={buttonClassName} to={path}>
                    {isLoading ? <MiniPreloader isLinkColor={true} /> : text}
                </Link>
                :
                <button className={buttonClassName} type='button' onClick={onClick}>
                    {isLoading ? <MiniPreloader isLinkColor={true} /> : text}
                </button>
            }
        </>

    );
}

export default Button