import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PlatformLinksContext } from "../../../../assets/contexts/platformLinksContext";
import ShopPreloader from "../../ShopPreloader/ShopPreloader";
import { UserContext } from "../../../../assets/contexts/userContext";
import mainApi from "../../../../assets/api/MainApi";
import useAutoDismissError from "../../../../assets/hooks/useAutoDismissError";
import { parseApiError } from "../../../../assets/utils/utils";


function ShopChange() {
    const { user, setUser } = useContext(UserContext)
    const { default_shop } = user

    const { default_link } = useContext(PlatformLinksContext)
    const [searchParams, setSearchParams] = useSearchParams();
    const [preloaders, setPreloaders] = useState({
        set_shop: false,
    })
    const navigate = useNavigate()
    useEffect(() => {
        let shop_id = searchParams.get("shop_id")
        if (!shop_id) {
            navigate(`${default_link}`)
            return
        } else {
            console.log(shop_id)
            handleSetDefaultShop({ shop_id })
        }
    }, [])

    const [error, showError] = useAutoDismissError()

    function handleSetDefaultShop({ shop_id }) {
        if (shop_id === default_shop._id) {
            navigate(`${default_link}`)
            return
        }
        setPreloaders(prevValue => ({
            ...prevValue,
            set_shop: true,
        }))
        mainApi.setDefaultShop({ _id: shop_id })
            .then((res) => {
                mainApi
                    .getMe()
                    .then((res) => {
                        console.log(res);
                        setUser(res);


                    })
                    .catch((err) => {
                        showError(parseApiError(err))
                        console.log(err);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            navigate(`${default_link}`)
                            window.scrollTo(0, 0)
                            setPreloaders(prevValue => ({
                                ...prevValue,
                                set_shop: false,
                            }))
                        }, 2000);

                    })
                console.log(res)
            })
            .catch((err) => {

                showError(parseApiError(err))
                console.log(err)
                setTimeout(() => {
                    navigate(`${default_link}`)
                    window.scrollTo(0, 0)
                    setPreloaders(prevValue => ({
                        ...prevValue,
                        set_shop: false,
                    }))
                }, 2000);
            })

    }
    return (
        <>
            <ShopPreloader error={error} isOpen={preloaders.set_shop} />
        </>
    );
}

export default ShopChange