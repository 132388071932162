import { useContext, useEffect, useRef, useState } from 'react';
import './PersonalInfo.css';
import mainApi from '../../../../../assets/api/MainApi';
import { useNavigate } from 'react-router-dom';
import { AUTH_LOGIN_LINK, PASSWORD_MIN_LENGTH } from '../../../../../assets/utils/constants';
import { UserContext } from '../../../../../assets/contexts/userContext';
import moment from 'moment-timezone';
import validator from 'validator';

function PersonalInfo({
    handleChange,
    form,
    handleResetForm,
}) {
    const { user, setUser } = useContext(UserContext)
    const passInputRef = useRef(null)
    const emailInputRef = useRef(null)
    const codeInputRef = useRef(null)
    const [emailChangeStep, setEmailChangeStep] = useState(0)

    const handleClickOutsidePass = () => {
        passInputRef.current.focus();
    };
    const handleClickOutsideEmail = () => {
        emailInputRef.current.focus();
    };

    const handleClickOutsideСode = () => {
        codeInputRef.current.focus();
    };
    const [now, setNow] = useState(moment())
    useEffect(() => {
        setTimeout(() => {
            setNow(moment())
        }, 10000);
    }, [now])

    const [isPasswordReset, setPasswordReset] = useState(now.diff(moment(user.new_password.generated_utc + '+00:00'), 'minutes') < 2)
    function handleResetPassword() {
        setPasswordReset(true)
        mainApi.resetPasswordWithAuth()
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const navigate = useNavigate()
    function handleLogout() {
        mainApi.logout()
            .then((res) => {
                console.log(res)
                setUser(undefined)
                navigate(`${AUTH_LOGIN_LINK}`)
                localStorage.removeItem("categories")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const [isPassError, setPassError] = useState(true)
    function handleCheckPassword() {
        mainApi.checkCurrentPassword({
            current_password: form.cur_pas,
        })
            .then((res) => {
                console.log(res)
                setEmailChangeStep(2)
                setTimeout(() => {
                    handleClickOutsideEmail()
                }, 100);
            })
            .catch((err) => {
                console.log(err)
                setPassError(true)
                setTimeout(() => {
                    setPassError(false)
                }, 3000);
            })

    }

    function handleChangeEmailSave() {
        mainApi.authChangeEmail({
            email: form.new_email,
        })
            .then((res) => {
                console.log(res)
                setEmailChangeStep(3)
                setTimeout(() => {
                    handleClickOutsideСode()
                }, 100);
            })
            .catch((err) => {
                console.log(err)
                setPassError(true)
                setTimeout(() => {
                    setPassError(false)
                }, 3000);
            })

    }

    useEffect(() => {
        setPassError(false)
    }, [form.cur_pas])

    const [codeError, setCodeError] = useState(false)
    function handleCheckCode() {
        mainApi.authChangeEmailСheckCode({
            code: form.code,
        })
            .then((res) => {
                console.log(res)
                handleResetForm({
                    email: form.new_email,
                })
                setUser(prevValue => ({
                    ...prevValue,
                    email: form.new_email,
                    previous_email: form.new_email,
                }))
                setTimeout(() => {
                    setEmailChangeStep(0)
                }, 100);

            })
            .catch((err) => {
                console.log(err)
                setCodeError(true)
                setTimeout(() => {
                    setCodeError(false)
                }, 3000);
            })

    }
    return (
        <div className='personal-info'>
            <div className='personal-info__input-container'>
                <div className='personal-info__input-heading'>
                    <p className={`personal-info__input-label ${emailChangeStep === 1 || emailChangeStep === 2 ? 'personal-info__input-label__blue' : ''} ${isPassError || codeError ? 'personal-info__input-label__error' : ''}`}>{emailChangeStep === 0 ? 'Адрес электронной почты' : emailChangeStep === 1 ? isPassError ? 'Неверный пароль, попробуйте еще раз' : 'Введите пароль для изменение адреса электронной почты' : emailChangeStep === 2 ? 'Введите новый адрес электронной почты' : codeError ? `Неверный код, попробуйте еще раз` : `Введите код отправленный на ${form.new_email}`}</p>
                </div>
                {emailChangeStep === 0 ?
                    <div className='personal-info__input-box'>
                        <p className='personal-info__input-prev-email-value'>{form.previous_email}</p>
                        <button className='personal-info__input-btn' type='button' onClick={() => {
                            setEmailChangeStep(1)
                            setTimeout(() => {
                                handleClickOutsidePass()
                            }, 100);
                        }}>
                            Изменить...
                        </button>
                    </div>
                    :
                    null
                }
                {emailChangeStep === 1 ?
                    <div className='personal-info__input-box personal-info__input-box_left-btn' onClick={handleClickOutsidePass}>
                        <input
                            ref={passInputRef}
                            className={"personal-info__input"}
                            type={"password"}

                            value={form.cur_pas || ""}
                            autoComplete="off"
                            name={'cur_pas'}
                            onChange={handleChange}
                        // placeholder={placeholder}
                        // onFocus={handleFocused}
                        // onBlur={handleUnfocused}
                        // readOnly={readOnly}
                        // onKeyDown={(e) => {
                        //     if (onKeyDown) {
                        //         onKeyDown(e);
                        //     }
                        // }}
                        // maxLength={maxLength ? maxLength : 1000}
                        />
                        <button className={`personal-info__input-btn ${!(form.cur_pas && form.cur_pas.length >= PASSWORD_MIN_LENGTH) ? 'personal-info__input-btn_inactive' : ''}`} type='button' onClick={() => {
                            handleCheckPassword()

                        }}>
                            ОК
                        </button>
                    </div>
                    :
                    null
                }

                {emailChangeStep === 2 ?
                    <div className='personal-info__input-box personal-info__input-box_left-btn' onClick={handleClickOutsideEmail}>
                        <input
                            ref={emailInputRef}
                            className={"personal-info__input"}
                            type={"text"}
                            inputMode='email'
                            value={form.new_email || ""}
                            autoComplete="off"
                            name={'new_email'}
                            onChange={handleChange}
                        // placeholder={placeholder}
                        // onFocus={handleFocused}
                        // onBlur={handleUnfocused}
                        // readOnly={readOnly}
                        // onKeyDown={(e) => {
                        //     if (onKeyDown) {
                        //         onKeyDown(e);
                        //     }
                        // }}
                        // maxLength={maxLength ? maxLength : 1000}
                        />
                        <button className={`personal-info__input-btn ${!(form.new_email && form.new_email.length >= 5 && validator.isEmail(form.new_email)) ? 'personal-info__input-btn_inactive' : ''}`} type='button' onClick={() => {
                            // setEmailChangeStep(0)
                            handleChangeEmailSave()
                        }}>
                            Сохранить
                        </button>
                    </div>
                    :
                    null
                }

                {emailChangeStep === 3 ?
                    <div className='personal-info__input-box personal-info__input-box_left-btn' onClick={handleClickOutsideСode}>
                        <input
                            ref={codeInputRef}
                            className={"personal-info__input"}
                            type={"text"}
                            inputMode='text'
                            value={form.code || ""}
                            autoComplete="off"
                            name={'code'}
                            onChange={handleChange}
                        // placeholder={placeholder}
                        // onFocus={handleFocused}
                        // onBlur={handleUnfocused}
                        // readOnly={readOnly}
                        // onKeyDown={(e) => {
                        //     if (onKeyDown) {
                        //         onKeyDown(e);
                        //     }
                        // }}
                        // maxLength={maxLength ? maxLength : 1000}
                        />
                        <button className={`personal-info__input-btn ${!(form.new_email && form.new_email.length >= 5 && validator.isEmail(form.new_email)) ? 'personal-info__input-btn_inactive' : ''}`} type='button' onClick={() => {
                            // setEmailChangeStep(0)
                            handleCheckCode()
                        }}>
                            Сохранить
                        </button>
                    </div>
                    :
                    null
                }

            </div>
            <div className='personal-info__input-container'>
                <div className='personal-info__input-heading'>
                    <p className={`personal-info__input-label`}>Пароль</p>
                </div>
                <button className='personal-info__btn' type='button' onClick={() => {
                    handleResetPassword()
                }}>
                    {isPasswordReset ? 'Мы отправили вам письмо с инструкцией' : 'Сбросить мой пароль'}
                </button>

            </div>
            <button className='personal-info__btn personal-info__btn_logout' type='button' onClick={() => {
                handleLogout()
            }}>
                Выйти из учетной записи
            </button>
        </div>
    );
}

export default PersonalInfo