export const handleToDelete = (item, setPopups, setItemToDelete) => {
    setItemToDelete(item);
    setPopups(prev => ({ ...prev, delete: true }));
};

export const updateList = (docs, index, direction) => {
    const newDocs = [...docs];
    const swapIndex = direction === 'up' ? index - 1 : index + 1;
    [newDocs[index], newDocs[swapIndex]] = [newDocs[swapIndex], newDocs[index]];
    return newDocs;
};