import './StatisticsInfo.css';

function StatisticsInfo({
    item,
}) {

    const { title, change } = item
    return (

        <div className={`statistics-info ${change && Number(change) < 0 ? 'statistics-info_type_negative' : ''}`} >
            <p className='statistics-info__title'>{title}</p>
            <span className='statistics-info__change'>
                {Number(change) > 0 ? '↑' : Number(change) === 0 ? '' : '↓'} {Math.abs(Number(change)).toLocaleString('us')}%
            </span>
        </div>
    );
}

export default StatisticsInfo