import { Navigate, Route, Routes } from 'react-router-dom';
import './Account.css';
import AccountMain from './AccountMain/AccountMain';
import { useContext } from 'react';
import { UserContext } from '../../../assets/contexts/userContext';
import { filterLinksForUserRBAC, getDefaultLinkForUserRBAC } from '../../../assets/utils/utils';
import { ACCOUNT_INFO_LINK, PLATFORM_ACCOUNT_LINKS, PLATFORM_MAIN_LINK } from '../../../assets/utils/constants';

function Account() {

    const { user } = useContext(UserContext)
    const LINKS_BY_USER_RBAC = user && user.default_shop && user.default_shop.role ? filterLinksForUserRBAC(user.default_shop.role.permissions, [PLATFORM_ACCOUNT_LINKS]) : null
    const DEFAULT_LINK_BY_USER_RBAC = PLATFORM_MAIN_LINK + getDefaultLinkForUserRBAC(LINKS_BY_USER_RBAC)



    return (
        <div className='account'>
            <Routes>
                <Route index element={
                    <Navigate to={DEFAULT_LINK_BY_USER_RBAC} />
                }>
                </Route>
                <Route path={`${ACCOUNT_INFO_LINK}/*`} element={
                    <AccountMain />
                }>
                </Route>
            </Routes>
        </div>
    );
}

export default Account