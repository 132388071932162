import MiniPreloader from "../../../../MiniPreloader/MiniPreloader";
import EditFilter from "../EditFilter/EditFilter";
import Toggle from "../../../../Toggle/Toggle";
import "./FilterControls.css";

function FilterControls({
    handleEditClick,
    editActive,
    isToggleActive,
    handleToggleClick,
    is_special,
    handleCancelEdit,
    save_inavtive,
    handleSaveEdit,
    save_preloader_active,
}) {


    return (
        <div className="filter-controls">
            {editActive ?
                <>
                    <button className="filter-controls__btn" type="button" onClick={handleCancelEdit}>
                        Отменить
                    </button>
                    <button className={`filter-controls__btn filter-controls__btn_type_main ${save_inavtive ? 'filter-controls__btn_inactive' : ''}`} type="button" onClick={handleSaveEdit}>
                        {save_preloader_active ? <MiniPreloader isLinkColor={true} /> : `Сохранить`}
                    </button>
                </>
                :
                <>
                    {!is_special ?
                        <EditFilter
                            handleClick={handleEditClick}
                            isActive={editActive}
                        />
                        : <div></div>}
                    <Toggle
                        isActive={isToggleActive}
                        handleClick={handleToggleClick}
                    />
                </>}
        </div>
    );
}

export default FilterControls;
