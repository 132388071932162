import {
  DEFAULT_MAX_IMG_SIZE,
  PHOTO_MAX_LENGTH,
} from "../../../assets/utils/constants";
import CategorySelectInput from "../CategorySelectInput/CategorySelectInput";
import ImagesInput from "../ImagesInput/ImagesInput";
import PlatformInput from "../PlatformInput/PlatformInput";
import "./ProductForm.css";
import ProductFormOptions from "./ProductFormOptions/ProductFormOptions";
import ProductFormParameters from "./ProductFormParameters/ProductFormParameters";

function ProductForm({
  isTwoColumnsOnPC,
  product,
  handleChange,
  productValidity,
  isImgPreloader,
  isPublished,
  isViewOnly,
}) {
  const is_main_amount =
    product.options.length > 1 || product.options.length === 0;

  return (
    <>
      {product ? (
        <div
          className={`product-form ${isTwoColumnsOnPC ? "product-form_type_two-columns-pc" : ""
            }`}
        >
          <div className={`product-form__container`}>
            <PlatformInput
              value={product.unique_sku}
              placeholder={""}
              label={"Уникальный ID*"}
              hint={"Пример: 12345XYZ или 1234"}
              handleChange={handleChange}
              name={"unique_sku"}
              error={
                productValidity?.unique_sku?.errorMessage
                  ? productValidity.unique_sku.errorMessage
                  : ""
              }
              readOnly={isViewOnly}
            />
            <PlatformInput
              value={product.title}
              placeholder={""}
              label={"Название*"}
              hint={`${product.title.length}/100`}
              handleChange={handleChange}
              name={"title"}
              maxLength={100}
              error={
                productValidity?.title?.errorMessage
                  ? productValidity.title.errorMessage
                  : ""
              }
              readOnly={isViewOnly}
            />
            <PlatformInput
              value={product.description}
              placeholder={""}
              label={"Описание"}
              hint={`${product.description.length}/1000`}
              // hint={'Видно только вам'}
              maxLength={1000}
              handleChange={handleChange}
              name={"description"}
              isTextArea={true}
              maxRows={5}
              minRows={1}
              readOnly={isViewOnly}
            />
            <ImagesInput
              name={"photo"}
              label={`Фото`}
              hint={`${PHOTO_MAX_LENGTH} фото не более ${DEFAULT_MAX_IMG_SIZE} Мб каждое`}
              addBtnText={"Добавить..."}
              isMultiple={true}
              images={product.photo}
              readOnly={isViewOnly}
              onRemove={(e) => {
                handleChange({
                  target: {
                    value: e.target.value,
                    name: "photo-delete",
                    name_key: "photo",
                  },
                });
              }}
              onAdd={handleChange}
              isPreloaderVisible={isImgPreloader}
              error={
                productValidity?.photo?.errorMessage
                  ? productValidity.photo.errorMessage
                  : ""
              }
            />
            <CategorySelectInput
              value={product.categories}
              label={`Категории${isPublished ? '*' : ''}`}
              handleChange={handleChange}
              name={"categories"}
              inputError={
                productValidity?.categories?.errorMessage
                  ? productValidity.categories.errorMessage
                  : ""
              }
              readOnly={isViewOnly}
            />
            <div
              className={`product-form__price-block ${is_main_amount
                ? "product-form__price-block_with-main-amount"
                : ""
                }`}
            >
              {is_main_amount ?
                (<PlatformInput
                  value={product.price}
                  placeholder={""}
                  label={"Цена*"}
                  readOnly={isViewOnly}

                  // hint={'Видно только вам'}
                  handleChange={handleChange}
                  name={"price"}
                  maxLength={10}
                  error={
                    productValidity?.price?.errorMessage
                      ? productValidity.price.errorMessage
                      : ""
                  }
                />)
                : null}
              < PlatformInput
                value={product.discount}
                placeholder={""}
                label={"Скидка"}
                readOnly={isViewOnly}

                // hint={'Видно только вам'}
                handleChange={handleChange}
                name={"discount"}
                maxLength={2}
                show_eror_color_only={true}
                error={
                  productValidity?.discount?.errorMessage
                    ? productValidity.discount.errorMessage
                    : ""
                }
              />
              {is_main_amount ? (
                <PlatformInput
                  value={product.amount}
                  placeholder={""}
                  readOnly={isViewOnly}

                  label={`Наличие${isPublished ? '*' : ''}`}
                  // hint={'Видно только вам'}
                  handleChange={handleChange}
                  name={"amount"}
                  maxLength={10}
                  show_eror_color_only={true}
                  error={
                    productValidity?.amount?.errorMessage
                      ? productValidity.amount.errorMessage
                      : ""
                  }
                />
              ) : null}
            </div>
            <PlatformInput
              value={product.visibility}
              placeholder={""}
              readOnly={isViewOnly}

              label={"Видимость на сайте"}
              // hint={'Видно только вам'}
              handleChange={handleChange}
              name={"visibility"}
              boolean_values={{
                true: {
                  title: "Включить",
                },
                false: {
                  title: "Выключить",
                },
              }}
              isBooleanSelector={true}
            />
          </div>

          <div className={`product-form__container`}>
            <ProductFormOptions
              product={product}
              readOnly={isViewOnly}
              is_main_amount={is_main_amount}
              handleChange={handleChange}
              error={productValidity?.options}
              isPublished={isPublished}
            />
            <ProductFormParameters
              product={product}
              readOnly={isViewOnly}
              handleChange={handleChange}
              error={productValidity?.parameters}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ProductForm;
