import mainApi from "../../../../../../../assets/api/MainApi";

export const fetchItem = (shopId, id) => {
    return mainApi.getSocial({
        shop_id: shopId,
        _id: id,
    });
};

export const createItem = (shopId, formData) => {
    return mainApi.createSocial({
        shop_id: shopId,
        ...formData,
    });
};

export const editItem = (shopId, formData) => {
    return mainApi.editSocial({
        shop_id: shopId,
        ...formData,
    });
};