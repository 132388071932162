import { useEffect, useState } from "react";
import { MuiColorInput } from "mui-color-input";
import { styled } from "styled-components";
import "./ColorInput.css";

const MuiColorInputStyled = styled(MuiColorInput)`
  .MuiInputBase-root {
    height: 45px;
    font-family: "Golos Text";
    font-size: 13px;
    line-height: 100%;
    letter-spacing: -0.9px;
    border: 1px solid var(--input-color-bg-color);
    overflow: hidden;
  }
`;

function ColorInput({ currentColor, className, handleChange }) {

  const onChange = (color) => {
    handleChange(color);
  };

  return (
    <div
      className={`color-input ${className ? className : ""}`}
      style={{ "--constructor-selected-color": currentColor }}
    >
      <MuiColorInputStyled
        value={currentColor}
        format="hex"
        isAlphaHidden
        fullWidth
        onChange={onChange}
      />
    </div>
  );
}

export default ColorInput;
