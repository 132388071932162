import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import "./ImagesInput.css";

function ImagesInput({
  name,
  label,
  hint,
  error,
  addBtnText,
  changeBtnText = 'Изменить...',
  images,
  onRemove,
  onAdd,
  isMultiple = false,
  isHorizontal = false,
  labelClassName,
  isPreloaderVisible,
  additionalKey = '',
  readOnly,
}) {
  return (
    <div className="images-input">
      {label || hint ? (
        <div className="images-input__heading">
          {label ? (
            <span
              className={`images-input__label ${labelClassName ? labelClassName : ""
                }`}
            >
              {label}
            </span>
          ) : null}
          {error ? (
            <span
              className="images-input__error"
              dangerouslySetInnerHTML={{ __html: error }}
            />
          ) : hint && !readOnly ? (
            <span
              className="images-input__hint"
              dangerouslySetInnerHTML={{ __html: hint }}
            />
          ) : null}
        </div>
      ) : null}

      <div className="images-input__input-box">
        {images?.length > 0
          ? images.map((item, i) => (
            <div
              key={`${i}`}
              className={`images-input__img-box ${isHorizontal ? "images-input__img-box_horizontal" : ""
                }`}
            >
              <button
                className="images-input__remove-btn"
                type="button"
                aria-label="Удалить фотографию"
                onClick={() => {
                  if (readOnly) return
                  onRemove({
                    target: {
                      value: i,
                    },
                  })
                }}
              >
                ×
              </button>
              <img
                className="images-input__img"
                src={item.urls?.orig}
                alt=""
              />
            </div>
          ))
          :
          readOnly ?
            <label className="images-input__read-only-placeholder">-</label>
            :
            null
        }

        {!readOnly ?
          <label
            className={`images-input__add-btn ${isPreloaderVisible ? "images-input__add-btn_disabled" : ""
              }`}
            htmlFor={name + additionalKey}
          >
            <input
              className="images-input__input"
              id={name + additionalKey}
              name={name}
              type="file"
              accept="image/jpg, image/jpeg, image/webp, image/png, image/heic"
              multiple={isMultiple}
              onChange={onAdd}
            />
            <span
              className={`images-input__add-btn-text ${!isPreloaderVisible ? "images-input__add-btn-text_visible" : ""
                }`}
            >
              {isMultiple ? addBtnText : images && images.length > 0 ? changeBtnText : addBtnText}
            </span>
            <span
              className={`images-input__preloader ${isPreloaderVisible ? "images-input__preloader_visible" : ""
                }`}
            >
              <MiniPreloader />
            </span>
          </label> : null}
      </div>
    </div>
  );
}

export default ImagesInput;
