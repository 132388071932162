import { useNavigate } from "react-router-dom";
import PageHeading from "../../../PageHeading/PageHeading";
import { useContext, useState } from "react";
import ItemForm from "../../../ItemForm/ItemForm";
import {
  CATALOG_DRAFTS_LINK,
  CATALOG_MAIN_LINK,
  PLATFORM_MAIN_LINK,
  PRODUCT_FORM_TYPE_NUMBER,
  PRODUCT_FORM_TYPE_TEXT,
} from "../../../../../assets/utils/constants";
import "./DraftsCreate.css";
import { UserContext } from "../../../../../assets/contexts/userContext";
import mainApi from "../../../../../assets/api/MainApi";
import { parseApiError } from "../../../../../assets/utils/utils";
import DraftsProductForm from "../DraftsMain/DraftsProductForm/DraftsProductForm";
import useAutoDismissError from "../../../../../assets/hooks/useAutoDismissError";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";

const initialValues = {
  title: "",
  description: "",
  price: "",
  amount: "",
  discount: "",
  article: "",
  options: [],
  unique_sku: "",
  parameters: [],
  visibility: true,
  photo: [],
  categories: [],
  promo_groups: [],
};

const initialValuesValidity = {
  unique_sku: {
    errorMessage: "",
    validState: false,
  },
  title: {
    errorMessage: "",
    validState: false,
  },
  price: {
    errorMessage: "",
    validState: false,
  },
  discount: {
    errorMessage: "",
    validState: true,
  },
  photo: {
    errorMessage: "",
    validState: true,
  },
  options: [],
  parameters: [],
};

function DraftsCreate() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [error, showError] = useAutoDismissError();
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const actions = [
    {
      name: "Отменить все изменения",
      onClick: handleRestoreProduct,
      inactive: isPreloaderVisible,
    },
    {
      name: "Сохранить",
      onClick: handleSaveProduct,
      isMainAction: true,
      inactive:
        !(
          valuesValidity.unique_sku?.validState &&
          valuesValidity.title?.validState &&
          (values.options.length === 1 ? true : (valuesValidity.price?.validState)) &&
          valuesValidity.discount?.validState &&
          valuesValidity.photo?.validState &&
          valuesValidity.options.every(
            (item) =>
              item.title.validState &&
              item.values.every((value) =>
                values.options.length === 1
                  ? value.value.validState && value.price.validState
                  : value.value.validState
              )
          ) &&
          valuesValidity.parameters.every(
            (item) => item.title.validState && item.value.validState
          )
        ) || isPreloaderVisible,
      isPreloaderVisible: isPreloaderVisible,
    },
  ];

  function handleSaveProduct() {
    if (!user) return;

    const shop_id = user.default_shop._id;
    setIsPreloaderVisible(true);
    mainApi
      .createDraft({
        shop_id,
        files:
          values.photo.length > 0 ? values.photo.map((img) => img._id) : null,
        unique_id: values.unique_sku,
        name: values.title,
        description: values.description ? values.description : null,
        price_data: {
          price: values.options.length !== 1
            ? values.price
              ? values.price
              : null
            : null,
          discount: values.discount ? Number(values.discount) : null,
          in_stock:
            values.options.length !== 1
              ? values.amount
                ? Number(values.amount)
                : null
              : null,
        },
        categories:
          values.categories.length > 0
            ? values.categories.map((item) => item._id)
            : [],
        promo_groups:
          values.promo_groups.length > 0 ? values.promo_groups : null,
        is_visible: values.visibility,
        options:
          values.options.length > 0
            ? values.options.map((item) => {
              return {
                name: item.title,
                values: item.values.map((value) => {
                  return {
                    value: value.value,
                    price: values.options.length === 1
                      ? value.price : null,
                    in_stock:
                      values.options.length === 1 ?
                        value.amount ?
                          Number(value.amount)
                          :
                          null
                        : null,
                  };
                }),
              };
            })
            : [],
        parameters: values.parameters
          .map((item) => {
            return {
              name: item.title,
              type: item.type.type,
              value:
                item.type.type === PRODUCT_FORM_TYPE_NUMBER.type
                  ? Number(item.value)
                  : item.value,
            };
          })
          .concat(
            values.article
              ? {
                name: "Артикул",
                type: PRODUCT_FORM_TYPE_TEXT.type,
                value: values.article,
              }
              : null
          )
          .filter((item) => Boolean(item)),
      })
      .then(() => {
        navigate(
          `${PLATFORM_MAIN_LINK}/${CATALOG_MAIN_LINK}/${CATALOG_DRAFTS_LINK}`
        );
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
      });
  }

  function handleRestoreProduct() {
    setValues(initialValues);
  }

  return (
    <div className="drafts-main">
      <PageHeading
        className={"drafts-main__heading"}
        title={"Новый товар"}
        goBack={{ onClick: () => navigate(-1) }}
        actions={actions}
      />
      <ErrorMessage error={error} />
      <ItemForm containerClassName={"drafts-main__item-from-container"}>
        <DraftsProductForm
          isTwoColumnsOnPC
          product={values}
          setProduct={setValues}
          productValidity={valuesValidity}
          setProductValidity={setValuesValidity}
          {...{ showError }}
        />
      </ItemForm>
    </div>
  );
}

export default DraftsCreate;
