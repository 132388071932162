import { CrossIcon, GoBackIcon } from '../../../../assets/icons/controls/controls';
import './PopupCloseBtn.css';

const icons = {
    'close': CrossIcon,
    'go-back': GoBackIcon,
}

function PopupControlBtn({ onClick, POPUP_NAME, type }) {
    return (
        <button className='popup-control-btn' type='button' onClick={() => {
            onClick({ name: POPUP_NAME })
        }}>
            {type ? icons[type]({
                mainClassName: 'popup-control-btn__icon',
                strokeClassName: 'popup-control-btn__icon-stroke',
            }) : null}
        </button>
    );
}

export default PopupControlBtn

