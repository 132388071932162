import { useContext, useEffect, useState } from 'react';
import PlatformInput from '../../PlatformInput/PlatformInput';
import './SystemConstants.css';
import mainApi from '../../../../assets/api/MainApi';
import ItemForm from '../../ItemForm/ItemForm';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import PageHeading from '../../PageHeading/PageHeading';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../assets/contexts/userContext';
import { PlatformLinksContext } from '../../../../assets/contexts/platformLinksContext';
import { createAction, deepEqual, getCurrentPlatfromLocation, hasPermission, parseApiError, parsePermissions } from '../../../../assets/utils/utils';
import useAutoDismissError from '../../../../assets/hooks/useAutoDismissError';
import { PERMISSIONS, RBAC_CONTENT_MANAGEMENT, RBAC_SHOP_SETTINGS } from '../../../../assets/utils/permissions_rbac';
import { SETTINGS_CONSTANTS_LINK } from '../../../../assets/utils/constants';
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';
import ConstantInput from './ConstantInput/ConstantInput';


function setValuesFromApi({ CONSTANTS, current_values }) {
    // Предполагается, что CONSTANTS — это ваш конфиг
    const configObject = {}; // Объект, который мы собираем

    // Проходим по всем элементам из конфигурации
    CONSTANTS.forEach((configItem) => {
        const key = configItem.key; // Берем ключ текущего элемента
        const defaultValue = configItem.validation.default; // Берем значение по умолчанию из конфигурации
        console.log(current_values[key])
        // Если значение для этого ключа присутствует в res2, то используем его, иначе - значение по умолчанию
        if (current_values.hasOwnProperty(key)) {
       
            configObject[key] = { value: current_values[key] };
        } else {
            configObject[key] = { value: defaultValue };
        }
        configObject[key].title = configItem.title
        configObject[key].validation = configItem.validation
        configObject[key].key = key
    });

    // Здесь вы можете установить это значение в state или использовать по назначению
    console.log(configObject); // Выводим результат
    return configObject; // Предполагается, что у вас есть стейт или другая логика для работы с этим объектом
}
function SystemConstants() {

    const { user, setUser } = useContext(UserContext)
    const location = useLocation();
    const navigate = useNavigate();

    const { links, default_link } = useContext(PlatformLinksContext)

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })

    const [preloaders, setPreloaders] = useState({
        init: true,
        update: false,
    })
    const [error, showError] = useAutoDismissError()
    const [step, setStep] = useState(0)
    const [config, setConfig] = useState(null)
    const [initValues, setInitValues] = useState(null)
    const [values, setValues] = useState(null)
    useEffect(() => {
        if (!user) return;

        const shop_id = user.default_shop._id;

        setPreloaders(prevValue => ({
            ...prevValue,
            init: true,
        }));

        const tasks = [
            () => mainApi.getSystemConstantsConfig(),
            (configRes) => {
                const CONSTANTS = configRes.constants;
                console.log({ CONSTANTS });
                setConfig(configRes);

                return mainApi.getSystemConstants({ shop_id }).then((valuesRes) => ({
                    CONSTANTS,
                    valuesRes: valuesRes.data,
                }));
            }
        ];

        tasks.reduce(
            (promiseChain, currentTask) =>
                promiseChain.then(currentTask),
            Promise.resolve()
        )
            .then(({ CONSTANTS, valuesRes }) => {
                const values = setValuesFromApi({ CONSTANTS, current_values: valuesRes });
                console.log(values);
                setValues(values);
                setInitValues(values)
            })
            .catch((err) => {
                console.log(err);
                showError(parseApiError(err));
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    init: false,
                }));
            });
    }, [user])


    function handleSave() {
        setPreloaders(prevValue => ({
            ...prevValue,
            update: true,
        }))
        mainApi.setSystemConstants({
            shop_id: user.default_shop._id,
            data: Object.values(values).map((item) => ({ key: item.key, value: item.value })),
        })
            .then((res) => {
                console.log(res)
                setInitValues(values)
                setStep(1)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    update: false,
                }))
                setTimeout(() => {
                    setStep(0)
                }, 2000);
            })
    }




    const hasEditLayoutPermission = hasPermission(parsePermissions(user), [RBAC_CONTENT_MANAGEMENT[PERMISSIONS.EDIT_LAYOUT]])


    function addActionsBasedOnPermissions() {
        const actions = [];

        if (hasEditLayoutPermission && initValues && values) {
            actions.push(createAction(step === 0 ? 'Сохранить' : 'Сохранено', handleSave, {
                isMainAction: true,
                inactive: deepEqual(initValues, values),
                isPreloaderVisible: preloaders.update,
            }));
        }

        return actions;
    }

    const actions = addActionsBasedOnPermissions();

    console.log({ values })
    return (
        <div className='system-constants'>
            <PageHeading
                className={'system-constants__heading'}
                title={'Настройки системы'}
                goBack={{ onClick: () => navigate(-1) }}
                actions={actions}
            />
            <ErrorMessage error={error} />
            {!preloaders.init ?
                <ItemForm
                    containerClassName={'system-constants__item-from-container'}
                >
                    <div className='system-constants__items'>
                        {config.constants && config.constants.length > 0 ?
                            config.constants.map((item, i) => (
                                <ConstantInput
                                    item={item}
                                    value={values[item.key]}
                                    setValues={setValues}
                                    key={`system-constants__constant-input${item.key}`}
                                />
                            ))
                            : null}
                    </div>


                </ItemForm>
                : (
                    <div className="orders-list__preloader">
                        <MiniPreloader />
                    </div>
                )}

        </div>
    );
}

export default SystemConstants