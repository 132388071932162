import "./FormControlBtn.css";

function FormControlBtn({ target, children, handleChange, isDisabled, readOnly }) {
  return (
    <button
      className={`form-control-btn ${isDisabled || readOnly ? "form-control-btn_disabled" : ""
        }`}
      type="button"
      onClick={() => {
        if (readOnly) return
        handleChange({ target })
      }}
    >
      {children}
    </button>
  );
}

export default FormControlBtn;
