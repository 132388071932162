import { Link } from 'react-router-dom';
import './AvatarBox.css';
import { getInitials } from '../../assets/utils/utils';
import { useEffect, useRef, useState } from 'react';
import useWindowSize from '../../assets/hooks/useWindowSize';


const PLACEHOLDER_TEXT_WIDTH_PERCENT = 1

// Simple hash function to convert initials to a numerical value
function simpleHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

// Function to generate color based on hash
function generateColorFromHash(hash) {
    const shortHash = Math.abs(hash % 360); // Use modulo to get a value between 0 and 360
    return `hsl(${shortHash}, 70%, 85%)`; // Adjust saturation and lightness to match the scheme
}

// Determine if color is light or dark
function isColorLight(color) {
    const [hue, saturation, lightness] = color.match(/\d+/g).map(Number);
    return lightness > 50; // Light if lightness is more than 50%
}

function AvatarBoxPlaceHolder({
    initials,
}) {
    const not_null_initials = initials.length > 0 ? initials : '-'
    const parentDivRef = useRef(null);
    const [bgColor, setBgColor] = useState('');
    const [fontSize, setFontSize] = useState('16px'); // Default font size
    const [fontColor, setFontColor] = useState('var(--avatar-placeholder-text-color)');// Default font color
    const { width } = useWindowSize()
    useEffect(() => {
        if (parentDivRef.current) {
            const parentWidth = parentDivRef.current.offsetWidth;
            const calculatedFontSize = parentWidth * PLACEHOLDER_TEXT_WIDTH_PERCENT / (Math.min(Math.max(not_null_initials.length, 2), 4)); // Example: 5% of parent width
            setFontSize(`${calculatedFontSize}px`);
        }
        const hash = simpleHash(not_null_initials);
        const color = generateColorFromHash(hash);
        setBgColor(color);
        setFontColor(isColorLight(color) ? 'var(--text-color)' : 'var(--avatar-placeholder-text-color)');
    }, [width, not_null_initials]); // Empty dependency array means this runs once on mount

    return (
        <div className='avatar-box__placeholder' ref={parentDivRef} style={{ backgroundColor: bgColor }}>
            <p className='avatar-box__placeholder-initials' style={{ fontSize: fontSize, color: fontColor }}>{not_null_initials.substring(0, 4)}</p>
        </div>
    )
}

function AvatarBox({
    path,
    className,
    img,
    name,
}) {


    const initials = name ? getInitials(name) : ''
    return (
        <>
            {
                path ?
                    <Link className={`avatar-box ${className ? className : ''} ${!img ? 'avatar-box_no-img' : ''}`} to={path}>
                        {img ?
                            <img
                                className="avatar-box__img"
                                src={img?.urls?.orig}
                                alt=""

                            />
                            :
                            <AvatarBoxPlaceHolder initials={initials} />
                        }
                    </Link>
                    :
                    <div className={`avatar-box ${className ? className : ''} ${!img ? 'avatar-box_no-img' : ''}`}>
                        {img ?
                            <img
                                className="avatar-box__img"
                                src={img?.urls?.orig}
                                alt=""

                            />
                            :
                            <AvatarBoxPlaceHolder initials={initials} />
                        }
                    </div>
            }
        </>

    );
}

export default AvatarBox