import { useForm } from '../../hooks/useForm';
import { fetchItem, createItem, editItem } from '../../services/documentsFormApi';
import { PLATFORM_MAIN_LINK, SETTINGS_MAIN_LINK, SETTINGS_PAGES_AND_FILES_LINK, SETTINGS_PAGES_AND_FILES_DOCS } from '../../../../../../../../assets/utils/constants';
import Form from './Form/Form';
import { deepEqual } from '../../../../../../../../assets/utils/utils';

const initialForm = {
    name: '',
    translit_name: '',
    is_visible: true,
    html: '',
    css: '',
};

function DocForm({ mode }) {
    const { form, preloaders, error, handleChange, handleSubmit } = useForm({
        mode,
        initialForm,
        fetchItem: fetchItem,
        createItem: createItem,
        editItem: editItem, 
        itemResponseFormater: (res) => ({
            name: res.name,
            translit_name: res.translit_name,
            is_visible: res.is_visible,
            html: res.content.html,
            css: res.content.css,
            _id: res._id,
        }),
        returnToListURL: `${PLATFORM_MAIN_LINK}/${SETTINGS_MAIN_LINK}/${SETTINGS_PAGES_AND_FILES_LINK}/${SETTINGS_PAGES_AND_FILES_DOCS}`,
        editFormValidity: (form, initForm) => (!form.name || !form.translit_name || !form.html || deepEqual(initForm, form)),
        createFormValidity: (form) => (!form.name || !form.translit_name || !form.html),
    });

    const formFields = [
        { name: 'name', label: 'Название', value: form.name, type: 'text' },
        { name: 'translit_name', label: 'Ссылка (Только латиница и цифры)', value: form.translit_name, type: 'text' },
        {
            name: 'is_visible', label: 'Видимость на сайте', value: form.is_visible, type: 'boolean',
            boolean_values: {
                true: {
                    title: 'Виден',
                },
                false: {
                    title: 'Скрыт',
                }
            }
        },
        { name: 'html', label: 'Текст HTML', value: form.html, type: 'textarea' },
        { name: 'css', label: 'Стили CSS', value: form.css, type: 'textarea' },
    ];

    return (
        <Form
            error={error}
            formFields={formFields}
            handleChange={handleChange}
            loading={preloaders.main}
        />
    );
}

export default DocForm;