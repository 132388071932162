import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeading from "../../PageHeading/PageHeading";
import ItemForm from "../../ItemForm/ItemForm";
import PlatformInput from "../../PlatformInput/PlatformInput";
import ImagesInput from "../../ImagesInput/ImagesInput";
import { CATALOG_CATEGORY_FILTERS_LINK, DEFAULT_MAX_IMG_SIZE } from "../../../../assets/utils/constants";
import "./EditCategory.css";
import mainApi from "../../../../assets/api/MainApi";
import { UserContext } from "../../../../assets/contexts/userContext";
import useAutoDismissError from "../../../../assets/hooks/useAutoDismissError";
import { createAction, hasPermission, parseApiError, parsePermissions } from "../../../../assets/utils/utils";
import MiniPreloader from "../../../MiniPreloader/MiniPreloader";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { PERMISSIONS, RBAC_CATEGORY_MANAGEMENT } from "../../../../assets/utils/permissions_rbac";

function EditCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [initialValues, setInitialValues] = useState({});
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);
  const [isSavePreloader, setIsSavePreloader] = useState(false);
  const [isPhotoLoading, setIsPhotoLoading] = useState(false);
  const [error, showError] = useAutoDismissError();

  const [values, setValues] = useState({
    name: "",
    translit_name: "",
    files: [],
  });
  const [valuesValidity, setValuesValidity] = useState({
    name: {},
    translit_name: {},
    files: {},
  });

  const hasEditPermission = hasPermission(parsePermissions(user), [RBAC_CATEGORY_MANAGEMENT[PERMISSIONS.EDIT]])


  function getSaveActionInactiveState() {
    // Define the condition for the "Save" action being inactive
    return !(
      values.name &&
      valuesValidity.files.validState &&
      (valuesValidity.name.isChanged ||
        valuesValidity.translit_name.isChanged ||
        valuesValidity.files.isChanged)
    ) || isSavePreloader;
  }

  const location = useLocation();
  function addActions() {
    const actions = [];

    if (hasEditPermission) {
      actions.push(createAction(`Настройка фильтров`, () => {
        navigate(`${location.pathname}/${CATALOG_CATEGORY_FILTERS_LINK}`)
      }, {
        isMainAction: false,
      }));
      actions.push(createAction("Сохранить", handleSave, {
        isMainAction: true,
        inactive: getSaveActionInactiveState(),
        isPreloaderVisible: isSavePreloader,
      }));
    }

    return actions;
  }

  const actions = addActions();

  useEffect(() => {
    if (!id || !user) return;

    const shop_id = user.default_shop._id;
    setIsPreloaderVisible(true);
    mainApi
      .getIdCategories({ _id: id, shop_id })
      .then((res) => {
        const data = {
          _id: res._id,
          name: res.name,
          translit_name: res.translit_name,
          files: res.file ? [res.file] : [],
        };
        setValues(data);
        setInitialValues(data);
        setValuesValidity((prevValue) => ({
          ...prevValue,
          name: { isChanged: false },
          translit_name: { isChanged: false },
          files: { errorMessage: "", validState: true, isChanged: false },
        }));
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setValues({
            name: "",
            translit_name: "",
            files: [],
          })
          setInitialValues({})
          setValuesValidity({
            name: {},
            translit_name: {},
            files: {},
          })
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  function handleSave() {
    const shop_id = user.default_shop._id;
    setIsSavePreloader(true);
    mainApi
      .editCategory({
        shop_id,
        files:
          values.files.length > 0 ? values.files.map((img) => img._id) : null,
        name: values.name,
        translit_name: values.translit_name ? values.translit_name : null,
        _id: id,
      })
      .then(() => {
        navigate(navigate(-1));
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsSavePreloader(false);
      });
  }

  function handleChange(e) {
    const input = e.target;
    const value = input.value;
    const name = input.name;

    switch (name) {
      case "translit_name": {
        let inputValue = value.replace(/[а-яё+=,.@#$%^*()?/<>:;!'"±]/gi, "");
        inputValue = inputValue.replace(/\s/g, "-");
        setValues((prevValue) => ({
          ...prevValue,
          [name]: inputValue,
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            ...prevValue[name],
            isChanged: initialValues[name] !== inputValue,
          },
        }));
        break;
      }

      case "files": {
        const file = input.files[0];
        handleFilesChange(file, name);
        break;
      }

      default:
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            ...prevValue[name],
            isChanged: initialValues[name] !== value,
          },
        }));
        break;
    }
  }

  function handleFilesChange(file, name) {
    if (file && file.size / 1000000 >= DEFAULT_MAX_IMG_SIZE) {
      setValuesValidity((prevValue) => ({
        ...prevValue,
        [name]: { ...prevValue[name], errorMessage: "", validState: false },
      }));
      return;
    }

    let data = new FormData();
    data.append("image", file);
    setIsPhotoLoading(true);
    mainApi
      .uploadImage({ data, type: "images" })
      .then((res) => {
        setValues((prevValue) => ({
          ...prevValue,
          [name]: [res],
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: { errorMessage: "", validState: true, isChanged: true },
        }));
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPhotoLoading(false);
      });
  }

  function handleRemove() {
    setValues((prevValue) => ({
      ...prevValue,
      files: [],
    }));
    setValuesValidity((prevValue) => ({
      ...prevValue,
      files: {
        errorMessage: "",
        validState: true,
        isChanged: initialValues?.files?.length !== 0,
      },
    }));
  }

  return (
    <section className="edit-category">
      <PageHeading
        className={"edit-category__heading"}
        title={initialValues?.name}
        goBack={{ onClick: () => navigate(-1) }}
        actions={actions}
        isTitlePreloader={isPreloaderVisible}
      />
      <ErrorMessage error={error} />
      {!isPreloaderVisible ? (
        <ItemForm>
          <div className="edit-category__form">
            <div className="edit-category__form-column">
              <PlatformInput
                value={values.name}
                placeholder={""}
                label={"Имя"}
                handleChange={handleChange}
                name={"name"}
              />
              <PlatformInput
                value={values.translit_name}
                placeholder={""}
                label={"Транслитерация"}
                handleChange={handleChange}
                name={"translit_name"}
              />
            </div>
            <div className="edit-category__form-column">
              <ImagesInput
                name={"files"}
                label={"Фото"}
                hint={`не более ${DEFAULT_MAX_IMG_SIZE} Мб`}
                addBtnText={"Изменить..."}
                images={values.files}
                onRemove={handleRemove}
                onAdd={handleChange}
                isPreloaderVisible={isPhotoLoading}
              />
            </div>
          </div>
        </ItemForm>
      ) : (
        <div className="orders-list__preloader">
          <MiniPreloader />
        </div>
      )}
    </section>
  );
}

export default EditCategory;
