import mainApi from "../../../../../../../assets/api/MainApi";

export const fetchItem = (shopId, docId) => {
    return mainApi.getDoc({
        shop_id: shopId,
        _id: docId,
    });
};

export const createItem = (shopId, formData) => {
    return mainApi.createDoc({
        shop_id: shopId,
        ...formData,
    });
};

export const editItem = (shopId, formData) => {
    return mainApi.editDoc({
        shop_id: shopId,
        ...formData,
    });
};