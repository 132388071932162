import { Route, Routes } from "react-router-dom";
import useWindowSize from "../../../../../../../assets/hooks/useWindowSize";
import { PagesAndFilesMobileRoutePage } from "../../../utils/pagesAndFilesUtils";
import { useContext } from "react";
import { ActionsContext } from "../../../../../../../assets/contexts/actionsContext";


function RouterComponent({routesConfig}) {
    const { actions } = useContext(ActionsContext)
    const { width } = useWindowSize()
    const indexElement = routesConfig.index.element ? routesConfig.index.element : null
    if (!width) return
    return (
        <Routes>
            <Route index element={
                width > 880 ?
                    indexElement
                    :
                    <PagesAndFilesMobileRoutePage
                        goBackActive={true}
                        title={routesConfig.index.mobileTitle}
                        actions={actions}
                    >
                        {indexElement}
                    </PagesAndFilesMobileRoutePage>

            }>
            </Route>
            {routesConfig.items.map((route, i) => {
                const element = route.element ? route.element : null
                return (
                    <Route path={route.path} element={
                        width > 880 ?
                            element
                            :
                            <PagesAndFilesMobileRoutePage
                                goBackActive={true}
                                title={route.mobileTitle}
                                actions={actions}
                            >
                                {element}
                            </PagesAndFilesMobileRoutePage>
                    }>
                    </Route>
                )
            })}
        </Routes>
    );
}

export default RouterComponent