import { useEffect, useState } from 'react';
import validator from 'validator';

import Input from '../../../Input/Input';
import './RegisterWithShop.css';
import Button from '../../../Button/Button';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AUTH_LOGIN_LINK, ERROR_PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH, PLATFORM_MAIN_LINK, PLATFORM_MAIN_LINKS } from '../../../../assets/utils/constants';
import { AnimatePresence, motion } from 'framer-motion';
import mainApi from '../../../../assets/api/MainApi';
import { parseApiError } from '../../../../assets/utils/utils';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import useAutoDismissError from '../../../../assets/hooks/useAutoDismissError';

import user_agreement_file from '../../../../assets/legal-docs/user_agreement_polza.tech.pdf'
import user_personal_data from '../../../../assets/legal-docs/user_personal_data.pdf'
function RegisterWithShop({
    setUser,
}) {

    const [searchParams, setSearchParams] = useSearchParams();





    const [values, setVelues] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        password: '',
    })


    const [valuesValidity, setVeluesValidity] = useState({
        phone: {},
    })




    function handleChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name

        switch (name) {
            case "phone":
                let inputValue = e.target.value.replace(/\D/g, '')
                let formattedInputValue = '';
                if (!inputValue) {
                    setVelues(prevValue => ({
                        ...prevValue,
                        [name]: '',
                    }))
                    setVeluesValidity((prevValue) => ({
                        ...prevValue,
                        [name]: {
                            errorMessage: 'Можно вводить только цифры',
                            validState: false
                        }
                    }))
                }
                else {
                    if (['7', '8', '9'].indexOf(inputValue[0]) > -1) {
                        setVeluesValidity((prevValue) => ({
                            ...prevValue,
                            [name]: {
                                errorMessage: '',
                                validState: false
                            }
                        }))
                        if (inputValue[0] === '9') inputValue = '7' + inputValue;

                        let firstSimbols = (inputValue[0] === '8') ? '8' : '+7';
                        formattedInputValue = firstSimbols + ' '

                        if (inputValue.length > 1) {
                            formattedInputValue += '(' + inputValue.substring(1, 4)
                        }
                        if (inputValue.length >= 5) {
                            formattedInputValue += ') ' + inputValue.substring(4, 7)
                        }
                        if (inputValue.length >= 8) {
                            formattedInputValue += '-' + inputValue.substring(7, 9)
                        }
                        if (inputValue.length >= 10) {
                            formattedInputValue += '-' + inputValue.substring(9, 11)
                        }
                        if (inputValue.length >= 11) {
                            setVeluesValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: '',
                                    validState: true
                                }
                            }));
                        } else {
                            setVeluesValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: '',
                                    validState: false
                                }
                            }));
                        }
                    } else {
                        formattedInputValue = '+' + inputValue.substring(0, 16)
                        if (inputValue.length >= 11) {
                            setVeluesValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: '',
                                    validState: true
                                }
                            }));
                        } else {
                            setVeluesValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: 'invalid frone',
                                    validState: false
                                }
                            }));
                        }
                    }


                    setVelues(prevValue => ({
                        ...prevValue,
                        [name]: formattedInputValue,
                    }))
                }

                break;


            default:
                setVelues(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
        }
    }

    function handlePhoneDelite(e) {
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length === 1) {
            setVelues(prevValue => ({
                ...prevValue,
                phone: '',
            }))
        }
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length < 11) {
            setVeluesValidity(prevValue => ({
                ...prevValue,
                phone: {
                    errorMessage: '',
                    validState: false
                }
            }));
        }

    }

    useEffect(() => {
        let phone = searchParams.get("phone")
        if (!phone) {
            return
        } else {
            handleChange({
                target: {
                    name: 'phone',
                    value: phone,
                }
            })
        }
    }, [])

    const navigate = useNavigate()

    const [isLoading, setLoading] = useState(false)
    const [error, showError] = useAutoDismissError();
    function handleSubmit() {
        setLoading(true)
        mainApi.signupWithShop({
            last_name: values.last_name,
            first_name: values.first_name,
            phone: values.phone.replace(/\D/g, ''),
            password: values.password && values.password.length > 0 ? values.password : null,
        })
            .then((res) => {
                console.log(res)
                setUser(res)

                navigate(PLATFORM_MAIN_LINKS[0].children
                    ? PLATFORM_MAIN_LINK +
                    "/" +
                    PLATFORM_MAIN_LINKS[0].path +
                    "/" +
                    PLATFORM_MAIN_LINKS[0].children[0].path
                    : PLATFORM_MAIN_LINK + "/" + PLATFORM_MAIN_LINKS[0].path)
            })
            .catch((err) => {
                console.log(err)
                switch (err.statusCode) {
                    case 400:
                        showError('Эта эл. почта уже зарегистрирована. Воспользуйтесь восстановлением пароля, если она ваша.')
                        break;

                    default:
                        showError(parseApiError(err))
                        break;
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <div className='register-with-shop'>
            <div className='register-with-shop__form'>
                <Input value={values.first_name}
                    placeholder={'Имя'}
                    handleChange={handleChange}
                    name={'first_name'}
                >
                </Input>
                <Input value={values.last_name}
                    placeholder={'Фамилия'}
                    handleChange={handleChange}
                    name={'last_name'}
                >
                </Input>
                <Input
                    value={values.phone}
                    placeholder={'Номер телефона'}
                    handleChange={handleChange}
                    name={'phone'}
                    onKeyDown={handlePhoneDelite}
                    inputMode='tel'
                />
                <Input value={values.password}
                    placeholder={'Новый пароль'}
                    handleChange={handleChange}
                    name={'password'}
                    isPassword={true}
                ></Input>
                <AnimatePresence>
                    {values.password && values.password.length >= 4 && values.password.length < PASSWORD_MIN_LENGTH ?
                        <motion.p
                            initial={{
                                height: '0px',
                                marginTop: '-12px'
                            }}
                            animate={{
                                height: 'fit-content',
                                marginTop: '0'
                            }}
                            exit={{
                                height: '0px',
                                marginTop: '-12px'
                            }}
                            className='register__form-error'>{ERROR_PASSWORD_MIN_LENGTH}</motion.p>
                        : null}
                </AnimatePresence>
                <Button
                    isLoading={isLoading}
                    isContrastColor={true}
                    text={'Зарегистрироваться'}
                    onClick={handleSubmit}
                    isInvalid={!(values.first_name && values.last_name && values.phone && valuesValidity.phone.validState && (!values.password || (values.password && values.password.length >= PASSWORD_MIN_LENGTH)))}
                />
                <ErrorMessage error={error} />
            </div>
            <p className='register__accept'>Регистрируясь, вы принимаете <a className='register__accept-link' href={user_agreement_file} target='_blank' rel='noreferrer'>пользовательское соглашение</a> и <a className='register__accept-link' href={user_personal_data} target='_blank' rel='noreferrer'> политику обработки персональных данных</a> сервиса</p>
        </div>
    );
}

export default RegisterWithShop