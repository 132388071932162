import { useEffect, useState } from 'react';



import './ChangePaymentMethodPopup.css';
import AdaptivePopup from '../../AdaptivePopup/AdaptivePopup';
import PopupControlBtn from '../../DesktopPopup/components/PopupCloseBtn/PopupCloseBtn';
import mainApi from '../../../assets/api/MainApi';
import PreloaderBox from '../../PreloaderBox/PreloaderBox';
import CardsController from '../CardsController/CardsController';


function ChangePaymentMethodPopup({
    isOpen,
    children,
    handleClose,
    popupName,
    current_payment_method_id,
    handleSelectActivePaymentMethod,
}) {

    const [paymentMethods, setPaymentMethods] = useState(null)
    const [preloaders, setPreloaders] = useState({
        initial: true,
    })
    useEffect(() => {
        if (!isOpen) return

        setPreloaders(prevValue => ({
            ...prevValue,
            initial: true,
        }))

        mainApi.getAllPaymentMethods()
            .then((res) => {
                console.log(res.data)
                setPaymentMethods(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    initial: false,
                }))
            })

    }, [isOpen])

    return (
        <AdaptivePopup
            {...{ isOpen, children, handleClose, popupName }}
        >

            {!preloaders.initial ?
                <div className='change-payment-method-popup'>
                    <div className='change-payment-method-popup__heading'>
                        <p className='change-payment-method-popup__title'>Изменение способа оплаты</p>
                        <PopupControlBtn
                            onClick={handleClose}
                            POPUP_NAME={popupName}
                            type={'close'}
                        />
                    </div>
                    <CardsController
                        cards={paymentMethods}
                        className={'change-payment-method-popup__cards'}
                        current_payment_method_id={current_payment_method_id}
                        handleSelectActivePaymentMethod={handleSelectActivePaymentMethod}
                        isDeleteAvailible={true}
                        setPaymentMethods={setPaymentMethods}
                    />
                    <button className='change-payment-method-popup__btn' type='button' onClick={() => {
                        handleClose({ name: popupName })
                    }}>
                        Закрыть
                    </button>
                </div>
                :
                <PreloaderBox />
            }


        </AdaptivePopup>

    );
}

export default ChangePaymentMethodPopup;