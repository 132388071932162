import mainApi from "../../../../../../../assets/api/MainApi";

export const fetchDocs = (shopId) => {
    return mainApi.getAllDocs({ shop_id: shopId });
};

export const deleteDoc = (shopId, docId) => {
    return mainApi.deleteDoc({ shop_id: shopId, _id: docId });
};

export const sortDocs = (shopId, docIds) => {
    return mainApi.sortDocs({ shop_id: shopId, data: docIds });
};