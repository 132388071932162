import { useEffect, useRef } from 'react';
import { AUTH_SIGNUP_TYPE, AUTH_LOGIN_TYPE, AUTH_RECOVERY_TYPE, AUTH_RESET_PASSWORD_TYPE, AUTH_SIGNUP_WITH_SHOP_TYPE } from '../../../../assets/utils/constants';
import Login from '../Login/Login';
import Recovery from '../Recovery/Recovery';
import Register from '../Register/Register';
import ResetPassword from '../ResetPassword/ResetPassword';
import './AuthFromInside.css';
import RegisterWithShop from '../RegisterWithShop/RegisterWithShop';

function AuthFromInside({
    title,
    subtitle,
    type,
    setUser,
    updateContentHeight,
}) {

    const contentRef = useRef(null); // Create a ref for the content

    // Use useEffect to measure the content height and call the update function
    useEffect(() => {
        if (contentRef.current) {
            const newHeight = contentRef.current.scrollHeight;
            updateContentHeight(newHeight);
        }
    }, [type]);

    return (
        <div className='auth-from-inside' ref={contentRef}>
            <div className='auth-from-inside__heading'>
                {title ? <h2 className='auth-from-inside__title'>{title}</h2> : null}
                {subtitle ? <p className='auth-from-inside__subtitle'>{subtitle}</p> : null}
            </div>
            {type === AUTH_SIGNUP_TYPE ? <Register setUser={setUser} /> : null}
            {type === AUTH_SIGNUP_WITH_SHOP_TYPE ? <RegisterWithShop setUser={setUser} /> : null}
            {type === AUTH_LOGIN_TYPE ? <Login setUser={setUser} /> : null}
            {type === AUTH_RECOVERY_TYPE ? <Recovery setUser={setUser} /> : null}
            {type === AUTH_RESET_PASSWORD_TYPE ? <ResetPassword setUser={setUser} /> : null}
        </div>
    );
}

export default AuthFromInside