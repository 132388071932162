
import { useContext, useEffect, useState } from 'react';
import ActionBtn from '../../../ActionBtn/ActionBtn';
import ServiceContainer from '../../../ServiceContainer/ServiceContainer';
import TwoFactorBtn from '../../../TwoFactorBtn/TwoFactorBtn';
import './PaymentSystems.css';
import { UserContext } from '../../../../../assets/contexts/userContext';
import mainApi from '../../../../../assets/api/MainApi';
import PreloaderBox from '../../../../PreloaderBox/PreloaderBox';
import PlatformInput from '../../../PlatformInput/PlatformInput';
import { DEFAULT_VAT_VALUES } from '../../../../../assets/utils/constants';


function PaymentSystems() {
    const { user } = useContext(UserContext)
    const [vatValue, setVatValue] = useState(DEFAULT_VAT_VALUES[0])
    const [data, setData] = useState(undefined)
    const [preloaders, setPreloaders] = useState({
        main: true,
    })
    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            main: true,
        }))
        mainApi.getYooKassaIntegration({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                setData({
                    ...res,
                    // is_connected: true,
                })
                if (res.tax_system_code) {
                    setVatValue(DEFAULT_VAT_VALUES.find(item => item.type === res.tax_system_code) ? DEFAULT_VAT_VALUES.find(item => item.type === res.tax_system_code) : DEFAULT_VAT_VALUES[0])
                }
            })
            .catch((err) => {
                console.log(err)
                if (err.statusCode === 403) {
                    setData(undefined)
                }
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    main: false,
                }))
            })
    }, [user])
    function handleClickAlreadyHaveAccount() {

        window.location.href = data.to_connect.url_exists
    }

    function handleCreateNewAccount() {

        window.location.href = data.to_connect.url_new
    }

    function handleDisconnect() {
        mainApi.unsetYooKassaIntegration({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                setData(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    function handleVatChange(e) {
        const value = e.target.value
        setVatValue(value)
        mainApi.setYooKassaVatCode({
            shop_id: user.default_shop._id,
            vat_code: value.type,
        })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    return (
        <>
            {!preloaders.main ?
                <div className='payment-systems'>
                    <p className='payment-systems__title'>Подключение платежной системы</p>
                    {data && !data.is_connected ?
                        <>
                            <ActionBtn
                                text={'Мне нужно создать новый аккаунт'}
                                handleAction={handleCreateNewAccount}
                            />
                            <ActionBtn
                                text={'У меня есть аккаунт ЮKassa'}
                                handleAction={handleClickAlreadyHaveAccount}
                            />
                            <p className='payment-systems__hint'>Для создания нового аккаунта, мы&nbsp;переведем вас на&nbsp;сайт платежного сервиса. Для регистрации вам понадобятся документы вашего юрлица.</p>
                        </>
                        :
                        <>

                            <ServiceContainer
                                title={'ЮKassa подключена'}
                                subtitle={`Номер магазина: ${data?.shop_id}`}
                            >
                                <div className='payment-systems__vat-code'>
                                    <p className='payment-systems__title'>Укажите Вашу систему налогообложения</p>
                                    <PlatformInput
                                        value={vatValue}
                                        placeholder={''}
                                        label={''}
                                        // hint={'Видно только вам'}
                                        isSelect={true}
                                        selected_text_value={vatValue.title}
                                        maxLength={100}
                                        handleChange={handleVatChange}
                                        selectList={DEFAULT_VAT_VALUES}
                                        selectDifferenceKey={'type'}
                                        selectTextValueKey={'title'}
                                        name={'vat-value'}
                                    />
                                </div>
                            </ServiceContainer>

                            <TwoFactorBtn
                                firstStepBtnText={'Отключить сервис'}
                                secondStepBtnText={'Отключить'}
                                handleAction={handleDisconnect}
                                cancelBtnText={'Отмена'}
                                confirmText={'Вы&nbsp;уверены что хотите отключить активное подключение к&nbsp;платежной системе?'}
                            />
                        </>
                    }
                </div>
                :
                <PreloaderBox />
            }
        </>

    );
}

export default PaymentSystems