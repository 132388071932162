import { useEffect, useState } from 'react';

import './CardsController.css';
import { getCardIcon } from '../../../assets/utils/paymentMethods/paymentMethods';
import { AddIcon } from '../../../assets/icons/controls/controls';
import { CrossDeleteIcon, PlusIcon } from '../../../assets/icons/paymentMethods/paymentMethods';
import PreloaderBox from '../../PreloaderBox/PreloaderBox';
import mainApi from '../../../assets/api/MainApi';
import SubmitActionPopup from '../../Platform/SubmitActionPopup/SubmitActionPopup';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import { parseApiError } from '../../../assets/utils/utils';

function CardsController({
    cards,
    className,
    current_payment_method_id,
    handleSelectActivePaymentMethod,
    isDeleteAvailible,
    setPaymentMethods,
}) {
    console.log(cards, current_payment_method_id)
    const [preloaders, setPreloaders] = useState({
        submit_delete: false,
        add: false,
    })
    const [popups, setPopups] = useState({
        delete_approve: false,
    })

    function addPaymnetMethod() {
        if (preloaders.add) return
        setPreloaders(prevValue => ({
            ...prevValue,
            add: true,
        }))
        console.log(window.location.href)
        mainApi.createPaymentMethod({
            return_url: window.location.href + '?open_change_payment_method=true'
        })
            .then((res) => {
                console.log(res)
                window.location.href = res.url
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    add: false,
                }))
            })

    }
    const [itemToDelete, setItemToDelete] = useState(null)
    const [deleteError, showDeleteError] = useAutoDismissError()
    function handleDeleteApprove(item) {
        setPopups(prevValue => ({
            ...prevValue,
            delete_approve: true,
        }))
        setItemToDelete(item)
    }

    function handleDeleteMethod() {
        setPreloaders(prevValue => ({
            ...prevValue,
            submit_delete: true,
        }))
        mainApi.deletePaymentMethod({
            _id: itemToDelete._id,
        })
            .then((res) => {
                console.log(res)
                setPaymentMethods(prevValue => prevValue.filter((item) => item._id !== itemToDelete._id))
                handleCloseSubmitDelete()
            })
            .catch((err) => {
                console.log(err)
                showDeleteError(parseApiError(err))
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    submit_delete: false,
                }))
            })
    }

    function handleCloseSubmitDelete() {
        setPopups(prevValue => ({
            ...prevValue,
            delete_approve: false,
        }))
        setTimeout(() => {
            setItemToDelete(null)
        }, 300);
    }
    return (
        <div className={`cards-controller ${className ? className : ''} ${isDeleteAvailible ? 'cards-controller_delete' : ''}`}>
            {isDeleteAvailible ?
                <SubmitActionPopup
                    isOpen={popups.delete_approve}
                    text={`Вы уверены что хотите удалить способ оплаты "•••• ${itemToDelete ? itemToDelete.payment_method.card.last4 : ''}"?`}
                    submitError={deleteError}
                    cencelText={'Отмена'}
                    submitText={'Удалить'}
                    hendleClose={handleCloseSubmitDelete}
                    hendleSubmit={handleDeleteMethod}
                    isSubmitPreloader={preloaders.submit_delete}
                />
                :
                null}
            {
                cards && cards.length > 0 ?
                    cards.map((item, i) => {
                        const { payment_method, _id } = item
                        const { card } = payment_method
                        const {
                            card_type,
                            last4,
                        } = card
                        const card_icon = getCardIcon(card_type)
                        return (
                            <button className={`cards-controller__card ${current_payment_method_id === _id ? 'cards-controller__card-selected' : ''}`} type='button' onClick={() => {
                                if (current_payment_method_id === _id) return
                                handleSelectActivePaymentMethod(item)
                            }}>
                                <div className='cards-controller__card-heading'>
                                    <p className='cards-controller__card-type'>{card_type}</p>
                                    {card_icon({ mainClassName: 'cards-controller__card-icon' })}
                                </div>
                                <p className='cards-controller__card-value'>•••• {last4}</p>
                                {isDeleteAvailible ?
                                    <button className='cards-controller__card-delete' type='button' onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()

                                        handleDeleteApprove(item)
                                    }}>
                                        <CrossDeleteIcon
                                            mainClassName={'cards-controller__card-delete-icon'}
                                            strokeClassName={'cards-controller__card-delete-icon-stroke'}
                                        />
                                    </button>
                                    :
                                    null}
                            </button>
                        )
                    })
                    :
                    null
            }
            {!cards || cards.length < 5 ?
                <button className='cards-controller__add' onClick={addPaymnetMethod}>
                    {!preloaders.add ?
                        <PlusIcon
                            mainClassName={'cards-controller__add-icon'}
                            strokeClassName={'cards-controller__add-icon-stroke'}
                        />
                        :
                        <PreloaderBox />
                    }
                </button>
                :
                null}
        </div>

    );
}

export default CardsController;