import { Route, Routes } from 'react-router-dom';
import './Promocodes.css';
import PromocodeGroups from './PromocodeGroups/PromocodeGroups';
import { PROMOCODES_CREATE_CODE_LINK, PROMOCODES_CREATE_GROUP_LINK, PROMOCODES_EDIT_GROUP_LINK, PROMOCODES_GROUP_LINK } from '../../../assets/utils/constants';
import PromocodeGroup from './PromocodeGroup/PromocodeGroup';
import CreatePromocodeGroup from './CreatePromocodeGroup/CreatePromocodeGroup';
import CreatePromocode from './CreatePromocode/CreatePromocode';

function Promocodes() {
    return (
        <div className='promocodes'>
            <Routes>
                <Route index element={
                    <PromocodeGroups />
                }>
                </Route>
                <Route path={`${PROMOCODES_CREATE_GROUP_LINK}`} element={
                    <CreatePromocodeGroup />
                }>
                </Route>
                <Route path={`${PROMOCODES_EDIT_GROUP_LINK}/:id`} element={
                    <CreatePromocodeGroup />
                }>
                </Route>
                <Route path={`${PROMOCODES_GROUP_LINK}`} element={
                    <PromocodeGroup />
                }>
                </Route>
                <Route path={`${PROMOCODES_GROUP_LINK}/:id`} element={
                    <PromocodeGroup />
                }>
                </Route>
                <Route path={`${PROMOCODES_GROUP_LINK}/:id/${PROMOCODES_CREATE_CODE_LINK}`} element={
                    <CreatePromocode />
                }>
                </Route>
            </Routes>
        </div>
    );
}

export default Promocodes