
import { useContext, useEffect, useState } from 'react';
import PlatformInput from '../../../PlatformInput/PlatformInput';
import ActionBtn from '../../../ActionBtn/ActionBtn';
import ServiceContainer from '../../../ServiceContainer/ServiceContainer';
import TwoFactorBtn from '../../../TwoFactorBtn/TwoFactorBtn';
import './СustomDomain.css';
import mainApi from '../../../../../assets/api/MainApi';
import { UserContext } from '../../../../../assets/contexts/userContext';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import useAutoDismissError from '../../../../../assets/hooks/useAutoDismissError';
import { parseApiError } from '../../../../../assets/utils/utils';
import PreloaderBox from '../../../../PreloaderBox/PreloaderBox';
import validator from 'validator';


function СustomDomain({
    domainInfo,
    setDomainInfo,
}) {

    const { user } = useContext(UserContext)
    const [isConnected, setConnected] = useState(false)

    const [form, setForm] = useState({
        domain: '',
        current_domain: '',
    })
    const [step, setStep] = useState(0)
    const [dnsTags, setDnsTags] = useState([
        {
            type: 'A',
            domain: '@',
            ip: '192.168.30.1',
        }
    ])

    useEffect(() => {
        if (!domainInfo) return
        const { custom } = domainInfo
        const { connected_ip, domain, is_connected, preferred_ip } = custom
        setConnected(is_connected)
        setDnsTags([
            {
                type: 'A',
                domain: '@',
                ip: preferred_ip,
            }
        ])
        setForm(prevValue => ({
            ...prevValue,
            current_domain: domain,
        }))
    }, [domainInfo])

    // Function to validate domain name
    const isValidDomain = (domain) => {
        return validator.isFQDN(domain);
    };

    const [error, showError] = useAutoDismissError()
    const [error2, showError2] = useAutoDismissError()
    const [preloaders, setPreloaders] = useState({
        check: false,
        connect: false,
        disconnect: false,
    })
    function handleCheck() {
        if (!isValidDomain(form.domain)) {
            showError(`Введенное значение не является корректным именем домена. Пожалуйста, убедитесь, что вы ввели адрес без 'http://', 'https://' или 'www', например, 'example.com'.`);
            return;
        }
        setPreloaders(prevValue => ({
            ...prevValue,
            check: true
        }))
        mainApi.checkCustomDomainConnection({
            domain: form.domain,
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                setStep(1)
                console.log(res)
            })
            .catch((err) => {

                showError2(parseApiError(err))
                console.log(err)
            })

            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    check: false
                }))
            })
    }

    function handleConnect() {
        setPreloaders(prevValue => ({
            ...prevValue,
            connect: true
        }))
        mainApi.setCustomDomain({
            shop_id: user.default_shop._id,
            domain: form.domain,
        })
            .then((res) => {
                console.log(res)
                setConnected(true)
                setForm(prevValue => ({
                    ...prevValue,
                    current_domain: res.custom.domain,
                }))
                setDomainInfo(prevValue => ({
                    ...prevValue,
                    custom: {
                        ...prevValue.custom,
                        domain: form.domain,
                        is_connected: true,
                    }
                }))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    connect: false
                }))
            })
        // mainApi.checkCustomDomainConnection({
        //     domain: form.domain,
        //     shop_id: user.default_shop._id,
        // })
        //     .then((res) => {
        //         setStep(1)
        //         console.log(res)
        //     })
        //     .catch((err) => {

        //         showError2(parseApiError(err))
        //         console.log(err)
        //     })

        //     .finally(() => {
        //         setPreloaders(prevValue => ({
        //             ...prevValue,
        //             check: false
        //         }))
        //     })
    }

    function handleDisconnect() {
        setPreloaders(prevValue => ({
            ...prevValue,
            disconnect: true
        }))
        mainApi.unsetCustomDomain({
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                setConnected(false)
                setForm({
                    domain: '',
                    current_domain: '',
                })
                setDomainInfo(prevValue => ({
                    ...prevValue,
                    custom: {
                        ...prevValue.custom,
                        domain: '',
                        is_connected: false,
                    }
                }))
                setStep(0)
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    disconnect: false
                }))
            })
    }

    function handleChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name
        let inputValue = value
        setStep(0)
        switch (name) {
            default:
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: inputValue,
                }))
                break;
        }
    }
    return (
        <>
            {
                domainInfo ?
                    <div className='custom-domain'>
                        <p className='custom-domain__title'>Собственный домен</p>
                        {!isConnected ?
                            <>
                                <ServiceContainer
                                    title={step === 0 ? error2 ? 'Ошибка подключения' : 'Домен не подключен' : step === 1 ? 'Записи настроены верно' : ''}
                                    title_error={Boolean(error2)}
                                >
                                    {step === 0 ?
                                        <>
                                            <p className='custom-domain__info-text'>Чтобы подключить домен, купите его у&nbsp;доменного регистратора и&nbsp;задайте следующие значения у&nbsp;DNS-записей:</p>
                                            <table className='custom-domain__table'>
                                                <thead className='custom-domain__thead'>
                                                    <tr className='custom-domain__thead-tr'>
                                                        <th className={`custom-domain__thead-th`} colSpan="1" >
                                                            Type
                                                        </th>
                                                        <th className={`custom-domain__thead-th`} colSpan="1" >
                                                            Domain
                                                        </th>
                                                        <th className={`custom-domain__thead-th`} colSpan="1" >
                                                            IP
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody className='custom-domain__tbody' >
                                                    {dnsTags && dnsTags.length > 0 ?
                                                        dnsTags.map((item, i) => (
                                                            <tr className='custom-domain__tbody-tr' key={`custom-domain__tbody-tr${i}`}>
                                                                <td className={`custom-domain__tbody-td `}>
                                                                    {item.type}
                                                                </td>
                                                                <td className={`custom-domain__tbody-td `}>
                                                                    {item.domain}
                                                                </td>
                                                                <td className={`custom-domain__tbody-td `}>
                                                                    {item.ip}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        : null}
                                                </tbody>
                                            </table >
                                            <p className={`custom-domain__info-text ${error2 ? 'custom-domain__info-text_error' : ''}`}>После этого впишите адрес домена в&nbsp;поле снизу и&nbsp;нажмите кнопку &laquo;Проверить&raquo;. Обратите внимание, что изменение DNS-записей у&nbsp;разных провайдеров может занимать до&nbsp;72&nbsp;часов.</p>

                                        </>
                                        :
                                        null
                                    }

                                    {step === 1 ?
                                        <p className='custom-domain__info-text'>Поздравляем, ваш домен готов к&nbsp;подключению. Чтобы ваш сайт начал открываться при посещении домена, нажмите на&nbsp;кнопку &laquo;Подключить домен&raquo;</p>

                                        :
                                        null
                                    }

                                </ServiceContainer>
                                <PlatformInput
                                    value={form.domain}
                                    handleChange={handleChange}
                                    placeholder={'Введите домен для проверки'}
                                    type={'text'}

                                    name={'domain'}
                                    error={error2}
                                />
                                {step === 0 ?
                                    <ActionBtn
                                        text={'Проверить'}
                                        handleAction={handleCheck}
                                        isInactive={!(isValidDomain(form.domain))}
                                        isLoading={preloaders.check}
                                    />
                                    : null}

                                {step === 1 ?
                                    <ActionBtn
                                        text={'Подключить домен'}
                                        handleAction={handleConnect}
                                        isInactive={!(form.domain.length >= 3)}
                                        isLoading={preloaders.connect}
                                        isContrast={true}
                                    />
                                    : null}

                                <ErrorMessage error={error} />
                            </>
                            :
                            <>
                                <ServiceContainer
                                    title={'Домен подключен'}
                                    subtitle={`Ваш сайт доступен по адресу <a class="custom-domain__hint-link" href="https://${form.current_domain}" target='_blank' rel='noreferrer' >${form.current_domain}</a>`}
                                >

                                </ServiceContainer>
                                <TwoFactorBtn
                                    firstStepBtnText={'Отключить домен'}
                                    secondStepBtnText={'Отключить'}
                                    handleAction={handleDisconnect}
                                    cancelBtnText={'Отмена'}
                                    confirmText={`Чтобы ваш сайт перестал открываться по адресу ${form.current_domain}, подтвердите отключение домена`}
                                    isLoading={preloaders.disconnect}
                                />
                            </>
                        }
                    </div >
                    :
                    <PreloaderBox />
            }
        </>

    );
}

export default СustomDomain