import { deleteDoc, fetchDocs, sortDocs } from '../../services/documentsApi';
import List from './List/List';

function DocsList() {

    return (
        <List
            deletePopupTextFormater={(itemToDelete) => (`Вы уверены, что хотите удалить документ "${itemToDelete ? itemToDelete.name : ''}: /${itemToDelete ? itemToDelete.translit_name : ''}"`)}
            fetchItems={fetchDocs}
            deleteItem={deleteDoc}
            sortItems={sortDocs}
            itemNameTextFormater={(item) => (`${!item.is_visible ? 'Скрыт - ' : ''} ${item.name}: /${item.translit_name}`)}
            emptyText={'Документов пока нет'}
        />
    );
}

export default DocsList