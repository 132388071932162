import { useNavigate, useSearchParams } from 'react-router-dom';
import './FinanceInfo.css';
import FinanceInfoBox from './FinanceInfoBox/FinanceInfoBox';
import { CARD_ICON_TYPE, ICON_TYPE, PLATFORM_MAIN_LINK, SUBSCRIPTIONS_MAIN_LINK, SUBSCRIPTIONS_PLATFROM_LINK, SUBSCRIPTION_SUBTYPE_MAIN, SUBSCRIPTION_SUBTYPE_SIMPLE, TEXT_TYPE } from '../../../../../assets/utils/constants';
import moment from 'moment-timezone';
import { parsePrice } from '../../../../../assets/utils/utils';
import { getCardIcon } from '../../../../../assets/utils/paymentMethods/paymentMethods';

import ChangePaymentMethodPopup from '../../../../PaymentMethods/ChangePaymentMethodPopup/ChangePaymentMethodPopup';
import { useContext, useEffect, useState } from 'react';
import mainApi from '../../../../../assets/api/MainApi';
import { UserContext } from '../../../../../assets/contexts/userContext';



function getSubscriptionName({ days, sub_type }) {
    const MAIN_SUBSCRIPTION_NAMES = {
        30: 'Polza - 1 мес.',
        365: 'Polza - 1 год',
        730: 'Polza - 2 года',
    }

    const PROMO_SUBSCRIPTION_NAMES = {
        30: 'Promo - 1 мес.',
        365: 'Promo - 1 год',
    }

    const SUBSCRIPTIONS_BY_SUBTYPES = {
        [SUBSCRIPTION_SUBTYPE_MAIN]: MAIN_SUBSCRIPTION_NAMES,
        [SUBSCRIPTION_SUBTYPE_SIMPLE]: PROMO_SUBSCRIPTION_NAMES,
    }
    console.log(SUBSCRIPTIONS_BY_SUBTYPES[sub_type], days)

    return SUBSCRIPTIONS_BY_SUBTYPES[sub_type][days]
}

function FinanceInfo({
    info,
    setInfo,
}) {
    const { user } = useContext(UserContext)
    const { default_shop } = user
    // open_change_payment_method
    const [searchParams, setSearchParams] = useSearchParams();
    const [popups, setPopups] = useState({
        change_payment_method: false,
    })
    useEffect(() => {
        let open_change_payment_method = searchParams.get("open_change_payment_method")
        if (!open_change_payment_method) return
        setSearchParams("")
        setPopups(prevValue => ({
            ...prevValue,
            change_payment_method: true,
        }))
    }, [])
    const navigate = useNavigate()
    // console.log(info.subscriptions.main)
    const main_subscriptions = info?.subscriptions?.main

    function handleOpenChangePaymentMethodPopup() {
        setPopups(prevValue => ({
            ...prevValue,
            change_payment_method: true,
        }))
    }

    function handlePopupClose({ name }) {
        setPopups(prevValue => ({
            ...prevValue,
            [name]: false,
        }))
    }
    function handleSelectActivePaymentMethod(item) {
        console.log(item)
        mainApi.subscribeMain({
            shop_id: default_shop._id,
            _id: main_subscriptions._id,
            payment_id: item._id,
            return_url: window.location.href
        })
            .then((res) => {
                console.log(res)
                setInfo(prevValue => ({
                    ...prevValue,
                    subscriptions: {
                        ...prevValue.subscriptions,
                        main: {
                            ...prevValue.subscriptions.main,
                            payment_method: item,
                            payment_id: item._id,
                        }
                    }
                }))
            })
            .catch((err) => {
                // showDeleteError(parseApiError(showDeleteError))
                console.log(err)
            })
    }



    return (

        <>
            <ChangePaymentMethodPopup
                isOpen={popups.change_payment_method}
                current_payment_method_id={main_subscriptions ? main_subscriptions.payment_id : ''}
                handleSelectActivePaymentMethod={handleSelectActivePaymentMethod}
                handleClose={handlePopupClose}
                popupName={'change_payment_method'}

            >

            </ChangePaymentMethodPopup>
            {main_subscriptions ?
                <div className="finance-info">

                    <div className='finance-info__subscription'>
                        <FinanceInfoBox
                            title={'Тарифный план'}
                            change_btn={{
                                text: 'Изменить',
                                onClick: () => {
                                    navigate(`${PLATFORM_MAIN_LINK}/${SUBSCRIPTIONS_MAIN_LINK}/${SUBSCRIPTIONS_PLATFROM_LINK}`)
                                }
                            }}
                            main_value={`${getSubscriptionName({ days: main_subscriptions.data.recurring.count, sub_type: main_subscriptions.data.sub_type })}`}
                            sub_value={{
                                type: TEXT_TYPE,
                                value: `${(!main_subscriptions.after_end_id || !main_subscriptions.payment_id) ? `Активна до ${moment(main_subscriptions.valid_until + '+00:00').format('DD MMMM, YYYY')}` : ''}`
                            }}
                        />
                        <FinanceInfoBox
                            title={'Следующий платеж'}
                            main_value={`${(!main_subscriptions.after_end_id || !main_subscriptions.payment_id) ? 'Отключено' : parsePrice(main_subscriptions.data.price.unit_amount, main_subscriptions.data.price.currency)}`}
                            sub_value={{
                                type: TEXT_TYPE,
                                value: `${(!main_subscriptions.after_end_id || !main_subscriptions.payment_id) ? '' : moment(main_subscriptions.valid_until + '+00:00').format('DD MMMM, YYYY')}`
                            }}
                        />
                        <FinanceInfoBox
                            title={'Способ оплаты'}
                            change_btn={{
                                text: 'Изменить',
                                onClick: () => {
                                    handleOpenChangePaymentMethodPopup()
                                }
                            }}
                            main_value={`•••• ${main_subscriptions?.payment_method && main_subscriptions?.payment_method?.payment_method ? main_subscriptions?.payment_method?.payment_method?.card?.last4 : ''}`}
                            sub_value={
                                main_subscriptions?.payment_method && main_subscriptions?.payment_method?.payment_method && main_subscriptions?.payment_method?.payment_method?.card ?
                                    {
                                        type: CARD_ICON_TYPE,
                                        value: getCardIcon(main_subscriptions?.payment_method?.payment_method?.card?.card_type)
                                    }
                                    : null
                            }
                        />
                    </div>
                </div>

                :
                <div className='finance-info__no-subscription'>
                    <p className='finance-info__no-subscription-text'>Вы не выбрали ни один тариф.</p>
                    <button className='finance-info__no-subscription-btn' type='button' onClick={() => {
                        navigate(`${PLATFORM_MAIN_LINK}/${SUBSCRIPTIONS_MAIN_LINK}/${SUBSCRIPTIONS_PLATFROM_LINK}`)
                    }}>
                        Выбрать тариф
                    </button>
                </div>
            }
        </>
    );
}

export default FinanceInfo