import MobilePopup from '../../MobilePopup/MobilePopup';
import { ACTION_POPUP_NAME } from '../../../assets/utils/constants';
import './ActionPopup.css';
import { Link, useLocation } from 'react-router-dom';
import { getCurrentPlatfromLocation } from '../../../assets/utils/utils';
import PopupControlBtn from '../../MobilePopup/components/PopupCloseBtn/PopupCloseBtn';
import React, { useContext, useEffect, useState } from 'react';
import { PlatformLinksContext } from '../../../assets/contexts/platformLinksContext';


function ActionPopup({ isOpen, handleClose, actions }) {
    const location = useLocation();
    const { links, default_link } = useContext(PlatformLinksContext)

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })

    const [popupLocation, setPopupLocation] = useState(current_location)

    useEffect(() => {
        if (!isOpen) return
        setPopupLocation(current_location)
    }, [isOpen])


    return (
        <MobilePopup
            isOpen={isOpen}
            handleClose={handleClose}
            popupName={ACTION_POPUP_NAME}

        >
            <div className='action-popup'>
                <div className='action-popup__heading'>
                    <p className='action-popup__title'>{popupLocation && popupLocation.location && popupLocation.location.name ? popupLocation.location.name : ''}</p>
                    <PopupControlBtn
                        POPUP_NAME={ACTION_POPUP_NAME}
                        onClick={handleClose}
                        type={'close'}
                    />
                </div>
                <div className='action-popup__actions'>
                    {actions && actions.length > 0 ?
                        actions.map((item, i) => (
                            <React.Fragment key={`action-popup__action_${i}`}>
                                {item.path ?
                                    <Link className={`action-popup__action ${item.isMainAction ? 'action-popup__action_type_main' : ''} ${item.inactive ? 'action-popup__action_inactive' : ''}`} to={item.path} target={item.path && !item.path.startsWith('/') ? '_blank' : '_self'} onClick={() => {
                                        handleClose({ name: ACTION_POPUP_NAME })
                                    }}>
                                        {item.name}
                                    </Link>
                                    : null}

                                {item.onClick ?
                                    <button className={`action-popup__action ${item.isMainAction ? 'action-popup__action_type_main' : ''} ${item.inactive ? 'action-popup__action_inactive' : ''}`} type='button' onClick={() => {
                                        item.onClick()
                                        handleClose({ name: ACTION_POPUP_NAME })
                                    }}>
                                        {item.name}
                                    </button>
                                    : null}
                            </React.Fragment>
                        ))
                        : null}
                </div>
            </div>
        </MobilePopup>

    );
}

export default ActionPopup