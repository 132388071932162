import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Rectangle } from 'recharts';

import './Graph.css';
import moment from 'moment-timezone';
import CustomTooltip from './CustomTooltip/CustomTooltip';




const customTickStyle = {
    fill: 'var(--table-title-color)', // text color
    fontSize: '12px', // text size
    fontFamily: 'Golos Text', // text font
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    letterSpacing: '-0.72px',

};

// Function to calculate the opacity based on value
const calculateOpacity = (value, min, max) => {
    const range = max - min;
    if (range === 0) return 1; // Avoid division by zero

    const normalized = (value - min) / range; // Normalize value between 0 and 1
    return 0.2 + (normalized * 0.8); // Scale between 0.2 and 1
};

// Custom bar component
const CustomBar = (props) => {
    const { fill, x, y, width, height, value } = props;

    // Calculate min and max from the data
    const min = Math.min(...props.data.map(d => d.value));
    const max = Math.max(...props.data.map(d => d.value));

    // Calculate opacity
    const opacity = calculateOpacity(value, min, max);

    return <Rectangle className="bar-transition" fill={fill} x={x} y={y} width={width} height={height} radius={2} fillOpacity={opacity} />;
};


function Graph({
    data,
    period_type,
}) {
    const processedData = data.map((item, index) => ({
        ...item,
        showTick: period_type === 'day' ? index % 4 === 2 : period_type === 'week' ? 1 :  index % 5 === 3
    }));

    const CustomTooltipComponent = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <CustomTooltip
                    title={moment(label + '+00:00').format(period_type === 'day' ? 'HH:mm' : 'DD.MM.YYYY')}
                    value={Number(payload[0].value).toLocaleString('us')}
                />
            );
        }

        return null;
    };


    const renderCustomAxisTick = (tickProps) => {
        const { x, y, payload } = tickProps;
        const currentItem = processedData.find(item => item.name === payload.value);

        if (currentItem && currentItem.showTick) {
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={8} textAnchor="middle" style={customTickStyle}>
                        {moment(payload.value + '+00:00').format(period_type === 'day' ? 'HH:mm' : 'DD.MM')}
                    </text>
                </g>
            );
        }
        return null;
    };

    return (
        <div className='graph'>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={processedData}
                    margin={{
                        top: 0, right: 0, left: 0, bottom: 0,
                    }}
                    padding={0}
                >
                    <XAxis dataKey="name" tick={renderCustomAxisTick} interval={0} axisLine={false} tickLine={false} height={16} />
                    <Tooltip content={<CustomTooltipComponent />} />
                    <Bar dataKey="value" fill="#505A72" shape={<CustomBar data={processedData} />} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default Graph