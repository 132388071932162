import { Link, Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import { motion } from 'framer-motion'

import './AuthForm.css';
import AuthFromInside from './AuthFromInside/AuthFromInside';
import { AUTH_SIGNUP_TYPE, AUTH_LOGIN_TYPE, AUTH_RECOVERY_TYPE, AUTH_RECOVERY_STEP_2_TYPE, AUTH_RESET_PASSWORD_TYPE, AUTH_LOGIN_LINK, AUTH_SIGNUP_WITH_SHOP_TYPE, PLATFORM_MAIN_LINK } from '../../../assets/utils/constants';
import { BgShape, LogoIcon } from '../../../assets/icons/auth/auth';

import login_placeholder_bg from '../../../assets/images/auth/login.webp'
import signup_placeholder_bg from '../../../assets/images/auth/signup.webp'
import recovery_placeholder_bg from '../../../assets/images/auth/recovery.webp'
import reset_password_placeholder_bg from '../../../assets/images/auth/reset-password.webp'
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { UserContext } from '../../../assets/contexts/userContext';



function AuthForm() {
    const { width, height } = useWindowSize();
    const { user, setUser } = useContext(UserContext)
    const [searchParams] = useSearchParams()
    const email_in_processing = searchParams.get('email_in_processing')

    const location = useLocation();

    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransistionStage] = useState("fade-in");

    useEffect(() => {
        if (location !== displayLocation) setTransistionStage("fade-out");
    }, [location, displayLocation]);

    const [contentHeight, setContentHeight] = useState('auto'); // Initialize with 'auto'

    // Function to dynamically update content height
    const updateContentHeight = (newHeight) => {
        setContentHeight(newHeight);
    };

    return (
        <motion.div
            className='auth-form'
            initial={{ opacity: 0, height: contentHeight }}
            animate={{ opacity: 1, height: contentHeight }}
            exit={{ opacity: 0, height: contentHeight }}
            transition={{ duration: 0.2 }}
            style={{ minHeight: width <= 650 && height }}
        >
            <div className={`auth-form__placeholder`}>
                <Link to={`${AUTH_LOGIN_LINK}`}>
                    <LogoIcon mainClassName={'auth-form__placeholder-logo'} />
                </Link>
                <div
                    className={`auth-form__placeholder-content auth-form__placeholder-content_${transitionStage}`}
                    onAnimationEnd={() => {
                        if (transitionStage === "fade-out") {
                            setTransistionStage("fade-in");
                            setDisplayLocation(location);
                        }
                    }}>
                    <Routes location={displayLocation}>
                        <Route path={`/${AUTH_SIGNUP_TYPE}`} element={
                            <>
                                <img className='auth-form__placeholder-bg auth-form__placeholder-bg_type_signup' src={signup_placeholder_bg} alt='' />
                                <BgShape
                                    mainClassName={'auth-form__placeholder-bg-shape'}
                                    fillClassName={'auth-form__placeholder-bg-shape-fill'}
                                />
                            </>
                        } ></Route>
                        <Route path={`/${AUTH_SIGNUP_WITH_SHOP_TYPE}`} element={
                            <>
                                <img className='auth-form__placeholder-bg auth-form__placeholder-bg_type_signup' src={signup_placeholder_bg} alt='' />
                                <BgShape
                                    mainClassName={'auth-form__placeholder-bg-shape'}
                                    fillClassName={'auth-form__placeholder-bg-shape-fill'}
                                />
                            </>
                        } ></Route>
                        <Route path={`/${AUTH_LOGIN_TYPE}`} element={
                            <>
                                <img className='auth-form__placeholder-bg auth-form__placeholder-bg_type_login' src={login_placeholder_bg} alt='' />
                                <BgShape
                                    mainClassName={'auth-form__placeholder-bg-shape'}
                                    fillClassName={'auth-form__placeholder-bg-shape-fill'}
                                />
                            </>
                        } ></Route>
                        <Route path={`/${AUTH_RECOVERY_TYPE}`} element={
                            <>
                                <img className='auth-form__placeholder-bg auth-form__placeholder-bg_type_recovery' src={recovery_placeholder_bg} alt='' />
                                <BgShape
                                    mainClassName={'auth-form__placeholder-bg-shape'}
                                    fillClassName={'auth-form__placeholder-bg-shape-fill'}
                                />
                            </>
                        }></Route>
                        <Route path={`/${AUTH_RECOVERY_STEP_2_TYPE}`} element={
                            <>
                                <img className='auth-form__placeholder-bg auth-form__placeholder-bg_type_recovery' src={recovery_placeholder_bg} alt='' />
                                <BgShape
                                    mainClassName={'auth-form__placeholder-bg-shape'}
                                    fillClassName={'auth-form__placeholder-bg-shape-fill'}
                                />
                            </>
                        }></Route>
                        <Route path={`/${AUTH_RESET_PASSWORD_TYPE}`} element={
                            <>
                                <img className='auth-form__placeholder-bg auth-form__placeholder-bg_type_reset-password' src={reset_password_placeholder_bg} alt='' />
                                <BgShape
                                    mainClassName={'auth-form__placeholder-bg-shape'}
                                    fillClassName={'auth-form__placeholder-bg-shape-fill'}
                                />
                            </>
                        }></Route>
                    </Routes>

                </div>

            </div>
            <div className='auth-form__inside'>
                <Routes>
                    <Route path={`/${AUTH_SIGNUP_TYPE}`} element={
                        user ?
                            <Navigate to={PLATFORM_MAIN_LINK} />
                            :
                            <AuthFromInside
                                setUser={setUser}
                                title={'Регистрация'}
                                type={AUTH_SIGNUP_TYPE}
                                updateContentHeight={updateContentHeight}
                            />}>
                    </Route>
                    <Route path={`/${AUTH_SIGNUP_WITH_SHOP_TYPE}`} element={
                        user && user.register_stage === 1 && user.email_verified === true ?
                            <AuthFromInside
                                setUser={setUser}
                                title={'Регистрация'}
                                type={AUTH_SIGNUP_WITH_SHOP_TYPE}
                                updateContentHeight={updateContentHeight}
                            />
                            :
                            <Navigate to={AUTH_LOGIN_LINK} />
                    }>
                    </Route>
                    <Route path={`/${AUTH_LOGIN_TYPE}`} element={
                        user ?
                            <Navigate to={PLATFORM_MAIN_LINK} />
                            :
                            <AuthFromInside
                                setUser={setUser}
                                title={'Вход'}
                                type={AUTH_LOGIN_TYPE}
                                updateContentHeight={updateContentHeight}
                            />}>
                    </Route>
                    <Route path={`/${AUTH_RECOVERY_TYPE}`} element={
                        user ?
                            <Navigate to={PLATFORM_MAIN_LINK} />
                            :
                            <AuthFromInside
                                setUser={setUser}
                                title={'Восстановление пароля'}
                                subtitle={'Введите адрес электронной почты, который вы указали при регистрации.'}
                                type={AUTH_RECOVERY_TYPE}
                                updateContentHeight={updateContentHeight}
                            />}>
                    </Route>
                    <Route path={`/${AUTH_RECOVERY_STEP_2_TYPE}`} element={
                        user ?
                            <Navigate to={PLATFORM_MAIN_LINK} />
                            :
                            <AuthFromInside
                                setUser={setUser}
                                title={'Проверьте почту'}
                                subtitle={`Мы отправили ссылку для восстановления пароля на ${email_in_processing}`}
                                type={AUTH_RECOVERY_TYPE}
                                updateContentHeight={updateContentHeight}
                            />}>
                    </Route>
                    <Route path={`/${AUTH_RESET_PASSWORD_TYPE}`} element={<AuthFromInside
                        setUser={setUser}
                        title={'Введите новый пароль'}
                        type={AUTH_RESET_PASSWORD_TYPE}
                        updateContentHeight={updateContentHeight}
                    />}>
                    </Route>
                    <Route path={`/*`} element={
                        user ?
                            <Navigate to={PLATFORM_MAIN_LINK} />
                            :
                            <AuthFromInside
                                setUser={setUser}
                                title={'Вход'}
                                type={AUTH_LOGIN_TYPE}
                                updateContentHeight={updateContentHeight}
                            />}>
                    </Route>
                </Routes>
            </div>
        </motion.div>
    );
}

export default AuthForm