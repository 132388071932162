import mainApi from "../../../../../../../assets/api/MainApi";

export const fetchItem = (shopId, id) => {
    return mainApi.getFooterLink({
        shop_id: shopId,
        _id: id,
    });
};

export const createItem = (shopId, formData) => {
    return mainApi.createFooterLink({
        shop_id: shopId,
        ...formData,
    });
};

export const editItem = (shopId, formData) => {
    return mainApi.editFooterLink({
        shop_id: shopId,
        ...formData,
    });
};