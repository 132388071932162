import { useContext, useEffect, useState } from "react";
import "./KitMain.css";
import PageHeading from "../../PageHeading/PageHeading";
import ItemForm from "../../ItemForm/ItemForm";
import ConstructorBlock from "../ConstructorBlock/ConstructorBlock";
import ImagesInput from "../../ImagesInput/ImagesInput";
import PlatformInput from "../../PlatformInput/PlatformInput";
import FormControls from "../../FormControls/FormControls";
import { getRandomId, parseApiError } from "../../../../assets/utils/utils";
import {
  BLOCK_TYPES,
  MENU_TYPES,
} from "../../../../assets/utils/constructorConstants";
import { DEFAULT_MAX_IMG_SIZE } from "../../../../assets/utils/constants";
import { AddIcon } from "../../../../assets/icons/controls/controls";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import mainApi from "../../../../assets/api/MainApi";
import useAutoDismissError from "../../../../assets/hooks/useAutoDismissError";
import { UserContext } from "../../../../assets/contexts/userContext";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { BLOCK_EXEPTION_TYPES, parseType } from "../../../../assets/utils/constructor_blocks_constants";

const initialValuesObj = {
  logo: [],
  icon: [],
  // menu: [],
  blocks: [],
};

const initialValuesValidity = {
  logo: {
    errorMessage: "",
    validState: false,
  },
  icon: {
    errorMessage: "",
    validState: false,
  },
};

function KitMain() {
  const { user } = useContext(UserContext)
  const [blocks, setBlocks] = useState(null)
  const [values, setValues] = useState(initialValuesObj);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const [preloaders, setPreloaders] = useState({
    main: true,
    blocks: true,
    save: false,
  })
  const actions = [
    {
      name: "Отменить все изменения",
      onClick: handleCancel,
      // inactive: !isValuesChanged,
      id: "kit-main__cancel-btn",
    },
    {
      name: "Сохранить",
      onClick: handleSave,
      isMainAction: true,
      // inactive: isSubmitDisabled,
      id: "kit-main__save-btn",
      isPreloaderVisible: preloaders.save,
    },
  ];

  useEffect(() => {
    if (!user) return
    setPreloaders(prevValue => ({
      ...prevValue,
      main: true,
      blocks: true,
    }))
    mainApi.getConstructorMainPageBlocks({
      shop_id: user.default_shop._id,
    })
      .then((res) => {
        console.log(res)
        setBlocks(res.data)
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setBlocks(null)
        }

        console.log(err)
      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          blocks: false,
        }))
      })
    mainApi.getConstructorMainPage({
      shop_id: user.default_shop._id,
    })
      .then((res) => {
        console.log(res)
        setValues({
          logo: res.logo ? [res.logo] : [],
          icon: res.icon ? [res.icon] : [],
          blocks: res.blocks.map((item) => {
            return {
              value: item,
              _id: getRandomId(),
              type: 'blocks',
              title: "",
            }
          }),
          initial_logo: res.logo ? [res.logo] : [],
          initial_icon: res.icon ? [res.icon] : [],
          initial_blocks: res.blocks,
        })
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setValues(initialValuesObj)
        }
        console.log(err)
      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          main: false,
        }))
      })
  }, [user])

  useEffect(() => {
    if (!values) return;

    const isChanged = Object.values(values).some((item) => item.length > 0);
    setIsValuesChanged(isChanged);

    const isDisabled = Object.values(values).some((item) => item.length === 0);
    setIsSubmitDisabled(isDisabled && !valuesValidity.logo.validState);
  }, [values, valuesValidity]);

  function handleChange(e) {
    const input = e.target;
    const value = input.value;
    const name = input.name;

    switch (name) {
      case "logo": {
        const file = input.files[0];
        handleFilesChange(file, name);
        break;
      }

      case "logo-delete": {
        setValues((prevValue) => ({
          ...prevValue,
          logo: [],
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          logo: {
            errorMessage: "",
            validState: false,
          },
        }));
        break;
      }

      case "icon": {
        const file = input.files[0];
        handleFilesChange(file, name);
        break;
      }

      case "icon-delete": {
        setValues((prevValue) => ({
          ...prevValue,
          icon: [],
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          icon: {
            errorMessage: "",
            validState: false,
          },
        }));
        break;
      }

      case "value-add": {
        const type = input.type;
        const values_list = input.values_list;
        console.log(
          {
            _id: getRandomId(),
            type: type,
            title: "",
            value: values_list.filter((item, i) => BLOCK_EXEPTION_TYPES.indexOf(item.type))[0],
          },
        )
        setValues((prevValue) => ({
          ...prevValue,
          [type]: prevValue[type].concat([
            {
              _id: getRandomId(),
              type: type,
              title: "",
              value: values_list.filter((item, i) => BLOCK_EXEPTION_TYPES.indexOf(item.type))[0],
            },
          ]),
        }));
        break;
      }

      case "value-sort-down": {
        const index = input.index;
        const type = input.type;
        setValues((prevValue) => {
          const newValues = [...prevValue[type]];
          [newValues[index], newValues[index + 1]] = [
            newValues[index + 1],
            newValues[index],
          ];
          return { ...prevValue, [type]: newValues };
        });
        break;
      }

      case "value-sort-up": {
        const index = input.index;
        const type = input.type;
        setValues((prevValue) => {
          const newValues = [...prevValue[type]];
          [newValues[index], newValues[index - 1]] = [
            newValues[index - 1],
            newValues[index],
          ];
          return { ...prevValue, [type]: newValues };
        });
        break;
      }

      case "value-delete": {
        const _id = input.value_id;
        const type = input.type;
        setValues((prevValue) => ({
          ...prevValue,
          [type]: prevValue[type].filter((item) => item._id !== _id),
        }));
        break;
      }

      default:
        const _id = input.value_id;
        setValues((prevValue) => ({
          ...prevValue,
          [name]: prevValue[name].map((item) => {
            if (item._id !== _id) return item;
            return {
              ...item,
              value: value,
            };
          }),
        }));
        break;
    }
  }
  const [error, showError] = useAutoDismissError()
  function handleFilesChange(file, name) {
    if (file && file.size / 1000000 >= DEFAULT_MAX_IMG_SIZE) {
      setValuesValidity((prevValue) => ({
        ...prevValue,
        [name]: { ...prevValue[name], errorMessage: "", validState: false },
      }));
      return;
    }

    let data = new FormData();
    data.append("image", file);
    // setIsPhotoLoading(true);
    mainApi
      .uploadImage({ data, type: name + 's' })
      .then((res) => {
        setValues((prevValue) => ({
          ...prevValue,
          [name]: [res],
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: { errorMessage: "", validState: true, isChanged: true },
        }));
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        // setIsPhotoLoading(false);
      });
  }


  function handleSave() {
    console.log("save", values);
    setPreloaders(prevValue => ({
      ...prevValue,
      save: true,
    }))
    mainApi.setConstructorMainPage({
      shop_id: user.default_shop._id,
      logo: values.logo && values.logo.length > 0 ? values.logo[0]._id : null,
      icon: values.icon && values.icon.length > 0 ? values.icon[0]._id : null,
      blocks: values.blocks && values.blocks.length > 0 ? values.blocks.map((item) => item.value._id) : []
    })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        showError(parseApiError(err))
      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          save: false,
        }))
      })
  }

  function handleCancel() {
    console.log("cancel");
    setValues(prevValue => ({
      ...prevValue,
      logo: prevValue.initial_logo,
      icon: prevValue.initial_icon,
      blocks: prevValue.initial_blocks,
    }));
    setValuesValidity(initialValuesValidity);
  }

  return (
    <div className="kit-main">
      <PageHeading
        className={"kit-main__heading"}
        title={"Главная страница"}
        actions={actions}
      />
      <ErrorMessage error={error} />
      <ItemForm containerClassName={"kit-main__container"}>
        <form className="kit-main__form" onSubmit={handleSave}>
          <ConstructorBlock>
            <ImagesInput
              labelClassName={"kit-main__img-input-label"}
              name={"logo"}
              label={"Логотип"}
              hint={`154 × 40 px, не более ${DEFAULT_MAX_IMG_SIZE} Мб`}
              addBtnText={"Изменить..."}
              images={values.logo}
              isHorizontal
              onAdd={handleChange}
              onRemove={() => {
                handleChange({
                  target: {
                    value: null,
                    name: "logo-delete",
                  },
                });
              }}
            />
            <ImagesInput
              labelClassName={"kit-main__img-input-label"}
              name={"icon"}
              label={"Иконка"}
              hint={`785 × 785 px, не более ${DEFAULT_MAX_IMG_SIZE} Мб`}
              addBtnText={"Изменить..."}
              images={values.icon}
              isHorizontal
              onAdd={handleChange}
              onRemove={() => {
                handleChange({
                  target: {
                    value: null,
                    name: "icon-delete",
                  },
                });
              }}
            />
            {/* <ConstructorBlock
              title={"Тип меню"}
              mainClassName={"kit-main__constructor-block"}
            >
              {values?.menu?.length > 0
                ? values.menu.map((item, i) => (
                  <div className="kit-main__block-container" key={item._id}>
                    <PlatformInput
                      name={`${item.type}-${item._id}`}
                      value={item.value}
                      placeholder={""}
                      label={""}
                      isSelect
                      selected_text_value={item.value.title}
                      handleChange={(e) => {
                        handleChange({
                          target: {
                            value: e.target.value,
                            name: item.type,
                            value_id: item._id,
                          },
                        });
                      }}
                      selectList={MENU_TYPES}
                      selectDifferenceKey={"value"}
                      selectTextValueKey={"title"}
                    />

                    <FormControls
                      index={i}
                      lastIndex={values.menu.length - 1}
                      sortDownTarget={{
                        name: "value-sort-down",
                        value: null,
                        index: i,
                        type: item.type,
                      }}
                      sortUpTarget={{
                        name: "value-sort-up",
                        value: null,
                        index: i,
                        type: item.type,
                      }}
                      deleteTarget={{
                        name: "value-delete",
                        value: null,
                        type: item.type,
                        value_id: item._id,
                      }}
                      handleChange={handleChange}
                      isDeleteType
                    />
                  </div>
                ))
                : null}

              {values.menu.length <= 2 ? (
                <button
                  className="kit-main__add-btn"
                  type="button"
                  onClick={() => {
                    handleChange({
                      target: {
                        value: null,
                        name: "value-add",
                        type: "menu",
                        values_list: MENU_TYPES,
                      },
                    });
                  }}
                >
                  <AddIcon
                    mainClassName={"kit-main__add-btn-icon"}
                    fillClassName={"kit-main__add-btn-icon-fill"}
                  />
                  Добавить позицию
                </button>
              ) : null}
            </ConstructorBlock> */}
          </ConstructorBlock>

          <div className="constructor-block__separator-line" />

          <ConstructorBlock title={"Блоки"}>
            <LayoutGroup>
              <AnimatePresence>


                {values?.blocks?.length > 0
                  ? values.blocks.map((item, i) => (
                    <motion.div
                      className="kit-main__block-container"
                      key={item._id}
                      layout
                      initial={{ opacity: 0, y: 0 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 0 }}
                      transition={{ duration: 0.2 }}>
                      <PlatformInput
                        name={`${item.type}-${item._id}`}
                        value={item.value}
                        placeholder={""}
                        label={""}
                        isSelect
                        readOnly={BLOCK_EXEPTION_TYPES.indexOf(item.value.type) >= 0}
                        selected_text_value={item.value.type}
                        handleChange={(e) => {
                          handleChange({
                            target: {
                              value: e.target.value,
                              name: item.type,
                              value_id: item._id,
                            },
                          });
                        }}
                        selectList={blocks.filter((item, i) => BLOCK_EXEPTION_TYPES.indexOf(item.type)).sort((a, b) => {
                          const aType = parseType(a.type);
                          const bType = parseType(b.type);

                          // First, sort by base type
                          if (aType.baseType < bType.baseType) return -1;
                          if (aType.baseType > bType.baseType) return 1;

                          // Then, by line count, if applicable
                          if (aType.lineCount !== undefined && bType.lineCount !== undefined) {
                            return aType.lineCount - bType.lineCount;
                          }

                          // Then, sort non-line types to be before line types
                          if (aType.lineCount === undefined && bType.lineCount !== undefined) return -1;
                          if (aType.lineCount !== undefined && bType.lineCount === undefined) return 1;

                          // Finally, place reverse types immediately after their counterparts
                          if (!aType.isReverse && bType.isReverse) return -1;
                          if (aType.isReverse && !bType.isReverse) return 1;

                          return 0;
                        })}
                        selectDifferenceKey={"type"}
                        selectTextValueKey={"type"}
                      />

                      <FormControls
                        index={i}
                        lastIndex={values.blocks.length - 1}
                        sortDownTarget={{
                          name: "value-sort-down",
                          value: null,
                          index: i,
                          type: item.type,
                        }}
                        sortUpTarget={{
                          name: "value-sort-up",
                          value: null,
                          index: i,
                          type: item.type,
                        }}
                        deleteTarget={{
                          name: "value-delete",
                          value: null,
                          type: item.type,
                          value_id: item._id,
                        }}
                        readOnly={BLOCK_EXEPTION_TYPES.indexOf(item.value.type) >= 0}
                        handleChange={handleChange}
                        isDeleteType
                      />
                    </motion.div>
                  ))
                  : null}
              </AnimatePresence>
            </LayoutGroup>
            {values.blocks.length <= 10 ? (
              <button
                className="kit-main__add-btn"
                type="button"
                onClick={() => {
                  handleChange({
                    target: {
                      value: null,
                      name: "value-add",
                      type: "blocks",
                      values_list: blocks,
                    },
                  });
                }}
              >
                <AddIcon
                  mainClassName={"kit-main__add-btn-icon"}
                  fillClassName={"kit-main__add-btn-icon-fill"}
                />
                Добавить блок
              </button>
            ) : null}
          </ConstructorBlock>
        </form>
      </ItemForm>
    </div>
  );
}

export default KitMain;
