import './CustomTooltip.css';

function CustomTooltip({
  title,
  value,
}) {
  return (
    <div className='custom-tooltip'>
      <p className='custom-tooltip__title'>{title}</p>
      <p className='custom-tooltip__value'>{value}</p>
    </div>
  );
}

export default CustomTooltip