import { DEFAULT_MAX_IMG_SIZE } from '../../../../../../../../../assets/utils/constants';
import ImagesInput from '../../../../../../../ImagesInput/ImagesInput';
import PlatformInput from '../../../../../../../PlatformInput/PlatformInput';
import './PromoTextPicture.css';

function PromoTextPicture({ item, handleChange }) {
    return (
        <div className='promo-text-picture'>
            <PlatformInput
                label={'Заголовок'}
                value={item.data[0].title}
                handleChange={(e) => {
                    handleChange({
                        target: {
                            index: 0,
                            value: e.target.value,
                            item: item,
                            name: 'promo-text-image',
                            subname: 'title',
                        }
                    })
                }}
                isTextArea={true}
                minRows={1}
                maxRows={5}
                type={'text'}
                name={''}
            />
            <PlatformInput
                label={'Текст'}
                value={item.data[0].text}
                handleChange={(e) => {
                    handleChange({
                        target: {
                            index: 0,
                            value: e.target.value,
                            item: item,
                            name: 'promo-text-image',
                            subname: 'text',
                        }
                    })
                }}
                isTextArea={true}
                minRows={1}
                maxRows={5}
                type={'text'}
                name={''}
            />
            {/* <p>image</p> */}
            <ImagesInput
                name={"image"}
                label={"Изображение"}
                hint={`Не более ${DEFAULT_MAX_IMG_SIZE} Мб`}
                addBtnText={"Добавить..."}
                isMultiple={false}
                images={item.data[0].image}
                additionalKey={`${item._id}_promo-text-picture`}
                onRemove={(e) => {
                    handleChange({
                        target: {
                            index: 0,
                            value: e.target.index,
                            item: item,
                            name: 'promo-text-image',
                            subname: 'image-delete',
                        }
                    })
                }}
                onAdd={(e) => {
                    handleChange({
                        target: {
                            index: 0,
                            ...e.target,
                            item: item,
                            name: 'promo-text-image',
                            subname: 'image',
                            files: e.target.files,
                        }
                    })
                }}
            />
            <PlatformInput
                label={'Ссылка'}
                value={item.data[0].link}
                handleChange={(e) => {
                    handleChange({
                        target: {
                            index: 0,
                            value: e.target.value,
                            item: item,
                            name: 'promo-text-image',
                            subname: 'link',
                        }
                    })
                }}
                type={'text'}
                name={''}
            />
            <PlatformInput
                label={'Текст Кнопки'}
                value={item.data[0].btn_text}
                handleChange={(e) => {
                    handleChange({
                        target: {
                            index: 0,
                            value: e.target.value,
                            item: item,
                            name: 'promo-text-image',
                            subname: 'btn_text',
                        }
                    })
                }}
                type={'text'}
                name={''}
            />
        </div>
    );
}

export default PromoTextPicture