import "./ConstructorBlock.css";

function ConstructorBlock({
  title,
  children,
  mainClassName,
  titleClassName,
  containerClassName,
}) {
  return (
    <div className={`constructor-block ${mainClassName ? mainClassName : ""}`}>
      {title ? (
        <p
          className={`constructor-block__title ${
            titleClassName ? titleClassName : ""
          }`}
        >
          {title}
        </p>
      ) : null}
      <div
        className={`constructor-block__container ${
          containerClassName ? containerClassName : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
}

export default ConstructorBlock;
