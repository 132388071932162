import { useContext, useEffect, useState } from 'react';
import './HomePageMain.css';
import WalkThrough from './WalkThrough/WalkThrough';
import { UserContext } from '../../../../assets/contexts/userContext';
import mainApi from '../../../../assets/api/MainApi';
import PreloaderBox from '../../../PreloaderBox/PreloaderBox';
import { Link } from 'react-router-dom';
import { CATALOG_DRAFTS_CREATE_ITEM_LINK, CATALOG_DRAFTS_LINK, CATALOG_MAIN_LINK, EXEPRION_SHOP_ICONS_IDS, FULL_STAT_RELOAD_TIMEOUT, ONLINE_PING_TIMEOUT, ORDERS_MAIN_LINK, PLATFORM_MAIN_LINK, SHOP_DOMAIN, STATISTICS_MAIN_LINK } from '../../../../assets/utils/constants';
import Graph from '../../Statistics/StatisticsMain/GraphContainer/Graph/Graph';
import moment from 'moment-timezone';
import AvatarBox from '../../../AvatarBox/AvatarBox';
import { StringOrNothingFormater, copyToClipboard, getBasketWordForm, getOrderWordForm, getShopLink, hasPermission } from '../../../../assets/utils/utils';
import { LinkIcon } from '../../../../assets/icons/controls/controls';
import { PERMISSIONS, RBAC_STATISTIC } from '../../../../assets/utils/permissions_rbac';

function HomePageMain() {

    const { user } = useContext(UserContext)
    // const [data, setData] = useState(null)
    // useEffect(() => {
    //     mainApi.getShopInfo({
    //         shop_id: user.default_shop._id,
    //     })
    //         .then((res) => {
    //             console.log(res)
    //             setData(res)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }, [])
    const { default_shop } = user
    const {
        ai_id,
        domain,
        icon,
        info,
        logo,
        role,
        subscriptions,
    } = default_shop

    const shop_link = getShopLink({ domain_info: domain })
    const [isCopied, setCopied] = useState(false)
    const [statistics, setStatistics] = useState(null)
    const [preloaders, setPreloaders] = useState({
        statistics: true,
    })
    useEffect(() => {
        if (!default_shop._id) return
        setPreloaders(prevValue => ({
            ...prevValue,
            statistics: true,
        }))
        mainApi.getMainPageStatistics({
            shop_id: default_shop._id,
        })
            .then((res) => {
                setStatistics(res)
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    statistics: false,
                }))
            })
    }, [default_shop])

    useEffect(() => {
        if (!default_shop) return;

        // Function to fetch statistics from the API
        const fetchOnlineStatistics = () => {
            mainApi.getOnlineStatistics({
                shop_id: default_shop._id,
            })
                .then((res) => {
                    console.log(res);
                    if (!statistics) return
                    setStatistics(prevValue => ({
                        ...prevValue,
                        online: {
                            ...prevValue.online,
                            data: {
                                ...prevValue.online.data,
                                '15min': res.current,
                            },
                        },
                    }));
                })
                .catch((err) => {
                    console.error(err);
                });
        };

        // Fetch statistics immediately
        fetchOnlineStatistics();

        // Set an interval to fetch statistics every 60 seconds
        const interval = setInterval(fetchOnlineStatistics, ONLINE_PING_TIMEOUT);

        // Clear interval on component unmount or when default_shop changes
        return () => clearInterval(interval);

    }, [default_shop]); // Depend on default_shop to refetch if it changes


    useEffect(() => {
        if (!default_shop._id) return;

        let timer;
        const fetchFullStatistics = () => {
            mainApi.getMainPageStatistics({
                shop_id: default_shop._id,
            })
                .then((res) => {
                    setStatistics(res)
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                });
        };

        // Function to start/restart the timer
        const startTimer = () => {
            // Clear existing timer
            if (timer) {
                clearTimeout(timer);
            }

            // Set a new timer
            timer = setTimeout(() => {
                fetchFullStatistics();
                // Once the timer runs out, set an interval
                timer = setInterval(fetchFullStatistics, FULL_STAT_RELOAD_TIMEOUT);
            }, FULL_STAT_RELOAD_TIMEOUT); // Start with a delay
        };

        // Initial start
        startTimer();


        return () => {
            clearTimeout(timer);
            clearInterval(timer);
        };
    }, [default_shop]);


    const HAS_ONLINE_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ONLINE]]) : false
    const HAS_ORDERS_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ORDERS]]) : false
    const HAS_ADD_TO_CART_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ADD_TO_CART]]) : false
    const HAS_ITEM_CREATE_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ITEM_CREATE]]) : false
    const HAS_ITEM_SALE_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ITEM_SALE]]) : false
    const HAS_COUPON_CODES_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_COUPON_CODES]]) : false
    const HAS_ITEM_TOTAL_PERMISSION = user ? hasPermission({
        ...user.default_shop.role.permissions,
        // allowed_stats: user.default_shop.role.allowed_stats,
    }, [RBAC_STATISTIC[PERMISSIONS.STATISTIC_ITEM_TOTAL]]) : false

    return (
        <div className='home-page-main'>
            <WalkThrough />
            {default_shop && !preloaders.statistics && statistics ?
                <div className='home-page-main__content'>
                    <div className='home-page-main__heading'>
                        <div className='home-page-main__shop'>
                            {/* <h2 className='home-page-main__shop-name'>{data.info.name}</h2>
                            <p className='home-page-main__shop-id'>ID: {data.ai_id}</p>
                            <a className='home-page-main__shop-link' href={`https://${SHOP_DOMAIN}/${initial_path}`} target='_blank' rel='noreferrer'>{SHOP_DOMAIN}/{initial_path}</a> */}
                            <AvatarBox className='home-page-main__shop-icon' name={info.name} img={icon ? EXEPRION_SHOP_ICONS_IDS.indexOf(icon._id) < 0 ? icon : null : null} />
                            <div className='home-page-main__shop-info'>
                                <h2 className='home-page-main__shop-name'>{info.name}</h2>
                                <div className='home-page-main__shop-additional-infos'>
                                    <p className='home-page-main__shop-id-and-role'>ID: {ai_id} • {role.title.ru}</p>
                                    <a className='home-page-main__shop-link' href={shop_link} target='_blank' rel='noreferrer'>
                                        <p className='home-page-main__shop-link-text'>В магазин</p>
                                        <button className={`home-page-main__shop-link-icon-box ${isCopied ? 'home-page-main__shop-link-icon-box_copied' : ''}`} type='button' onClick={(e) => {
                                            if (isCopied) return
                                            e.preventDefault()
                                            e.stopPropagation()
                                            copyToClipboard(shop_link)
                                            setCopied(true)

                                            setTimeout(() => {
                                                setCopied(false)
                                            }, 1000);
                                        }}>
                                            <LinkIcon
                                                mainClassName={'home-page-main__shop-link-icon'}
                                                strokeClassName={'home-page-main__shop-link-icon-stroke'}
                                            />
                                        </button>
                                    </a>
                                </div>
                            </div>

                        </div>
                        {/* <div className='home-page-main__info-contaienr'>
                            <h4 className='home-page-main__balance-title'>Баланс</h4>
                            <p className='home-page-main__balance-value'>0 ₽</p>
                        </div> */}
                    </div>
                    <div className='home-page-main__infos'>
                        {HAS_ONLINE_PERMISSION && statistics.online ?
                            <div className='home-page-main__info-contaienr'>
                                <div className='home-page-main__info-heading'>
                                    <p className='home-page-main__info-title home-page-main__info-title_stats'>Статистика</p>
                                    <Link className='home-page-main__info-link' to={`${PLATFORM_MAIN_LINK}/${STATISTICS_MAIN_LINK}`}>
                                        Показать все
                                    </Link>
                                </div>
                                <div className='home-page-main__online'>
                                    <p className='home-page-main__online-count'>{statistics.online.data['15min']}</p>
                                    <p className='home-page-main__online-title'>сейчас онлайн</p>
                                </div>
                                <Graph
                                    data={statistics.online.graph.map((item) => {
                                        return {
                                            ...item,
                                            name: item.utc_datetime,
                                        }
                                    })}
                                />
                                <p className='home-page-main__online-per-days-main-title'>Всего уникальных пользователей:</p>
                                <div className='home-page-main__online-per-days'>

                                    <div className='home-page-main__online-per-days-iten'>
                                        <p className='home-page-main__online-per-days-title'>Сегодня</p>
                                        <p className='home-page-main__online-per-days-value'>{statistics.online.data['1day']}</p>
                                    </div>
                                    <div className='home-page-main__online-per-days-iten'>
                                        <p className='home-page-main__online-per-days-title'>За 7 дней</p>
                                        <p className='home-page-main__online-per-days-value'>{statistics.online.data['7days']}</p>
                                    </div>
                                    <div className='home-page-main__online-per-days-iten'>
                                        <p className='home-page-main__online-per-days-title'>За 30 дней</p>
                                        <p className='home-page-main__online-per-days-value'>{statistics.online.data['30days']}</p>
                                    </div>
                                </div>
                            </div>
                            : null}

                        <div className='home-page-main__infos-column'>
                            {HAS_ORDERS_PERMISSION && statistics.orders !== null ?
                                <div className='home-page-main__info-contaienr'>
                                    <div className='home-page-main__info-heading'>
                                        <p className='home-page-main__info-title home-page-main__info-title_orders'>Новых</p>
                                        <Link className='home-page-main__info-link' to={`${PLATFORM_MAIN_LINK}/${ORDERS_MAIN_LINK}`}>
                                            Показать
                                        </Link>
                                    </div>
                                    <p className='home-page-main__info-parameter'>{statistics.orders.new} {getOrderWordForm(statistics.orders.new)}</p>
                                </div>
                                : null
                            }
                            {HAS_ORDERS_PERMISSION && statistics.orders !== null ?
                                <div className='home-page-main__info-contaienr'>
                                    <div className='home-page-main__info-heading'>
                                        <p className='home-page-main__info-title home-page-main__info-title_orders'>Завершено</p>
                                        <Link className='home-page-main__info-link' to={`${PLATFORM_MAIN_LINK}/${ORDERS_MAIN_LINK}`}>
                                            Показать
                                        </Link>
                                    </div>
                                    <p className='home-page-main__info-parameter'>{statistics.orders.done} {getOrderWordForm(statistics.orders.done)}</p>
                                </div>
                                : null
                            }
                            {HAS_ADD_TO_CART_PERMISSION && statistics.unfinished_carts !== null ? <div className='home-page-main__info-contaienr'>
                                <div className='home-page-main__info-heading'>
                                    <p className='home-page-main__info-title home-page-main__info-title_orders'>Незавершенные</p>
                                    {/* <Link className='home-page-main__info-link' to={`${PLATFORM_MAIN_LINK}/${ORDERS_MAIN_LINK}`}>
                                        Показать
                                    </Link> */}
                                </div>
                                <p className='home-page-main__info-parameter'>{statistics.unfinished_carts} {getBasketWordForm(statistics.unfinished_carts)}</p>
                            </div>
                                : null
                            }
                        </div>
                        <div className='home-page-main__infos-column'>
                            <div className='home-page-main__info-contaienr'>
                                <div className='home-page-main__info-heading'>
                                    <p className='home-page-main__info-title home-page-main__info-title_products'>Товары</p>
                                    <Link className='home-page-main__info-link' to={`${PLATFORM_MAIN_LINK}/${CATALOG_MAIN_LINK}/${CATALOG_DRAFTS_LINK}/${CATALOG_DRAFTS_CREATE_ITEM_LINK}`}>
                                        Добавить
                                    </Link>
                                </div>
                                <div className='home-page-main__online-per-days'>
                                    {HAS_ITEM_TOTAL_PERMISSION && statistics.items_total !== null ?
                                        <div className='home-page-main__online-per-days-iten'>
                                            <p className='home-page-main__online-per-days-title'>В каталоге</p>
                                            <p className='home-page-main__online-per-days-value'>{statistics.items_total}</p>
                                        </div>
                                        : null
                                    }
                                    {HAS_ITEM_CREATE_PERMISSION && statistics.items_create !== null ?
                                        <div className='home-page-main__online-per-days-iten'>
                                            <p className='home-page-main__online-per-days-title'>Добавлено за сутки</p>
                                            <p className='home-page-main__online-per-days-value'>{statistics.items_create}</p>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                            {HAS_ITEM_SALE_PERMISSION && statistics.top_sales ?
                                // <div className='home-page-main__info-contaienr'>
                                //     <div className='home-page-main__info-heading'>
                                //         <p className='home-page-main__info-title home-page-main__info-title_products'>Топ продаж</p>
                                //     </div>
                                //     {statistics.top_sales && statistics.top_sales.length > 0 ?
                                //         <div className='home-page-main__info-top-sales'>
                                //             {statistics.top_sales.map((top_sale, i) => (
                                //                 <div className='home-page-main__info-top-sale' key={`home-page-main__info-top-sale_${top_sale.item._id}`}>
                                //                     {top_sale.item.files && top_sale.item.files.length !== 0 ? (
                                //                         <img
                                //                             className="home-page-main__info-top-sale-img"
                                //                             src={top_sale.item.files[0]?.urls?.orig}
                                //                             alt=""
                                //                         />
                                //                     ) : (
                                //                         <div className="home-page-main__info-top-sale-img home-page-main__info-top-sale-img-placeholder" />
                                //                     )}
                                //                     <div className='home-page-main__info-top-sale-texts'>
                                //                         <h5 className="home-page-main__info-top-sale-title">
                                //                             {StringOrNothingFormater(top_sale.item.name)}
                                //                         </h5>
                                //                         {/* <p>OPTION HERE</p> */}
                                //                     </div>

                                //                 </div>
                                //             ))}
                                //         </div>
                                //         :
                                //         <p className='home-page-main__info-hint'>У вас еще не было продаж</p>
                                //     }

                                // </div>
                                null
                                : null
                            }
                        </div>
                    </div>
                </div>
                :
                <PreloaderBox />}

        </div>
    );
}

export default HomePageMain