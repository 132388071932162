import { EditIcon } from "../../../../../assets/icons/category/category";
import { CrossIcon } from "../../../../../assets/icons/controls/controls";
import "./EditFilter.css";

function EditFilter({
    handleClick,
    isActive,
}) {


    return (
        <button className={`edit-filter`} type="button" onClick={handleClick}>
            {isActive ?
                <CrossIcon
                    mainClassName={'edit-filter__icon'}
                    strokeClassName={'edit-filter__icon-stroke'}
                />
                :
                <EditIcon
                    mainClassName="edit-filter__icon"
                    fillClassName="edit-filter__icon-fill"
                />
            }
        </button>
    );
}

export default EditFilter;
