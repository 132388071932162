import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import './Orders.css';
import { ORDERS_ITEM_LINK, ORDERS_MAIN_LINK, PLATFORM_MAIN_LINK } from '../../../assets/utils/constants';
import OrdersList from './OrdersList/OrdersList';
import OrderPage from './OrderPage/OrderPage';

function Orders() {



    return (
        <div className='orders'>
            <Routes>
                <Route index element={
                    <OrdersList />
                }>
                </Route>
                <Route path={`${ORDERS_ITEM_LINK}`} element={
                    <Navigate to={`${PLATFORM_MAIN_LINK}/${ORDERS_MAIN_LINK}`} />
                }>
                </Route>
                <Route path={`${ORDERS_ITEM_LINK}/:id/*`} element={
                    <OrderPage />
                }>
                </Route>
            </Routes>
        </div>
    );
}

export default Orders




