
import { useContext, useEffect, useState } from 'react';
import PlatformInput from '../../../PlatformInput/PlatformInput';
import ActionBtn from '../../../ActionBtn/ActionBtn';
import ServiceContainer from '../../../ServiceContainer/ServiceContainer';
import TwoFactorBtn from '../../../TwoFactorBtn/TwoFactorBtn';
import './TechnicalDomain.css';
import { DEBOUNCE_TIME, MAIN_DOMAIN, SHOP_DOMAIN } from '../../../../../assets/utils/constants';
import mainApi from '../../../../../assets/api/MainApi';
import { UserContext } from '../../../../../assets/contexts/userContext';
import MiniPreloader from '../../../../MiniPreloader/MiniPreloader';
import PreloaderBox from '../../../../PreloaderBox/PreloaderBox';

import { motion, AnimatePresence, LayoutGroup } from 'framer-motion'


function TechnicalDomain({
    domainInfo,
    setDomainInfo,
}) {


    const { user } = useContext(UserContext)


    const initial_path = domainInfo ? domainInfo.path.path ? domainInfo.path.path : domainInfo.path.default : ''
    console.log(initial_path)

    const [form, setForm] = useState({
        link: '',
        initial_link: initial_path,
    })
    useEffect(() => {
        if (!domainInfo) return
        const initial_path = domainInfo ? domainInfo.path.path ? domainInfo.path.path : domainInfo.path.default : ''
        setForm(prevValue => ({
            ...prevValue,
            initial_link: initial_path,
        }))
    }, [domainInfo])
    const [formValidity, setFormValidity] = useState({
        link: {},
    })
    function handleUpdate() {
        const path = form.link
        mainApi.editTechnicalDomainPath({
            shop_id: user.default_shop._id,
            path: path,
        })
            .then((res) => {
                console.log(res)
                setFormValidity({
                    link: {}
                })
                setForm({
                    link: '',
                    initial_link: path,
                })
                setDomainInfo(prevValue => ({
                    ...prevValue,
                    path: {
                        ...prevValue.path,
                        path: path,
                    }
                }))
            })
            .catch((err) => {
                console.log(err)
            })
    }

    function handleDisconnect() {

    }

    function handleChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name
        let inputValue = value.replace(/[^a-zA-Z0-9\-_]/g, '').toLowerCase();
        setFormValidity((prevValue) => ({
            ...prevValue,
            link: {
                errorMessage: "",
                validState: false,
            },
        }));
        setPreloaders(prevValue => ({
            ...prevValue,
            path_validation: true
        }))
        switch (name) {
            default:
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: inputValue,
                }))
                break;
        }
    }

    const [preloaders, setPreloaders] = useState({
        path_validation: false,
    })
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log(form.link)
            if (!form.link || form.link.length === 0) return
            if (!form.link) {
                setFormValidity((prevValue) => ({
                    ...prevValue,
                    link: {
                        errorMessage: "",
                        validState: false,
                    },
                }));
            }
            if (form.link.length >= 5) {
                if (form.initial_link !== form.link) {
                    setPreloaders(prevValue => ({
                        ...prevValue,
                        path_validation: true
                    }))
                    mainApi.checkTechnicalDomainPathUniqueness({
                        shop_id: user.default_shop._id,
                        path: form.link,
                    })
                        .then((res) => {
                            console.log(res)
                            setFormValidity((prevValue) => ({
                                ...prevValue,
                                link: {
                                    errorMessage: "",
                                    validState: true,
                                },
                            }));
                        })
                        .catch((err) => {
                            console.log(err)
                            setFormValidity((prevValue) => ({
                                ...prevValue,
                                link: {
                                    errorMessage: "Данный адрес занят",
                                    validState: false,
                                },
                            }));
                        })
                        .finally(() => {
                            setPreloaders(prevValue => ({
                                ...prevValue,
                                path_validation: false
                            }))
                        })
                } else {
                    setFormValidity((prevValue) => ({
                        ...prevValue,
                        link: {
                            errorMessage: "",
                            validState: false,
                        },
                    }));
                }


                // ADD LOGIC TO CHECK UNIQUNESS OF EMAIL IN CURRENT SHOP/SYSTEM

            }

        }, DEBOUNCE_TIME)

        return () => {
            clearTimeout(delayDebounceFn);
        }
    }, [form.link, form.initial_link])

    return (
        <>
            {
                domainInfo ?
                    <div className='technical-domain'>
                        < p className='technical-domain__title' > Адрес на {MAIN_DOMAIN}</p >
                        <div className='technical-domain__input-box'>
                            <p className='technical-domain__main-domain'>{MAIN_DOMAIN}/</p>
                            <PlatformInput
                                value={form.link}
                                handleChange={handleChange}
                                label={'Уникальная ссылка'}
                                type={'text'}
                                name={'link'}
                                hint={'Только латинские буквы и цифры. Мин. 5 символов'}
                                error={formValidity.link.errorMessage ? formValidity.link.errorMessage : ''}
                            />
                        </div>
                        <AnimatePresence>
                            <LayoutGroup>
                                {preloaders.path_validation ?
                                    <motion.div
                                        className='technical-domain__preloader'
                                        layout
                                    >
                                        <MiniPreloader />

                                    </motion.div>
                                    : null}
                            </LayoutGroup>

                        </AnimatePresence>

                        {
                            form.link.length >= 5 && formValidity.link.validState ?
                                <p className='technical-domain__hint'>Поздравляем, адрес доступен, сохрание изменения, чтобы ваш магазин открывался по&nbsp;адресу {SHOP_DOMAIN}/{form.link}</p>

                                :
                                <p className='technical-domain__hint'>Ваш магазин доступен по&nbsp;адресу <a className='technical-domain__hint_contrast' href={`https://${SHOP_DOMAIN}/${form.initial_link}`} target='_blank' rel='noreferrer'>{SHOP_DOMAIN}/{form.initial_link}</a>. Вы&nbsp;можете выбрать другой адрес, для этого введите его в&nbsp;строку и&nbsp;проверьте, свободен&nbsp;ли он</p>

                        }
                        <ActionBtn
                            text={'Сохранить изменения'}
                            handleAction={handleUpdate}
                            isInactive={!(form.link.length >= 5 && formValidity.link.validState && form.initial_link !== form.link)}
                        />
                    </div >
                    :
                    <PreloaderBox />
            }
        </>
    );
}

export default TechnicalDomain