import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import PageHeading from '../../PageHeading/PageHeading';
import './PagesAndFiles.css';
import { filterLinksForUserRBAC, getCurrentPlatfromLocation, getDefaultLinkForUserRBAC } from '../../../../assets/utils/utils';
import { useContext, useState } from 'react';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import ItemForm from '../../ItemForm/ItemForm';
import { PLATFORM_MAIN_LINK, SETTINGS_PAGES_AND_FILES_LINKS, } from '../../../../assets/utils/constants';


import InternalLinks from '../../InternalLinks/InternalLinks';
import { UserContext } from '../../../../assets/contexts/userContext';
import { PlatformLinksContext } from '../../../../assets/contexts/platformLinksContext';
import { ActionsContext } from '../../../../assets/contexts/actionsContext';
import { routesConfig } from './utils/routesConfig';
import { PagesAndFilesMobileRoutePage } from './utils/pagesAndFilesUtils';







function PagesAndFiles() {
    const { user } = useContext(UserContext)
    const { links, default_link } = useContext(PlatformLinksContext)

    const { width } = useWindowSize()

    const location = useLocation();

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })

    const [actions, setActions] = useState([])

    const LINKS_BY_USER_RBAC = user && user.default_shop && user.default_shop.role ? filterLinksForUserRBAC(user.default_shop.role.permissions, SETTINGS_PAGES_AND_FILES_LINKS) : null
    const DEFAULT_LINK_BY_USER_RBAC = PLATFORM_MAIN_LINK + getDefaultLinkForUserRBAC(LINKS_BY_USER_RBAC)



    return (
        <ActionsContext.Provider value={{ actions, setActions }}>
            {
                width ?
                    width > 880 ?
                        <div className='pages-and-files'>
                            <PageHeading
                                className={'pages-and-files__heading'}
                                title={current_location.location.name}
                                actions={actions}
                            />
                            <ItemForm
                                containerClassName={'pages-and-files__item-from-container'}
                            >
                                <div className='pages-and-files__two-columns'>
                                    <div className='pages-and-files__products-list'>
                                        <InternalLinks
                                            links={LINKS_BY_USER_RBAC}
                                            keyValue={'integrations__desctop'}
                                        />
                                    </div>
                                    <div className='pages-and-files__products-form'>
                                        <Routes>
                                            {routesConfig.map((route, index) => (
                                                <Route
                                                    key={index}
                                                    path={route.path}
                                                    element={route.element}
                                                />
                                            ))}
                                            <Route index element={<Navigate to={LINKS_BY_USER_RBAC[0].path} />} />
                                        </Routes>
                                    </div>

                                </div>
                            </ItemForm>
                        </div>
                        :
                        <div className='pages-and-files'>
                            <Routes>
                                <Route index element={
                                    <PagesAndFilesMobileRoutePage
                                        goBackActive={false}
                                        title={current_location.location.name}
                                        actions={actions}
                                    >
                                        <InternalLinks
                                            links={LINKS_BY_USER_RBAC}
                                            keyValue={'pages-and-files__mobile'}
                                        />
                                    </PagesAndFilesMobileRoutePage>
                                }>
                                </Route>

                                {routesConfig.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={route.withItemForm ?
                                            <PagesAndFilesMobileRoutePage
                                                goBackActive={true}
                                                title={route.mobileTitle}
                                                actions={actions}
                                            >
                                                {route.element}
                                            </PagesAndFilesMobileRoutePage>
                                            :
                                            route.element}
                                    />
                                ))}
                            </Routes>

                        </div >
                    :
                    null
            }
        </ActionsContext.Provider>
    );
}

export default PagesAndFiles