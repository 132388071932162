export function ImportHintIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={100}
            height={104}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 100 104"
        >
            <path
                d="M23.009 51.713V14.945c0-4.85 1.253-8.552 3.76-11.11C29.308 1.28 33.01 0 37.877 0H58.01v34.079c0 5.408 2.699 8.112 8.097 8.112H100v39.016c0 4.88-1.268 8.583-3.805 11.11-2.537 2.528-6.224 3.792-11.062 3.792H48.097a27.821 27.821 0 003.186-7.142 27.03 27.03 0 001.15-7.892c0-4.026-.766-7.803-2.3-11.33-1.534-3.556-3.658-6.672-6.372-9.347-2.684-2.704-5.811-4.82-9.38-6.348-3.54-1.528-7.33-2.307-11.372-2.337zm44.38-16.312c-1.74 0-2.61-.852-2.61-2.557V.441c1.062.117 2.124.558 3.186 1.322 1.062.735 2.168 1.69 3.318 2.866L95.31 28.833c1.21 1.234 2.183 2.38 2.92 3.438.738 1.058 1.18 2.102 1.328 3.13H67.389zM23.01 104c-3.157 0-6.121-.603-8.894-1.808a23.155 23.155 0 01-7.3-4.981 23.065 23.065 0 01-5-7.275C.604 87.174 0 84.22 0 81.075c0-3.174.605-6.128 1.814-8.861a23.594 23.594 0 015-7.319 22.555 22.555 0 017.301-4.937c2.773-1.205 5.737-1.808 8.894-1.808 3.156 0 6.12.603 8.894 1.808a23.065 23.065 0 017.345 4.937 22.466 22.466 0 014.956 7.275c1.18 2.762 1.77 5.73 1.77 8.905 0 3.115-.605 6.055-1.815 8.817a22.973 22.973 0 01-4.956 7.319 23.686 23.686 0 01-7.345 4.981c-2.773 1.205-5.722 1.808-8.85 1.808zm0-8.288c.973 0 1.755-.294 2.345-.882.59-.588.885-1.367.885-2.337v-8.2h8.23c.944 0 1.71-.294 2.3-.881.62-.588.93-1.367.93-2.337 0-.97-.31-1.749-.93-2.337-.59-.587-1.356-.881-2.3-.881h-8.23V69.7c0-.97-.295-1.749-.885-2.337-.59-.617-1.372-.911-2.345-.882-.974 0-1.755.294-2.345.882-.59.588-.885 1.367-.885 2.337v8.156h-8.23c-.974 0-1.755.294-2.345.881-.59.588-.885 1.367-.885 2.337 0 .97.31 1.749.929 2.337.59.587 1.357.881 2.3.881h8.23v8.2c0 .97.296 1.75.886 2.337.59.588 1.371.882 2.345.882z"
                fill="#505A72"
                className={fillClassName}
            />
        </svg>
    );
}

export function LoaderLogoNumberIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={19}
            height={66}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 19 66"
        >
            <path
                d="M0 1.512v7.407h8.007v56.484a32.807 32.807 0 0010.295-1.044V1.096A32.81 32.81 0 009.866 0C6.428 0 3.113.53 0 1.512z"
                fill="#505A72"
                className={fillClassName}
            />
            <path
                d="M0 1.512v7.407h8.007v56.484a32.807 32.807 0 0010.295-1.044V1.096A32.81 32.81 0 009.866 0C6.428 0 3.113.53 0 1.512z"
                fill="#505A72"
                className={fillClassName}
            />
        </svg>
    );
}


export function LoaderLogoCircleIcon({ mainClassName, fillClassName }) {
    return (
      <svg
        width={100}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={mainClassName}
        viewBox="0 0 100 100"
      >
        <path
          d="M24.888 93.253C32.484 97.751 40.83 100 49.925 100c2.864 0 5.654-.223 8.37-.669V88.966c-2.703.56-5.492.84-8.37.84-7.196 0-13.843-1.75-19.94-5.248-6.097-3.599-10.944-8.446-14.543-14.543-3.498-6.097-5.247-12.744-5.247-19.94 0-7.196 1.749-13.843 5.247-19.94 3.599-6.097 8.446-10.895 14.543-14.393 6.097-3.598 12.744-5.397 19.94-5.397 7.196 0 13.843 1.799 19.94 5.397 6.097 3.498 10.895 8.296 14.393 14.393 3.598 6.097 5.397 12.744 5.397 19.94 0 7.196-1.799 13.843-5.397 19.94-3.498 6.097-8.296 10.945-14.393 14.543a40.98 40.98 0 01-1.244.685v11.172a48.589 48.589 0 006.342-3.162c7.696-4.497 13.793-10.544 18.29-18.14C97.751 67.415 100 59.07 100 50.075c0-8.995-2.249-17.341-6.747-25.038-4.497-7.696-10.594-13.793-18.29-18.29C67.266 2.249 58.92 0 49.925 0 40.93 0 32.584 2.249 24.888 6.747 17.29 11.244 11.244 17.34 6.747 25.037 2.249 32.635 0 40.98 0 50.076s2.249 17.441 6.747 25.037c4.497 7.597 10.544 13.644 18.14 18.141z"
          fill="#505A72"
          className={fillClassName}
        />
        <path
          d="M24.888 93.253C32.484 97.751 40.83 100 49.925 100c2.864 0 5.654-.223 8.37-.669V88.966c-2.703.56-5.492.84-8.37.84-7.196 0-13.843-1.75-19.94-5.248-6.097-3.599-10.944-8.446-14.543-14.543-3.498-6.097-5.247-12.744-5.247-19.94 0-7.196 1.749-13.843 5.247-19.94 3.599-6.097 8.446-10.895 14.543-14.393 6.097-3.598 12.744-5.397 19.94-5.397 7.196 0 13.843 1.799 19.94 5.397 6.097 3.498 10.895 8.296 14.393 14.393 3.598 6.097 5.397 12.744 5.397 19.94 0 7.196-1.799 13.843-5.397 19.94-3.498 6.097-8.296 10.945-14.393 14.543a40.98 40.98 0 01-1.244.685v11.172a48.589 48.589 0 006.342-3.162c7.696-4.497 13.793-10.544 18.29-18.14C97.751 67.415 100 59.07 100 50.075c0-8.995-2.249-17.341-6.747-25.038-4.497-7.696-10.594-13.793-18.29-18.29C67.266 2.249 58.92 0 49.925 0 40.93 0 32.584 2.249 24.888 6.747 17.29 11.244 11.244 17.34 6.747 25.037 2.249 32.635 0 40.98 0 50.076s2.249 17.441 6.747 25.037c4.497 7.597 10.544 13.644 18.14 18.141z"
          fill="#505A72"
          className={fillClassName}
        />
      </svg>
    );
  }