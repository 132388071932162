import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { AddIcon, DeleteCrossIcon, SortArrowIcon } from '../../../../assets/icons/controls/controls';
import PlatformInput from '../../PlatformInput/PlatformInput';
import './ProductFormOptions.css';

function ProductFormOptions({
    product,
    is_main_amount,
    handleChange,
    error,
    readOnly,
    isPublished
}) {
    return (
        <div className='product-form-options__block'>
            {product.options.map((option, option_i) => (
                <div className={`product-form-options ${!is_main_amount ? 'product-form-options_with-amount' : ''}`}>
                    <PlatformInput
                        value={option.title}
                        placeholder={''}
                        label={`Опция*`}
                        readOnly={readOnly}
                        // hint={'Видно только вам'}
                        handleChange={(e) => {

                            handleChange({
                                target: {
                                    value: e.target.value,
                                    option_id: option._id,
                                    name: 'option-title',
                                }
                            })
                        }}
                        name={'option-title'}
                        show_eror_color_only={true}
                        error={
                            error && error[option_i].title.errorMessage
                                ? error[option_i].title.errorMessage
                                : ""
                        }
                    >
                    </PlatformInput>
                    <div className='product-form__option-values-box'>
                        <LayoutGroup>
                            <AnimatePresence>
                                {option.values.map((item2, i2) => (
                                    <motion.div
                                        className='product-form__option-values'
                                        key={`product-form__option-${option_i}-values-${item2._id}`}
                                        layout
                                        initial={{ opacity: 0, y: 0 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: 0 }}
                                        transition={{ duration: 0.2 }}>
                                        <PlatformInput
                                            value={item2.value}
                                            placeholder={''}
                                            label={i2 === 0 ? `Значение*` : ''}
                                            readOnly={readOnly}
                                            // hint={'Видно только вам'}
                                            handleChange={(e) => {
                                                handleChange({
                                                    target: {
                                                        value: e.target.value,
                                                        option_id: option._id,
                                                        value_id: item2._id,
                                                        name: 'option-value',
                                                    }
                                                })
                                            }}
                                            name={'option-value'}
                                            show_eror_color_only={true}
                                            error={
                                                error && error[option_i].values[i2].value.errorMessage
                                                    ? error[option_i].values[i2].value.errorMessage
                                                    : ""
                                            }
                                        >
                                        </PlatformInput>
                                            {console.log(item2.amount)}
                                        {!is_main_amount ?
                                            <PlatformInput
                                                readOnly={readOnly}
                                                value={`${item2.amount}`}
                                                placeholder={''}
                                                label={i2 === 0 ? `Наличие${isPublished ? '*' : ''}` : ''}
                                                // hint={'Видно только вам'}
                                                handleChange={(e) => {
                                                    handleChange({
                                                        target: {
                                                            value: e.target.value,
                                                            option_id: option._id,
                                                            value_id: item2._id,
                                                            name: 'option-amount',
                                                        }
                                                    })
                                                }}
                                                name={'option-amount'}
                                                show_eror_color_only={true}
                                                error={
                                                    error && error[option_i].values[i2].amount.errorMessage
                                                        ? error[option_i].values[i2].amount.errorMessage
                                                        : ""
                                                }
                                            >
                                            </PlatformInput>
                                            : null}
                                        {!is_main_amount ?
                                            <PlatformInput
                                                readOnly={readOnly}
                                                value={item2.price}
                                                placeholder={''}
                                                label={i2 === 0 ? 'Цена*' : ''}
                                                // hint={'Видно только вам'}
                                                handleChange={(e) => {
                                                    handleChange({
                                                        target: {
                                                            value: e.target.value,
                                                            option_id: option._id,
                                                            value_id: item2._id,
                                                            name: 'option-price',
                                                        }
                                                    })
                                                }}
                                                name={'option-price'}
                                                show_eror_color_only={true}
                                                error={
                                                    error && error[option_i].values[i2].price.errorMessage
                                                        ? error[option_i].values[i2].price.errorMessage
                                                        : ""
                                                }
                                            >
                                            </PlatformInput>
                                            : null}
                                        {console.log(option)}
                                        <div className={`product-form__option-value-controls ${i2 === 0 ? 'product-form__option-value-controls_first' : ''}`}>
                                            <button className={`product-form__option-value-control ${i2 + 1 === option.values.length ? 'product-form__option-value-control_inactive' : ''}`} type='button' onClick={() => {
                                                if (readOnly) return
                                                handleChange({
                                                    target: {
                                                        value: null,
                                                        option_id: option._id,
                                                        value_index: i2,
                                                        name: 'option-value-sort-down',
                                                    }
                                                })
                                            }}>
                                                <SortArrowIcon
                                                    mainClassName={'product-form__option-value-control-icon'}
                                                    fillClassName={'product-form__option-value-control-icon-fill'}
                                                />
                                            </button>
                                            <button className={`product-form__option-value-control ${i2 === 0 ? 'product-form__option-value-control_inactive' : ''}`} type='button' onClick={() => {
                                                if (readOnly) return
                                                handleChange({
                                                    target: {
                                                        value: null,
                                                        option_id: option._id,
                                                        value_index: i2,
                                                        name: 'option-value-sort-up',
                                                    }
                                                })
                                            }}>
                                                <SortArrowIcon
                                                    mainClassName={'product-form__option-value-control-icon product-form__option-value-control-icon_up'}
                                                    fillClassName={'product-form__option-value-control-icon-fill'}
                                                />
                                            </button>
                                            <button className='product-form__option-value-control' type='button' onClick={() => {
                                                if (readOnly) return
                                                handleChange({
                                                    target: {
                                                        value: null,
                                                        option_id: option._id,
                                                        value_id: item2._id,
                                                        name: 'option-value-delete',
                                                    }
                                                })
                                            }}>
                                                <DeleteCrossIcon
                                                    mainClassName={'product-form__option-value-control-icon'}
                                                    fillClassName={'product-form__option-value-control-icon-fill'}
                                                />
                                            </button>
                                        </div>
                                    </motion.div>
                                ))}
                            </AnimatePresence>
                        </LayoutGroup>
                        {option.values.length < 10 && !readOnly ?
                            <button className='product-form__btn product-form__btn_option-values' type='button' onClick={() => {
                                handleChange({
                                    target: {
                                        option_id: option._id,
                                        value: null,
                                        name: 'add-value',
                                    }
                                })
                            }}>
                                Добавить значение
                            </button>
                            : null}

                    </div>

                </div>
            ))}
            {product.options.length < 10 && !readOnly ?
                <button className='product-form__btn' type='button' onClick={() => {
                    handleChange({
                        target: {
                            value: null,
                            name: 'add-option',
                        }
                    })
                }}>
                    <AddIcon
                        mainClassName={'product-form__btn-icon'}
                        fillClassName={'product-form__btn-icon-fill'}
                    />
                    Добавить опцию
                </button>
                : null}


        </div>
    );
}

export default ProductFormOptions