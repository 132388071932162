import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';

import { AddIcon, DeleteCrossIcon, SortArrowIcon } from '../../../../../../../../../assets/icons/controls/controls';
import { DEFAULT_BANNER_SIZE, DEFAULT_MAX_IMG_SIZE } from '../../../../../../../../../assets/utils/constants';
import ImagesInput from '../../../../../../../ImagesInput/ImagesInput';
import PlatformInput from '../../../../../../../PlatformInput/PlatformInput';
import './PromoBanner.css';



function PromoBanner({ item, handleChange }) {
    return (

        <div className='promo-banner'>
            <LayoutGroup>
                <AnimatePresence>
                    {item.data.map((data_item, i) => (
                        <motion.div
                            className='promo-banner__card'
                            key={`${item._id}_promo-banner__card_${data_item._id}`}
                            layout
                            initial={{ opacity: 0, y: 0 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 0 }}
                            transition={{ duration: 0.2 }}
                        >
                            <div className='promo-banner__card-heading'>
                                <p className='promo-banner__card-title'>Слайд {i + 1}</p>
                                <div className={`promo-banner__card-controls`}>
                                    <button className={`promo-banner__card-control ${i + 1 === item.data.length ? 'promo-banner__card-control_inactive' : ''}`} type='button' onClick={() => {
                                        handleChange({
                                            target: {
                                                value: null,
                                                index: i,
                                                item: item,
                                                name: 'promo-banner',
                                                subname: 'sort',
                                                direction: 'down',
                                            }
                                        })
                                    }}>
                                        <SortArrowIcon
                                            mainClassName={'promo-banner__card-control-icon'}
                                            fillClassName={'promo-banner__card-control-icon-fill'}
                                        />
                                    </button>
                                    <button className={`promo-banner__card-control ${i === 0 ? 'promo-banner__card-control_inactive' : ''}`} type='button' onClick={() => {
                                        handleChange({
                                            target: {
                                                value: null,
                                                index: i,
                                                item: item,
                                                name: 'promo-banner',
                                                subname: 'sort',
                                                direction: 'up',
                                            }
                                        })
                                    }}>
                                        <SortArrowIcon
                                            mainClassName={'promo-banner__card-control-icon promo-banner__card-control-icon_up'}
                                            fillClassName={'promo-banner__card-control-icon-fill'}
                                        />
                                    </button>

                                    <button className={`promo-banner__card-control ${item.data.length === 1 ? 'promo-banner__card-control_inactive' : ''}`} type='button' onClick={() => {
                                        handleChange({
                                            target: {
                                                value: null,
                                                index: i,
                                                item: item,
                                                name: 'promo-banner',
                                                subname: 'delete',
                                            }
                                        })
                                    }}>
                                        <DeleteCrossIcon
                                            mainClassName={'promo-banner__card-control-icon'}
                                            fillClassName={'promo-banner__card-control-icon-fill'}
                                        />
                                    </button>
                                </div>
                            </div>

                            <PlatformInput
                                label={'Заголовок'}
                                value={data_item.title}
                                handleChange={(e) => {
                                    handleChange({
                                        target: {
                                            value: e.target.value,
                                            index: i,
                                            item: item,
                                            name: 'promo-banner',
                                            subname: 'title',
                                        }
                                    })
                                }}
                                isTextArea={true}
                                minRows={1}
                                maxRows={5}
                                type={'text'}
                                name={''}
                            />

                            <ImagesInput
                                name={"image"}
                                label={"Изображение"}
                                hint={`(${DEFAULT_BANNER_SIZE}) Не более ${DEFAULT_MAX_IMG_SIZE} Мб`}
                                addBtnText={"Добавить..."}
                                images={data_item.image}
                                additionalKey={`${item._id}_promo-banner__card_${data_item._id}`}
                                isHorizontal
                                onAdd={(e) => {
                                    console.log(e.target)
                                    handleChange({
                                        target: {
                                            ...e.target,
                                            index: i,
                                            item: item,
                                            name: 'promo-banner',
                                            subname: 'image',
                                            files: e.target.files,
                                            resolution: '',
                                        }
                                    })
                                }}
                                onRemove={(e) => {
                                    handleChange({
                                        target: {
                                            value: e.target.index,
                                            index: i,
                                            item: item,
                                            name: 'promo-banner',
                                            subname: 'image-delete',
                                            resolution: '',
                                        }
                                    })
                                }}
                            />
                            <ImagesInput
                                name={"image_tablet"}
                                label={"Изображение для планшета"}
                                hint={`Не более ${DEFAULT_MAX_IMG_SIZE} Мб`}
                                addBtnText={"Добавить..."}
                                images={data_item.image_tablet}
                                additionalKey={`${item._id}_promo-banner__card_tablet_${data_item._id}`}
                                isHorizontal
                                onAdd={(e) => {
                                    console.log(e.target)
                                    handleChange({
                                        target: {
                                            ...e.target,
                                            index: i,
                                            item: item,
                                            name: 'promo-banner',
                                            subname: 'image',
                                            files: e.target.files,
                                            resolution: 'tablet',
                                        }
                                    })
                                }}
                                onRemove={(e) => {
                                    handleChange({
                                        target: {
                                            value: e.target.index,
                                            index: i,
                                            item: item,
                                            name: 'promo-banner',
                                            subname: 'image-delete',
                                            resolution: 'tablet',
                                        }
                                    })
                                }}
                            />
                            <ImagesInput
                                name={"image_mobile"}
                                label={"Изображение для телефона"}
                                hint={`Не более ${DEFAULT_MAX_IMG_SIZE} Мб`}
                                addBtnText={"Добавить..."}
                                images={data_item.image_mobile}
                                additionalKey={`${item._id}_promo-banner__card_mobile_${data_item._id}`}
                                isHorizontal
                                onAdd={(e) => {
                                    console.log(e.target)
                                    handleChange({
                                        target: {
                                            ...e.target,
                                            index: i,
                                            item: item,
                                            name: 'promo-banner',
                                            subname: 'image',
                                            files: e.target.files,
                                            resolution: 'mobile',
                                        }
                                    })
                                }}
                                onRemove={(e) => {
                                    handleChange({
                                        target: {
                                            value: e.target.index,
                                            index: i,
                                            item: item,
                                            name: 'promo-banner',
                                            subname: 'image-delete',
                                            resolution: 'mobile',
                                        }
                                    })
                                }}
                            />
                            <PlatformInput
                                label={'Ссылка'}
                                value={data_item.link}
                                handleChange={(e) => {
                                    handleChange({
                                        target: {
                                            value: e.target.value,
                                            index: i,
                                            item: item,
                                            name: 'promo-banner',
                                            subname: 'link',
                                        }
                                    })
                                }}
                                type={'text'}
                                name={''}
                            />
                        </motion.div>
                    ))}
                </AnimatePresence>
            </LayoutGroup>
            {item.data.length < 6 ?
                <button className='promo-banner__btn' type='button' onClick={() => {
                    handleChange({
                        target: {
                            value: null,
                            item: item,
                            name: 'promo-banner',
                            subname: 'add',
                        }
                    })
                }}>
                    <AddIcon
                        mainClassName={'promo-banner__btn-icon'}
                        fillClassName={'promo-banner__btn-icon-fill'}
                    />
                    Добавить слайд
                </button>
                : null}

        </div>

    );
}

export default PromoBanner