import { DEFAULT_SOCIAL_VALUES_MAP } from '../../../../../../../../assets/utils/socials_constants';
import { deleteItem, fetchItems, sortItems } from '../../services/socialsApi';
import List from './List/List';

function SocialsList() {

    return (
        <List
            deletePopupTextFormater={(itemToDelete) => (`Вы уверены, что хотите удалить ссылку на ${itemToDelete ? DEFAULT_SOCIAL_VALUES_MAP[itemToDelete.type].title : ''} - ${itemToDelete ? itemToDelete.url : ''}`)}
            fetchItems={fetchItems}
            deleteItem={deleteItem}
            sortItems={sortItems}
            itemNameTextFormater={(item) => (`${!item.is_visible ? 'Скрыт - ' : ''} ${DEFAULT_SOCIAL_VALUES_MAP[item.type].title}`)}
            emptyText={'Социальных сетей пока нет'}
        />
    );
}

export default SocialsList