import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import './ImportFile.css';
import PageHeading from '../../../PageHeading/PageHeading';
import { useState } from 'react';
import useWindowSize from '../../../../../assets/hooks/useWindowSize';
import ItemForm from '../../../ItemForm/ItemForm';
import ProductsList from '../../../ProductsList/ProductsList';
import { getRandomId } from '../../../../../assets/utils/utils';

import ImportProductForm from './ImportProductForm/ImportProductForm';
import ProductFormPlaceholder from '../../../ProductForm/ProductFormPlaceholder/ProductFormPlaceholder';
import { CATALOG_IMPORT_FILE_ITEM_LINK } from '../../../../../assets/utils/constants';

function ImportFile() {
    const { width } = useWindowSize();
    const navigate = useNavigate()
    const { id } = useParams()
    const [file, setFile] = useState({

        items_count: 314,
        file_name: 'baza_18.12.2023.csv'
    })

  

    const [products, setProducts] = useState([
        {
            _id: '6589b682c7e2d41747fb9f2f',
            title: 'Пальто Misteks design',
            description: 'Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных...',
            price: '314159',
            img: 'https://picsum.photos/200/300',
            options: [
                {
                    title: 'Размер',
                    values: [
                        {
                            amount: '0',
                            value: 'XS',
                        },
                        {
                            amount: '0',
                            value: 'S',
                        },
                        {
                            amount: '0',
                            value: 'M',
                        },
                        {
                            amount: '20',
                            value: 'L',
                        },
                        {
                            amount: '10',
                            value: 'XL',
                        },
                        {
                            amount: '10',
                            value: '2XL',
                        },
                        {
                            amount: '0',
                            value: 'XS',
                        },
                        {
                            amount: '0',
                            value: 'S',
                        },
                        {
                            amount: '0',
                            value: 'M',
                        },
                        {
                            amount: '20',
                            value: 'L',
                        },
                        {
                            amount: '10',
                            value: 'XL',
                        },
                        {
                            amount: '10',
                            value: '2XL',
                        },
                        {
                            amount: '0',
                            value: 'XS',
                        },
                        {
                            amount: '0',
                            value: 'S',
                        },
                        {
                            amount: '0',
                            value: 'MMMM',
                        },
                        {
                            amount: '20',
                            value: 'L',
                        },
                        {
                            amount: '10',
                            value: 'XL',
                        },
                        {
                            amount: '10',
                            value: '2XL',
                        },
                    ],
                },
                {
                    title: 'Размер',
                    values: [
                        {
                            amount: '0',
                            value: 'XS',
                        },
                        {
                            amount: '0',
                            value: 'S',
                        },
                        {
                            amount: '0',
                            value: 'M',
                        },
                        {
                            amount: '20',
                            value: 'L',
                        },
                        {
                            amount: '10',
                            value: 'XL',
                        },
                        {
                            amount: '10',
                            value: '2XL',
                        },
                    ],
                },
            ],
        },
        {
            _id: getRandomId(),
            title: 'Пальто Misteks design',
            description: 'Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных...',
            price: '314159',
            img: 'https://picsum.photos/200/300',
            options: [
                {
                    title: 'Размер',
                    values: [
                        {
                            amount: '0',
                            value: 'XS',
                        },
                        {
                            amount: '0',
                            value: 'S',
                        },
                        {
                            amount: '0',
                            value: 'M',
                        },
                        {
                            amount: '20',
                            value: 'L',
                        },
                        {
                            amount: '10',
                            value: 'XL',
                        },
                        {
                            amount: '10',
                            value: '2XL',
                        },
                    ],
                }
            ],
        },
        {
            _id: getRandomId(),
            title: 'Пальто Misteks design',
            description: 'Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных...',
            price: '314159',
            img: 'https://picsum.photos/200/300',
            options: [
                {
                    title: 'Размер',
                    values: [
                        {
                            amount: '0',
                            value: 'XS',
                        },
                        {
                            amount: '0',
                            value: 'S',
                        },
                        {
                            amount: '0',
                            value: 'M',
                        },
                        {
                            amount: '20',
                            value: 'L',
                        },
                        {
                            amount: '10',
                            value: 'XL',
                        },
                        {
                            amount: '10',
                            value: '2XL',
                        },
                    ],
                }
            ],
        },
        {
            _id: getRandomId(),
            title: 'Пальто Misteks design',
            description: 'Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных...',
            price: '314159',
            img: 'https://picsum.photos/200/300',
            options: [
                {
                    title: 'Размер',
                    values: [
                        {
                            amount: '0',
                            value: 'XS',
                        },
                        {
                            amount: '0',
                            value: 'S',
                        },
                        {
                            amount: '0',
                            value: 'M',
                        },
                        {
                            amount: '20',
                            value: 'L',
                        },
                        {
                            amount: '10',
                            value: 'XL',
                        },
                        {
                            amount: '10',
                            value: '2XL',
                        },
                    ],
                }
            ],
        },
        {
            _id: getRandomId(),
            title: 'Пальто Misteks design',
            description: 'Пальто мужское ..',
            price: '314159',
            img: 'https://picsum.photos/200/300',
            options: [
                {
                    title: 'Размер',
                    values: [
                        {
                            amount: '0',
                            value: 'XS',
                        },
                        {
                            amount: '0',
                            value: 'S',
                        },
                        {
                            amount: '0',
                            value: 'M',
                        },
                        {
                            amount: '20',
                            value: 'L',
                        },
                        {
                            amount: '10',
                            value: 'XL',
                        },
                        {
                            amount: '10',
                            value: '2XL',
                        },
                    ],
                }
            ],
        },
        {
            _id: getRandomId(),
            title: 'Пальто Misteks design',
            description: 'Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных...',
            price: '314159',
            img: 'https://picsum.photos/200/300',
            options: [
                {
                    title: 'Размер',
                    values: [
                        {
                            amount: '0',
                            value: 'XS',
                        },
                        {
                            amount: '0',
                            value: 'S',
                        },
                        {
                            amount: '0',
                            value: 'M',
                        },
                        {
                            amount: '20',
                            value: 'L',
                        },
                        {
                            amount: '10',
                            value: 'XL',
                        },
                        {
                            amount: '10',
                            value: '2XL',
                        },
                    ],
                }
            ],
        },
        {
            _id: getRandomId(),
            title: 'Пальто Misteks design',
            description: 'Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных...',
            price: '314159',
            img: 'https://picsum.photos/200/300',
            options: [
                {
                    title: 'Размер',
                    values: [
                        {
                            amount: '0',
                            value: 'XS',
                        },
                        {
                            amount: '0',
                            value: 'S',
                        },
                        {
                            amount: '0',
                            value: 'M',
                        },
                        {
                            amount: '20',
                            value: 'L',
                        },
                        {
                            amount: '10',
                            value: 'XL',
                        },
                        {
                            amount: '10',
                            value: '2XL',
                        },
                    ],
                }
            ],
        },



    ])

    const [selectedProducts, setSelectedProducts] = useState({
        all: false,
        exclude: [],
        include: [],
    })

    function handleSelect({ type, item }) {
        console.log(type, item)
        switch (type) {
            case 'all': {

                // setAllSelected(prevValue => !prevValue)
                setSelectedProducts(prevValue => {
                    if (prevValue.exclude.length > 0) {
                        return {
                            all: true,
                            exclude: [],
                            include: [],
                        }
                    } else {
                        if (!prevValue.all) {
                            return {
                                all: true,
                                exclude: [],
                                include: [],
                            }
                        } else {
                            return {
                                all: false,
                                exclude: [],
                                include: [],
                            }
                        }
                    }

                })

                break;
            }
            case 'item': {
                setSelectedProducts(prevValue => {
                    // console.log(prevValue, prevValue.exclude.filter(item2 => item2._id !== item._id).length)
                    // console.log(prevValue.include, item._id, prevValue.include.filter((item2)=>{
                    //     console.log({1:item._id, 2:item2._id, 3:})
                    // }))
                    if (prevValue.all) {
                        return {
                            all: true,
                            exclude: prevValue.exclude.indexOf(item._id) >= 0 ? prevValue.exclude.filter(item2 => item2 !== item._id) : prevValue.exclude.concat([item._id]),
                            include: [],
                        }
                    } else {
                        return {
                            all: false,
                            exclude: [],
                            include: prevValue.include.indexOf(item._id) >= 0 ? prevValue.include.filter(item2 => item2 !== item._id) : prevValue.include.concat([item._id]),
                        }
                    }
                })

                break;
            }

            default:
                break;
        }
    }



    function handleMoveAllToDrafts() {

    }
    

    function handleMoveSelectedToDrafts() {

    }

    const [product, setProduct] = useState({
        _id: getRandomId(),
        title: 'Пальто Misteks design',
        description: 'Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных Пальто мужское из натуральной шерсти с карманами и атласной подкладкой. Идеально подойдет для знойных...',
        price: '314159',
        img: 'https://picsum.photos/200/300',
        amount: '',
        discount: '',
        options: [],
        unique_sku: '120',
        parameters: [],
        visibility: true,
        photo: [],
    })


    const [clickedSide, setClickedSide] = useState('left')

    function handleSaveProduct() {

    }

    function handleRestoreProduct() {

    }

    const actions_for_list = selectedProducts.all || selectedProducts.include.length > 0 ?
        [
            {
                name: 'Импортировать выбранные',
                onClick: handleMoveSelectedToDrafts,
                isMainAction: true,
            },
        ]
        :
        [
            {
                name: 'Импортировать все',
                onClick: handleMoveAllToDrafts,
                isMainAction: true,
            },
        ]

    const actions_for_form = product ?
        [

            {
                name: 'Отменить все изменения',
                onClick: handleRestoreProduct,
            },
            {
                name: 'Сохранить',
                onClick: handleSaveProduct,
                isMainAction: true,
            },
        ]
        :
        []
    const actions =
        clickedSide === 'left' ?
            actions_for_list
            :
            actions_for_form



    return (
        <>
            {
                width ?
                    width > 880 ?
                        <div className='import-file'>
                            <PageHeading
                                className={'import-file__heading'}
                                title={`${file ? Number(file.items_count).toLocaleString('us') : '0'} товаров из файла ${file ? file.file_name : ''}`}
                                // goBack={{
                                //     onClick: () => { console.log('fdffd') },
                                // }}
                                actions={actions}
                            />
                            <ItemForm
                                containerClassName={'import-file__item-from-container'}
                            >
                                <div className='import-file__two-columns'>
                                    <div className='import-file__products-list' onClick={() => {
                                        setClickedSide('left')
                                    }}>
                                        <ProductsList
                                            className={''}
                                            handleSelect={handleSelect}
                                            isSelectable={true}
                                            isAllSelected={selectedProducts.all}
                                            products={products}
                                            selectedExcludeProducts={selectedProducts.exclude}
                                            selectedIncludeProducts={selectedProducts.include}
                                            name={'import-file'}

                                        />
                                    </div>
                                    <div className='import-file__products-form' onClick={() => {

                                        setClickedSide('right')
                                    }}>
                                        <Routes>
                                            <Route index element={
                                                <ProductFormPlaceholder />
                                            }>

                                            </Route>
                                            <Route path={`/${CATALOG_IMPORT_FILE_ITEM_LINK}/:item_id`} element={
                                                <ImportProductForm
                                                    product={product}
                                                    setProduct={setProduct}
                                                />
                                            }>
                                            </Route>
                                        </Routes>
                                    </div>

                                </div>
                            </ItemForm>
                        </div>
                        :
                        <div className='import-file'>
                            <Routes>
                                <Route index element={
                                    <div className='import-file__content'>
                                        <PageHeading
                                            className={'import-file__heading'}
                                            title={`${file ? Number(file.items_count).toLocaleString('us') : '0'} товаров из файла ${file ? file.file_name : ''}`}
                                            // goBack={{
                                            //     onClick: () => { console.log('fdffd') },
                                            // }}
                                            actions={actions_for_list}
                                        />
                                        <ProductsList
                                            className={''}
                                            handleSelect={handleSelect}
                                            isSelectable={true}
                                            isAllSelected={selectedProducts.all}
                                            products={products}
                                            selectedExcludeProducts={selectedProducts.exclude}
                                            selectedIncludeProducts={selectedProducts.include}
                                            name={'import-file'}

                                        />
                                    </div>
                                }>
                                </Route>
                                <Route path={`/${CATALOG_IMPORT_FILE_ITEM_LINK}/:item_id`} element={
                                    <div>
                                        <PageHeading
                                            className={'import-main__heading'}
                                            title={product ? product.title : ''}
                                            goBack={{
                                                onClick: () => { navigate(-1) },
                                            }}
                                            actions={actions_for_form}
                                        />
                                        <ItemForm>
                                            <ImportProductForm
                                                product={product}
                                                setProduct={setProduct}
                                            />
                                        </ItemForm>
                                    </div>


                                }>
                                </Route>
                            </Routes>

                        </div >
                    :
                    null
            }
        </>


    );
}

export default ImportFile