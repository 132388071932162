import { Route, Routes } from "react-router-dom";
import "./Categories.css";
import Category from "./Category/Category";
import EditCategory from "../EditCategory/EditCategory";
import { CATALOG_CATEGORY_EDIT_LINK, CATALOG_CATEGORY_FILTERS_LINK } from "../../../../assets/utils/constants";
import EditFilters from "../EditFilters/EditFilters";

function Categories() {
  return (
    <div className="categories">
      <Routes>
        <Route
          index
          element={<Category />}
        />
        <Route
          path={`/${CATALOG_CATEGORY_EDIT_LINK}/:id`}
          element={
            <EditCategory />
          }
        />
        <Route
          path={`/${CATALOG_CATEGORY_EDIT_LINK}/:id/${CATALOG_CATEGORY_FILTERS_LINK}`}
          element={
            <EditFilters />
          }
        />
      </Routes>
    </div>
  );
}

export default Categories;
