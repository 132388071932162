import { useState, useContext, useEffect } from "react";
import PageHeading from "../../PageHeading/PageHeading";
import "./PromocodeGroups.css";
import {
  CONTROLS_TYPE,
  COUPON_GROUPS_LIMIT,
  PLATFORM_MAIN_LINK,
  PRICE_TYPE,
  PROMOCODES_CREATE_GROUP_LINK,
  PROMOCODES_EDIT_GROUP_LINK,
  PROMOCODES_GROUP_LINK,
  PROMOCODES_MAIN_LINK,
  TEXT_TYPE,
  TITLE_TYPE,
} from "../../../../assets/utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../Table/Table";
import { createAction, hasPermission, parseApiError, parsePermissions, parsePrice } from "../../../../assets/utils/utils";
import { UserContext } from "../../../../assets/contexts/userContext";
import mainApi from "../../../../assets/api/MainApi";
import MiniPreloader from "../../../MiniPreloader/MiniPreloader";
import useAutoDismissError from "../../../../assets/hooks/useAutoDismissError";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { PERMISSIONS, RBAC_COUPONS } from "../../../../assets/utils/permissions_rbac";

function PromocodeGroups() {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const [coupons, setCoupons] = useState(undefined);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);
  const [isFeedPreloader, setIsFeedPreloader] = useState(false);
  const [isMoreBtnVisible, setIsMoreBtnVisible] = useState(false);
  const [error, showError] = useAutoDismissError();

  const hasAddPermission = hasPermission(parsePermissions(user), [RBAC_COUPONS[PERMISSIONS.ADD]])
  const hasEditPermission = hasPermission(parsePermissions(user), [RBAC_COUPONS[PERMISSIONS.EDIT]])

  const navigate = useNavigate()
  function addActionsWithPermissions() {
    const actions = [];

    if (hasAddPermission) {
      actions.push(createAction("Создать группу", () => navigate(`${location.pathname}/${PROMOCODES_CREATE_GROUP_LINK}`), {
        isMainAction: true,
      }));
    }

    return actions;
  }

  const actions = addActionsWithPermissions();

  useEffect(() => {
    if (!user) return;

    setIsPreloaderVisible(true);
    getCouponGroups({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function getCouponGroups({ last_id }) {
    const shop_id = user.default_shop._id;
    mainApi
      .getCouponGroups({ shop_id, limit: COUPON_GROUPS_LIMIT, last_id })
      .then((res) => {
        const data =
          res.data.length > 0
            ? res.data.map((item) => {
              return {
                _id: item._id,
                title: item.name,
                count: item.count,
                sales_count: item.stats.sold_items,
                sales_amount: item.stats.sold_value,
                discounted_amount: item.stats.discount,
              };
            })
            : res.data;
        setCoupons(last_id ? (prevArr) => prevArr.concat(data) : data);
        setIsMoreBtnVisible(res.is_more);
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setCoupons(undefined)
          setIsMoreBtnVisible(false)
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
        setIsFeedPreloader(false);
      });
  }

  function getMore() {
    const last_id = coupons[coupons.length - 1]._id;
    setIsFeedPreloader(true);
    getCouponGroups({ last_id });
  }

  return (
    <div className="promocode-groups">
      <PageHeading
        className={"promocode-groups__heading"}
        title={"Группы"}
        // goBack={{
        //     onClick: () => { console.log('fdffd') },
        // }}
        actions={actions}
      />
      <ErrorMessage error={error} />
      <div className="promocode-groups__table">
        {!isPreloaderVisible ? (
          <>
            <Table
              noting_btn={hasAddPermission ? { text: 'Создать группу', path: `${location.pathname}/${PROMOCODES_CREATE_GROUP_LINK}` } : null}
              headingList={[
                {
                  name: "Название",
                  key: "title",
                  type: TITLE_TYPE,
                },
                {
                  name: "Количество",
                  key: "count",
                  type: TEXT_TYPE,
                },
                {
                  name: "Продажи",
                  key: "sales",
                  type: TEXT_TYPE,
                },
                {
                  name: "Сумма скидок",
                  key: "discounted_amount",
                  type: PRICE_TYPE,
                },
                {
                  name: "Управление",
                  key: "controls",
                  type: CONTROLS_TYPE,
                },
              ]}
              nothingText={`У вас еще нет групп${hasAddPermission ? `,
              создайте новую группу, для того чтобы создать промо-код` : ''}`}
              list={
                coupons?.length > 0
                  ? coupons.map((item, i) => {

                    let controls = [
                      {
                        name: "Открыть группу",
                        path: `${PLATFORM_MAIN_LINK}/${PROMOCODES_MAIN_LINK}/${PROMOCODES_GROUP_LINK}/${item._id}`,
                      }
                    ]
                    if (hasEditPermission) {
                      controls.unshift({
                        name: "Редактировать",
                        path: `${PLATFORM_MAIN_LINK}/${PROMOCODES_MAIN_LINK}/${PROMOCODES_EDIT_GROUP_LINK}/${item._id}`,
                      })
                    }

                    return {
                      ...item,
                      sales: `${Number(item.sales_count).toLocaleString(
                        "us"
                      )} (${parsePrice(item.sales_amount, "RUB")})`,
                      discounted_amount: "-" + item.discounted_amount,
                      controls: controls,
                    };
                  })
                  : []
              }
            />

            {isMoreBtnVisible ? (
              <button
                className={`promocode-groups__more-btn ${isFeedPreloader ? "promocode-groups__more-btn_disabled" : ""
                  }`}
                onClick={getMore}
                type="button"
              >
                <p
                  className={`promocode-groups__more-btn-text ${!isFeedPreloader
                    ? "promocode-groups__more-btn-text_visible"
                    : ""
                    }`}
                >
                  Загрузить ещё
                </p>
                <span
                  className={`promocode-groups__btn-preloader ${isFeedPreloader
                    ? "promocode-groups__btn-preloader_visible"
                    : ""
                    }`}
                >
                  <MiniPreloader />
                </span>
              </button>
            ) : null}
          </>
        ) : (
          <div className="promocode-groups__preloader">
            <MiniPreloader />
          </div>
        )}
      </div>
    </div>
  );
}

export default PromocodeGroups;
