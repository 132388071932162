import { useLocation } from 'react-router-dom';
import PageHeading from '../../PageHeading/PageHeading';
import './AccountMain.css';
import { areArraysDifferent, getCurrentPlatfromLocation } from '../../../../assets/utils/utils';
import ItemForm from '../../ItemForm/ItemForm';
import { useContext, useEffect, useState } from 'react';

import PersonalInfo from './PersonalInfo/PersonalInfo';
import { UserContext } from '../../../../assets/contexts/userContext';
import mainApi from '../../../../assets/api/MainApi';
import { PlatformLinksContext } from '../../../../assets/contexts/platformLinksContext';

function AccountMain() {

    const { user } = useContext(UserContext)
    // const location = useLocation()
    // const [actions, setActions] = useState([
    //     {
    //         name: 'Создать группу',
    //         path: `${location.pathname}/${PROMOCODES_CREATE_GROUP_LINK}`,
    //         isMainAction: true,
    //     },
    //     // {
    //     //     name: 'OnClick',
    //     //     onClick: () => { console.log('fddsfeee') },
    //     //     inactive: true,
    //     // },
    // ])

    const location = useLocation();
    const { links, default_link } = useContext(PlatformLinksContext)

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })

    const [clickedSide, setClickedSide] = useState('left')


    const [personalInfoForm, setPersonalInfoForm] = useState({
        previous_email: user.email,
        cur_pas: '',
        new_email: '',
        code: '',
    })

    function handleResetForm({ email }) {
        setPersonalInfoForm({
            previous_email: email,
            cur_pas: '',
            new_email: '',
            code: '',
        })
    }

    function handlePersonalInfoChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name
        switch (name) {

            default:
                setPersonalInfoForm(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
        }
    }




    const actions = clickedSide === 'left' ?
        [

        ]
        :
        [
            // {
            //     name: 'Сохранить',
            //     onClick: handleShopInfoSave,
            //     isMainAction: true,
            //     isPreloaderVisible: preloaders.update,
            // },
        ]

    return (
        <div className='account-main'>
            <PageHeading
                className={'account__heading'}
                title={current_location.location.name}
                // goBack={{
                //     onClick: () => { console.log('fdffd') },
                // }}
                actions={actions}
            />
            <ItemForm>

                <div className='account-main__content'>
                    <div className='account-main__personal' onClick={() => {
                        setClickedSide('left')
                    }}>
                        <h4 className='account-main__title'>Персональные данные</h4>
                        <PersonalInfo
                            form={personalInfoForm}
                            handleChange={handlePersonalInfoChange}
                            handleResetForm={handleResetForm}
                        />
                    </div>
                </div>
            </ItemForm>
        </div>
    );
}

export default AccountMain