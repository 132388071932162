import { useLocation } from 'react-router-dom';
import PageHeading from '../../PageHeading/PageHeading';
import './ProductPage.css';
import { getCurrentPlatfromLocation } from '../../../../assets/utils/utils';
import ItemForm from '../../ItemForm/ItemForm';
import PlatformInput from '../../PlatformInput/PlatformInput';
import { useContext, useState } from 'react';
import { PHOTO_GALLERY_TYPES, PHOTO_GALLERY_TYPE_GALLERY } from '../../../../assets/utils/constructorConstants';
import { PlatformLinksContext } from '../../../../assets/contexts/platformLinksContext';

function ProductPage() {
    const location = useLocation();
    const { links, default_link } = useContext(PlatformLinksContext)

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })
    const [initialValues, setInitialValues] = useState({
        PHOTO_GALLERY: PHOTO_GALLERY_TYPE_GALLERY,
    });


    const [values, setValues] = useState({
        PHOTO_GALLERY: PHOTO_GALLERY_TYPE_GALLERY,
    });

    function handleChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name
        let inputValue = value
        switch (name) {
            default:
                setValues(prevValue => ({
                    ...prevValue,
                    [name]: inputValue,
                }))
                break;
        }
    }


    function handleReset() {
        setValues(initialValues)
    }
    function handleSave() {

    }

  
    const actions = [
        {
            name: "Отменить все изменения",
            onClick: handleReset,
            // inactive: !isValuesChanged,
            id: "tokens__cancel-btn",
        },
        {
            name: "Сохранить",
            onClick: handleSave,
            isMainAction: true,
            // inactive: isSubmitDisabled,
            id: "tokens__save-btn",
        },
    ];

    return (
        <div className='product-page'>
            <PageHeading
                className={'product-page__heading'}
                title={current_location.location.name}
                actions={actions}
            // goBack={goBackActive ? {
            //     onClick: () => { navigate(-1) },
            // } : undefined}
            />
            <ItemForm
                containerClassName={'product-page__item-from-container'}
            >
                <div className='product-page__two-columns'>
                    <div className='product-page__column'>
                        <PlatformInput
                            name={`PHOTO_GALLERY`}
                            value={values.PHOTO_GALLERY}
                            placeholder={""}
                            label={"Представление фотографий"}
                            isSelect
                            selected_text_value={values.PHOTO_GALLERY.title}
                            handleChange={(e) => {
                                handleChange({
                                    target: {
                                        value: e.target.value,
                                        name: 'PHOTO_GALLERY',
                                    },
                                });
                            }}
                            selectList={PHOTO_GALLERY_TYPES}
                            selectDifferenceKey={"value"}
                            selectTextValueKey={"title"}
                        />
                    </div>

                </div>
            </ItemForm>
        </div>
    );
}

export default ProductPage