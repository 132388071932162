import { Navigate, Route, Routes } from "react-router-dom";
import "./Constructor.css";
import {
  CONSTRUCTOR_CARD_LINK,
  CONSTRUCTOR_INDEX_LINK,
  CONSTRUCTOR_PRODUCT_PAGE_LINK,
  CONSTRUCTOR_LANDING_LINK,
  CONSTRUCTOR_MAIN_LINK,
  CONSTRUCTOR_TOKENS_LINK,
  PLATFORM_MAIN_LINK,
} from "../../../assets/utils/constants";
import Tokens from "./Tokens/Tokens";
import KitCard from "./KitCard/KitCard";
import KitMain from "./KitMain/KitMain";
import ProductPage from "./ProductPage/ProductPage";

function Constructor() {
  return (
    <section className="constructor">
      <Routes>
        <Route
          index
          element={
            <Navigate
              to={`${PLATFORM_MAIN_LINK}/${CONSTRUCTOR_MAIN_LINK}/${CONSTRUCTOR_INDEX_LINK}`}
            />
          }
        />
        <Route
          path={`${CONSTRUCTOR_TOKENS_LINK}/*`}
          element={<Tokens />}
        />
        <Route
          path={`${CONSTRUCTOR_CARD_LINK}/*`}
          element={<KitCard />}
        />
        <Route
          path={`${CONSTRUCTOR_LANDING_LINK}/*`}
          element={<KitMain />}
        />
        <Route
          path={`${CONSTRUCTOR_PRODUCT_PAGE_LINK}/*`}
          element={<ProductPage />}
        />
      </Routes>
    </section>
  );
}

export default Constructor;
