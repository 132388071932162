export function LogoIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={137}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 137 36"
        >
            <g clipPath="url(#prefix__clip0_50_18208)">
                <path
                    d="M6.88 22.56v-4.44h6.52c.853 0 1.627-.173 2.32-.52a4.004 4.004 0 001.64-1.48c.4-.667.6-1.467.6-2.4 0-.907-.2-1.693-.6-2.36a3.921 3.921 0 00-1.64-1.52c-.693-.347-1.467-.52-2.32-.52H6.88V4.88h7.08c1.787 0 3.387.36 4.8 1.08 1.44.72 2.573 1.747 3.4 3.08.827 1.307 1.24 2.867 1.24 4.68 0 1.813-.413 3.387-1.24 4.72-.827 1.307-1.96 2.32-3.4 3.04-1.413.72-3.013 1.08-4.8 1.08H6.88zM2.88 33V4.88h5.44V33H2.88zm32.27.4c-1.947 0-3.707-.44-5.28-1.32a10.573 10.573 0 01-3.72-3.64c-.907-1.547-1.36-3.267-1.36-5.16 0-1.893.453-3.587 1.36-5.08a9.846 9.846 0 013.72-3.6c1.573-.907 3.32-1.36 5.24-1.36 1.973 0 3.733.453 5.28 1.36a9.846 9.846 0 013.72 3.6c.907 1.493 1.36 3.187 1.36 5.08 0 1.893-.453 3.613-1.36 5.16-.907 1.52-2.147 2.733-3.72 3.64-1.547.88-3.293 1.32-5.24 1.32zm-.04-4.88c.987 0 1.853-.213 2.6-.64a4.494 4.494 0 001.76-1.84c.427-.8.64-1.707.64-2.72 0-1.013-.213-1.907-.64-2.68a4.572 4.572 0 00-1.76-1.8c-.747-.453-1.613-.68-2.6-.68-.96 0-1.813.227-2.56.68a4.572 4.572 0 00-1.76 1.8c-.427.773-.64 1.667-.64 2.68 0 1.013.213 1.92.64 2.72a4.861 4.861 0 001.76 1.84c.747.427 1.6.64 2.56.64zM48.843 33V4.08h5.24V33h-5.24zm8.523-3l10.24-13.32h6.32L63.686 30h-6.32zm0 3v-3l4.16-1.56h12.16V33h-16.32zm.72-14.76v-4.56h15.84v3l-4.2 1.56h-11.64zM85.088 33.4c-1.76 0-3.347-.44-4.76-1.32-1.414-.88-2.52-2.08-3.32-3.6-.8-1.52-1.2-3.227-1.2-5.12 0-1.92.4-3.64 1.2-5.16.8-1.52 1.906-2.72 3.32-3.6 1.413-.88 3-1.32 4.76-1.32 1.386 0 2.626.28 3.72.84a6.538 6.538 0 012.6 2.36c.666.987 1.026 2.107 1.08 3.36v6.96c-.054 1.28-.414 2.413-1.08 3.4-.64.987-1.507 1.773-2.6 2.36-1.094.56-2.334.84-3.72.84zm.96-4.84c1.466 0 2.653-.48 3.56-1.44.906-.987 1.36-2.253 1.36-3.8 0-1.013-.214-1.907-.64-2.68-.4-.8-.974-1.413-1.72-1.84-.72-.453-1.574-.68-2.56-.68-.96 0-1.814.227-2.56.68-.72.427-1.294 1.04-1.72 1.84-.4.773-.6 1.667-.6 2.68 0 1.04.2 1.96.6 2.76.426.773 1 1.387 1.72 1.84.746.427 1.6.64 2.56.64zm4.64 4.44v-5.2l.84-4.72-.84-4.64v-4.76h5.2V33h-5.2z"
                    fill="#505A72"
                    className={fillClassName}
                />
                <path fill="#FF8585" d="M103 7h6v6h-6z" />
                <path fill="#FFB84F" d="M113.062 7h6v6h-6z" />
                <path fill="#7AE448" d="M123.125 7h6v6h-6z" />
            </g>
            <defs>
                <clipPath id="prefix__clip0_50_18208">
                    <path fill="#fff" d="M0 0h137v36H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}


export function HeaderMenuArrowIcon({ mainClassName, strokeClassName }) {
    return (
        <svg
            width={12}
            height={12}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 12 12"
        >
            <path
                d="M2 8l4-4 4 4"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={strokeClassName}
            />
        </svg>
    );
}

export function HomeIcon({
    mainClassName,
    fillClassName,
}) {
    return (
        <svg
            width={20}
            height={20}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 20 20"
        >
            <path
                d="M11.931 16.532v-4.859a.572.572 0 00-.155-.419.553.553 0 00-.412-.157H8.636a.578.578 0 00-.42.157.572.572 0 00-.155.42v4.858h3.87zm-8.543-.254v-5.705L9.55 5.324c.3-.26.602-.26.907 0l6.162 5.249v5.705c0 .539-.157.96-.472 1.265-.314.305-.742.457-1.282.457H5.143c-.546 0-.976-.152-1.29-.457-.31-.304-.465-.726-.465-1.265zM1 9.547c0-.23.096-.427.287-.591l7.681-6.552c.32-.27.666-.404 1.04-.404.373 0 .717.135 1.031.404l7.673 6.552c.192.16.288.364.288.613a.632.632 0 01-.221.51.777.777 0 01-.53.187.681.681 0 01-.325-.083 1.907 1.907 0 01-.288-.187L10.324 3.76a.481.481 0 00-.317-.134.481.481 0 00-.317.134L2.371 9.996c-.093.07-.192.133-.295.187a.64.64 0 01-.317.083c-.236 0-.422-.07-.56-.21A.73.73 0 011 9.547zm13.555-3.631V4.08c0-.164.05-.297.148-.396a.534.534 0 01.398-.158h.995a.52.52 0 01.545.554v3.617l-2.086-1.782z"
                fill="#E4E6EA"
                className={fillClassName}
            />
        </svg>
    );
}

export function AccountIcon({ mainClassName, strokeClassName }) {
    return (
        <svg
            width={22}
            height={22}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 22 22"
        >
            <path
                d="M11 12.833a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5z"
                stroke="#505A72"
                strokeWidth={1.2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={strokeClassName}
            />
            <path
                d="M11 19.708a8.708 8.708 0 100-17.416 8.708 8.708 0 000 17.416z"
                stroke="#505A72"
                strokeWidth={1.2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={strokeClassName}
            />
            <path
                d="M16.348 17.863a3.198 3.198 0 00-3.056-2.28H8.709a3.198 3.198 0 00-3.056 2.28"
                stroke="#505A72"
                strokeWidth={1.2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={strokeClassName}
            />
        </svg>
    );
}


export function SelectedTickIcon({
    mainClassName,
    strokeClassName,
}) {
    return (
        <svg
            width={14}
            height={14}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 14 14"
        >
            <path
                d="M2 6.348l3.499 3.514a.2.2 0 00.279.004L12 4"
                stroke="#505A72"
                strokeWidth={2}
                strokeLinecap="round"
                className={strokeClassName}
            />
        </svg>
    );
}

export function LogoutIcon({
    mainClassName,
    strokeClassName,
}) {
    return (
        <svg
            width={22}
            height={22}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 22 22"
        >
            <path
                d="M10.083 17.776V6.878c0-.633-.325-1.22-.861-1.555L5.555 3.031C4.334 2.27 2.75 3.146 2.75 4.586v10.897c0 .633.325 1.22.862 1.555l3.666 2.292c1.221.763 2.805-.115 2.805-1.554zM13.75 10.083h5.5M17.416 11.917l1.834-1.834-1.834-1.833M10.084 17.417h3.666c1.013 0 1.834-.82 1.834-1.834v-.916"
                stroke="#505A72"
                strokeWidth={1.2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={strokeClassName}
            />
            <path
                d="M15.584 5.5v-.917c0-1.013-.82-1.833-1.834-1.833H4.584"
                stroke="#505A72"
                strokeWidth={1.2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={strokeClassName}
            />
        </svg>
    );
}
