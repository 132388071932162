import mainApi from "../../../../../../../assets/api/MainApi";

export const fetchItems = (shopId) => {
    return mainApi.getAllFooterLinks({ shop_id: shopId });
};

export const deleteItem = (shopId, id) => {
    return mainApi.deleteFooterLink({ shop_id: shopId, _id: id });
};

export const sortItems = (shopId, ids) => {
    return mainApi.sortFooterLinks({ shop_id: shopId, data: ids });
};