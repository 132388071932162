import { useForm } from '../../hooks/useForm';
import { fetchItem, createItem, editItem } from '../../services/headerLinksFormApi';
import { PLATFORM_MAIN_LINK, SETTINGS_MAIN_LINK, SETTINGS_PAGES_AND_FILES_LINK, SETTINGS_PAGES_AND_FILES_HEADER_LINKS } from '../../../../../../../../assets/utils/constants';
import Form from './Form/Form';
import { deepEqual, isValidUrl } from '../../../../../../../../assets/utils/utils';

const initialForm = {
    name: '',
    url: '',
    is_visible: true,
};

function HeaderLinksForm({ mode }) {
    const { form, preloaders, error, handleChange, handleSubmit } = useForm({
        mode,
        initialForm,
        fetchItem: fetchItem,
        createItem: createItem,
        editItem: editItem, 
        itemResponseFormater: (res) => ({
            ...res,
        }),
        returnToListURL: `${PLATFORM_MAIN_LINK}/${SETTINGS_MAIN_LINK}/${SETTINGS_PAGES_AND_FILES_LINK}/${SETTINGS_PAGES_AND_FILES_HEADER_LINKS}`,
        editFormValidity: (form, initForm) => (!(form.name && form.url && isValidUrl(form.url) && !deepEqual(initForm, form))),
        createFormValidity: (form) => (!(form.name && form.url && isValidUrl(form.url))),
    });

    const formFields = [
        { name: 'name', label: 'Название', value: form.name, type: 'text' },
        { name: 'url', label: 'Ссылка', value: form.url, type: 'text' },
        {
            name: 'is_visible', label: 'Видимость на сайте', value: form.is_visible, type: 'boolean',
            boolean_values: {
                true: {
                    title: 'Виден',
                },
                false: {
                    title: 'Скрыт',
                }
            }
        },
    ];

    return (
        <Form
            error={error}
            formFields={formFields}
            handleChange={handleChange}
            loading={preloaders.main}
        />
    );
}

export default HeaderLinksForm;