import { useLocation, useNavigate } from 'react-router-dom';
import PageHeading from '../../../PageHeading/PageHeading';
import './UsersMain.css';
import { createAction, getCurrentPlatfromLocation, hasPermission, parseApiError, parsePermissions } from '../../../../../assets/utils/utils';
import { CONTROLS_TYPE, SETTINGS_USERS_СREATE_LINK, TEXT_TYPE, TITLE_TYPE } from '../../../../../assets/utils/constants';
import Table from '../../../Table/Table';
import moment from 'moment-timezone';
import "moment/min/locales";
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../assets/contexts/userContext';
import mainApi from '../../../../../assets/api/MainApi';
import { PlatformLinksContext } from '../../../../../assets/contexts/platformLinksContext';
import SubmitActionPopup from '../../../SubmitActionPopup/SubmitActionPopup';
import useAutoDismissError from '../../../../../assets/hooks/useAutoDismissError';
import { PERMISSIONS, RBAC_USER_MANAGEMENT } from '../../../../../assets/utils/permissions_rbac';
moment.locale('ru')

function UsersMain() {
    const { user } = useContext(UserContext)
    const [users, setUsers] = useState(null)
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        if (!user) return
        setLoading(true)
        mainApi.getUserManagentAll({
            shop_id: user.default_shop._id,
            limit: 25,
            last_id: null,
        })
            .then((res) => {
                console.log(res, moment().toISOString())
                setUsers(res.data)
            })
            .catch((err) => {
                console.log(err)
                if (err.statusCode === 403) {
                    setUsers(null)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, [user])
    const location = useLocation();
    const { links, default_link } = useContext(PlatformLinksContext)

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })

    const hasAddPermission = hasPermission(parsePermissions(user), [RBAC_USER_MANAGEMENT[PERMISSIONS.ADD]])
    const hasDeletePermission = hasPermission(parsePermissions(user), [RBAC_USER_MANAGEMENT[PERMISSIONS.DELETE]])
    const hasEditPermission = hasPermission(parsePermissions(user), [RBAC_USER_MANAGEMENT[PERMISSIONS.EDIT]])

    const navigate = useNavigate()

    function addActionsBasedOnPermissions() {
        const actions = [];
        if (hasAddPermission) {
            actions.push(createAction('Создать пользователя', () => navigate(`${location.pathname}/${SETTINGS_USERS_СREATE_LINK}`), {
                isMainAction: true,
            }));
        }


        return actions;
    }
    const [deleteError, showDeleteError] = useAutoDismissError()
    const [popups, setPopups] = useState({
        delete: false,
    })
    const [isPreloaderVisible, setIsPreloaderVisible] = useState({
        delete: false,
    })
    const [userToDelete, setUserToDelete] = useState(null)
    function toggleDeletePopup(user) {
        if (!user || !user.comment) {
            setTimeout(() => {
                setUserToDelete(null)
            }, 300);
        } else {
            setUserToDelete(user)
        }
        setPopups(prevValue => ({
            ...prevValue,
            delete: !prevValue.delete,
        }))

    }

    function handleDeleteUser() {
        const user_to_delete = userToDelete
        console.log(user_to_delete)
        setIsPreloaderVisible(prevValue => ({
            ...prevValue,
            delete: true
        }))
        mainApi.revokeAccessUserManagent({
            _id: user_to_delete.user._id,
            shop_id: user.default_shop._id,
        })
            .then((res) => {
                console.log(res)
                setUsers(prevValue => prevValue.filter((item) => item.user._id !== user_to_delete.user._id))
                setUserToDelete(null)
                setPopups(prevValue => ({
                    ...prevValue,
                    delete: false,
                }))
            })
            .catch((err) => {
                console.log(err)
                showDeleteError(parseApiError(err))
            })
            .finally(() => {
                setIsPreloaderVisible(prevValue => ({
                    ...prevValue,
                    delete: false
                }))
            })
    }

    const actions = addActionsBasedOnPermissions();


    let table_heading_list = [
        {
            name: 'Аккаунт',
            key: 'comment',
            type: TITLE_TYPE,
        },
        {
            name: 'Тип',
            key: 'role_title',
            type: TEXT_TYPE,
        },
        {
            name: 'Последний визит',
            key: 'last_visit',
            type: TEXT_TYPE,
        },
        {
            name: 'Cтатус регистрации',
            key: 'register_stage',
            type: TEXT_TYPE,
        },
    ]

    if (hasDeletePermission) {
        table_heading_list.push({
            name: "Управление",
            key: "controls",
            type: CONTROLS_TYPE,
        })
    }

    return (
        <div className='users-main'>
            <SubmitActionPopup
                isSubmitPreloader={isPreloaderVisible.delete}
                hendleSubmit={handleDeleteUser}
                hendleClose={toggleDeletePopup}
                isOpen={popups.delete}
                text={`Вы уверены что хотите удалить пользователя "${userToDelete ? userToDelete.comment : ''}"?`}
                submitText={"Удалить"}
                cencelText={"Отменить"}
                submitError={deleteError}
            />
            <PageHeading
                className={'users-main__heading'}
                title={current_location.location.name}
                // goBack={{
                //     onClick: () => { console.log('fdffd') },
                // }}
                actions={actions}
            />
            <div className='users-main__table'>
                <Table
                    noting_btn={hasAddPermission ? { text: 'Создать пользователя', path: `${location.pathname}/${SETTINGS_USERS_СREATE_LINK}` } : null}
                    headingList={table_heading_list}

                    nothingText={`Вы еще не добавили ни одного пользователя`}


                    list={
                        users && users.length > 0 ?
                            users.map((item, i) => {
                                const now = moment()
                                const last_seen = moment(item.user.last_seen + "+00:00")
                                let controls = []
                                const hasUndeletablePermission = hasPermission(item.role.permissions, [RBAC_USER_MANAGEMENT[PERMISSIONS.UNDELETABLE]])
                                if (!hasUndeletablePermission && item.user._id !== user._id && hasDeletePermission) {
                                    controls.push({
                                        name: "Удалить",
                                        onClick: () => {
                                            toggleDeletePopup(item);
                                        },
                                    })
                                }
                                return {
                                    ...item,
                                    comment: item.user._id !== user._id ? item.comment : `${item.comment} (Вы)`,
                                    role_title: item.role.title.ru,
                                    last_visit: item.user.is_online ? 'Сейчас онлайн' : last_seen.diff(now, 'ms') > 0 ? 'Сейчас онлайн' : last_seen.fromNow(),
                                    // edits_count: `${item.edits_count} изменений`,
                                    register_stage: item.user.last_name && item.user.first_name ? 'Прошел регистрацию' : 'Ожидание',
                                    controls: controls,
                                }
                            })
                            :
                            null}

                    isInitTablePreloaderVisible={isLoading}
                />
            </div>
        </div>
    );
}

export default UsersMain