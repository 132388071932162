import PromoBanner from '../components/Promo/PromoBanner/PromoBanner';
import ShowCase from '../components/ShowCase/ShowCase';
import PromoTextPicture from '../components/Promo/PromoTextPicture/PromoTextPicture';

export const TYPE_MAP = {
    'promo-banner-100%': {
        title: 'Промо баннер на весь экран',
        form: PromoBanner,
    },
    'promo-banner-scroll': {
        title: 'Промо баннер со скроллом',
        form: PromoBanner,
    },
    'promo-text-image': {
        title: 'Промо текст с фото',
        form: PromoTextPicture,
    },
    'promo-text-image-reverse': {
        title: 'Промо текст с фото Reverse',
        form: PromoTextPicture,
    },
    'showcase-line-1': {
        title: 'Товары 1 строка',
        form: ShowCase,
    },
    'showcase-line-2': {
        title: 'Товары 2 строки',
        form: ShowCase,
    },
    'showcase-line-3': {
        title: 'Товары 3 строки',
        form: ShowCase,
    },
    'showcase-line-1-with-image': {
        title: 'Товары 1 строка с фото',
        form: ShowCase,
    },
    'showcase-line-2-with-image': {
        title: 'Товары 2 строки с фото',
        form: ShowCase,
    },
    'showcase-line-3-with-image': {
        title: 'Товары 3 строки с фото',
        form: ShowCase,
    },
    'showcase-scroll': {
        title: 'Товары со скроллом',
        form: ShowCase,
    },
    'showcase-line-1-with-hero': {
        title: 'Товары 1 строка с главным товаром',
        form: ShowCase,
    },
    'showcase-line-2-with-hero': {
        title: 'Товары 2 строки с главным товаром',
        form: ShowCase,
    },
    'showcase-line-3-with-hero': {
        title: 'Товары 3 строки с главным товаром',
        form: ShowCase,
    },
}