import { useState, useEffect } from 'react';
import { fetchDocs, deleteDoc, sortDocs } from '../services/documentsApi';

export const useList = ({ user, fetchItems, deleteItem, sortItems }) => {
    const [items, setItmes] = useState(null);
    const [initialItems, setInitialItems] = useState(null);
    const [preloaders, setPreloaders] = useState({ all: true, delete: false, sort: false });
    const [popups, setPopups] = useState({ delete: false });
    const [itemToDelete, setItemToDelete] = useState(null);

    useEffect(() => {
        if (!user) return;
        setPreloaders(prev => ({ ...prev, all: true }));
        fetchItems(user.default_shop._id)
            .then((res) => {
                setItmes(res.data);
                setInitialItems(res.data);
            })
            .catch(console.error)
            .finally(() => setPreloaders(prev => ({ ...prev, all: false })));
    }, [user]);

    const handleDeleteItem = () => {
        setPreloaders(prev => ({ ...prev, delete: true }));
        deleteItem(user.default_shop._id, itemToDelete._id)
            .then(() => {
                setItmes(prev => prev.filter(item => item._id !== itemToDelete._id));
                setInitialItems(prev => prev.filter(item => item._id !== itemToDelete._id));
                setPopups(prev => ({ ...prev, delete: false }));
                setItemToDelete(null);
            })
            .catch(console.error)
            .finally(() => setPreloaders(prev => ({ ...prev, delete: false })));
    };

    const handleSortItems = () => {
        setPreloaders(prev => ({ ...prev, sort: true }));
        sortItems(user.default_shop._id, items.map(item => item._id))
            .then(() => setInitialItems(items))
            .catch(console.error)
            .finally(() => setPreloaders(prev => ({ ...prev, sort: false })));
    };

    return {
        items,
        initialItems,
        preloaders,
        popups,
        itemToDelete,
        setItmes,
        setPopups,
        setItemToDelete,
        handleDeleteItem,
        handleSortItems,
    };
};