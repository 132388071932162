import "./ItemForm.css";

function ItemForm({ children, mainClassName, containerClassName }) {
  return (
    <div className={`item-form ${mainClassName ? mainClassName : ""}`}>
      <div
        className={`item-form__container ${
          containerClassName ? containerClassName : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
}

export default ItemForm;
