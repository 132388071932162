import mainApi from "../../../../../../../assets/api/MainApi";
import { getRandomId } from "../../../../../../../assets/utils/utils";
import { transformFormatV2 } from "../utils/editMainPageHelpers";


export const uploadMainPageImage = (data) => mainApi.uploadImage({ data, type: 'static' });

// Функция для получения данных статической страницы
export const fetchStaticPagesData = async (shopId) => {
    try {
        const response = await mainApi.getStaticPagesData({ shop_id: shopId });
        const formattedData = response.data.map((item) => ({
            ...item,
            _id: getRandomId(),
            data: item.data.map((subItem) => ({
                ...subItem,
                image: subItem.image ? [subItem.image] : null,
                image_mobile: subItem.image_mobile ? [subItem.image_mobile] : null,
                image_tablet: subItem.image_tablet ? [subItem.image_tablet] : null,
                _id: getRandomId(),
            })),
        }));
        return formattedData;
    } catch (error) {
        throw error;
    }
};

// Функция для сохранения данных
export const saveStaticPageData = async (shopId, layout) => {
    try {
        const formattedLayout = layout.map((item) => ({
            _id: item.block._id,
            data: transformFormatV2(item.data),
        }));
        
        const response = await mainApi.setStaticData({
            shop_id: shopId,
            data: formattedLayout,
        });
        return response;
    } catch (error) {
        throw error;
    }
};