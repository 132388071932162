export function LabelIcon({ mainClassName, fillClassName }) {
  return (
    <svg
      width={13}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mainClassName}
      viewBox="0 0 13 13"
    >
      <path
        d="M6.86 12.377c-.414.415-.835.623-1.262.623-.427.004-.853-.206-1.28-.629L.634 8.691C.208 8.266-.004 7.839 0 7.412c.004-.427.213-.848.629-1.263L6.319.466c.144-.144.274-.249.39-.315.12-.066.25-.106.39-.122C7.24.01 7.41 0 7.611 0H9.83c.205 0 .372.02.5.058a.943.943 0 01.36.187c.11.085.237.2.385.343l1.338 1.339c.148.147.264.277.35.39a.987.987 0 01.18.361c.039.128.058.295.058.5v2.213c0 .206-.01.378-.03.518a1.036 1.036 0 01-.121.384 1.777 1.777 0 01-.315.396l-5.673 5.688zm-.628-.704l5.645-5.647a.663.663 0 00.134-.187.517.517 0 00.052-.244V3.138c0-.16-.062-.3-.186-.425l-1.589-1.584a.577.577 0 00-.425-.192h-2.45a.554.554 0 00-.25.053.78.78 0 00-.186.14l-5.65 5.64c-.206.206-.31.42-.314.641 0 .217.106.433.32.646l3.613 3.61c.43.434.86.436 1.286.006zm2.916-7.016a.763.763 0 01-.57-.232.78.78 0 01-.227-.565c0-.225.075-.414.226-.565a.763.763 0 01.57-.233c.23 0 .42.078.571.233.151.151.227.34.227.565a.78.78 0 01-.227.565.763.763 0 01-.57.232z"
        fill="#505A72"
        className={fillClassName}
      />
    </svg>
  );
}
export function EditIcon({ mainClassName, fillClassName }) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mainClassName}
      viewBox="0 0 16 16"
    >
      <path
        d="M2.54 16c-.841 0-1.475-.21-1.901-.632C.213 14.952 0 14.326 0 13.488V4.158c0-.837.213-1.464.639-1.88.426-.42 1.06-.631 1.9-.631h8.696L9.933 2.95H2.564c-.404 0-.717.108-.938.324-.216.216-.324.535-.324.956v9.194c0 .421.108.74.324.956.221.21.534.316.938.316h9.456a.948.948 0 00.712-.316c.2-.216.3-.535.3-.956v-7.29l1.301-1.305v8.66c0 .837-.202 1.463-.606 1.88-.4.42-.963.631-1.69.631H2.54zm2.944-5.25c-.075.033-.145.017-.21-.048-.06-.07-.073-.14-.04-.21l.727-1.523 7.547-7.558 1.109 1.093-7.555 7.558-1.578.689zm9.731-8.837L14.107.795l.59-.583c.135-.13.289-.2.462-.21a.564.564 0 01.452.17l.186.194c.14.135.208.291.203.47a.69.69 0 01-.203.478l-.582.599z"
        fill="#fff"
        className={fillClassName}
      />
    </svg>
  );
}
export function ProductIcon({ mainClassName, fillClassName }) {
  return (
    <svg
      width={12}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mainClassName}
      viewBox="0 0 12 13"
    >
      <path
        d="M6.003 6.153L.426 2.957c.087-.08.197-.157.331-.233L4.927.323C5.293.108 5.652 0 6.003 0c.347 0 .704.108 1.07.323l4.176 2.401c.13.076.238.154.325.233L6.003 6.153zM5.595 13a.184.184 0 01-.06-.024.797.797 0 01-.053-.03L.846 10.282a1.997 1.997 0 01-.627-.544C.073 9.531 0 9.21 0 8.776V4.122c0-.183.016-.334.047-.454L5.595 6.87V13zm.81 0V6.87l5.548-3.202c.031.12.047.271.047.454v4.654c0 .434-.073.755-.219.962-.142.203-.35.384-.627.544l-4.63 2.664a.41.41 0 01-.06.03.184.184 0 01-.059.024z"
        fill="#505A72"
        className={fillClassName}
      />
    </svg>
  );
}

export function ProductListIcon({ mainClassName, fillClassName }) {
  return (
    <svg
      className={mainClassName}
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={fillClassName}
        d="M8 16a7.702 7.702 0 01-3.09-.627 8.192 8.192 0 01-2.55-1.734A8.19 8.19 0 01.628 11.09 7.702 7.702 0 010 8c0-1.093.21-2.12.627-3.082A8.232 8.232 0 012.353 2.36 8.19 8.19 0 014.902.627 7.702 7.702 0 017.992 0c1.093 0 2.123.21 3.09.627.968.419 1.82.997 2.557 1.734a8.166 8.166 0 011.734 2.557C15.79 5.88 16 6.908 16 8c0 1.093-.21 2.123-.627 3.09a8.191 8.191 0 01-1.734 2.55 8.165 8.165 0 01-2.557 1.733A7.646 7.646 0 018 16zm0-1.333a6.52 6.52 0 002.596-.518 6.788 6.788 0 002.126-1.427 6.614 6.614 0 001.427-2.126A6.52 6.52 0 0014.667 8a6.52 6.52 0 00-.518-2.596 6.72 6.72 0 00-1.435-2.126 6.5 6.5 0 00-2.126-1.427 6.52 6.52 0 00-2.596-.518 6.52 6.52 0 00-2.596.518 6.524 6.524 0 00-2.118 1.427 6.787 6.787 0 00-1.427 2.126c-.34.805-.51 1.67-.51 2.596 0 .925.17 1.79.51 2.596.345.805.82 1.514 1.427 2.126a6.787 6.787 0 002.126 1.427A6.52 6.52 0 008 14.667zM6.086 5.882a.46.46 0 01-.337-.14.46.46 0 01-.141-.338.47.47 0 01.478-.47h5.883c.13 0 .24.044.329.133a.444.444 0 01.141.337.47.47 0 01-.47.478H6.086zm0 5.193a.484.484 0 01-.345-.134.484.484 0 01-.133-.345c0-.13.047-.24.141-.33a.46.46 0 01.337-.14h5.883c.13 0 .24.044.329.133a.444.444 0 01.141.337.47.47 0 01-.47.479H6.086zm0-2.597a.46.46 0 01-.337-.14A.46.46 0 015.608 8c0-.13.047-.24.141-.33a.46.46 0 01.337-.14h5.883c.13 0 .24.044.329.133a.444.444 0 01.141.337.47.47 0 01-.47.478H6.086zm-1.89-2.4a.65.65 0 01-.478-.196.65.65 0 01-.196-.478c0-.188.065-.348.196-.479a.65.65 0 01.478-.196c.188 0 .348.066.479.196.13.131.196.29.196.479a.655.655 0 01-.204.478.642.642 0 01-.47.196zm0 2.597a.65.65 0 01-.478-.197A.65.65 0 013.522 8c0-.188.065-.348.196-.478a.65.65 0 01.478-.197.63.63 0 01.47.204.655.655 0 010 .949.642.642 0 01-.47.197zm0 2.596a.651.651 0 01-.478-.197.65.65 0 01-.196-.478c0-.188.065-.348.196-.478.13-.131.29-.196.478-.196a.63.63 0 01.47.204.63.63 0 01.205.47.65.65 0 01-.196.479.651.651 0 01-.479.196z"
        fill="#505A72"
      />
    </svg>
  );
}
