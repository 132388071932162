import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import PageHeading from '../../PageHeading/PageHeading';
import './Integrations.css';
import { filterLinksForUserRBAC, getCurrentPlatfromLocation, getDefaultLinkForUserRBAC } from '../../../../assets/utils/utils';
import { useContext, useState } from 'react';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import ItemForm from '../../ItemForm/ItemForm';
import { PLATFORM_MAIN_LINK, SETTINGS_INTEGRATIONS_1S_PRODUCTS_LINK, SETTINGS_INTEGRATIONS_LINKS, SETTINGS_INTEGRATIONS_NOTIFICATIONS_EMAIL_LINK, SETTINGS_INTEGRATIONS_NOTIFICATIONS_TELEGRAM_LINK, SETTINGS_INTEGRATIONS_PAYMNET_SYSTEMS_LINK, SETTINGS_INTEGRATIONS_WEBSERVICE_LINK, SETTINGS_INTEGRATIONS_YANDEX_METRICS_LINK, keyForErrorOfAccess } from '../../../../assets/utils/constants';
import PaymentSystems from './PaymentSystems/PaymentSystems';

import OneSProducts from './OneSProducts/OneSProducts';
import InternalLinks from '../../InternalLinks/InternalLinks';
import YandexMetrics from './YandexMetrics/YandexMetrics';
import WebService from './WebService/WebService';
import NotificationsEmail from './NotificationsEmail/NotificationsEmail';
import NotificationsTelegram from './NotificationsTelegram/NotificationsTelegram';
import { PlatformLinksContext } from '../../../../assets/contexts/platformLinksContext';
import { UserContext } from '../../../../assets/contexts/userContext';
import NotFoundErrorPage from '../../../NotFoundErrorPage/NotFoundErrorPage';

function IntegrationsMobileRoutePage({
    title,
    goBackActive,
    children,
}) {

    const navigate = useNavigate()

    return (
        <div className='integrations__content'>
            <PageHeading
                className={'integrations__heading'}
                title={title}
                goBack={goBackActive ? {
                    onClick: () => { navigate(-1) },
                } : undefined}
            />
            <ItemForm
                containerClassName={'integrations__item-from-container'}
            >
                {children}
            </ItemForm>
        </div>
    )
}

function Integrations() {
    const { width } = useWindowSize()
    // const location = useLocation()
    // const [actions, setActions] = useState([
    //     {
    //         name: 'Создать группу',
    //         path: `${location.pathname}/${PROMOCODES_CREATE_GROUP_LINK}`,
    //         isMainAction: true,
    //     },
    //     // {
    //     //     name: 'OnClick',
    //     //     onClick: () => { console.log('fddsfeee') },
    //     //     inactive: true,
    //     // },
    // ])

    const [clickedSide, setClickedSide] = useState('left')


    const location = useLocation();

    const { links, default_link } = useContext(PlatformLinksContext)

    const current_location = getCurrentPlatfromLocation({
        location: location,
        links,
    })

    const { user } = useContext(UserContext)
    const LINKS_BY_USER_RBAC = user && user.default_shop && user.default_shop.role ? filterLinksForUserRBAC(user.default_shop.role.permissions, SETTINGS_INTEGRATIONS_LINKS) : null
    const DEFAULT_LINK_BY_USER_RBAC = PLATFORM_MAIN_LINK + getDefaultLinkForUserRBAC(LINKS_BY_USER_RBAC)
    console.log(LINKS_BY_USER_RBAC)

    return (
        <>
            {!current_location ?
                <NotFoundErrorPage errorType={keyForErrorOfAccess} />
                :
                width ?
                    width > 880 ?
                        <div className='integrations'>
                            <PageHeading
                                className={'integrations__heading'}
                                title={current_location.location.name}
                            />
                            <ItemForm
                                containerClassName={'integrations__item-from-container'}
                            >
                                <div className='integrations__two-columns'>
                                    <div className='integrations__products-list' onClick={() => {
                                        setClickedSide('left')
                                    }}>
                                        <InternalLinks
                                            links={LINKS_BY_USER_RBAC}
                                            keyValue={'integrations__desctop'}
                                        />
                                    </div>
                                    <div className='integrations__products-form' onClick={() => {

                                        setClickedSide('right')
                                    }}>
                                        <Routes>
                                            <Route index element={<Navigate to={LINKS_BY_USER_RBAC[0].path} />}>
                                            </Route>
                                            <Route path={`/${SETTINGS_INTEGRATIONS_PAYMNET_SYSTEMS_LINK}`} element={
                                                <PaymentSystems />
                                            }>
                                            </Route>
                                            <Route path={`/${SETTINGS_INTEGRATIONS_1S_PRODUCTS_LINK}`} element={
                                                <OneSProducts />
                                            }>
                                            </Route>
                                            <Route path={`/${SETTINGS_INTEGRATIONS_YANDEX_METRICS_LINK}`} element={
                                                <YandexMetrics />
                                            }>
                                            </Route>
                                            <Route path={`/${SETTINGS_INTEGRATIONS_WEBSERVICE_LINK}`} element={
                                                <WebService />
                                            }>
                                            </Route>
                                            <Route path={`/${SETTINGS_INTEGRATIONS_NOTIFICATIONS_EMAIL_LINK}`} element={
                                                <NotificationsEmail />
                                            }>
                                            </Route>
                                            <Route path={`/${SETTINGS_INTEGRATIONS_NOTIFICATIONS_TELEGRAM_LINK}`} element={
                                                <NotificationsTelegram />
                                            }>
                                            </Route>
                                        </Routes>
                                    </div>

                                </div>
                            </ItemForm>
                        </div>
                        :
                        <div className='integrations'>
                            <Routes>
                                <Route index element={
                                    <IntegrationsMobileRoutePage
                                        goBackActive={false}
                                        title={current_location.location.name}
                                    >
                                        <InternalLinks
                                            links={LINKS_BY_USER_RBAC}
                                            keyValue={'integrations__mobile'}
                                        />
                                    </IntegrationsMobileRoutePage>
                                }>
                                </Route>
                                <Route path={`/${SETTINGS_INTEGRATIONS_PAYMNET_SYSTEMS_LINK}`} element={
                                    <IntegrationsMobileRoutePage
                                        goBackActive={true}
                                        title={'Платежная система ЮKassa'}
                                    >
                                        <PaymentSystems />
                                    </IntegrationsMobileRoutePage>
                                }>
                                </Route>
                                <Route path={`/${SETTINGS_INTEGRATIONS_1S_PRODUCTS_LINK}`} element={
                                    <IntegrationsMobileRoutePage
                                        goBackActive={true}
                                        title={'1С: Товары'}
                                    >
                                        <OneSProducts />
                                    </IntegrationsMobileRoutePage>
                                }>
                                </Route>
                                <Route path={`/${SETTINGS_INTEGRATIONS_YANDEX_METRICS_LINK}`} element={
                                    <IntegrationsMobileRoutePage
                                        goBackActive={true}
                                        title={'Яндекс Метрика'}
                                    >
                                        <YandexMetrics />
                                    </IntegrationsMobileRoutePage>
                                }>
                                </Route>
                                <Route path={`/${SETTINGS_INTEGRATIONS_WEBSERVICE_LINK}`} element={
                                    <IntegrationsMobileRoutePage
                                        goBackActive={true}
                                        title={'Веб-сервисы'}
                                    >
                                        <WebService />
                                    </IntegrationsMobileRoutePage>
                                }>
                                </Route>
                                <Route path={`/${SETTINGS_INTEGRATIONS_NOTIFICATIONS_EMAIL_LINK}`} element={
                                    <IntegrationsMobileRoutePage
                                        goBackActive={true}
                                        title={'Email уведомления'}
                                    >
                                        <NotificationsEmail />
                                    </IntegrationsMobileRoutePage>
                                }>
                                </Route>
                                <Route path={`/${SETTINGS_INTEGRATIONS_NOTIFICATIONS_TELEGRAM_LINK}`} element={
                                    <IntegrationsMobileRoutePage
                                        goBackActive={true}
                                        title={'Telegram уведомления'}
                                    >
                                        <NotificationsTelegram />
                                    </IntegrationsMobileRoutePage>
                                }>
                                </Route>
                            </Routes>

                        </div >
                    :
                    null

            }
        </>
    );
}

export default Integrations