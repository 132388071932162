import { Navigate, Route, Routes } from 'react-router-dom';

import './Settings.css';
import { PLATFORM_MAIN_LINK, PLATFORM_SETTINGS_LINKS, SETTINGS_CONSTANTS_LINK, SETTINGS_DOMAIN_LINK, SETTINGS_FINANCE_LINK, SETTINGS_INDEX_LINK, SETTINGS_INFO_LINK, SETTINGS_INTEGRATIONS_LINK, SETTINGS_MAIN_LINK, SETTINGS_PAGES_AND_FILES_LINK, SETTINGS_USERS_LINK } from '../../../assets/utils/constants';

import Integrations from './Integrations/Integrations';
import PagesAndFiles from './PagesAndFiles/PagesAndFiles';
import Users from './Users/Users';
import Domain from './Domain/Domain';
import { useContext } from 'react';
import { UserContext } from '../../../assets/contexts/userContext';
import { filterLinksForUserRBAC, getDefaultLinkForUserRBAC, hasPermission, parsePermissions } from '../../../assets/utils/utils';
import ShopInfo from './ShopInfo/ShopInfo';
import Finance from './Finance/Finance';
import SystemConstants from './SystemConstants/SystemConstants';
import { PERMISSIONS, RBAC_CONTENT_MANAGEMENT } from '../../../assets/utils/permissions_rbac';

function Settings() {

    const { user } = useContext(UserContext)
    const LINKS_BY_USER_RBAC = user && user.default_shop && user.default_shop.role ? filterLinksForUserRBAC(user.default_shop.role.permissions, [PLATFORM_SETTINGS_LINKS]) : null
    const DEFAULT_LINK_BY_USER_RBAC = PLATFORM_MAIN_LINK + getDefaultLinkForUserRBAC(LINKS_BY_USER_RBAC)
    const hasEditLayoutPermission = hasPermission(parsePermissions(user), [RBAC_CONTENT_MANAGEMENT[PERMISSIONS.EDIT_LAYOUT]])

    return (
        <div className='settings'>
            <Routes>
                <Route index element={
                    <Navigate to={DEFAULT_LINK_BY_USER_RBAC} />
                }>
                </Route>
                <Route path={`${SETTINGS_INFO_LINK}`} element={
                    <ShopInfo />
                }>
                </Route>
                <Route path={`${SETTINGS_INFO_LINK}/${SETTINGS_CONSTANTS_LINK}`} element={
                    hasEditLayoutPermission ? <SystemConstants /> : <Navigate to={DEFAULT_LINK_BY_USER_RBAC} />
                }>
                </Route>
                <Route path={`${SETTINGS_INTEGRATIONS_LINK}/*`} element={
                    <Integrations />
                }>
                </Route>
                <Route path={`${SETTINGS_PAGES_AND_FILES_LINK}/*`} element={
                    <PagesAndFiles />
                }>
                </Route>
                <Route path={`${SETTINGS_USERS_LINK}/*`} element={
                    <Users />
                }>
                </Route>
                <Route path={`${SETTINGS_DOMAIN_LINK}/*`} element={
                    <Domain />
                }>
                </Route>
                <Route path={`${SETTINGS_FINANCE_LINK}/*`} element={
                    <Finance />
                }>
                </Route>
            </Routes>
        </div>
    );
}

export default Settings

