import { useContext, useEffect, useState } from 'react';
// import SubscribeCard from '../SubscribeCard/SubscribeCard';
import './SimpleSubscription.css';

import { UserContext } from '../../../../../assets/contexts/userContext';
import mainApi from '../../../../../assets/api/MainApi';
import SimpleSubsctiptionCard from './SimpleSubsctiptionCard/SimpleSubsctiptionCard';
import Button from '../../../../Button/Button';
import { PLATFORM_MAIN_LINK, SUBSCRIPTIONS_MAIN_LINK, SUBSCRIPTIONS_PLATFROM_LINK } from '../../../../../assets/utils/constants';


function SimpleSubscription() {

    const { user } = useContext(UserContext)
    const { default_shop } = user
    const current_subscription = default_shop?.subscriptions?.main


    const [subscriptions, setSubscriptions] = useState(null)

    useEffect(() => {
        mainApi.getSubscrtiptionsWithAuth({ type: 'simple', shop_id: default_shop._id })
            .then((res) => {
                console.log(res)
                setSubscriptions(res.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }, [])

    console.log(current_subscription)



    return (
        <div className="simple-subscription-wix">
            <div className='simple-subscription-wix__titles-box'>
                <div className='simple-subscription-wix__title-with-logo'>
                    <svg className='simple-subscription-wix__logo' width="311" height="121" viewBox="0 0 311 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1971_7)">
                            <path d="M177.998 2.30002C171.998 5.30002 169.398 10.9 169.398 26.1C169.398 26.1 172.398 23.1 177.198 21.3C180.698 20 183.198 18.3 184.998 17C190.198 13.1 190.998 8.40002 190.998 0.200018C190.898 0.200018 182.698 -0.299982 177.998 2.30002Z" fill="#FBBD71" />
                            <path d="M141.3 5.8C136.1 10.1 134.8 17.5 134.8 17.5L118 81.9L104.2 29.2C102.9 23.6 100.3 16.7 96.4 11.9C91.6 5.79999 81.6 5.4 80.4 5.4C79.5 5.4 69.6 5.79999 64.4 11.9C60.5 16.7 57.9 23.6 56.6 29.2L43.6 81.9L26.8 17.5C26.8 17.5 25.5 10.6 20.3 5.8C12.1 -1.6 0 0.199996 0 0.199996L32 120.7C32 120.7 42.4 121.6 47.6 119C54.5 115.5 58 113 61.9 96.5C65.8 81.8 76.2 39 77 36C77.4 34.7 78.3 30.8 80.9 30.8C83.5 30.8 84.4 34.3 84.8 36C85.7 39 96 81.8 99.9 96.5C104.2 112.9 107.2 115.5 114.2 119C119.4 121.6 129.8 120.7 129.8 120.7L161.6 0.199996C161.6 0.199996 149.5 -1.5 141.3 5.8Z" fill="black" />
                            <path d="M190.901 19.6C190.901 19.6 188.701 22.6 184.401 25.2C181.401 26.9 178.801 27.8 175.801 29.5C170.601 32.1 169.301 34.7 169.301 38.6V39.9V46.4V47.7V120.3C169.301 120.3 177.501 121.2 182.701 118.6C189.601 115.1 190.901 111.7 190.901 97V24.4V19.6Z" fill="black" />
                            <path d="M270.401 60.7L311.001 0.600006C311.001 0.600006 294.201 -2.39999 285.501 5.40001C279.901 10.2 274.301 19.2 274.301 19.2L259.601 40.8C258.701 42.1 257.901 43 256.601 43C255.301 43 254.001 41.7 253.601 40.8L238.901 19.2C238.901 19.2 232.901 10.6 227.701 5.40001C219.101 -2.39999 202.201 0.600006 202.201 0.600006L241.501 60.6L201.301 120.6C201.301 120.6 219.001 122.8 227.701 115C233.301 110.2 238.501 102 238.501 102L253.201 80.4C254.101 79.1 254.901 78.2 256.201 78.2C257.501 78.2 258.801 79.5 259.201 80.4L273.901 102C273.901 102 279.501 110.2 284.701 115C293.301 122.8 310.601 120.6 310.601 120.6L270.401 60.7Z" fill="black" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1971_7">
                                <rect width="311" height="121" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <p className='simple-subscription-wix__title'>Специальная цена для клиентов <span className='simple-subscription-wix__title_wix'>WIX</span></p>
                </div>
                <p className='simple-subscription-wix__subtitle'>Для магазинов, переходящих с WIX, мы предлагаем эксклюзивную подписку по специальной цене.</p>
            </div>
            {subscriptions && subscriptions.length > 0 ? <SimpleSubsctiptionCard subscriptions={subscriptions} current_subscription={current_subscription} /> : null}

        </div>
    );
}

export default SimpleSubscription;