import { useEffect, useRef, useState } from "react";
import "./Input.css";
import { HidePassonrdIcon, ShowPassonrdIcon } from "../../assets/icons/auth/auth";

function Input({
  index,
  label,
  boxType,
  isPassword,
  value,
  name,
  type,
  inputMode = "text",
  handleChange,
  isRequired,
  readOnly = false,
  icon,
  handleVisibility,
  error,
  isTextArea,
  maxRows,
  minRows,
  onKeyDown,
  placeholder,
}) {

  const inputRef = useRef(null)
  const [isFocuced, setIsFocuced] = useState(false);

  useEffect(() => {
    Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
  }, [value]);

  function handleFocused() {
    setIsFocuced(true);
  }

  function handleUnfocused(evt) {
    setIsFocuced(false);
  }

  const handleClickOutside = () => {
    inputRef.current.focus();
  };

  const [isHidden, setHidden] = useState(true)

  return (
    <div className={`input__input-box ${isFocuced ? 'input__input-box_focused' : ''} ${error ? 'input__input-box_error' : ''}`} onClick={handleClickOutside}>
      <input
        ref={inputRef}
        className={'input__input'}
        type={isPassword ? isHidden ? 'password' : 'text' : type}
        inputMode={inputMode}
        value={value || ""}
        autoComplete="off"
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        onFocus={handleFocused}
        onBlur={handleUnfocused}
        onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e)
          }
        }}
      />
      {isPassword ?
        <button className="input__show-btn" type="button" onClick={() => {
          setHidden(prevValue => !prevValue)
        }}>
          {isHidden ?
            <HidePassonrdIcon
              mainClassName={'input__show-btn-icon'}
              fillClassName={'input__show-btn-icon-fill'}
            />
            :
            <ShowPassonrdIcon
              mainClassName={'input__show-btn-icon'}
              fillClassName={'input__show-btn-icon-fill'}
            />}
        </button>
        : null}
    </div>
  );
}

export default Input;
