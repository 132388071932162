import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeading from "../../PageHeading/PageHeading";
import ItemForm from "../../ItemForm/ItemForm";
import PlatformInput from "../../PlatformInput/PlatformInput";
import ImagesInput from "../../ImagesInput/ImagesInput";
import { CATALOG_CATEGORY_FILTERS_LINK, DEFAULT_MAX_IMG_SIZE } from "../../../../assets/utils/constants";
import "./EditFilters.css";
import mainApi from "../../../../assets/api/MainApi";
import { UserContext } from "../../../../assets/contexts/userContext";
import useAutoDismissError from "../../../../assets/hooks/useAutoDismissError";
import { createAction, hasPermission, parseApiError, parsePermissions } from "../../../../assets/utils/utils";
import MiniPreloader from "../../../MiniPreloader/MiniPreloader";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { PERMISSIONS, RBAC_CATEGORY_MANAGEMENT } from "../../../../assets/utils/permissions_rbac";
import RangeFilter from "./RangeFilter/RangeFilter";
import SubmitActionPopup from "../../SubmitActionPopup/SubmitActionPopup";
import FilterCard from "./FilterCard/FilterCard";

function EditFilters() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [initialValues, setInitialValues] = useState({});
  const [filters, setFilters] = useState(null)
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);
  const [preloaders, setPreloaders] = useState({
    init: true,
    special_update: false,
    conver_filter: false,
  })
  const [popups, setPopups] = useState({
    special_update: false,
    conver_filter: false,
  })
  const [error, showError] = useAutoDismissError();


  // const hasEditPermission = hasPermission(parsePermissions(user), [RBAC_CATEGORY_MANAGEMENT[PERMISSIONS.EDIT]])


  function addActions() {
    const actions = [];

    // if (hasEditPermission) {
    //   actions.push(createAction(`Настройка фильтров`, () => {
    //     navigate(`${location.pathname}/${CATALOG_CATEGORY_FILTERS_LINK}`)
    //   }, {
    //     isMainAction: false,
    //   }));
    //   actions.push(createAction("Сохранить", handleSave, {
    //     isMainAction: true,
    //     inactive: getSaveActionInactiveState(),
    //     isPreloaderVisible: isSavePreloader,
    //   }));
    // }

    return actions;
  }

  const actions = addActions();

  useEffect(() => {
    if (!id || !user) return;
    const shop_id = user.default_shop._id;
    setPreloaders(prevValue => ({
      ...prevValue,
      init: true,
    }));
    mainApi
      .getIdCategories({ _id: id, shop_id })
      .then((res) => {
        const data = {
          _id: res._id,
          name: res.name,
          translit_name: res.translit_name,
          files: res.file ? [res.file] : [],
        };
        mainApi.getFiltersByCategory({
          _id: id, shop_id
        })
          .then((res2) => {
            setInitialValues(data);
            console.log({ res: res2.data })
            setFilters(res2.data)
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            setPreloaders(prevValue => ({
              ...prevValue,
              init: false,
            }));
          });
      })

      .catch((err) => {
        if (err.statusCode === 403) {
          setInitialValues({})
        }
        showError(parseApiError(err));
        setPreloaders(prevValue => ({
          ...prevValue,
          init: false,
        }));
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  const [filterToUpdate, setFilterToUpdate] = useState(null)
  function handleToggleFilter({ filter }) {
    const shop_id = user.default_shop._id;
    const _id = filter._id
    if (filter.is_special) {
      setPopups(prevValue => ({
        ...prevValue,
        special_update: true,
      }))
      setFilterToUpdate(filter)
      return
    }
    setFilters(prevValue => prevValue.map((filter) => {
      if (filter._id !== _id) return filter
      else return {
        ...filter,
        is_active: !filter.is_active,
      }
    }))
    mainApi.toggleFilterActivity({
      shop_id,
      _id,
    })


  }

  function handleUpdatePriceActiveState() {
    const shop_id = user.default_shop._id;
    setPreloaders(prevValue => ({
      ...prevValue,
      special_update: true,
    }))
    mainApi.editSpecialPriceFilter({
      shop_id,
      is_active: !filterToUpdate.is_active,
    })
      .then((res) => {
        setFilters(prevValue => prevValue.map((filter) => {
          if (filter._id !== filterToUpdate._id) return filter
          else return {
            ...filter,
            is_active: !filterToUpdate.is_active,
          }
        }))
      })
      .catch((err) => {

      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          special_update: false,
        }))
        setPopups(prevValue => ({
          ...prevValue,
          special_update: false,
        }))
        setTimeout(() => {
          setFilterToUpdate(null)
        }, 300);
      })
  }

  function handleUpdateInStockActiveState() {
    const shop_id = user.default_shop._id;
    setPreloaders(prevValue => ({
      ...prevValue,
      special_update: true,
    }))
    mainApi.editSpecialInStockFilter({
      shop_id,
      is_active: !filterToUpdate.is_active,
    })
      .then((res) => {
        setFilters(prevValue => prevValue.map((filter) => {
          if (filter._id !== filterToUpdate._id) return filter
          else return {
            ...filter,
            is_active: !filterToUpdate.is_active,
          }
        }))
      })
      .catch((err) => {

      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          special_update: false,
        }))
        setPopups(prevValue => ({
          ...prevValue,
          special_update: false,
        }))
        setTimeout(() => {
          setFilterToUpdate(null)
        }, 300);
      })
  }

  function handleToggleEdit({ _id }) {
    setFilters(prevValue => prevValue.map((filter) => {
      if (filter._id !== _id) return filter
      else return {
        ...filter,
        _is_edit_active: !filter._is_edit_active,
      }
    }))
  }

  function handleSaveEditedFilter({ _id, name, is_active }) {
    setFilters(prevValue => prevValue.map((filter) => {
      if (filter._id !== _id) return filter
      else return {
        ...filter,
        _is_edit_active: !filter._is_edit_active,
        name: name,
        is_active: is_active,
      }
    }))
  }

  function handleConvertFilterClick({ filter }) {
    setPopups(prevValue => ({
      ...prevValue,
      conver_filter: true,
    }))
    setFilterToUpdate(filter)
  }

  function handleConvertFilter() {
    const shop_id = user.default_shop._id;
    const _id = filterToUpdate._id
    setPreloaders(prevValue => ({
      ...prevValue,
      conver_filter: true,
    }))
    mainApi.convertFilterType({
      shop_id,
      _id,
    })
      .then((res) => {
        console.log(res)
        setFilters(prevValue => prevValue.map((filter) => {
          if (filter._id !== filterToUpdate._id) return filter
          else return {
            res
          }
        }))
      })
      .catch((err) => {

      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          conver_filter: false,
        }))
        setPopups(prevValue => ({
          ...prevValue,
          conver_filter: false,
        }))
        setTimeout(() => {
          setFilterToUpdate(null)
        }, 300);
      })
  }

  return (
    <section className="edit-filters">
      <PageHeading
        className={"edit-filters__heading"}
        title={"Настройка фильтров: " + initialValues?.name}
        goBack={{ onClick: () => navigate(-1) }}
        actions={actions}
        isTitlePreloader={preloaders.init}
      />
      <ErrorMessage error={error} />
      {!preloaders.init ? (
        <ItemForm>
          <SubmitActionPopup
            isOpen={popups.special_update}
            hendleClose={() => {
              setPopups(prevValue => ({
                ...prevValue,
                special_update: false,
              }))
              setTimeout(() => {
                setFilterToUpdate(null)
              }, 300);
            }}
            hendleSubmit={() => {
              if (!filterToUpdate) return
              if (filterToUpdate.filter_type === 'price') {
                handleUpdatePriceActiveState()
              }
              if (filterToUpdate.filter_type === 'in_stock') {
                handleUpdateInStockActiveState()
              }
            }}
            text={`Вы действительно хотите ${filterToUpdate ? !filterToUpdate.is_active ? 'активировать' : 'скрыть' : ''} фильтр <b>«${filterToUpdate ? filterToUpdate.name : ''}»</b> на всем сайте?`}
            submitText={`${filterToUpdate ? !filterToUpdate.is_active ? 'Активировать' : 'Скрыть' : ''}`}
            cencelText={'Отменить'}
            isSubmitPreloader={preloaders.special_update}
          />
          <SubmitActionPopup
            isOpen={popups.conver_filter}
            hendleClose={() => {
              setPopups(prevValue => ({
                ...prevValue,
                conver_filter: false,
              }))
              setTimeout(() => {
                setFilterToUpdate(null)
              }, 300);
            }}
            hendleSubmit={() => {
              if (!filterToUpdate) return
              handleConvertFilter()
            }}
            text={`Вы действительно хотите конвертировать фильтр <b>«${filterToUpdate ? filterToUpdate.name : ''}»</b> в ${filterToUpdate ? filterToUpdate.data_type === 'integer' ? 'Диапазон' : 'Несколько вариантов' : ''}?`}
            submitText={`Конвертировать`}
            cencelText={'Отменить'}
            isSubmitPreloader={preloaders.conver_filter}
          />
          <div className="edit-filters__form">
            <div className="edit-filters__filters">
              {filters && filters.length > 0 ?
                filters.map((filter, i) => (
                  <FilterCard
                    filter={filter}
                    key={`edit-filters__filter-${filter._id}-${i}`}
                    handleToggleFilter={handleToggleFilter}
                    handleToggleEdit={handleToggleEdit}
                    handleSaveEditedFilter={handleSaveEditedFilter}
                    handleConvertFilterClick={handleConvertFilterClick}
                  />
                ))
                : null}
            </div>

          </div>
        </ItemForm>
      ) : (
        <div className="orders-list__preloader">
          <MiniPreloader />
        </div>
      )}
    </section>
  );
}

export default EditFilters;
