import { useParams } from 'react-router-dom';
import './ImportProductForm.css';
import { useEffect, useState } from 'react';
import ProductForm from '../../../../ProductForm/ProductForm';
import { getRandomId } from '../../../../../../assets/utils/utils';
import { DEBOUNCE_TIME, DEFAULT_MAX_IMG_SIZE, PRODUCT_FORM_TYPE_BOOLEAN, PRODUCT_FORM_TYPE_NUMBER } from '../../../../../../assets/utils/constants';

function ImportProductForm({
    setProduct,
    product,
}) {
    const { item_id } = useParams()

    useEffect(() => {
        if (!item_id) return
        //GET PRODUCT
    }, [item_id])
    const [productValidity, setProductValidity] = useState({
        unique_sku: {},
        price: {},
        discount: {},
        photo: {},
    })



    function handleChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name

        switch (name) {

            case 'unique_sku': {
                let inputValue = value.replace((/[ ]+/g), '')
                setProductValidity(prevValue => ({
                    ...prevValue,
                    [name]: {
                        errorMessage: '',
                        validState: false
                    },
                }))
                setProduct(prevValue => ({
                    ...prevValue,
                    [name]: inputValue,
                }))
                break;
            }

            case 'discount':
            case 'price': {
                let inputValue = value.replace(/\D/g, '')
                setProduct(prevValue => ({
                    ...prevValue,
                    [name]: !inputValue ? '' : Number(inputValue).toString(),
                }))
                setProductValidity(prevValue => ({
                    ...prevValue,
                    [name]: inputValue ?
                        Number(inputValue) > 0 ?
                            {
                                errorMessage: '',
                                validState: true
                            }
                            :
                            {
                                errorMessage: 'Минимальное значение 1',
                                validState: false
                            }
                        :
                        {
                            errorMessage: '',
                            validState: true
                        }
                }))
                break;
            }
            case 'amount': {
                let inputValue = value.replace(/\D/g, '')
                setProduct(prevValue => ({
                    ...prevValue,
                    [name]: !inputValue ? '' : Number(inputValue).toString(),
                }))
                setProductValidity(prevValue => ({
                    ...prevValue,
                    [name]:
                    {
                        errorMessage: '',
                        validState: true
                    }
                }))
                break;
            }

            case 'add-option': {
                setProduct(prevValue => ({
                    ...prevValue,
                    options: prevValue.options.concat([{
                        title: '',
                        _id: getRandomId(),
                        values: [
                            {
                                value: '',
                                amount: '',
                                _id: getRandomId(),
                            },
                        ],
                    }]),
                }))
                break;
            }

            case 'add-value': {
                let option_id = input.option_id;
                setProduct(prevValue => ({
                    ...prevValue,
                    options: prevValue.options.map((item, i) => {
                        if (item._id !== option_id) return item
                        return {
                            ...item,
                            values: item.values.concat([{
                                value: '',
                                amount: '',
                                _id: getRandomId(),
                            },])
                        }
                    }
                    )
                }))
                break;
            }



            case 'option-title': {
                let option_id = input.option_id;
                setProduct(prevValue => ({
                    ...prevValue,
                    options: prevValue.options.map((item, i) => {
                        if (item._id !== option_id) return item
                        return {
                            ...item,
                            title: value,
                        }
                    }
                    )
                }))
                break;
            }
            case 'option-value': {
                let option_id = input.option_id;
                let value_id = input.value_id;
                setProduct(prevValue => ({
                    ...prevValue,
                    options: prevValue.options.map((item, i) => {
                        if (item._id !== option_id) return item
                        return {
                            ...item,
                            values: item.values.map((item2, i2) => {
                                if (item2._id !== value_id) return item2
                                return {
                                    ...item2,
                                    value: value,
                                }
                            }),
                        }
                    }
                    )
                }))
                break;
            }
            case 'option-amount': {
                let option_id = input.option_id;
                let value_id = input.value_id;
                setProduct(prevValue => ({
                    ...prevValue,
                    options: prevValue.options.map((item, i) => {
                        if (item._id !== option_id) return item
                        return {
                            ...item,
                            values: item.values.map((item2, i2) => {
                                if (item2._id !== value_id) return item2
                                return {
                                    ...item2,
                                    amount: value,
                                }
                            }),
                        }
                    }
                    )
                }))
                break;
            }

            case 'option-value-delete': {
                let option_id = input.option_id;
                let value_id = input.value_id;
                setProduct(prevValue => ({
                    ...prevValue,
                    options: prevValue.options.map((item, i) => {
                        if (item._id !== option_id) return item
                        return {
                            ...item,
                            values: item.values.filter((item2) => item2._id !== value_id),
                        }
                    }
                    ).filter((item) => item.values.length >= 1)
                }))
                break;
            }

            case 'option-value-sort-down': {
                let option_id = input.option_id;
                let value_index = input.value_index;

                setProduct(prevValue => {
                    const updatedOptions = prevValue.options.map(option => {
                        if (option._id !== option_id) return option;

                        if (value_index === option.values.length - 1) return option;

                        // Create a new array for values and swap the elements
                        const newValues = [...option.values];
                        [newValues[value_index], newValues[value_index + 1]] = [newValues[value_index + 1], newValues[value_index]];

                        return { ...option, values: newValues };
                    });

                    return { ...prevValue, options: updatedOptions };
                });

                break;
            }


            case 'option-value-sort-up': {
                let option_id = input.option_id;
                let value_index = input.value_index;

                setProduct(prevValue => {
                    const updatedOptions = prevValue.options.map(option => {
                        if (option._id !== option_id) return option;
                        if (value_index === 0) return option;
                        const newValues = [...option.values];
                        [newValues[value_index], newValues[value_index - 1]] = [newValues[value_index - 1], newValues[value_index]];

                        return { ...option, values: newValues };
                    });

                    return { ...prevValue, options: updatedOptions };
                });

                break;
            }

            case 'add-parameter': {
                setProduct(prevValue => ({
                    ...prevValue,
                    parameters: prevValue.parameters.concat([{
                        title: '',
                        value: '',
                        type: PRODUCT_FORM_TYPE_NUMBER,
                        _id: getRandomId(),
                    }]),
                }))
                break;
            }


            case 'parameter-title': {
                const parameter_id = input.parameter_id;
                const parameter_key = input.parameter_key;
                setProduct(prevValue => ({
                    ...prevValue,
                    parameters: prevValue.parameters.map((item, i) => {
                        if (item._id !== parameter_id) return item
                        return {
                            ...item,
                            [parameter_key]: value,
                        }
                    }
                    )
                }))
                break;
            }

            case 'parameter-type': {
                const parameter_id = input.parameter_id;
                const parameter_key = input.parameter_key;

                setProduct(prevValue => ({
                    ...prevValue,
                    parameters: prevValue.parameters.map((item, i) => {
                        if (item._id !== parameter_id) return item
                        return {
                            ...item,
                            [parameter_key]: value,
                            value: value.type === PRODUCT_FORM_TYPE_BOOLEAN.type ? true : '',
                        }
                    }
                    )
                }))
                break;
            }

            case 'parameter-value': {
                const parameter_id = input.parameter_id;
                const parameter_key = input.parameter_key;
                const parameter_type = input.parameter_type;
                let inputValue = value
                if (parameter_type.type === PRODUCT_FORM_TYPE_NUMBER.type) inputValue = value.replace(/\D/g, '')
                setProduct(prevValue => ({
                    ...prevValue,
                    parameters: prevValue.parameters.map((item, i) => {
                        if (item._id !== parameter_id) return item
                        return {
                            ...item,
                            [parameter_key]: inputValue,
                        }
                    }
                    )
                }))
                break;
            }

            case 'parameter-delete': {
                const parameter_id = input.parameter_id
                setProduct(prevValue => ({
                    ...prevValue,
                    parameters: prevValue.parameters.filter((item) => item._id !== parameter_id)
                }))
                break;
            }

            case "photo": {
                const photo = e.target.files[0]
                console.log(photo.size / 1000000)

                // setPhotoLoading(true)
                let data = new FormData();
                data.append("image", photo)


                // setPhotoLoading(true)
                const files = Array.from(e.target.files);


                let photos = [];




                function makeRequest(photo) {
                    let data = new FormData();
                    data.append("image", photo)


                    // return new Promise((resolve, reject) => {
                    //     mainApi.uploadImage({ data, type: isBreederPage ? 'profile_photos' : 'images' })
                    //         .then((res) => {
                    //             photos.push(res);
                    //             setTimeout(() => {
                    //                 resolve(res)
                    //             }, 2000);

                    //         })
                    //         .catch((err) => {
                    //             setTimeout(() => {
                    //                 reject(err)
                    //             })
                    //         })
                    // })
                }

                async function runAsync(files) {
                    for (let file of files) {
                        console.log(file);
                        if (file && file.size / 1000000 >= DEFAULT_MAX_IMG_SIZE) {
                            // setPopups(prevValue => ({
                            //     ...prevValue,
                            //     max_size: true,
                            // }))
                            return
                        }
                        await makeRequest(file)
                            .then((res) => {
                                console.log(res)
                            })
                            .catch((err) => {
                                console.log(err)
                            })

                    };
                }

                runAsync(files)
                    .finally(() => {
                        console.log('FINISHED', photos)
                        // setPhotoLoading(false)
                        setProduct(prevValue => ({
                            ...prevValue,
                            photos: prevValue.photos ? prevValue.images.concat(photos) : photos
                        }));

                    });
                break;
            }

            case 'photo-delete': {
                setProduct(prevValue => ({
                    ...prevValue,
                    photos: prevValue.photos.filter((item, i) => i !== value)
                }));

                break;
            }

            default:
                setProduct(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
        }
    }


    useEffect(() => {
        if (!product.unique_sku) return
        //CHACK
    }, [product.unique_sku])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (!product.unique_sku) return
            setProductValidity(prevValue => ({
                ...prevValue,
                unique_sku: {
                    // errorMessage: '',
                    // validState: true,

                    errorMessage: 'Данный ID уже используется вами',
                    validState: false
                },
            }))

        }, DEBOUNCE_TIME)

        return () => clearTimeout(delayDebounceFn)
    }, [product.unique_sku])

    return (
        <div className='import-product-form'>
            <ProductForm
                isTwoColumnsOnPC={false}
                handleChange={handleChange}
                product={product}
                productValidity={productValidity}
            />
        </div>
    );
}

export default ImportProductForm