import Graph from './Graph/Graph';
import './GraphContainer.css';
import StatisticsInfo from './StatisticsInfo/StatisticsInfo';

function GraphContainer({
    title,
    statistics_info,
    graphKeyName,
    graph_data,
    period_type,
}) {
    return (
        <div className='graph-container'>
            {title ?
                <h5 className='graph-container__title'>{title}</h5>
                :
                null
            }
            {statistics_info && statistics_info.length > 0 ?
                <div className='graph-container__statistics-infos'>
                    {statistics_info.map((item, i) => (
                        <StatisticsInfo
                            item={item}
                            key={`statistics-info-${i}-${graphKeyName}`}
                        />
                    ))}

                </div>
                :
                null
            }
            {graph_data && graph_data.length > 0 ?
                <Graph
                    data={graph_data}
                    period_type={period_type}
                />
                :
                null
            }

        </div>
    );
}

export default GraphContainer