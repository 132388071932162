import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import dayjs from "dayjs";
import {
  DEBOUNCE_TIME,
  PLATFORM_MAIN_LINK,
  PROMOCODES_GROUP_LINK,
  PROMOCODES_MAIN_LINK,
} from "../../../../assets/utils/constants";
import ItemForm from "../../ItemForm/ItemForm";
import PlatformInput from "../../PlatformInput/PlatformInput";
import PageHeading from "../../PageHeading/PageHeading";
import "./CreatePromocode.css";
import { UserContext } from "../../../../assets/contexts/userContext";
import mainApi from "../../../../assets/api/MainApi";
import useAutoDismissError from "../../../../assets/hooks/useAutoDismissError";
import { createAction, hasPermission, parseApiError, parsePermissions } from "../../../../assets/utils/utils";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { PERMISSIONS, RBAC_COUPONS } from "../../../../assets/utils/permissions_rbac";

const minDate = moment().add("days", 1);

function CreatePromocode() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(false);
  const [error, showError] = useAutoDismissError();



  const [values, setValues] = useState({
    name: "",
    code: "",
    discount: "",
    expire_at: "",
    usage_count: "",
  });

  const [valuesValidity, setValuesValidity] = useState({
    code: {},
    discount: {},
    expire_at: {},
    usage_count: {},
  });

  const hasAddPermission = hasPermission(parsePermissions(user), [RBAC_COUPONS[PERMISSIONS.ADD]])


  function isSaveInactive() {
    // Consolidate the logic to determine if the "Save" action should be inactive
    const isValid = values.name &&
      values.code &&
      valuesValidity.code.validState &&
      values.discount &&
      valuesValidity.discount.validState &&
      (values.expire_at ? valuesValidity.expire_at.validState : true) &&
      (values.usage_count ? valuesValidity.usage_count.validState : true) &&
      user?.email_verified;

    return !isValid || isPreloaderVisible;
  }

  function addActions() {
    const actions = [];

    if (hasAddPermission) {
      actions.push(createAction("Сохранить", handleSave, {
        isMainAction: true,
        inactive: isSaveInactive(),
        isPreloaderVisible: isPreloaderVisible,
      }));
    }

    return actions;
  }

  const actions = addActions();
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!values.code || !user) return;

      const shop_id = user.default_shop._id;
      mainApi
        .checkUniquePromo({ shop_id, code: values.code })
        .then(() => {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            code: {
              errorMessage: "",
              validState: true,
            },
          }));
        })
        .catch((err) => {
          switch (err.statusCode) {
            case 400:
              setValuesValidity((prevValue) => ({
                ...prevValue,
                code: {
                  errorMessage: "Данный код уже используется вами",
                  validState: false,
                },
              }));
              break;

            default:
              setValuesValidity((prevValue) => ({
                ...prevValue,
                code: {
                  errorMessage: parseApiError(err),
                  validState: false,
                },
              }));
              break;
          }
        });
    }, DEBOUNCE_TIME);

    return () => clearTimeout(delayDebounceFn);
  }, [user, values.code]);

  function handleSave() {
    const shop_id = user.default_shop._id;
    const valid_until = values.expire_at
      ? moment(values.expire_at, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DD")
      : null;
    setIsPreloaderVisible(true);
    mainApi
      .createPromo({
        shop_id,
        _id: id,
        name: values.name,
        code: values.code,
        discount: values.discount,
        valid_until,
        usage_limit: values.usage_count ? values.usage_count : null,
      })
      .then(() => {
        navigate(
          `${PLATFORM_MAIN_LINK}/${PROMOCODES_MAIN_LINK}/${PROMOCODES_GROUP_LINK}/${id}`
        );
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
      });
  }

  function handleChange(e) {
    const input = e.target;
    let value = input.value;
    const name = input.name;

    switch (name) {
      case "code": {
        let inputValue = value.replace(/[ ]+/g, "");
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: "",
            validState: false,
          },
        }));
        setValues((prevValue) => ({
          ...prevValue,
          [name]: inputValue,
        }));
        break;
      }

      case "discount": {
        let inputValue = value.replace(/\D/g, "");
        setValues((prevValue) => ({
          ...prevValue,
          [name]: !inputValue ? "" : Number(inputValue).toString(),
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]:
            Number(inputValue) > 0
              ? {
                errorMessage: "",
                validState: true,
              }
              : {
                errorMessage: "Минимальное значение 1%",
                validState: false,
              },
        }));
        break;
      }

      case "usage_count": {
        let inputValue = value.replace(/\D/g, "");
        setValues((prevValue) => ({
          ...prevValue,
          [name]: !inputValue ? "" : Number(inputValue).toString(),
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: inputValue
            ? Number(inputValue) > 0
              ? {
                errorMessage: "",
                validState: true,
              }
              : {
                errorMessage:
                  "Минимальное значение 1, Либо оставьте поле пустым",
                validState: false,
              }
            : {
              errorMessage: "",
              validState: true,
            },
        }));
        break;
      }

      case "expire_at": {
        const selected_date = moment(value);
        const date_now = moment();
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value ? selected_date : "",
        }));
        // console.log(value, selected_date.diff(date_now, "seconds"));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: value
            ? selected_date.isValid()
              ? selected_date.diff(date_now, 'seconds') >= 10
                ? {
                    errorMessage: '',
                    validState: true,
                  }
                : {
                    errorMessage: `Дата должна быть после ${date_now.format(
                      'DD.MM.YYYY'
                    )}`,
                    validState: false,
                  }
              : {
                  errorMessage: `Недопустимое значение даты`,
                  validState: false,
                }
            : {
                errorMessage: '',
                validState: true,
              },
        }));
        break;
      }

      default:
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;
    }
  }

  return (
    <div className="create-promocode">
      <PageHeading
        className={"create-promocode__heading"}
        title={"Новый промокод"}
        goBack={{
          onClick: () => {
            navigate(-1);
          },
        }}
        actions={actions}
      />
      <ErrorMessage error={error} />
      <ItemForm>
        <div className="create-promocode-group_form">
          <div className="create-promocode-group_form-column">
            <PlatformInput
              value={values.name}
              placeholder={""}
              label={"Название"}
              hint={"Видно только вам"}
              handleChange={handleChange}
              name={"name"}
            ></PlatformInput>
            <PlatformInput
              value={values.code}
              placeholder={""}
              label={"Код"}
              hint={`${values.code ? `${values.code.length}/24` : `до 24`
                } символов`}
              handleChange={handleChange}
              name={"code"}
              error={
                valuesValidity.code.errorMessage
                  ? valuesValidity.code.errorMessage
                  : ""
              }
              maxLength={"24"}
            ></PlatformInput>
            <PlatformInput
              value={values.discount}
              placeholder={"%"}
              label={"Процент скидки"}
              hint={"Макс. 99%"}
              handleChange={handleChange}
              name={"discount"}
              maxLength={"2"}
              error={
                valuesValidity.discount.errorMessage
                  ? valuesValidity.discount.errorMessage
                  : ""
              }
            ></PlatformInput>
            <PlatformInput
              value={values.expire_at}
              placeholder={""}
              label={"Срок действия"}
              hint={"Оставьте пустым, чтобы код действовал постоянно"}
              handleChange={handleChange}
              name={"expire_at"}
              minDate={dayjs(minDate)}
              isDateInput={true}
              error={
                valuesValidity.expire_at.errorMessage
                  ? valuesValidity.expire_at.errorMessage
                  : ""
              }
            ></PlatformInput>
            <PlatformInput
              value={values.usage_count}
              placeholder={""}
              label={"Использования"}
              hint={"Оставьте пустым, чтобы код действовал постоянно"}
              handleChange={handleChange}
              name={"usage_count"}
              maxLength={"10"}
              error={
                valuesValidity.usage_count.errorMessage
                  ? valuesValidity.usage_count.errorMessage
                  : ""
              }
            ></PlatformInput>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                            <DatePicker
                                // label="Срок действия"
                                value={values.expire_at}
                                format="DD.MM.YYYY"
                                minDate={dayjs(moment().add('days', 1))}
                                onChange={(newValue) => setValues(prevValue => ({
                                    ...prevValue,
                                    expire_at: newValue
                                }))}
                            />
                        </LocalizationProvider> */}
          </div>
        </div>
      </ItemForm>
    </div>
  );
}

export default CreatePromocode;
