import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeading from "../../PageHeading/PageHeading";
import "./PromocodeGroup.css";
import { useContext, useEffect, useState } from "react";
import Table from "../../Table/Table";
import {
  CODES_GROUPS_LIMIT,
  CONTROLS_TYPE,
  PLATFORM_MAIN_LINK,
  PRICE_TYPE,
  PROMOCODES_CREATE_CODE_LINK,
  PROMOCODES_MAIN_LINK,
  TEXT_TYPE,
  TITLE_TYPE,
} from "../../../../assets/utils/constants";
import moment from "moment-timezone";
import "moment/min/locales";
import {
  createAction,
  hasPermission,
  parseApiError,
  parsePermissions,
  parsePrice,
  parsePromoLimit,
} from "../../../../assets/utils/utils";
import { UserContext } from "../../../../assets/contexts/userContext";
import mainApi from "../../../../assets/api/MainApi";
import MiniPreloader from "../../../MiniPreloader/MiniPreloader";
import useAutoDismissError from "../../../../assets/hooks/useAutoDismissError";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { PERMISSIONS, RBAC_COUPONS } from "../../../../assets/utils/permissions_rbac";
moment.locale("ru");

function PromocodeGroup() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [list, setList] = useState({
    name: "",
    items: [],
  });
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);
  const [isNamePreloader, setIsNamePreloader] = useState(false);
  const [isFeedPreloader, setIsFeedPreloader] = useState(false);
  const [isMoreBtnVisible, setIsMoreBtnVisible] = useState(false);
  const [error, showError] = useAutoDismissError();
  const hasAddPermission = hasPermission(parsePermissions(user), [RBAC_COUPONS[PERMISSIONS.ADD]])
  const hasEditPermission = hasPermission(parsePermissions(user), [RBAC_COUPONS[PERMISSIONS.EDIT]])



  function addActionsWithPermissions() {
    const actions = [];

    if (hasAddPermission) {
      actions.push(createAction("Создать код", () => navigate(`${location.pathname}/${PROMOCODES_CREATE_CODE_LINK}`), {
        isMainAction: true,
      }));
    }

    return actions;
  }

  const actions = addActionsWithPermissions();


  useEffect(() => {
    if (!user || !id) return;

    setIsPreloaderVisible(true);
    setIsNamePreloader(true);
    getGroup();
    getCodes({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  function getGroup() {
    const shop_id = user.default_shop._id;
    mainApi
      .getExactCouponGroup({ shop_id, _id: id })
      .then((res) => {
        setList((prevVal) => ({ ...prevVal, name: res.name }));
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setList({
            name: "",
            items: [],
          })
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsNamePreloader(false);
      });
  }

  function getCodes({ last_id }) {
    const shop_id = user.default_shop._id;
    mainApi
      .getCodesGroup({ shop_id, _id: id, last_id, limit: CODES_GROUPS_LIMIT })
      .then((res) => {
        const data =
          res.data.length > 0
            ? res.data.map((item) => {
              return {
                _id: item._id,
                title: item.name,
                code: item.code,
                expire_date: item.valid_until,
                usage_count: item.usage_limit,
                sales_count: item.stats.sold_items,
                sales_amount: item.stats.sold_value,
                discounted_amount: item.stats.discount,
                discount: item.discount,
                is_disabled: !item.is_valid,
                // is_changable: true,
              };
            })
            : res.data;
        setList((prevVal) => ({
          ...prevVal,
          items: last_id ? prevVal.items.concat(data) : data,
        }));
        setIsMoreBtnVisible(res.is_more);
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setList({
            name: "",
            items: [],
          })
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
        setIsFeedPreloader(false);
      });
  }

  function getMore() {
    const last_id = list.items[list.items.length - 1]._id;
    setIsFeedPreloader(true);
    getCodes({ last_id });
  }

  function toggleCoupon({ _id, value }) {
    const shop_id = user.default_shop._id;
    mainApi
      .toggleCoupon({ shop_id, _id, value })
      .then(() => {
        setList((prevVal) => ({
          ...prevVal,
          items: prevVal.items.map((item) => {
            if (item._id !== _id) return item;
            return { ...item, is_disabled: !item.is_disabled };
          }),
        }));
      })
      .catch((err) => {
        showError(parseApiError(err));
      });
  }

  return (
    <div className="promocode-group">
      <PageHeading
        className={"promocode-group__heading"}
        title={list.name} //GROUP NAME
        isTitlePreloader={isNamePreloader}
        goBack={{
          onClick: () => {
            navigate(`${PLATFORM_MAIN_LINK}/${PROMOCODES_MAIN_LINK}`);
          },
        }}
        actions={actions}
      />
      <ErrorMessage error={error} />
      <div className="promocode-group__table">
        {!isPreloaderVisible ? (
          <>
            <Table
              noting_btn={hasAddPermission ? { text: 'Создать промокод', path: `${location.pathname}/${PROMOCODES_CREATE_CODE_LINK}` } : null}
              headingList={[
                {
                  name: "Название",
                  key: "title",
                  type: TITLE_TYPE,
                },
                {
                  name: "Код",
                  key: "code",
                  type: TEXT_TYPE,
                },
                {
                  name: "Лимиты",
                  key: "limit",
                  type: TEXT_TYPE,
                },
                {
                  name: "Продажи",
                  key: "sales",
                  type: TEXT_TYPE,
                },
                {
                  name: "Cкидка",
                  key: "discount",
                  type: TEXT_TYPE,
                },
                {
                  name: "Сумма скидок",
                  key: "discounted_amount",
                  type: PRICE_TYPE,
                },
                {
                  name: "Управление",
                  key: "controls",
                  type: CONTROLS_TYPE,
                },
              ]}
              nothingText={`У вас еще нет промо-кодов в группе ${list.name}`}
              list={
                list?.items?.length > 0
                  ? list.items.map((item, i) => {

                    let controls = hasEditPermission ?
                      item.is_disabled
                        ? [
                          {
                            name: "Включить",
                            onClick: () => {
                              toggleCoupon({
                                _id: item._id,
                                value: item.is_disabled,
                              });
                            },
                          },
                        ]
                        : [
                          {
                            name: "Выключить",
                            onClick: () => {
                              toggleCoupon({
                                _id: item._id,
                                value: item.is_disabled,
                              });
                            },
                          },
                        ]
                      : []
                    return {
                      ...item,
                      sales: `${Number(item.sales_count).toLocaleString(
                        "us"
                      )} (${parsePrice(item.sales_amount, "RUB")})`,
                      discounted_amount: "-" + item.discounted_amount,
                      limit: parsePromoLimit({
                        expire_date: item.expire_date,
                        usage_count: item.usage_count,
                      }),
                      discount: `${Number(item.discount).toLocaleString(
                        "us"
                      )}%`,
                      controls: controls,
                    };
                  })
                  : []
              }
            />

            {isMoreBtnVisible ? (
              <button
                className={`promocode-groups__more-btn ${isFeedPreloader ? "promocode-groups__more-btn_disabled" : ""
                  }`}
                onClick={getMore}
                type="button"
              >
                <p
                  className={`promocode-groups__more-btn-text ${!isFeedPreloader
                    ? "promocode-groups__more-btn-text_visible"
                    : ""
                    }`}
                >
                  Загрузить ещё
                </p>
                <span
                  className={`promocode-groups__btn-preloader ${isFeedPreloader
                    ? "promocode-groups__btn-preloader_visible"
                    : ""
                    }`}
                >
                  <MiniPreloader />
                </span>
              </button>
            ) : null}
          </>
        ) : (
          <div className="promocode-group__preloader">
            <MiniPreloader />
          </div>
        )}
      </div>
    </div>
  );
}

export default PromocodeGroup;
