import { Link, NavLink, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from 'framer-motion'
import "./Header.css";
import {
  ACCOUNT_MAIN_LINK,
  AUTH_LOGIN_LINK,
  EXEPRION_SHOP_ICONS_IDS,
  HOME_MAIN_LINK,
  PLATFORM_MAIN_LINK,
  PLATFORM_MAIN_LINKS,
} from "../../../assets/utils/constants";
import {
  AccountIcon,
  HeaderMenuArrowIcon,
  LogoIcon,
  LogoutIcon,
  SelectedTickIcon,
} from "../../../assets/icons/header/header";
import { filterLinksForUserRBAC, getCurrentPlatfromLocation, getDefaultLinkForUserRBAC, getShopLink } from "../../../assets/utils/utils";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../assets/contexts/userContext";
import AvatarBox from "../../AvatarBox/AvatarBox";
import mainApi from "../../../assets/api/MainApi";


import { PlatformLinksContext } from "../../../assets/contexts/platformLinksContext";
import ShopPreloader from "../ShopPreloader/ShopPreloader";
import PreloaderBox from "../../PreloaderBox/PreloaderBox";


const dropdownVariants = {
  open: {
    clipPath: "inset(0% 0% 0% 0% round 6px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.5,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  closed: {
    clipPath: "inset(10% 10% 90% 90% round 6px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3,
    },
  },
};


const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

const listVariants = {
  open: i => ({
    opacity: 1,
    transition: {
      delay: i * 0.1, // Each item will appear with a delay of 0.1 seconds after the previous one
    },
  }),
  closed: { opacity: 0, },
};

const preloaderVariants = {
  hidden: {
    opacity: 0,
    scale: 0.95 // Starts slightly smaller
  },
  visible: {
    opacity: 1,
    scale: 1, // Scale to normal size
    transition: { duration: 0.5 } // Control the speed of the animation
  },
  exit: {
    opacity: 0,
    scale: 1.05, // Slightly larger on exit
    transition: { duration: 0.3 } // Make exit slightly faster
  }
};
function HomeIcon(props) {
  return (
    <svg
      width={14}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.63 10.71V7.335a.403.403 0 00-.424-.424H6.16a.403.403 0 00-.43.424v3.373h2.9zm-6.413.053V6.96l4.62-3.877c.232-.194.463-.194.692 0l4.614 3.877v3.803c0 .412-.127.736-.381.972-.25.24-.591.36-1.02.36H3.624c-.434 0-.778-.12-1.032-.36-.25-.236-.376-.56-.376-.972zM.37 5.875c0-.21.093-.395.279-.553l5.597-4.7c.286-.24.6-.36.94-.36.343 0 .657.12.94.36l5.59 4.695a.717.717 0 01.275.57c0 .2-.066.36-.199.477a.71.71 0 01-.5.178.685.685 0 01-.29-.065 1.382 1.382 0 01-.263-.172l-5.253-4.41a.46.46 0 00-.3-.123c-.1 0-.2.041-.296.124l-5.264 4.41c-.082.067-.17.125-.263.171a.685.685 0 01-.29.064.708.708 0 01-.515-.187.66.66 0 01-.188-.479zM10.376 2.96V1.691c0-.125.038-.225.113-.3a.425.425 0 01.306-.113h.988c.126 0 .226.037.301.113a.407.407 0 01.113.3V4.48l-1.821-1.52z"
        fill="#fff"
      />
    </svg>
  );
}


function ShopBtn({ shop, index, default_shop, handleSetDefaultShop }) {
  const {
    ai_id,
    domain,
    icon,
    info,
    logo,
    role,
    subscriptions,
    _id,
  } = shop
  const is_selected = default_shop ? default_shop._id === _id : false

  return (
    <motion.button
      className="header__dropdown-shop"
      key={`header__dropdown-shop_${_id}`}
      variants={listVariants}
      custom={index} // Pass index to the variant function
      onClick={() => {
        handleSetDefaultShop({ shop: shop })
      }}
    >
      <AvatarBox
        className={'header__dropdown-shop-icon'}
        name={info.name}
        img={icon ? EXEPRION_SHOP_ICONS_IDS.indexOf(icon._id) < 0 ? icon : null : null}
      />
      <div className="header__dropdown-shop-info">
        <p className="header__dropdown-shop-name">{info.name ? info.name : '-'}</p>
        <p className='header__dropdown-shop-id-and-role'>ID: {ai_id} • {role.title.ru}</p>
      </div>
      {is_selected ?
        <SelectedTickIcon
          mainClassName={'header__dropdown-shop-selected-icon'}
          strokeClassName={'header__dropdown-shop-selected-icon-stroke'}
        />
        : null}
    </motion.button>
  )
}

function SecondLevelHeader({ links, keyValue }) {
  const animationVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    hidden: { opacity: 0, y: 20, transition: { duration: 0.2 } },
  };

  return (
    <AnimatePresence mode="popLayout">
      {links && links.length > 1 ?
        <motion.div
          className="header__second-level-links"
          key={keyValue}
          variants={animationVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          {
            links.map((link, i) => (
              <NavLink
                key={`header__second-level-link_${i}`}
                to={link.path}
                className={({ isActive }) =>
                  `header__second-level-link ${isActive ? "header__second-level-link_active" : ""
                  } ${link.disabled ? "header__second-level-link_disabled" : ""}`
                }
              >
                {link.name}
              </NavLink>
            ))
          }
        </motion.div>
        :
        null
      }
    </AnimatePresence>
  );
}

function CustomNavLink({ item, children, keyValue }) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(
    `${PLATFORM_MAIN_LINK}/${item.path}`
  );
  const DEFAULT_LINK_BY_USER_RBAC = PLATFORM_MAIN_LINK + getDefaultLinkForUserRBAC([item])
  return (
    <NavLink
      to={DEFAULT_LINK_BY_USER_RBAC}
      className={`header__link ${isActive ? "header__link_active" : ""}`}
      key={keyValue}
    >
      {children}
    </NavLink>
  );
}

function Header({ handlePopupOpen, isMenuOpen, isAccountOpen }) {
  const { user, setUser } = useContext(UserContext)
  const location = useLocation();
  const { links, default_link } = useContext(PlatformLinksContext)
  const current_location = getCurrentPlatfromLocation({
    location: location,
    links,
  });

  const [drops, setDrops] = useState({
    account: false,
  })

  const { default_shop } = user



  const isAccountDropOpen = drops.account

  const [shops, setShops] = useState(null)
  const [preloaders, setPreloaders] = useState({
    shops: true,
    set_shop: false,
  })

  useEffect(() => {
    setPreloaders(prevValue => ({
      ...prevValue,
      shops: true,
    }))
    mainApi.getAvailibleShops({
      limit: 25,
      last_id: null,
    })
      .then((res) => {
        setShops(res)
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          shops: false,
        }))
      })
  }, [])

  useEffect(() => {
    if (!drops.account) return

    setPreloaders(prevValue => ({
      ...prevValue,
      shops: true,
    }))
    mainApi.getAvailibleShops({
      limit: 25,
      last_id: null,
    })
      .then((res) => {
        setShops(res)
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          shops: false,
        }))
      })
  }, [drops.account])

  const navigate = useNavigate()
  function handleLogout() {
    mainApi.logout()
      .then((res) => {
        console.log(res)
        setUser(undefined)
        navigate(`${AUTH_LOGIN_LINK}`)
        localStorage.removeItem("categories")
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleSetDefaultShop({ shop }) {
    if (shop._id === default_shop._id) return
    setPreloaders(prevValue => ({
      ...prevValue,
      set_shop: true,
    }))
    mainApi.setDefaultShop({ _id: shop._id })
      .then((res) => {
        setUser(prevValue => ({
          ...prevValue,
          default_shop: shop,
        }))
        localStorage.removeItem("categories")
        navigate(`${default_link}`)
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setTimeout(() => {
          window.scrollTo(0, 0)
          setPreloaders(prevValue => ({
            ...prevValue,
            set_shop: false,
          }))
        }, 2000);

      })
  }


  const dropdownRef = useRef();
  const dropContainerRef = useRef()

  function toggleDrop(name) {
    switch (name) {
      case 'account': {
        setDrops(prevValue => ({
          ...prevValue,
          [name]: !prevValue[name],
        }))
        dropContainerRef.current.scrollTo(0, 0)
        break;
      }
      default:
        break;
    }
  }

  useEffect(() => {
    function handleOutsideClickClose(evt) {
      if (
        drops.account &&
        dropdownRef.current &&
        !dropdownRef.current.contains(evt.target)
      ) {
        toggleDrop('account')
      }
    }

    document.addEventListener("mousedown", handleOutsideClickClose);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickClose);
    };
  });

  return (
    <header className="header">

      <div className="header__content">
        <ShopPreloader isOpen={preloaders.set_shop} />
        <div className="header__main-row">
          <div className="header__logo-and-links">
            <Link
              className="header__logo-link"
              to={links ? default_link : null}
            >
              <LogoIcon
                mainClassName={"header__logo"}
                fillClassName={"header__logo-fill"}
              />
            </Link>
            <div className="header__links">
              {links && links.length > 0 ?
                links.map((item, i) => (
                  <>
                    {item.isInvisible ?
                      null
                      :
                      <CustomNavLink
                        item={item}
                        key={`header__link__${i}`}
                        keyValue={`header__link_main_${i}`}
                      >
                        {item.icon ? item.icon({
                          mainClassName: 'header__link-icon',
                          fillClassName: 'header__link-icon-fill',
                        }) : item.name}
                      </CustomNavLink>
                    }
                  </>

                ))
                : null}
            </div>
          </div>
          {/* <Link
            className="header__current-user"
            to={`${PLATFORM_MAIN_LINK}/${ACCOUNT_MAIN_LINK}`}
          >
            {user.last_name} {user.first_name}
          </Link> */}
          <div className="header__mobile-menu-and-account">
            {links && links.length > 0 ?
              <button
                className={`header__mobile-menu ${isMenuOpen ? "header__mobile-menu_opened" : ""
                  }`}
                type="button"
                onClick={() => {
                  handlePopupOpen({ name: "menu" });
                }}
              >
                <p className="header__mobile-menu-text">
                  {current_location && current_location.location && current_location?.location.icon ? current_location?.location.icon({
                    mainClassName: 'header__mobile-menu-icon',
                    fillClassName: 'header__mobile-menu-icon-fill'
                  }) : null}
                  {current_location &&
                    current_location?.location &&
                    current_location?.location.name
                    ? current_location?.location.name
                    :
                    <div className="header__mobile-menu-text_default">
                      <HomeIcon />
                      Домашний экран
                    </div>
                  }
                </p>
                <HeaderMenuArrowIcon
                  mainClassName={"header__mobile-menu-arrow"}
                  strokeClassName={"header__mobile-menu-arrow-stroke"}
                />
              </button>
              :
              null
            }
            <div className="header__current-user-box" ref={dropdownRef}>
              <button className={`header__current-user-active ${isAccountDropOpen ? 'header__current-user-active_open' : ''}`} type="button" onClick={() => {
                toggleDrop('account')
              }}>
                <AvatarBox className='header__current-user-active-shop-icon' name={default_shop?.info.name} img={default_shop?.icon ? EXEPRION_SHOP_ICONS_IDS.indexOf(default_shop?.icon._id) < 0 ? default_shop?.icon : null : null} />
                <p className="header__current-user-active-shop-name">{default_shop?.info.name}</p>
                {/* <div className="header__current-user-active-shop-name-separator"></div>
              <p className="header__current-user-active-shop-id">ID: {ai_id}</p> */}
                <HeaderMenuArrowIcon
                  mainClassName={'header__current-user-active-arrow'}
                  strokeClassName={'header__current-user-active-arrow-stroke'}
                />
              </button>
              <motion.div
                className="header__box-with-dropdown"
                initial={false}
                animate={isAccountDropOpen ? "open" : "closed"}
              >
                <div
                  className={`header__dropdown ${isAccountDropOpen ? "header__dropdown_opened" : ""
                    }`}
                  ref={dropContainerRef}
                >
                  <motion.div
                    variants={dropdownVariants}
                    style={{ pointerEvents: isAccountDropOpen ? "auto" : "none" }}
                    className="header__dropdown-container"
                  >
                    <motion.div
                      className="header__dropdown-heading"
                      variants={itemVariants}
                    >
                      <AvatarBox className={'header__dropdown-heading-avatar'} name={`${user.last_name} ${user.first_name}`} />
                      <p className="header__dropdown-heading-user-name">{user.last_name} {user.first_name}</p>
                      <p className="header__dropdown-heading-user-email">{user.email}</p>
                      {links ?
                        <Link className="header__dropdown-heading-account" to={`${PLATFORM_MAIN_LINK}/${ACCOUNT_MAIN_LINK}`} onClick={() => {
                          setDrops(prevValue => ({
                            ...prevValue,
                            account: false,
                          }))
                        }}>
                          <AccountIcon
                            mainClassName={'header__dropdown-heading-account-icon'}
                            strokeClassName={'header__dropdown-heading-account-icon-stroke'}
                          />
                          <p className="header__dropdown-heading-account-title">Аккаунт</p>
                        </Link>
                        : null
                      }
                    </motion.div>
                    <motion.div
                      className="header__dropdown-shops"
                      variants={itemVariants}
                    >
                      {!preloaders.shops ?
                        shops.data && shops.data.length > 0 ?
                          shops.data.map((shop, i) => (
                            <ShopBtn shop={shop} index={i} key={`header__dropdown-shop_map_${shop._id}`} default_shop={default_shop} handleSetDefaultShop={handleSetDefaultShop} />
                          ))
                          : null
                        : <PreloaderBox />}
                    </motion.div>
                    <motion.div
                      className="header__dropdown-logout"
                      variants={itemVariants}
                    >
                      <button className="header__dropdown-logout-btn" type="button" onClick={handleLogout}>
                        <LogoutIcon
                          mainClassName={'header__dropdown-logout-icon'}
                          strokeClassName={'header__dropdown-logout-icon-stroke'}
                        />
                        <p className="header__dropdown-logout-btn-text">Выход</p>
                      </button>
                    </motion.div>
                  </motion.div>
                </div>

              </motion.div>

            </div>
          </div>

        </div>
        <Routes>
          {links && links.length > 0 ?
            links.map((item, i) => (
              <>
                {item.children ? (
                  <Route
                    key={`header__second-level-link_key_${i}`}
                    path={item.path + "/*"}
                    element={
                      <SecondLevelHeader
                        keyValue={`header__second-level-links_key_${i}`}
                        links={item.children}
                      />
                    }
                  />
                ) : null}
              </>
            ))
            :
            null}
        </Routes>

      </div>

    </header>
  );
}

export default Header;
