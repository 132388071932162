import { useContext, useEffect, useState } from 'react';
import SubscribeCard from '../SubscribeCard/SubscribeCard';
import './Subscription.css';
import mainApi from '../../../assets/api/MainApi';
import { UserContext } from '../../../assets/contexts/userContext';
import SubmitActionPopup from '../SubmitActionPopup/SubmitActionPopup';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import { parseApiError } from '../../../assets/utils/utils';

function Subscription() {

    const { user } = useContext(UserContext)
    const { default_shop } = user
    const current_subscription = default_shop?.subscriptions?.main

  

    const text = 'Чтобы начать работу с вашим магазином, нужно выбрать и оплатить тариф. Сразу после этого, наш менеджер свяжется с вами, чтобы начать разработку дизайна и настройки торговой платформы.'
    const title = 'Тариф Polza'
    const [subscriptions, setSubscriptions] = useState(null)

    useEffect(() => {
        mainApi.getSubscrtiptions({ type: 'main' })
            .then((res) => {
                console.log(res)
                setSubscriptions(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    console.log(current_subscription)

  

    return (
        <div className="subscription">
            <div className='subscription__titles-box'>
                <p className='subscription__titles-box__title_main'>{title}</p>
                <p>{text}</p>
            </div>

            {subscriptions && subscriptions.length > 0 ? <SubscribeCard subscriptions={subscriptions} current_subscription={current_subscription} /> : null}

        </div>
    );
}

export default Subscription;