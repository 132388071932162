export function Card1({mainClassName, strokeClassName}) {
    return (
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={mainClassName}
      >
        <path
          d="M32 31.667L42.667 21"
          stroke="#8AD90A"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}

        />
        <path
          d="M56 16v24a8 8 0 01-8 8H33.333l-9.844 7.714a1.334 1.334 0 01-2.156-1.05V48H16a8 8 0 01-8-8V16a8 8 0 018-8h32a8 8 0 018 8z"
          stroke="#4E8D75"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}
        />
        <path
          d="M32 21.333h10.667V32"
          stroke="#8AD90A"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}

        />
      </svg>
    );
  }
  export function Card1Mobile({mainClassName, strokeClassName}) {
    return (
      <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mainClassName}
    >
      <path
        d="M20 19.75l8-8"
        stroke="#8AD90A"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={strokeClassName}

      />
      <path
        d="M38 8v18a6 6 0 01-6 6H21l-7.383 5.785A1 1 0 0112 36.998V32H8a6 6 0 01-6-6V8a6 6 0 016-6h24a6 6 0 016 6z"
        stroke="#4E8D75"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={strokeClassName}
      />
      <path
        d="M20 12h8v8"
        stroke="#8AD90A"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={strokeClassName}

      />
    </svg>
    );
  }
  
  export function Card2({strokeClassName,mainClassName }) {
    return (
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={mainClassName }
      >
        <path
          d="M35.334 33.333h5.333M24.667 20L30 25.333l-5.333 5.334"
          stroke="#4E8D75"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}
        />
        <path
          d="M35.334 33.333h5.333M24.667 20L30 25.333l-5.333 5.334"
          stroke="#8AD90A"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}

   
        />
        <path
          d="M8.667 45.333v-32A5.333 5.333 0 0114 8h37.333a5.333 5.333 0 015.334 5.333v32M7.333 45.333H58A2.667 2.667 0 0160.667 48a8 8 0 01-8 8h-40a8 8 0 01-8-8 2.667 2.667 0 012.666-2.667z"
          stroke="#4E8D75"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}
        />
      </svg>
    );
  }
  export function Card2Mobile({strokeClassName, mainClassName}) {
    return (
      <svg
        width={46}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={mainClassName}
      >
        <path
          d="M25 21h4M17 11l4 4-4 4"
          stroke="#4E8D75"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}
        />
        <path
          d="M25 21h4M17 11l4 4-4 4"
          stroke="#8AD90A"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}

        />
        <path
          d="M5 30V6a4 4 0 014-4h28a4 4 0 014 4v24M4 30h38a2 2 0 012 2 6 6 0 01-6 6H8a6 6 0 01-6-6 2 2 0 012-2z"
          stroke="#4E8D75"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}
        />
      </svg>
    );
  }
  export function Card3( {strokeClassName,mainClassName }) {
    return (
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={mainClassName}
      >
        <path
          d="M32.334 56h-16A5.333 5.333 0 0111 50.667V24a5.333 5.333 0 015.334-5.333h32A5.333 5.333 0 0153.667 24v5.333"
          stroke="#4E8D75"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}
        />
        <path
          d="M41.667 18.667V16A9.333 9.333 0 1023 16v2.667"
          stroke="#4E8D75"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}
        />
        <path
          d="M33.667 33.333L32.333 32M43 32l1.334-1.333M31 44l1.334-1.333M54.7 47.325l-3.95 1.66a2.666 2.666 0 00-1.424 1.424l-1.666 3.958a2.667 2.667 0 01-5.016-.28L39.112 42.09a2.666 2.666 0 013.311-3.311l11.996 3.53a2.667 2.667 0 01.28 5.016z"
          stroke="#8AD90A"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}

        />
      </svg>
    );
  }
    
  export function Card3Mobile({strokeClassName, mainClassName}) {
    return (
      <svg
        width={48}
        height={48}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={mainClassName}
      >
        <path
          d="M24 42H12a4 4 0 01-4-4V18a4 4 0 014-4h24a4 4 0 014 4v4M31 14v-2a7 7 0 00-14 0v2"
          stroke="#4E8D75"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}
        />
        <path
          d="M25 25l-1-1M32 24l1-1M23 33l1-1M40.775 35.493l-2.962 1.245a2 2 0 00-1.069 1.069l-1.249 2.968a2 2 0 01-3.762-.21l-2.649-8.998a2 2 0 012.483-2.483l8.998 2.647a2 2 0 01.21 3.762z"
          stroke="#8AD90A"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}

        />
      </svg>
    );
  }
  