import { useContext, useEffect, useState } from 'react'
import './NoShop.css'
import mainApi from '../../../../assets/api/MainApi'
import PreloaderBox from '../../../PreloaderBox/PreloaderBox'
import AvatarBox from '../../../AvatarBox/AvatarBox'
import { EXEPRION_SHOP_ICONS_IDS, PLATFORM_MAIN_LINK } from '../../../../assets/utils/constants'
import { SelectedTickIcon } from '../../../../assets/icons/header/header'
import { UserContext } from '../../../../assets/contexts/userContext'
import { useNavigate } from 'react-router-dom'
import ShopPreloader from '../../ShopPreloader/ShopPreloader'
import useAutoDismissError from '../../../../assets/hooks/useAutoDismissError'
import { parseApiError } from '../../../../assets/utils/utils'


function ShopBtn({ shop, index, default_shop, handleSetDefaultShop }) {
    const {
        ai_id,
        domain,
        icon,
        info,
        logo,
        role,
        subscriptions,
        _id,
    } = shop
    console.log(icon)
    return (
        <button
            className="no-shop__shop-btn"
            key={`no-shop__shop-btn_${_id}`}
            onClick={() => {
                handleSetDefaultShop({ shop: shop })
            }}
        >
            <AvatarBox
                className={'no-shop__shop-btn-icon'}
                name={info.name}
                img={icon ? EXEPRION_SHOP_ICONS_IDS.indexOf(icon._id) < 0 ? icon : null : null}
            />
            <div className="no-shop__shop-btn-info">
                <p className="no-shop__shop-btn-name">{info.name ? info.name : '-'}</p>
                <p className='no-shop__shop-btn-id-and-role'>ID: {ai_id} • {role.title.ru}</p>
            </div>
        </button>
    )
}


function NoShop() {

    const { user, setUser } = useContext(UserContext)


    const [shops, setShops] = useState(null)
    const [isCreateAvalible, setCreateAvalible] = useState(null)
    const [preloaders, setPreloaders] = useState({
        shops: true,
        set_shop: false,
    })

    const [error, showError] = useAutoDismissError()

    useEffect(() => {
        setPreloaders(prevValue => ({
            ...prevValue,
            shops: true,
            create_availible: true,
        }))
        mainApi.getAvailibleShops({
            limit: 25,
            last_id: null,
        })
            .then((res) => {
                setShops(res)
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    shops: false,
                }))
            })

        mainApi.checkIsCreateShopAvailible()
            .then((res) => {
                setCreateAvalible(true)
            })
            .catch((err) => {
                setCreateAvalible(false)
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    create_availible: false,
                }))
            })
    }, [])

    const navigate = useNavigate()

    function handleSetDefaultShop({ shop }) {
        // if (shop._id === default_shop._id) return
        setPreloaders(prevValue => ({
            ...prevValue,
            set_shop: true,
        }))
        mainApi.setDefaultShop({ _id: shop._id })
            .then((res) => {
                setUser(prevValue => ({
                    ...prevValue,
                    default_shop: shop,
                }))
                localStorage.removeItem("categories")

                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                showError(parseApiError(err))
            })
            .finally(() => {
                setTimeout(() => {
                    navigate(`${PLATFORM_MAIN_LINK}`)
                    window.scrollTo(0, 0)
                    setPreloaders(prevValue => ({
                        ...prevValue,
                        set_shop: false,
                    }))
                }, 2000);

            })
    }

    function handleCreateShop() {
        setPreloaders(prevValue => ({
            ...prevValue,
            set_shop: true,
        }))
        mainApi.createShop()
            .then((res) => {
                setUser(prevValue => ({
                    ...prevValue,
                    default_shop: res.default_shop,
                }))
                localStorage.removeItem("categories")

                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                showError(parseApiError(err))
            })
            .finally(() => {
                setTimeout(() => {
                    navigate(`${PLATFORM_MAIN_LINK}`)
                    window.scrollTo(0, 0)
                    setPreloaders(prevValue => ({
                        ...prevValue,
                        set_shop: false,
                    }))
                }, 2000);
            })
    }

    return (
        <div className='no-shop'>
            <ShopPreloader isOpen={preloaders.set_shop} error={error} />
            <div className='no-shop__content'>
                <p className='no-shop__title'>Выберите магазин <br />или создайте новый</p>
                {!preloaders.shops ?
                    shops.data && shops.data.length > 0 ?
                        <div className='no-shop__availible-shops'>
                            {
                                shops.data.map((shop, i) => (
                                    <ShopBtn shop={shop} index={i} key={`no-shop__shop-btn_map_${shop._id}`} default_shop={null} handleSetDefaultShop={handleSetDefaultShop} />
                                ))
                            }
                        </div>
                        :
                        null
                    : <PreloaderBox />}
                {isCreateAvalible || true ?
                    <button className='no-shop__create' type='button' onClick={handleCreateShop}>
                        Создать магазин
                    </button>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default NoShop