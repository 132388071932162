import React, { useContext, useState } from 'react';
import { GoBackIcon } from '../../../assets/icons/controls/controls';
import './PageHeading.css';
import { Link } from 'react-router-dom';
import { ACTION_POPUP_NAME } from '../../../assets/utils/constants';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import { ActionPopupContext } from '../../../assets/contexts/actionPopupContext';

function PageHeading({
    className,
    title,
    goBack,
    actions,
    isTitlePreloader,
    isvisible
}) {
    const { handleActionPopupOpen } = useContext(ActionPopupContext)
    return (
        <div className={`page-heading ${className ? className : ''}`}>
            <div className='page-heading__left-box'>
                {goBack ?
                    <button className='page-heading__go-back' type='button' onClick={goBack.onClick}>
                        <GoBackIcon
                            mainClassName={'page-heading__go-back-icon'}
                            strokeClassName={'page-heading__go-back-icon-stroke'}
                        />
                    </button>
                    : null}
                {title ?
                    <h3 className='page-heading__title'>{!isTitlePreloader ? title : <MiniPreloader />}</h3>
                    : null}
            </div>
            <div className='page-heading__right-box'>
                {actions && actions.length > 0 ?
                    <>
                        <div className='page-heading__right-controls page-heading__right-controls_pc'>
                            {actions.map((item, i) => (
                                <React.Fragment key={item.id ? item.id : `page-heading__action_${i}`}>
                                    {item.path ?
                                        <Link className={`page-heading__action ${item.isMainAction ? 'page-heading__action_type_main' : ''} ${item.inactive ? 'page-heading__action_inactive' : ''}`} to={item.path} target={item.path && !item.path.startsWith('/') ? '_blank' : '_self'}>

                                            <p
                                                className={`page-heading__action-text ${!item.isPreloaderVisible ? "page-heading__action-text_visible" : ""
                                                    }`}
                                            >
                                                {item.name}
                                            </p>

                                        </Link>
                                        : null}

                                    {item.onClick ?
                                        <button className={`page-heading__action ${item.isMainAction ? 'page-heading__action_type_main' : ''} ${item.inactive ? 'page-heading__action_inactive' : ''}`} type='button' onClick={item.onClick}>
                                            <p
                                                className={`page-heading__action-text ${!item.isPreloaderVisible ? "page-heading__action-text_visible" : ""
                                                    }`}
                                            >
                                                {item.name}
                                            </p>
                                            <span
                                                className={`page-heading__action-preloader ${item.isPreloaderVisible ? "page-heading__action-preloader_visible" : ""
                                                    }`}
                                            >
                                                <MiniPreloader isLinkColor={Boolean(item.isMainAction)} />
                                            </span>
                                        </button>
                                        : null}
                                </React.Fragment>
                            ))}
                        </div>
                        <div className='page-heading__right-controls page-heading__right-controls_mobile'>
                            {actions.length === 1 ?
                                <>
                                    {actions.map((item, i) => (
                                        <React.Fragment key={item.id ? item.id : `page-heading__action_${item.name}`}>
                                            {item.path ?
                                                <Link className={`page-heading__action ${item.isMainAction ? 'page-heading__action_type_main' : ''} ${item.inactive ? 'page-heading__action_inactive' : ''}`} to={item.path} target={item.path && !item.path.startsWith('/') ? '_blank' : '_self'}>
                                                    <p
                                                        className={`page-heading__action-text ${!item.isPreloaderVisible ? "page-heading__action-text_visible" : ""
                                                            }`}
                                                    >
                                                        {item.name}
                                                    </p>
                                                </Link>
                                                : null}

                                            {item.onClick ?
                                                <button className={`page-heading__action ${item.isMainAction ? 'page-heading__action_type_main' : ''} ${item.inactive ? 'page-heading__action_inactive' : ''}`} type='button' onClick={item.onClick}>
                                                    <p
                                                        className={`page-heading__action-text ${!item.isPreloaderVisible ? "page-heading__action-text_visible" : ""
                                                            }`}
                                                    >
                                                        {item.name}
                                                    </p>
                                                    <span
                                                        className={`page-heading__action-preloader ${item.isPreloaderVisible ? "page-heading__action-preloader_visible" : ""
                                                            }`}
                                                    >

                                                        <MiniPreloader isLinkColor={Boolean(item.isMainAction)} />
                                                    </span>
                                                </button>
                                                : null}
                                        </React.Fragment>
                                    ))}
                                </>
                                :
                                <button className={`page-heading__action page-heading__action_type_button`} type='button' onClick={() => {
                                    handleActionPopupOpen({ name: ACTION_POPUP_NAME, actions: actions })
                                }}>
                                    Действия
                                </button>
                            }
                            {/* <button className={`page-heading__action page-heading__action_type_button`} type='button' onClick={() => {
                                handleActionPopupOpen({ name: ACTION_POPUP_NAME, actions: actions })
                            }}>
                                Действия
                            </button> */}
                        </div>
                    </>
                    : null}
            </div>
        </div>
    );
}

export default PageHeading