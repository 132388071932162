import { deleteItem, fetchItems, sortItems } from '../../services/headerLinkApi';
import List from './List/List';

function HeaderLinksList() {

    return (
        <List
            deletePopupTextFormater={(itemToDelete) => (`Вы уверены, что хотите удалить ссылку ${itemToDelete ? itemToDelete.url : ''}`)}
            fetchItems={fetchItems}
            deleteItem={deleteItem}
            sortItems={sortItems}
            itemNameTextFormater={(item) => (`${!item.is_visible ? 'Скрыт - ' : ''} ${item.name}`)}
            emptyText={'Ссылок пока нет'}
        />
    );
}

export default HeaderLinksList