import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Header from './Header/Header';
import './Platform.css';
import MenuPopup from './MenuPopup/MenuPopup';
import { useContext, useState } from 'react';
import Promocodes from './Promocodes/Promocodes';
import ActionPopup from './ActionPopup/ActionPopup';
import { CATALOG_MAIN_LINK, ACCOUNT_MAIN_LINK, CONSTRUCTOR_MAIN_LINK, ORDERS_MAIN_LINK, PROMOCODES_MAIN_LINK, SETTINGS_MAIN_LINK, STATISTICS_MAIN_LINK, HOME_MAIN_LINK, PLATFORM_MAIN_LINK, CONTROLLERS_MAIN_LINK, SUBSCRIPTIONS_MAIN_LINK, SUBSCRIPTIONS_PLATFROM_LINK, SUBSCRIPTIONS_PROMO_SIMPLE_LINK, SUBSCRIPTIONS_PROMO_SIMPLE_WIX_LINK } from '../../assets/utils/constants';
import Orders from './Orders/Orders';
import Settings from './Settings/Settings';
import Statistics from './Statistics/Statistics';
import Account from './Account/Account';
import Constructor from './Constructor/Constructor';
import Catalog from './Catalog/Catalog';
import HomePage from './HomePage/HomePage';
import { ActionPopupContext } from '../../assets/contexts/actionPopupContext';
import { PlatformLinksContext } from '../../assets/contexts/platformLinksContext';
import Controllers from './Controllers/Controllers';
import Subscription from './Subscription/Subscription';
import NotFoundErrorPage from '../NotFoundErrorPage/NotFoundErrorPage';
import SimpleSubscription from './CustomSubscription/Sellavi/SimpleSubscription/SimpleSubscription';
import SimpleSubscriptionWix from './CustomSubscription/WIX/SimpleSubscription/SimpleSubscription';

function Platform() {
    const [popups, setPopups] = useState({
        menu: false,
        action: false,
        account: false,
    })

    function handlePopupOpen({ name }) {
        setPopups(prevValue => ({
            ...prevValue,
            [name]: true,
        }))
    }

    const [actions, setActions] = useState(null)

    function handleActionPopupOpen({ name, actions }) {
        console.log({ name, actions })
        handlePopupOpen({ name })
        setActions(actions)
    }

    function handlePopupClose({ name }) {
        setPopups(prevValue => ({
            ...prevValue,
            [name]: false,
        }))
    }

    const location = useLocation()

    const { links, default_link } = useContext(PlatformLinksContext)

    return (
        <div className='platform'>
            <ActionPopupContext.Provider value={{
                handleActionPopupOpen: handleActionPopupOpen,
            }}>
                <Header
                    handlePopupOpen={handlePopupOpen}
                    isMenuOpen={popups.menu}
                    isAccountOpen={popups.account}
                />

                <div className={`platform__content ${location.pathname === `${PLATFORM_MAIN_LINK}/${HOME_MAIN_LINK}` ? 'platform__content_home' : ''}`}>
                    <Routes>
                        <Route index element={
                            <Navigate to={links ? default_link : PLATFORM_MAIN_LINK} />
                        }>
                        </Route>
                        <Route path={`${HOME_MAIN_LINK}/*`} element={
                            <HomePage />
                        }>
                        </Route>
                        <Route path={`${STATISTICS_MAIN_LINK}/*`} element={
                            <Statistics />
                        }>
                        </Route>
                        <Route path={`${PROMOCODES_MAIN_LINK}/*`} element={
                            <Promocodes />
                        }>
                        </Route>
                        <Route path={`${ORDERS_MAIN_LINK}/*`} element={
                            <Orders />
                        }>
                        </Route>
                        <Route path={`${SETTINGS_MAIN_LINK}/*`} element={
                            <Settings />
                        }>
                        </Route>
                        <Route path={`${ACCOUNT_MAIN_LINK}/*`} element={
                            <Account />
                        }>
                        </Route>
                        <Route path={`${CONSTRUCTOR_MAIN_LINK}/*`} element={
                            <Constructor />
                        }>
                        </Route>
                        <Route path={`${CATALOG_MAIN_LINK}/*`} element={
                            <Catalog />
                        }>
                        </Route>
                        <Route path={`${CONTROLLERS_MAIN_LINK}/*`} element={
                            <Controllers />
                        }>
                        </Route>
                        <Route path={`${SUBSCRIPTIONS_MAIN_LINK}/${SUBSCRIPTIONS_PLATFROM_LINK}`} element={
                            <Subscription />
                        }></Route>
                        <Route path={`${SUBSCRIPTIONS_MAIN_LINK}/${SUBSCRIPTIONS_PROMO_SIMPLE_LINK}`} element={
                            <SimpleSubscription />
                        }></Route>
                         <Route path={`${SUBSCRIPTIONS_MAIN_LINK}/${SUBSCRIPTIONS_PROMO_SIMPLE_WIX_LINK}`} element={
                            <SimpleSubscriptionWix />
                        }></Route>

                        <Route path={`/*`} element={
                            <NotFoundErrorPage />
                        }></Route>
                    </Routes>
                </div>
                <MenuPopup
                    isOpen={popups.menu}
                    handleClose={handlePopupClose}
                />
                <ActionPopup
                    isOpen={popups.action}
                    handleClose={handlePopupClose}
                    actions={actions}
                />
            </ActionPopupContext.Provider>
        </div>
    );
}

export default Platform