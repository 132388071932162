import { motion, AnimatePresence } from 'framer-motion';


import './ErrorMessage.css';

const errorVariants = {
    hidden: {
        opacity: 0,
        height: 0,
        marginTop: '-12px',
        overflow: 'hidden',
        transition: { duration: 0.3 },
    },
    visible: {
        opacity: 1,
        height: 'auto',
        transition: { duration: 0.3 },
        marginTop: '0',
    }
};




function ErrorMessage({
    className,
    error,
}) {
    return (
        <AnimatePresence>
            {error && (
                <motion.p
                    variants={errorVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    layout
                    className={`error-message ${className ? className : ''}`}
                >
                    {error}
                </motion.p>
            )}
        </AnimatePresence>

    );
}

export default ErrorMessage