import { DEFAULT_BANNER_SIZE, DEFAULT_MAX_IMG_SIZE } from '../../../../../../../../assets/utils/constants';
import ImagesInput from '../../../../../../ImagesInput/ImagesInput';
import PlatformInput from '../../../../../../PlatformInput/PlatformInput';
import './ShowCase.css';

function ShowCase({ item, handleChange }) {
    return (
        <div className='show-case'>
            {item.block.input_data.title && item.block.input_data.title.editable ?
                <PlatformInput
                    label={'Заголовок'}
                    value={item.data[0].title}
                    handleChange={(e) => {
                        handleChange({
                            target: {
                                index: 0,
                                value: e.target.value,
                                item: item,
                                name: 'show-case',
                                subname: 'title',
                            }
                        })
                    }}
                    isTextArea={true}
                    minRows={1}
                    maxRows={5}
                    type={'text'}
                    name={''}
                />
                : null}
            {item.block.input_data.text && item.block.input_data.text.editable ?
                <PlatformInput
                    label={'Текст'}
                    value={item.data[0].text}
                    handleChange={(e) => {
                        handleChange({
                            target: {
                                index: 0,
                                value: e.target.value,
                                item: item,
                                name: 'show-case',
                                subname: 'text',
                            }
                        })
                    }}
                    isTextArea={true}
                    minRows={1}
                    maxRows={5}
                    type={'text'}
                    name={''}
                />
                : null}

            {item.block.input_data.image && item.block.input_data.image.editable ?
                <ImagesInput
                    name={"image"}
                    label={"Изображение"}
                    hint={`(${DEFAULT_BANNER_SIZE}) Не более ${DEFAULT_MAX_IMG_SIZE} Мб`}
                    addBtnText={"Добавить..."}
                    isMultiple={false}
                    images={item.data[0].image}
                    additionalKey={`${item._id}_show-case`}
                    onRemove={(e) => {
                        handleChange({
                            target: {
                                index: 0,
                                value: e.target.index,
                                item: item,
                                name: 'show-case',
                                subname: 'image-delete',
                            }
                        })
                    }}
                    onAdd={(e) => {
                        handleChange({
                            target: {
                                index: 0,
                                ...e.target,
                                item: item,
                                name: 'show-case',
                                subname: 'image',
                                files: e.target.files,
                            }
                        })
                    }}
                />
                : null}

            {item.block.input_data.link && item.block.input_data.link.editable ?
                <PlatformInput
                    label={'Ссылка'}
                    value={item.data[0].link}
                    handleChange={(e) => {
                        handleChange({
                            target: {
                                index: 0,
                                value: e.target.value,
                                item: item,
                                name: 'show-case',
                                subname: 'link',
                            }
                        })
                    }}
                    type={'text'}
                    name={''}
                />
                : null}


        </div>
    );
}

export default ShowCase