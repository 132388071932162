import moment from 'moment-timezone';
import { ADDRESS_TYPE, DATE_WITH_FORMAT_TYPE, EMAIL_TYPE, NUMBER_STRING_TYPE, PAYMENT_TYPE_TYPE, PAYMENT_TYPE_VALUES, PHONE_TYPE, PRICE_TYPE, REQUEST_NUMBER_TYPE, STATUS_TYPE, SUBMIT_TYPE_TYPE, SUBMIT_TYPE_VALUES, TEXT_TYPE } from '../../../../../../assets/utils/constants';
import { formatAddress, getAvailableStatuses, hasPermission, parsePermissions, parsePhone, parsePrice, parseRequestNumber, parseStatus } from '../../../../../../assets/utils/utils';

import { motion } from 'framer-motion'
import './InfoBox.css';
import { useContext, useEffect, useRef, useState } from 'react';
import SubmitActionPopup from '../../../../SubmitActionPopup/SubmitActionPopup';
import mainApi from '../../../../../../assets/api/MainApi';
import { UserContext } from '../../../../../../assets/contexts/userContext';
import { OrderConfigContext } from '../../../../../../assets/contexts/orderConfigContext';
import { PERMISSIONS, RBAC_ORDERS } from '../../../../../../assets/utils/permissions_rbac';

const EXEMPLE_CONFIG = {
    payments: {
        "manager": {
            "limit": "1000000",
            "statuses": [
                "new",
                "in_work",
                "paid",
                "ready",
                "done",
                "cancelled"
            ],
            "status_changes": {
                "in_work": [
                    "new"
                ],
                "paid": [
                    "new",
                    "in_work"
                ],
                "ready": [
                    "new",
                    "in_work",
                    "paid"
                ],
                "cancelled": [
                    "new",
                    "in_work",
                    "paid",
                    "ready"
                ],
                "done": [
                    "new",
                    "in_work",
                    "paid",
                    "ready"
                ]
            }
        },
        "yookassa": {
            "limit": "250000",
            "statuses": [
                "new",
                "paid",
                "in_work",
                "ready",
                "done",
                "cancelled"
            ],
            "status_changes": {
                "in_work": [
                    "paid"
                ],
                "ready": [
                    "in_work"
                ],
                "cancelled": [
                    "paid",
                    "in_work",
                    "ready"
                ],
                "done": [
                    "in_work",
                    "ready"
                ]
            }
        },
    },
}

const dropdownVariants = {
    open: {
        clipPath: "inset(0% 0% 0% 0% round 8px)",
        transition: {
            type: "spring",
            bounce: 0,
            duration: 0.5,
            delayChildren: 0.2,
            staggerChildren: 0.05,
        },
    },
    closed: {
        clipPath: "inset(0 90% 90% 10% round 8px)",
        transition: {
            type: "spring",
            bounce: 0,
            duration: 0.3,
        },
    },
};

const itemVariants = {
    open: {
        opacity: 1,
        y: 0,
        transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

function InfoBox({
    title,
    data_map,
    data,
    key_name,
    setOrder
}) {
    const config = useContext(OrderConfigContext)
    const [isSelectOpen, setSelectOpen] = useState(false)
    const dropdownRef = useRef(null)
    const { user } = useContext(UserContext);
    const hasEditPermission = hasPermission(parsePermissions(user), [RBAC_ORDERS[PERMISSIONS.EDIT]])


    console.log({ config })
    const AVALIBLE_STATUSES = data && key_name === 'main_data' && config ? getAvailableStatuses({
        config: config,
        order: data,
    })[data.status] : null

    const [isSubmitOpen, setSubmitOpen] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState(undefined)

    function handleSelectStatus(status) {
        setSelectedStatus(status)
        setSubmitOpen(true)
    }

    function handleCloseSubmit() {
        setSelectOpen(false)
        setSubmitOpen(false)
        setTimeout(() => {
            setSelectedStatus(undefined)
        }, 300);
    }

    const [isSubmitPreloader, setSubmitPreloader] = useState(false)

    function hendleSubmit() {
        if (isSubmitPreloader || !user) return

        const shop_id = user.default_shop._id;
        setSubmitPreloader(true)
        mainApi.editStatus({ shop_id, _id: data._id, status: selectedStatus, payment_type: data.payment_type })
            .then((res) => {
                console.log(res)
                setOrder(prevValue => ({
                    ...prevValue,
                    status: selectedStatus,
                }))
                handleCloseSubmit()
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setSubmitPreloader(false)
            })
    }

    useEffect(() => {
        function handleOutsideClickClose(evt) {
            if (
                isSelectOpen &&
                dropdownRef.current &&
                !dropdownRef.current.contains(evt.target)
            ) {
                setSelectOpen(false)
                setSubmitOpen(false)
                setTimeout(() => {
                    setSelectedStatus(undefined)
                }, 300);
            }
        }

        document.addEventListener("mousedown", handleOutsideClickClose);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClickClose);
        };
    });

    return (
        <div className='info-box'>
            {data && key_name === 'main_data' && hasEditPermission && AVALIBLE_STATUSES && AVALIBLE_STATUSES.length > 0 ?
                <SubmitActionPopup isSubmitPreloader={isSubmitPreloader} hendleSubmit={hendleSubmit} hendleClose={handleCloseSubmit} isOpen={isSubmitOpen} text={selectedStatus ? `Вы уверены что хотите изменить статус заказа ${parseRequestNumber(data.ai_id)} на "${parseStatus(selectedStatus)}"` : ''} submitText={'Изменить статус'} cencelText={'Отменить'} />
                :
                null
            }
            <p className='info-box__title'>{title}</p>
            <div className='info-box__infos'>
                {data_map && data_map.length > 0 ?
                    data_map.map((item, i) => (
                        <div className='info-box__info' key={`${key_name}_info-box__info_${i}`}>
                            <p className='info-box__info-title'>{item.title}</p>
                            {item.type === TEXT_TYPE ?
                                <p className='info-box__info-value'>{data[item.key]}</p>
                                :
                                null
                            }
                            {item.type === PHONE_TYPE ?
                                <a className='info-box__info-value info-box__info-value_link' rel="noreferrer" href={`tel:+${data[item.key]}`} >{parsePhone(data[item.key])}</a>
                                :
                                null
                            }
                            {item.type === EMAIL_TYPE ?
                                <a className='info-box__info-value info-box__info-value_link' rel="noreferrer" href={`mailto:${data[item.key]}`} >{data[item.key]}</a>
                                :
                                null
                            }
                            {item.type === SUBMIT_TYPE_TYPE ?
                                <p className='info-box__info-value'>{SUBMIT_TYPE_VALUES[data[item.key]]}</p>
                                :
                                null
                            }
                            {item.type === PAYMENT_TYPE_TYPE ?
                                <p className='info-box__info-value'>{PAYMENT_TYPE_VALUES[data[item.key]]}</p>
                                :
                                null
                            }
                            {
                                item.type === ADDRESS_TYPE ?
                                        <p className='info-box__info-value'>{formatAddress(data[item.key])}</p>
                                    :
                                    null
                            }

                            {item.type === REQUEST_NUMBER_TYPE ?
                                <p className='info-box__info-value'>{parseRequestNumber(data[item.key])}</p>
                                :
                                null
                            }

                            {item.type === DATE_WITH_FORMAT_TYPE ?
                                <p className='info-box__info-value'>{moment(data[item.key]).format(item.format)}</p>
                                :
                                null
                            }

                            {item.type === PRICE_TYPE ?
                                <p className='info-box__info-value'>{parsePrice(data[item.key], 'RUB')}</p>
                                :
                                null
                            }
                            {item.type === NUMBER_STRING_TYPE ?
                                <p className='info-box__info-value'>{Number(data[item.key]).toLocaleString()}</p>
                                :
                                null
                            }
                            {item.type === STATUS_TYPE ?
                                <div className='info-box__status'>
                                    <div className='info-box__status-current'>
                                        <p className='info-box__info-value'>{parseStatus(data[item.key])}</p>
                                        {AVALIBLE_STATUSES && AVALIBLE_STATUSES.length > 0 && data[item.key] !== 'cancelled' && hasEditPermission ?
                                            <button className='info-box__btn' type='button' onClick={() => {
                                                setSelectOpen(prevValue => !prevValue)
                                            }}>
                                                Изменить...
                                            </button>
                                            :
                                            null
                                        }
                                    </div>
                                    {hasEditPermission ?
                                        <div
                                            className={`platform-input__dropdown ${isSelectOpen ? "platform-input__dropdown_opened" : ""
                                                }`}
                                            ref={dropdownRef}
                                        >
                                            <motion.div
                                                className="platform-input__box-with-dropdown"
                                                initial={false}
                                                animate={isSelectOpen ? "open" : "closed"}
                                            >
                                                <motion.div
                                                    variants={dropdownVariants}
                                                    style={{ pointerEvents: isSelectOpen ? "auto" : "none" }}
                                                    className="platform-input__dropdown-container"
                                                >
                                                    <ul className="platform-input__dropdown-list">

                                                        {AVALIBLE_STATUSES && AVALIBLE_STATUSES.length > 0 ?
                                                            AVALIBLE_STATUSES.map((item, i) => (
                                                                <motion.li
                                                                    className={`platform-input_dropdown-item `}
                                                                    key={`info-box__status-drop-item-${i}`}
                                                                    variants={itemVariants}
                                                                >
                                                                    <button
                                                                        className="platform-input_dropdown-btn"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            // console.log(item, value);
                                                                            setSelectOpen(false);
                                                                            handleSelectStatus(item)
                                                                            // handleChange({
                                                                            //     target: {
                                                                            //         value: item,
                                                                            //         name: name,
                                                                            //     },
                                                                            // });
                                                                        }}
                                                                    >
                                                                        {parseStatus(item)}
                                                                    </button>

                                                                </motion.li>
                                                            ))
                                                            :
                                                            null
                                                        }
                                                    </ul>
                                                </motion.div>
                                            </motion.div>
                                        </div> : null}
                                </div>

                                :
                                null
                            }

                        </div>
                    ))
                    :
                    null
                }
            </div>
        </div>
    );
}

export default InfoBox