import { Link } from "react-router-dom";
import "./ProductPreviewCard.css";
import {
  CATALOG_DRAFTS_ITEM_LINK,
  CATALOG_DRAFTS_LINK,
  CATALOG_MAIN_LINK,
  CATALOG_PRODUCTS_ITEM_LINK,
  CATALOG_PRODUCTS_LINK,
  PLATFORM_MAIN_LINK,
} from "../../../../../../../assets/utils/constants";
import { GoBackIcon } from "../../../../../../../assets/icons/controls/controls";
import { getMinPrice, parsePrice } from "../../../../../../../assets/utils/utils";

function ProductPreviewCard({ item, selectedProduct, handleProductSelect }) {
  const { options, name, files, price_data } = item;
  const optionValues =
    options?.length > 0 ? options[0].values.map((item) => item.value) : [];
  const price = item ? options && options.length === 1 && options[0].values.length > 0 ? getMinPrice(options[0].values) : price_data.price : null
  return (
    <div
      className={`product-preview ${selectedProduct?._id === item._id ? "product-preview_selected" : ""
        }`}
      onClick={() => handleProductSelect(item)}
    >
      <img className="product-preview__img" src={files[0]?.urls?.orig} alt="" />
      <div className="product-preview__info">
        <p className="product-preview__title">{name}</p>
        <div className="product-preview__info-box">
          {optionValues.length > 0 ? (
            <p className="product-preview__text product-preview__text_type_opacity">
              {optionValues.join(` • `)}
            </p>
          ) : null}
          {price ? (
            <p className="product-preview__price">
              {options && options.length === 1 ? 'от ' : ''}{parsePrice(price, "RUB")}
            </p>
          ) : null}
        </div>
      </div>
      <Link
        className="product-preview__link"
        to={`${PLATFORM_MAIN_LINK}/${CATALOG_MAIN_LINK}/${item?.is_draft ? CATALOG_DRAFTS_LINK : CATALOG_PRODUCTS_LINK
          }/${item?.is_draft ? CATALOG_DRAFTS_ITEM_LINK : CATALOG_PRODUCTS_ITEM_LINK
          }/${item._id}`}
      >
        <GoBackIcon
          fillClassName="product-preview__arrow-icon"
          strokeClassName="product-preview__icon-stroke"
        />
      </Link>
    </div>
  );
}

export default ProductPreviewCard;
