import { useNavigate } from "react-router-dom"
import PageHeading from "../../../PageHeading/PageHeading"
import ItemForm from "../../../ItemForm/ItemForm"

export function PagesAndFilesMobileRoutePage({
    title,
    goBackActive,
    children,
    actions,
}) {

    const navigate = useNavigate()

    return (
        <div className='pages-and-files__content'>
            <PageHeading
                className={'pages-and-files__heading'}
                title={title}
                goBack={goBackActive ? {
                    onClick: () => { navigate(-1) },
                } : undefined}
                actions={actions}
            />
            <ItemForm
                containerClassName={'pages-and-files__item-from-container'}
            >
                {children}
            </ItemForm>
        </div>
    )
}