
import { Navigate, Route, Routes } from 'react-router-dom';
import './Catalog.css';
import { CATALOG_CATEGORY_LINK, CATALOG_DRAFTS_LINK, CATALOG_IMPORT_LINK, CATALOG_INDEX_LINK, CATALOG_MAIN_LINK, CATALOG_PRODUCTS_LINK, PLATFORM_CATALOG_LINKS, PLATFORM_MAIN_LINK } from '../../../assets/utils/constants';
import Import from './Import/Import';
import Drafts from './Drafts/Drafts';
import Products from './Products/Products';
import Categories from './Categories/Categories';
import { filterLinksForUserRBAC, getDefaultLinkForUserRBAC } from '../../../assets/utils/utils';
import { useContext } from 'react';
import { UserContext } from '../../../assets/contexts/userContext';

function Catalog() {
    const { user } = useContext(UserContext)
    const LINKS_BY_USER_RBAC = user && user.default_shop && user.default_shop.role ? filterLinksForUserRBAC(user.default_shop.role.permissions, [PLATFORM_CATALOG_LINKS]) : null
    const DEFAULT_LINK_BY_USER_RBAC = PLATFORM_MAIN_LINK + getDefaultLinkForUserRBAC(LINKS_BY_USER_RBAC)


    return (
        <div className='catalog'>
            <Routes>
                <Route index element={
                    <Navigate to={DEFAULT_LINK_BY_USER_RBAC} />
                }>
                </Route>
                <Route path={`${CATALOG_CATEGORY_LINK}/*`} element={
                    <Categories
                    />
                }>
                </Route>
                <Route path={`${CATALOG_IMPORT_LINK}/*`} element={
                    <Import />
                }>
                </Route>
                <Route path={`${CATALOG_DRAFTS_LINK}/*`} element={
                    <Drafts />
                }>
                </Route>
                <Route path={`${CATALOG_PRODUCTS_LINK}/*`} element={
                    <Products />
                }>
                </Route>
            </Routes>
        </div>
    );
}

export default Catalog
