import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import EditFilter from "../EditFilter/EditFilter";
import Toggle from "../../../../Toggle/Toggle";
import "./RangeFilter.css";
import FilterControls from '../FilterControls/FilterControls';
import FilterEditForm from '../FilterEditForm/FilterEditForm';
import { useContext, useState } from 'react';
import mainApi from '../../../../../assets/api/MainApi';
import { UserContext } from '../../../../../assets/contexts/userContext';
import { name } from 'dayjs/locale/ru';
import useAutoDismissError from '../../../../../assets/hooks/useAutoDismissError';
import { parseApiError, parsePrice } from '../../../../../assets/utils/utils';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';

function RangeFilter({
    filter,
    handleToggleFilter,
    handleToggleEdit,
    handleSaveEditedFilter,
}) {
    const { user } = useContext(UserContext);
    const [preloaders, setPreloaders] = useState({
        save: false,
    })
    const [values, setValues] = useState(filter)

    function handleToggleClick() {
        handleToggleFilter({ filter })
    }

    function handleEditClick() {
        handleToggleEdit({ _id: filter._id })
    }

    function handleCancelEdit() {
        setValues(filter)
        handleToggleEdit({ _id: filter._id })
    }

    const [error, showError] = useAutoDismissError();
    function handleSaveEdit() {
        setPreloaders(prevValue => ({
            ...prevValue,
            save: true,
        }))
        const shop_id = user.default_shop._id;
        mainApi.editFilter({
            shop_id,
            name: values.name,
            is_active: values.is_active,
            _id: filter._id,
        })
            .then((res) => {
                console.log(res)
                handleSaveEditedFilter({
                    _id: filter._id,
                    name: values.name,
                    is_active: values.is_active,
                })
            })
            .catch((err) => {
                console.log(err)
                showError(parseApiError(err))
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    save: false,
                }))

            })

    }

    return (
        <motion.div className={`range-filter ${filter._is_edit_active ? 'range-filter_type_edit' : ''}`}>

            <div className="range-filter__heading">
                <p className="range-filter__name">{filter.name} <span className='range-filter__name-type'>(Диапазон)</span></p>
                <FilterControls
                    handleEditClick={handleEditClick}
                    editActive={filter._is_edit_active}
                    isToggleActive={filter.is_active}
                    handleToggleClick={handleToggleClick}
                    is_special={filter.is_special}
                    handleCancelEdit={handleCancelEdit}
                    save_inavtive={!(values.name && values.name !== filter.name)}
                    handleSaveEdit={handleSaveEdit}
                    save_preloader_active={preloaders.save}
                />
            </div>
            <p className='range-filter__value'>от {parsePrice(filter.features.lte, 'RUB')} до {parsePrice(filter.features.gte, 'RUB')}</p>
            <AnimatePresence>
                <ErrorMessage error={error} />
                {filter._is_edit_active && (
                    <motion.div
                        initial={{ opacity: 0, height: 0, y: -20, }}
                        animate={{ opacity: 1, height: 'auto', y: 0, }}
                        exit={{ opacity: 0, height: 0, y: -20, }}
                        transition={{ duration: 0.3 }}
                        className='range-filter__form'
                    >
                        <FilterEditForm
                            values={values}
                            setValues={setValues}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
}

export default RangeFilter;
