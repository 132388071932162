import { useEffect } from 'react';



import './AdaptivePopup.css';
import { useWindowSize } from '../../assets/utils/utils';
import DesktopPopup from '../DesktopPopup/DesktopPopup';
import MobilePopup from '../MobilePopup/MobilePopup';

function AdaptivePopup({ isOpen, children, handleClose, popupName }) {
    const { width } = useWindowSize()

    if (width >= 880) {
        return (
            <DesktopPopup
                {... { isOpen, children, handleClose, popupName }}
            />
        )
    } else {
        return (
            <MobilePopup
                {... { isOpen, children, handleClose, popupName }}
            />
        )
    }

}

export default AdaptivePopup;