import { AnimatePresence, motion } from "framer-motion";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import loader from '../../../assets/images/header/loader.webp'

import './ShopPreloader.css'
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

const ShopPreloader = ({ isOpen, error }) => {
    const animationVariants = {
        hidden: {
            opacity: 0,
            scale: 0.95,
            transition: { type: "spring", stiffness: 300, damping: 24 },

        },
        visible: {
            opacity: 1,
            scale: 1,
            transition: { type: "spring", stiffness: 300, damping: 24, duration: 0.5 }
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="shop-preloader shop-preloader_visible"
                    variants={animationVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                >
                    <img className="shop-preloader__img" src={loader} alt="" />
                    {!error ?
                        <MiniPreloader isLinkColor={true} />
                        : null}
                    <ErrorMessage error={error} />
                    <p className="shop-preloader__text">Мгновение, обновляем ваш магазин!</p>
                </motion.div>
            )}
        </AnimatePresence>
    );
};


export default ShopPreloader
