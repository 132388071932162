import { useContext, useEffect, useState } from 'react';
// import SubscribeCard from '../SubscribeCard/SubscribeCard';
import './SimpleSubscription.css';

import { UserContext } from '../../../../../assets/contexts/userContext';
import mainApi from '../../../../../assets/api/MainApi';
import SimpleSubsctiptionCard from './SimpleSubsctiptionCard/SimpleSubsctiptionCard';
import Button from '../../../../Button/Button';
import { PLATFORM_MAIN_LINK, SUBSCRIPTIONS_MAIN_LINK, SUBSCRIPTIONS_PLATFROM_LINK } from '../../../../../assets/utils/constants';


function SimpleSubscription() {

    const { user } = useContext(UserContext)
    const { default_shop } = user
    const current_subscription = default_shop?.subscriptions?.main


    const [subscriptions, setSubscriptions] = useState(null)

    useEffect(() => {
        mainApi.getSubscrtiptionsWithAuth({ type: 'simple', shop_id: default_shop._id })
            .then((res) => {
                console.log(res)
                setSubscriptions(res.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }, [])

    console.log(current_subscription)



    return (
        <div className="simple-subscription">
            <div className='simple-subscription__titles-box'>
                <div className='simple-subscription__title-with-logo'>
                    <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M35.5 69.625C54.3467 69.625 69.625 54.3467 69.625 35.5C69.625 16.6533 54.3467 1.375 35.5 1.375C16.6533 1.375 1.375 16.6533 1.375 35.5C1.375 54.3467 16.6533 69.625 35.5 69.625Z" fill="#2BBC5D" stroke="#2BBC5D" stroke-width="1.4734" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M37.6707 42.6053H43.6007V16H37.6707V42.6053ZM27.4476 42.6053H33.3776V16H27.4476V42.6053ZM19.251 46.0574L21.5661 44.9067C22.4824 44.4638 23.1081 44.1989 23.5905 44.1989C24.3615 44.1989 24.7954 44.7307 25.4232 45.4386C27.0621 47.2526 30.005 49.9988 35.501 49.9988C40.997 49.9988 43.9399 47.2545 45.5788 45.4386C46.2065 44.7307 46.6405 44.1989 47.4115 44.1989C47.8939 44.1989 48.5195 44.4638 49.4359 44.9067L51.751 46.0574C47.7001 52.8746 41.6248 55 35.501 55C29.3772 55 23.3019 52.8746 19.251 46.0574Z" fill="white" />
                    </svg>
                    <p className='simple-subscription__title'>Специальная цена для клиентов <span className='simple-subscription__title_sellavi'>sellavi</span></p>
                </div>
                <p className='simple-subscription__subtitle'>Для магазинов, переходящих с Sellavi, мы предлагаем эксклюзивную подписку по специальной цене.</p>
            </div>
            {subscriptions && subscriptions.length > 0 ? <SimpleSubsctiptionCard subscriptions={subscriptions} current_subscription={current_subscription} /> : null}

        </div>
    );
}

export default SimpleSubscription;