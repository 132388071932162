import { NavLink } from 'react-router-dom';
import {
  StringOrNothingFormater,
  getMinPrice,
  parsePrice,
} from '../../../../assets/utils/utils';
import SelectCheckbox from '../../SelectCheckbox/SelectCheckbox';
import './ProductCard.css';
import { useState } from 'react';
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';

function ProductCard({
  isSelectable,
  handleSelect,
  item,
  isAllSelected,
  keyValue,
  selectedIncludeProducts,
  selectedExcludeProducts,
  style,
  is_order_item,
  withMoreBtn,
  isPreloaderVisible,
  onMoreClick,
}) {
  const { options, name, description, files, price_data } = item;
  const [isActive, setIsActive] = useState(undefined);

  const is_selected = isSelectable
    ? isAllSelected
      ? selectedExcludeProducts.filter((item2) => item2 === item._id).length ===
      0
      : selectedIncludeProducts.filter((item2) => item2 === item._id).length !==
      0
    : null;

  const price = is_order_item ? item.price : item ? options && options.length === 1 && options[0].values.length > 0 ? getMinPrice(options[0].values) : price_data.price : null

  return (
    <>
      {is_order_item ? (
        <div
          className={`product-card__card product-card__card_type_no-selectable`}
          style={style ? style : null}
        >
          <div className="product-card__info-container">
            {item.item.files && item.item.files.length !== 0 ? (
              <img
                className="product-card__img"
                src={item.item.files[0]?.urls?.orig}
                alt=""
              />
            ) : (
              <div className="product-card__img product-card__img-placeholder" />
            )}
            <div className="product-card__info">
              <div className="product-card__name-info">
                <h5 className="product-card__title">
                  {StringOrNothingFormater(item.item.name)}
                </h5>
                <p className="product-card__description">
                  {StringOrNothingFormater(item.item.description)}
                </p>
                <div className="product-card__infos">
                  <p className="product-card__info">
                    Колличество: {Number(item.amount).toLocaleString()} шт.
                  </p>
                  <p className="product-card__info">
                    Уникальный ID: {item.item.unique_id}
                  </p>
                  {options && options.length > 0
                    ? options.map((option, option_i) => (
                      <p
                        className="product-card__info"
                        key={`product-card__info_option_${option_i}`}
                      >
                        {option.name}: {option.value}
                      </p>
                    ))
                    : null}
                </div>
              </div>
              {/* {options && options.length > 0 ? (
                <div className="product-card__options">
                  {options[0].values.map((option_item, option_i) => (
                    <div
                      className="product-card__option"
                      key={keyValue + `product-card__option_${option_i}`}
                    >
                      <p
                        className={`product-card__option-value ${
                          option_item.in_stock &&
                          Number(option_item.in_stock) === 0
                            ? "product-card__option-value_type_no-in-stock"
                            : ""
                        }`}
                      >
                        {option_item.value}
                        {option_item.in_stock ? (
                          <span className="product-card__option-value-amount">
                            :{option_item.in_stock}
                          </span>
                        ) : null}
                      </p>
                    </div>
                  ))}
                </div>
              ) : null} */}
            </div>
          </div>
          <p className="product-card__price">
            {price ? parsePrice(price, 'RUB') : ''}
          </p>
        </div>
      ) : (
        <div
          className="product-card"
          style={
            style
              ? withMoreBtn
                ? { ...style, height: style.height + 24 }
                : style
              : null
          }
        >
          <NavLink
            to={`item/${item._id}`}
            className={({ isActive }) => {
              setIsActive(isActive); // Update state
              return `product-card__card ${!isSelectable ? 'product-card__card_type_no-selectable' : ''
                } ${isActive ? 'product-card__card_type_main-selected' : ''}`;
            }}
            onClick={() => {
              if (isActive) handleSelect({ type: 'item', item: item });
            }}
          >
            {isSelectable && handleSelect && isActive !== undefined ? (
              <SelectCheckbox
                isSelected={is_selected}
                toggleSelect={() => {
                  handleSelect({ item: item });
                }}
                isColorInvert={isActive}
              />
            ) : null}
            <div className="product-card__info-container">
              {files && files.length !== 0 ? (
                <img
                  className="product-card__img"
                  src={files[0]?.urls?.orig}
                  alt=""
                />
              ) : (
                <div className="product-card__img product-card__img-placeholder" />
              )}
              <div className="product-card__info">
                <div className="product-card__name-info">
                  <h5 className="product-card__title">
                    {StringOrNothingFormater(name)}
                  </h5>
                  <p className="product-card__description">
                    {StringOrNothingFormater(description)}
                  </p>
                </div>
                {options && options.length > 0 ? (
                  <div className="product-card__options">
                    {options[0].values.map((option_item, option_i) => (
                      <div
                        className="product-card__option"
                        key={keyValue + `product-card__option_${option_i}`}
                      >
                        <p
                          className={`product-card__option-value ${option_item.in_stock &&
                            Number(option_item.in_stock) === 0
                            ? 'product-card__option-value_type_no-in-stock'
                            : ''
                            }`}
                        >
                          {option_item.value}
                          {option_item.in_stock || option_item.in_stock === 0 ? (
                            <span className="product-card__option-value-amount">
                              :{option_item.in_stock}
                            </span>
                          ) : null}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            <p className="product-card__price">
              {price ? `${options && options.length === 1 ? 'от ' : ''}${parsePrice(price, 'RUB')}` : ''}
            </p>
          </NavLink>

          {withMoreBtn ? (
            <button
              className={`products-card__more-btn ${isPreloaderVisible ? 'products-card__more-btn_disabled' : ''
                }`}
              onClick={onMoreClick}
              type="button"
            >
              {!isPreloaderVisible ? (
                <p className="products-card__more-btn-text">Загрузить ещё</p>
              ) : (
                <span className="products-card__preloader">
                  <MiniPreloader />
                </span>
              )}
            </button>
          ) : null}
        </div>
      )}
    </>
  );
}

export default ProductCard;
